import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Button, Checkbox, Divider, Popper, Step, StepContent, StepLabel, Stepper, styled, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import cn from "classnames";
import { t } from "i18next";
import moment, { Moment } from 'moment';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessageType } from '../../../../core/components/dialog/dialog-message';
import { getDialogMessage } from "../../../../core/components/dialog/store/dialogMessage.store";
import { getCustomerSettings } from "../../../../core/store/customer/settings-customer/settings-customer.action";
import { AddUserReservation, getAvailableRoomsAndShiftsByDate, getAvailableRoomSeats } from '../../../../core/store/room/user-room/user-room.actions';
import { RootState } from '../../../../core/store/store';
import { getUserReservations, getUsersUsrGroups } from "../../../../core/store/users/users.actions";
import { RoomSeat } from "../../../../models/room";
import { Shift } from "../../../../models/shift";
import style from "./Quick-reservation-stepper.module.scss";
import { getCustomerSettingsProfiles } from '../../../../core/store/customer/settings-profiles-customer/settings-profiles-customer.action';

interface RoomOption {
  room: string;
  siteName: string;
}

const QuickReservationStepper: React.FC = () => {
    const { availableRoomsByDate, userSites, availableRoomSeats } =  useSelector((state: RootState) => state.room.userRoom);
    const { user } = useSelector((state: RootState) => state.auth)
    const [shifts, setShifts] = React.useState<Shift[]>([]);
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedRoom, setSelectedRoom] = React.useState<RoomOption | null>(null);
    const [isShared, setIsShared] = React.useState<boolean>(false)
    const [selectedShift, setSelectedShift] = React.useState<Shift[]>([]);
    const [date, setDate] = React.useState<Moment>(null);
    const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
    const checkedIcon = <CheckBoxIcon fontSize="medium" />;
    const [roomOptions, setRoomOptions] = React.useState<RoomOption[]>([]);
    const [sharedSeat, setSharedSeat] = React.useState<RoomSeat[]>([]);
    const [selectedSeat, setSelectedSeat] = React.useState<RoomSeat | null>(null);
    const { customerSettings } = useSelector((state:RootState)=> state.customerSettings);
    const { customerSettingsProfiles } = useSelector((state:RootState)=> state.customerSettingsProfiles);
    const { usersusrgroups } = useSelector((state: RootState) => state.users);
    const [dayLimit, setDayLimit] = React.useState<Moment>(moment().add(100,'y'));
    //Logica per la select all dei turni
    const [checkAll, setCheckAll] = React.useState<boolean>(false);

    const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckAll(event.target.checked);
      if (event.target.checked) {
        setSelectedShift(shifts);
      } else {
        setSelectedShift([]);
      }
    };
    const PopperStyledComponent = styled(Popper)(({ theme }) => ({
      border: `1px solid ${
        theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
      }`
    }));

    useEffect(()=>{
      dispatch(getUserReservations());
      dispatch(getCustomerSettings(user.fkcustomers))
      dispatch(getUsersUsrGroups())
      dispatch(getCustomerSettingsProfiles(user.fkcustomers))
    },[dispatch])

    useEffect(()=> {
      if(user.ispremium){
        if(customerSettingsProfiles != null && usersusrgroups != undefined){
          let setting = customerSettingsProfiles.find((csp) => csp.fkusrgroups == usersusrgroups.fkusrgroups);
          if(setting != undefined){
            setDayLimit(  setting.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                          setting.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                          moment().add(setting.limitdays,'d'));
          }else if(customerSettings != null){
            setDayLimit(customerSettings.limitisactive ?
                    customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                    customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                           moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
          }else{
            setDayLimit(moment().add(100,'y'));
          }
        }else if(customerSettings != null){
          setDayLimit(customerSettings.limitisactive ?
                  customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                  customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                         moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
        }else{
          setDayLimit(moment().add(100,'y'));
        }
      }else{
        setDayLimit(moment().add(100,'y'));
      }
    },[customerSettings,customerSettingsProfiles,usersusrgroups])

    const handleNext = () => {
      if(activeStep === 0){
        setSelectedRoom(null);
        setIsShared(false);
        setSharedSeat([]);
        setRoomOptions([]);
        dispatch(getAvailableRoomsAndShiftsByDate(date))
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const getShiftsConcat = () =>{
      let label = "";
      selectedShift.forEach(s => {
        if(selectedShift.length === 1){
          label += s.shiftname;
        } else{
          if(selectedShift.indexOf(s) === (selectedShift.length -1)){
            label += s.shiftname;
          } else{
            label += s.shiftname+", ";
          }
        }
      });
      return label;
    }

    const onReserve = () => {
      if(selectedShift.length > 0 && date != null) {
        let message : DialogMessageType = {
          dialogMsg : (<div>{moment(date).format("DD/MM/YYYY")}<br/> {selectedRoom.room} - {getShiftsConcat()} {user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? "- " + selectedSeat.seatname : ""}</div>),
          dialogTitle : t("Reservation summary"),
          dialogAcceptButton: t("Confirm"),
          dialogDismissButton: t("Cancel"),
          isOpen : true,
          onDismiss: () => {},
            onAccept: () => {
              selectedShift.forEach(s => dispatch(AddUserReservation(s.pkshifts, date, s.fkrooms, 2, user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? selectedSeat.pkroomseats : 0)));
              handleNext();
          }
        }
        dispatch(getDialogMessage(message))
      }
    }

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (newDate: Moment) => {
      setDate(newDate);
    };

    const handleSelectShiftComplete = () => {
      dispatch(getAvailableRoomSeats(encodeURIComponent(availableRoomsByDate.filter((r) => r.roomname === selectedRoom.room)[0].shifts.filter((s) => selectedShift.includes(s)).map(s => s.pkshifts).join(',')), availableRoomsByDate.find(r => r.roomname === selectedRoom.room).pkrooms, date))
      handleNext()
    }

    //MAP ROOMS FOR AUTOCOMPLETE
    useEffect(() => {
      let tmpRoomOptions:RoomOption[] = [];

      userSites.forEach((us) => {
        if(us.rooms && us.rooms.length > 0 ){
          availableRoomsByDate.filter((r)=> r.fksites === us.pksites).forEach((r) => {
            tmpRoomOptions.push({room:r.roomname, siteName: us.sitename});
          });
        }
      });
      setRoomOptions(tmpRoomOptions)
    },[availableRoomsByDate, userSites])

    useEffect(() => {
      if(availableRoomsByDate.length > 0 && selectedRoom !== null && selectedRoom.room !== "") {
        setSelectedShift([]);
        setShifts(availableRoomsByDate.find(r=>r.roomname === selectedRoom.room).shifts);
      }
    },[selectedRoom, availableRoomsByDate, dispatch])

    const handleReset = () => {
      setActiveStep(0)
      setDate(null)
      setSelectedRoom(null)
      setIsShared(false)
      setSharedSeat([])
      setSelectedShift([])
      setCheckAll(false)
      setSelectedSeat(null)
    }

    return (
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>
              {date !== null ? moment(date).format("DD/MM/YYYY") : t("When")}
            </StepLabel>
            <StepContent>
              <Box className={cn(style["stepperBox"])}>
                <div className={cn(style["date-step"])}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      className="w-100"
                      label={t("Reservation date")}
                      inputFormat="DD/MM/yyyy"
                      value={date}
                      onChange={handleChange}
                      minDate={moment()}
                      maxDate={dayLimit}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <Button
                    disabled={date === null}
                    variant="contained"
                    onClick={handleNext}
                    className={cn(style["button"])}
                  >
                    {t('Continue')}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              {selectedRoom != null ? selectedRoom.room : t("Where")}
            </StepLabel>
            <StepContent>
              <Box className={cn(style["stepperBox"])}>
                <Autocomplete
                  value={selectedRoom}
                  onChange={(e: any, newValue: RoomOption | null) => {
                    setSelectedRoom(newValue);
                    setSharedSeat([{pkroomseats: 0, seatname: newValue.room ,coordinates: '0 0 0 0', available: true}]);
                    setIsShared(availableRoomsByDate.find((r) => newValue.room === r.roomname).isshared);
                  }}
                  disablePortal
                  fullWidth
                  options={roomOptions}
                  groupBy={(option) => option.siteName}
                  getOptionLabel={(option) => (option as RoomOption).room}
                  renderInput={(params) => <TextField {...params} label={t("Rooms")} />}
                />
                <Button
                  onClick={handleBack}
                  className={cn(style["button"])}
                >
                  {t('Back')}
                </Button>
                <Button
                  disabled={(selectedRoom !== null && selectedRoom.room === "") || selectedRoom === null}
                  variant="contained"
                  onClick={handleNext}
                  className={cn(style["button"])}
                >
                  {t('Continue')}
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>
              {selectedShift.length > 0 ? getShiftsConcat() : t("Which shift")}
            </StepLabel>
            <StepContent>
              <Box className={cn(style["stepperBox"])}>
                <Autocomplete
                  value={selectedShift}
                  onChange={(e, value)=> setSelectedShift(value as Shift[])}
                  multiple={true}
                  options={shifts}
                  getOptionLabel={(option) => (option as Shift).shiftname}
                  PopperComponent={(param) => (
                    <PopperStyledComponent {...param}>
                    <>
                      <Box
                        sx={{
                          backgroundColor: "white",
                          height: "45px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <Checkbox
                          checked={checkAll}
                          onChange={checkAllChange}
                          id="check-all"
                          sx={{ marginRight: "8px" }}
                          onMouseDown={(e) => e.preventDefault()}
                        />
                        {t("Reserve all")}
                      </Box>
                      <Divider />
                      <Box {...param} />
                      </>
                    </PopperStyledComponent>
                  )}
                  renderInput={(params) => <TextField {...params} label={t("Shifts")} />}
                  fullWidth
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.shiftname}
                    </li>
                  )}
                />
                <Button
                  onClick={handleBack}
                  className={cn(style["button"])}
                >
                  {t('Back')}
                </Button>
                <Button
                  disabled={selectedShift.length === 0}
                  onClick={handleSelectShiftComplete}
                  variant="contained"
                  className={cn(style["button"])}
                  hidden={user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") < 0}>
                    {t('Continue')}
                </Button>
                <Button
                    disabled={(selectedRoom !== null && selectedRoom.room === "") || selectedRoom === null}
                    variant="contained"
                    onClick={onReserve}
                    className={cn(style["button"])}
                    hidden={user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0}
                  >
                    {t('Reserve a seat')}
                  </Button>
              </Box>
            </StepContent>
          </Step>
          {
            user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 &&
            <Step>
              <StepLabel>
                {t('Which seat')}
              </StepLabel>
              <StepContent>
                <Box className={cn(style["stepperBox"])}>
                  <Autocomplete
                    value={selectedSeat}
                    onChange={(e: any, newValue: RoomSeat | null) => {
                      setSelectedSeat(newValue);
                    }}
                    disablePortal
                    fullWidth
                    options={isShared ? sharedSeat : availableRoomSeats}
                    getOptionLabel={(option) => (option as RoomSeat).seatname}
                    renderInput={(params) => <TextField {...params} label={t("Seats")} />}
                  />
                  <Button
                    onClick={handleBack}
                    className={cn(style["button"])}
                  >
                    {t('Back')}
                  </Button>
                  <Button
                    disabled={(selectedRoom !== null && selectedRoom.room === "") || selectedRoom === null}
                    variant="contained"
                    onClick={onReserve}
                    className={cn(style["button"])}
                  >
                    {t('Reserve a seat')}
                  </Button>
                </Box>
              </StepContent>
            </Step>
          }
          <Step>
            <StepLabel>
                {t('Done')}
            </StepLabel>
            <StepContent>
              <p>{t("Reservation created successfully")}</p>
              <Button onClick={handleReset} hidden={activeStep !== (user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? 4 : 3)}>{t("Reserve again")}</Button>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    );
}

export default QuickReservationStepper

// dispatch(getAvailableRoomSeats(encodeURIComponent(shift.join()), availableRoomsByDate.find(r => r.roomname === room).pkrooms, reservationDate));