type Environment = {
  COOKIE_TOKEN_KEY: string;
  LANG: string;
  BASE_URL: string;
  API_URL: string;
  UID: string;
  UPN: string;
  VERSION: string;
};

const environment_dev: Pick<Environment, "API_URL" | "BASE_URL"> = {
  BASE_URL: "https://localhost:44387",
  API_URL: "https://localhost:44387/api",
};

const environment_test: Pick<Environment, "API_URL" | "BASE_URL"> = {
  BASE_URL: "https://justseat-backend-test.azurewebsites.net",
  API_URL: "https://justseat-backend-test.azurewebsites.net/api",
};

const environment_ms_test: Pick<Environment, "API_URL" | "BASE_URL"> = {
  BASE_URL: "https://justseat-test.azurewebsites.net",
  API_URL: "https://justseat-test.azurewebsites.net/api",
}

const environment_ms_prod: Pick<Environment, "API_URL" | "BASE_URL"> = {
  BASE_URL: "https://justseat.azurewebsites.net",
  API_URL: "https://justseat.azurewebsites.net/api",
}

const environment_prod: Pick<Environment, "API_URL" | "BASE_URL"> = {
  BASE_URL: "https://justseat-backend.azurewebsites.net",
  API_URL: "https://justseat-backend.azurewebsites.net/api",
};

function getEnvironment(): Pick<Environment, "API_URL" | "BASE_URL"> {
  console.log(process.env.REACT_APP_ENVIRONMENT)
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      return environment_prod;
    case "test":
      return environment_test;
    case "ms_test":
      return environment_ms_test;
    case "ms_prod":
      return environment_ms_prod;
    default:
      return environment_dev;
  }
}

const environment: Environment = {
  COOKIE_TOKEN_KEY: "tk",
  LANG: "lang",
  UID: "uid",
  UPN: "upn",
  VERSION: "2.12.5",
  ...getEnvironment(),
};

export default environment;
