import { Button, Dialog } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import cn from "classnames";
import css from "./dialog-message.module.scss";

export type DialogMessageType = {
  dialogTitle?: string,
  dialogDate?: string,
  dialogMsg: JSX.Element | string,
  dialogMsgStyle?: string,
  dialogSubmsg?: JSX.Element | string,
  dialogAcceptButton: string,
  dialogAcceptButtonStyle?: string,
  dialogCenterButtons?: boolean,
  dialogDismissButton?: string,
  dialogUnderActionsTitle?: string,
  dialogUnderActionsSub?: string,
  onAccept: () => void,
  onDismiss: () => void,
  isOpen: boolean
}


const DialogMessage: FC = () => {
  const {dialogMessage} = useSelector((state: RootState) => state.dialogMessage)
  const [open, setOpen] = useState(dialogMessage.isOpen);

  const handleClose = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    setOpen(dialogMessage.isOpen)
  },[dialogMessage])

  const handleAccept = () => {
    dialogMessage.onAccept()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div className={cn(css["dialog-container"])}>
        {dialogMessage.dialogTitle &&  
          <h2 className={cn(css["dialog-title"])}> {dialogMessage.dialogTitle} </h2>
        }
        
        <div className={cn(css["dialog-content"], (dialogMessage.dialogMsgStyle ? dialogMessage.dialogMsgStyle : ""))}>
          {dialogMessage.dialogDate && 
            <p className="p-0 m-0 fw-bold" >
              {dialogMessage.dialogDate}
            </p>
          }
          <div className="p-0 m-0">
            {dialogMessage.dialogMsg}
          </div>
          {dialogMessage.dialogSubmsg &&
            <div className={cn(css["submsg-container"])}>
              {dialogMessage.dialogSubmsg}
            </div>
          }
        </div>

        {/* BUTTONS (ACCEPT - DISMISS) */}
        <div className={cn(
          css["dialog-action"],
          "mt-1",
          (dialogMessage.dialogUnderActionsTitle ? "pb-3" : ""),
          (dialogMessage.dialogCenterButtons)?"justify-content-center": ""
        )}>
          {dialogMessage.dialogDismissButton && 
            <Button
              className={cn(css["dismiss-btn"]," dialog-btn dialog-dismiss")}
              onClick={handleClose}
            >
              {dialogMessage.dialogDismissButton}
            </Button>
          }
            <Button 
            className={cn(css["accept-btn"]," dialog-btn dialog-accept",(dialogMessage.dialogAcceptButtonStyle)?dialogMessage.dialogAcceptButtonStyle: "") }
            onClick={handleAccept}
          >
            {dialogMessage.dialogAcceptButton}
          </Button>
        </div>

        {/* Section after buttons */}
        {dialogMessage.dialogUnderActionsTitle && 
          <div className={cn(css["content-bottom"])}>
            <hr className={cn(css["divider"])} />
            <div className={cn(css["bottom-title"])}>
              {dialogMessage.dialogUnderActionsTitle}
            </div>
            <div className={cn(css["bottom-subtitle"])}>
              {dialogMessage.dialogUnderActionsSub}
            </div>
          </div>
        }
      </div>
    </Dialog>
  )
}

export default DialogMessage