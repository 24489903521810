import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import svg from "../../asset/img/whereabouts-img.svg";
import style from "./whereabouts.module.scss"
import cn from 'classnames'
import { Checkbox, FormControl, IconButton, ListItemText, MenuItem, TextField, Button, Accordion, AccordionDetails, AccordionSummary, SwipeableDrawer, CssBaseline, Modal  } from "@mui/material";
import { User } from "../../models/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/store/store";
import { getTenantUsers, getUsersReservationsByDateRange } from "../../core/store/users/users.actions";
import { Moment } from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faX } from "@fortawesome/pro-solid-svg-icons";
import { isMobile } from "react-device-detect"
import moment from "moment";
import { WhereaboutsFilter } from "../../models/whereabouts";
import { useForm } from "react-hook-form";
import { ControlledAutocomplete, ControlledDatePicker, ControlledMobileDatePicker } from "../../core/components/mui-input/MuiInput";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WhereaboutsTabs } from "./WhereaboutsTab";
import { GetAdminRoomsImage, getAdminSites, getAdminSitesChanged } from "../../core/store/room/administration-room/administration-room.actions";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface WhereaboutsForm{
    users: User[];
    fromDate: Moment;
    toDate: Moment;
}

const drawerBleeding = 50;

export const Whereabouts: FC = () =>{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ users, setUsers ] = useState<User[]>([]);
    const [ startDate, setStartDate ] = useState<Moment | null>(null);
    const [ endDate, setEndDate ] = useState<Moment | null>(null);
    const [ searchStarted, setSearchStarted ] = useState<boolean>(false);
    const [ days, setDays ] = useState<string[]>([]);
    const [ isMobileDrawerOpen, setIsMobileDrawerOpen ] = useState<boolean>(false);
    const [ isRoomImageModalOpen, setIsRoomImageModalOpen ] = useState<boolean>(false);
    const [ selectedRoomImage, setSelectedRoomImage ] = useState<string>("");

    const { tenantUsers, usersReservations } = useSelector((state:RootState)=> state.users);
    const { adminSites, imageHasLoaded, adminSitesChanged } = useSelector((state: RootState) => state.room.administrationRoom);

    const { control, handleSubmit }  = useForm<WhereaboutsForm>({
        mode: "onSubmit", 
        reValidateMode: "onSubmit",
    });
    
    // Get TenantUsers and adminSites (for roomsimage only)
    useEffect(()=>{
        (adminSites.length === 0)?dispatch(getAdminSites()): dispatch(getAdminSitesChanged(adminSites));
        dispatch(getTenantUsers());
    }, [dispatch]);

    //Manage to get adminRooms (for the roomsImages)
    useEffect(()=>{
        if(adminSites.length > 0 && !imageHasLoaded ){
            dispatch(GetAdminRoomsImage());
        }
        if(adminSitesChanged){
          dispatch(getAdminSites());
        }
    },[adminSites])


    const handleSearch = handleSubmit(data=>{
        if(data.users && data.users.length> 0 && data.fromDate && data.toDate){
            const castedFromDate = (data.fromDate as Moment);
            const castedToDate = (data.toDate as Moment);
            const filter: WhereaboutsFilter = {
                usersPk: data.users.map(u=>(u.pkusers)),
                dataDa: castedFromDate.format("YYYY-MM-DDTHH:mm:ss"),
                dataA: castedToDate.format("YYYY-MM-DDTHH:mm:ss")
            };
            dispatch(getUsersReservationsByDateRange(filter));
            setSearchStarted(true);
            setDays(getDaysBetweenDates(castedFromDate, castedToDate));
            
        }
    });

    function getDaysBetweenDates(startDate:Moment, endDate: Moment): string[] {
        const now = startDate.clone();
        const dates: string[] = [];

        while(now.isSameOrBefore(endDate)){
            dates.push(now.format("DD-MM-YYYY"));
            now.add(1,'days');
        }
        return dates;
    }

    const desktopSearchForm =  <>
        <ControlledAutocomplete 
            name="users"
            control={control}
            multiple
            value={users}
            onChange={(val)=>setUsers(val)}
            options={tenantUsers.filter(u=> u.isactive)}
            label={t("Who to look for")}
            getOptionLabel={(option)=>option.username}
            renderOption={(props, option, {selected})=>(
                <MenuItem key={option.pkusers} {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option.username} />
                </MenuItem>
            )}
            fullwidth
            disableCloseOnSelect
            limitTags={4}
            isOptionEqualToValue={(o,v)=> o.pkusers === v.pkusers}
            rules={{required: {value: true, message: t("Field Required")}}}
        />
        <ControlledDatePicker
            name="fromDate"
            control={control}
            label={t("From day")}
            value={startDate}
            onChange={(value)=> setStartDate(value as Moment)}
            renderInput={(params) => <TextField {...params} />}
            minDate={moment()}
            rules={{required: {value: true, message: t("Field Required")}}}
        />  
        <ControlledDatePicker
            name="toDate"
            control={control}
            label={t("To day")}
            value={endDate}
            onChange={(value)=> setEndDate(value as Moment)}
            renderInput={(params) => <TextField {...params} />}
            minDate={(startDate)?startDate:null}
            rules={{required: {value: true, message: t("Field Required")}}}
        />                  
        <IconButton onClick={handleSearch} className={cn(style["search-btn-desktop"])}>
            <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
        </IconButton>
    </>;

    const mobileSearchForm = <>
        <div className="row mb-4">
            <div className="col-12 d-flex justify-content-center">
                <ControlledAutocomplete 
                    name="users"
                    control={control}
                    multiple
                    value={users}
                    onChange={(val)=>setUsers(val)}
                    options={tenantUsers.filter(u=>u.isactive)}
                    label={t("Who to look for")}
                    getOptionLabel={(option)=>option.username}
                    renderOption={(props, option, {selected})=>(
                        <MenuItem key={option.pkusers} {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option.username} />
                        </MenuItem>
                    )}
                    fullwidth
                    disableCloseOnSelect
                    limitTags={1}
                    isOptionEqualToValue={(o,v)=> o.pkusers === v.pkusers}
                    rules={{required: {value: true, message: t("Field Required")}}}
                />  
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-12 d-flex justify-content-center">
                <FormControl className="w-100">
                    <ControlledMobileDatePicker
                        name="fromDate"
                        control={control}
                        label={t("From day")}
                        value={startDate}
                        onChange={(value)=> setStartDate(value as Moment)}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={moment()}
                        rules={{required: {value: true, message: t("Field Required")}}}
                    />  
                </FormControl>
            </div>  
        </div>
        <div className="row mb-4">
            <div className="col-12 d-flex justify-content-center">
                <FormControl className="w-100">
                    <ControlledMobileDatePicker
                        name="toDate"
                        control={control}
                        label={t("To day")}
                        value={endDate}
                        onChange={(value)=> setEndDate(value as Moment)}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={(startDate)?startDate:null}
                        rules={{required: {value: true, message: t("Field Required")}}}
                    />   
                </FormControl>
            </div>  
        </div>
        <div className="row mb-4">
            <div className="col-12 d-flex justify-content-center">
                <Button 
                    variant="contained" 
                    className="w-100" 
                    size="large"
                    onClick={handleSearch}
                >
                    {t("Search")}
                </Button>
            </div>  
        </div>
    </>

    return(
        <LocalizationProvider dateAdapter={AdapterMoment}>
        
        {/* DESKTOP HOMEPAGE */}
        {!isMobile && !searchStarted && 
        <div className="container-fluid mt-5 justify-content-center ">
            <div className="row mb-3">
                <div className="col-12 d-flex justify-content-center">
                    <img 
                        className={cn(style["img-desktop"])}
                        src={svg}
                        alt={t("Person sitting on a map marker")}
                    />
                </div>
            </div>
            <div className="row mb-0">
                <div className="col-12 d-flex justify-content-center">
                    <h2 className={cn(style["title"], "pb-0")}> {t("Whereabouts")} </h2>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 d-flex justify-content-center">
                    <h4> {t("Whereabout subtitle")} </h4>
                </div>
            </div>
            <div className={cn(style["pse-8-desktop"],"d-flex flex-row gap-3 w-100 justify-content-center align-items-center")}>
                {desktopSearchForm}
            </div>
        </div>}

        {/* DESKTOP SEARCH */}
        {!isMobile && searchStarted &&
        <>
            <div className="container-fluid mt-4 justify-content-center px-5 ">
                <div className="d-flex flex-column">

                    {/* SERACH BAR */}
                    <div className="d-flex flex-row gap-3 justify-content-center align-items-center mb-4">
                       {desktopSearchForm}
                    </div>

                    {/* RESULTS */}
                    {(usersReservations.length === 0)?
                        <p>{t("No results")}</p>
                    : usersReservations.map(ur=>(
                        <Accordion className="mb-3">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}                        
                            >
                                <h4 className={cn(style["title"], "px-2")}>{ur.user.username}</h4>
                            </AccordionSummary>
                            <AccordionDetails className="d-flex" style={{"maxHeight": "200px"}}>
                                <WhereaboutsTabs days={days} userReservation={ur} pkuser={ur.user.pkusers} setIsModalOpen={setIsRoomImageModalOpen} setRoomImage={setSelectedRoomImage} mobile={false} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
            <Modal
                open={isRoomImageModalOpen}
                onClose={()=>setIsRoomImageModalOpen(false)}
            >
                <div className={cn(style["desktop-modal-container"])}>
                    <div className="d-flex justify-content-end">
                        <IconButton onClick={()=>setIsRoomImageModalOpen(false)}>
                            <FontAwesomeIcon icon={faX} size="xs" />
                        </IconButton>
                    </div>
                    {selectedRoomImage.length > 0 ? 
                        <img src={`data:image/jpeg;base64,${selectedRoomImage}`} className="w-100" /> :
                        <p className={cn(style["mobile-modal-item"], "px-1")}>{t("No image")}</p>
                    }
                </div>
            </Modal>
        </>
        }

        {/* MOBILE HOMEPAGE */}
        {isMobile && !searchStarted &&
        <div className="container-fluid px-3 mt-5 justify-content-center">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <h2 className={cn(style["title"])}>{t("Whereabouts")}</h2>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 d-flex justify-content-center">
                    <h6 className="text-center">{t("Whereabout subtitle")}</h6>
                </div>
            </div>
            {mobileSearchForm}
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <img 
                        className={cn(style["img-mobile"])}
                        src={svg}
                        alt={t("Person sitting on a map marker")}
                    />
                </div>  
            </div>
            
        </div>
        }

        {/* MOBILE RESULTS */}
        {isMobile && searchStarted &&
        <>
        <div className="container-fluid px-3 mt-5 justify-content-center">
            {usersReservations.length === 0?
                <p>{t("No results")}</p>
            : usersReservations.map(ur=>(
                <Accordion className="mb-3">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}                        
                    >
                        <h4 className={cn(style["title"], "px-2")}>{ur.user.username}</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <WhereaboutsTabs days={days} userReservation={ur} pkuser={ur.user.pkusers} setIsModalOpen={setIsRoomImageModalOpen} setRoomImage={setSelectedRoomImage} mobile={true} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
        
        <Modal
            open={isRoomImageModalOpen}
            onClose={()=>setIsRoomImageModalOpen(false)}
        >
            <div className={cn(style["mobile-modal-container"])}>
                <div className="d-flex justify-content-end">
                    <IconButton onClick={()=>setIsRoomImageModalOpen(false)}>
                        <FontAwesomeIcon icon={faX} size="xs" />
                    </IconButton>
                </div>
                {selectedRoomImage.length > 0 ? 
                    <img src={`data:image/jpeg;base64,${selectedRoomImage}`} className="w-100" /> :
                    <p className={cn(style["mobile-modal-item"], "px-1")}>{t("No image")}</p>
                }
            </div>
        </Modal>

        <CssBaseline />

        <SwipeableDrawer
            className="whereabouts-drawer"
            anchor="bottom"
            open={isMobileDrawerOpen}
            onClose={()=>setIsMobileDrawerOpen(false)}
            onOpen={()=> setIsMobileDrawerOpen(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{keepMounted: true}}
        >
            <div className={cn(style["drawer-header"])} >
                <div className={cn(style["drawer-puller"])}/>
                <p className={cn(style["drawer-header-text"])}> {(!isMobileDrawerOpen)?t("Swipe up placeholder"):""} </p>
            </div>
            
            <div className={cn(style["drawer-content-container"])}>
                <div className="row mb-4 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                        <ControlledAutocomplete 
                            name="users"
                            control={control}
                            multiple
                            value={users}
                            onChange={(val)=>setUsers(val)}
                            options={tenantUsers.filter(u=>u.isactive)}
                            label={t("Who to look for")}
                            getOptionLabel={(option)=>option.username}
                            renderOption={(props, option, {selected})=>(
                                <MenuItem key={option.pkusers} {...props}>
                                    <Checkbox checked={selected} />
                                    <ListItemText primary={option.username} />
                                </MenuItem>
                            )}
                            fullwidth
                            disableCloseOnSelect
                            limitTags={1}
                            isOptionEqualToValue={(o,v)=> o.pkusers === v.pkusers}
                            rules={{required: {value: true, message: t("Field Required")}}}
                        />  
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-center">
                        <FormControl className="w-100">
                            <ControlledMobileDatePicker
                                name="fromDate"
                                control={control}
                                label={t("From day")}
                                value={startDate}
                                onChange={(value)=> setStartDate(value as Moment)}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={moment()}
                                rules={{required: {value: true, message: t("Field Required")}}}
                            />  
                        </FormControl>
                    </div>  
                </div>
                <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-center">
                        <FormControl className="w-100">
                            <ControlledMobileDatePicker
                                name="toDate"
                                control={control}
                                label={t("To day")}
                                value={endDate}
                                onChange={(value)=> setEndDate(value as Moment)}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={(startDate)?startDate:null}
                                rules={{required: {value: true, message: t("Field Required")}}}
                            />   
                        </FormControl>
                    </div>  
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <Button 
                            variant="contained" 
                            className="w-100" 
                            size="large"
                            onClick={handleSearch}
                        >
                            {t("Search")}
                        </Button>
                    </div>  
                </div>
            </div>
        </SwipeableDrawer>
        </>
        }
       </LocalizationProvider>
    );
}

