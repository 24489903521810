import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { faStar as starEmpty } from "@fortawesome/pro-regular-svg-icons";
import { IconName, IconPrefix, faChevronLeft, faChevronRight, faXmark, faStar as starFull } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Grid, IconButton, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from "classnames";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { closeDialogMessage, getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";
import { getCustomerSettings } from "../../core/store/customer/settings-customer/settings-customer.action";
import { getCustomerSettingsProfiles } from "../../core/store/customer/settings-profiles-customer/settings-profiles-customer.action";
import { getRoomseatsMap, getSeatsDialog } from "../../core/store/dialogs/dialogs.store";
import { AddRoomToFavorites, AddUserReservationWithoutSeat, GetNotAvailableSeatsNumber, GetReservationByWeek, GetUserRoomsImage, RemoveRoomFromFavorites, addDayReservation, changeRoomSeat, deleteUserReservation, getInfoCheckRoomIntersectingTimeSlot } from "../../core/store/room/user-room/user-room.actions";
import { getRoomSuccess } from "../../core/store/room/user-room/user-room.store";
import { RootState } from "../../core/store/store";
import { getUsersUsrGroups } from "../../core/store/users/users.actions";
import { Room, RoomSeat } from "../../models/room";
import { Shift } from "../../models/shift";
import style from "./reserve-seat.module.scss";

function ReserveSeat() {
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        backgroundColor: 'transparent',
        padding: '0'
      },
  });

  const [data, setData] = useState<Date>(new Date());
  const { userSites, roomReservations, selectedRoom: room, imageHasLoaded, selectedTab, checkRoomIntersectingTimeSlots } = useSelector((state: RootState) => state.room.userRoom);
  const { user } = useSelector((state: RootState) => state.auth);
  const [value, setValue] = useState<string>("");
  const [allRooms, setAllRooms] = useState<Room[]>([]);
  const { customerSettings } = useSelector((state:RootState)=> state.customerSettings);
  const { customerSettingsProfiles } = useSelector((state:RootState)=> state.customerSettingsProfiles);
  const { usersusrgroups } = useSelector((state: RootState) => state.users);
  const [dayLimit, setDayLimit] = useState<Moment>(moment().add(100,'y'));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ChangeWeekForward = () => {
    const date = moment(data, "DD/MM/YYYY").add(7, "d").toDate();
    setData(date);
  };

  const ChangeWeekBackards = () => {
    const date = moment(data, "DD/MM/YYYY").subtract(7, "d").toDate();
    setData(date);
  };


  /**
   * Callback che apre la dialog di avvenuta prenotazione e, solo se si è PREMIUM, permette la modifica della postazione
   * APPLICABILE SOLO ALLA PRENOTAZIONE SINGOLA
   * @param date
   * @param shift
   * @param seatName
   */
  const changeSeatCallback = (date: Moment, shift: Shift, roomSeat: RoomSeat) =>{
    //Calcolo il numero di prenotazioni effetuate nel turno selezionato
    const reservations = roomReservations
      .find(rr=>rr.pkshifts === shift.pkshifts).reservations
      .filter(r=>moment(r.reservationdate).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY"));

    const numAvailableSeats = room.roomseats - (reservations.length + 1); //+1 in quanto non contiene la prenotazione fatta ora

    //Controllo se l'utente è premium
    const isUserPremium = (user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 && user.ispremium);

    let message : DialogMessageType = {
      dialogAcceptButton: t("Close"),
      dialogCenterButtons: true,
      dialogMsgStyle: "pb-0",
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {} ,
      dialogMsg: <div className="text-center">

        {/* Icona stile sweetalert */}
        <div className={cn(style["sa"], "mb-0")}>
          <div className={cn(style["sa-success"])}>
            <div className={cn(style["sa-success-tip"])}></div>
            <div className={cn(style["sa-success-long"])}></div>
            <div className={cn(style["sa-success-placeholder"])}></div>
            <div className={cn(style["sa-success-fix"])}></div>
          </div>
        </div>

        {/* Titolo */}
        <p className="mb-0 fw-bold" style={{fontSize:"24px"}}>{t("Booking reserved")}</p>

        {/* Dettagli prenotazione */}
        <p className="mb-3">{t("Reservation details")}:</p>
        <div className="mb-3">
          <p className="mb-1"><b>{t("Date")}:</b> {moment(date).format("DD/MM/YYYY")} </p>
          <p className="mb-1"><b>{t("Shift")}:</b> {shift.shiftname}</p>
          {isUserPremium && <p className="mb-0"><b>{t("Seat")}:</b> {room.isshared ? room.roomname : roomSeat.seatname} </p>}
        </div>

        {/* Cambia postazione */}
        {
          numAvailableSeats > 0 &&
          isUserPremium &&
          <>
            <hr className="hr" />
            <p>
              {t("Change seat?")} <span
                onClick={() => openChangeSeatDialog(shift, date)}
                className={cn(style["link-text"])}
              >{t("Click here")}</span>
            </p>
            <hr className="hr" />
          </>
        }
      </div>
    }

    dispatch(getDialogMessage(message));
  }

  /**
   * SOLO PER UTENTI PREMIUM, permette il cambio della postazione della prenotazione appena effettuata
   * @param shift
   * @param date
   */
  const openChangeSeatDialog = (shift: Shift, date:Moment) =>{
    //Prima di cambiare posto chiudo la dialog di successo prenotazione
    dispatch(closeDialogMessage());

    console.log("Informazioni stanza: ", room)
    console.log("Informazioni utente: ", user)
    console.log("Functionality: ", user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0)
    console.log("Allegato mappa: ",  room.mapattachment)

    if(room.mapattachment) {
      dispatch(getRoomseatsMap({
        shift: shift,
        date: date,
        isOpen: true,
        onDismiss: () => {},
        onAccept: (newRoomSeat: RoomSeat, pkOldReservation: number) => {
          dispatch(changeRoomSeat("desktop", pkOldReservation, shift,date,room.pkrooms,newRoomSeat,moment(data)))
        },
      }))
    } else {
      dispatch(getSeatsDialog({
        isOpen: true,
        shift,
        selectedDate: date,
        startDate: moment(),
        variant: "desktop",
        room: room
      }));
    }
  }

  const openReserveDialog = async (date, shift?) => {
    dispatch(AddUserReservationWithoutSeat(shift.pkshifts, moment(date), shift.fkrooms, moment(data),"desktop", changeSeatCallback));
  };



  const openDeleteDialog = (date, shift) => {
    let dialog: DialogMessageType = {
      dialogTitle: t("Prenotation discard"),
      dialogMsg: (
        <p className="mb-0">
          {moment(date).format("DD/MM/YYYY")} <br /> {room.roomname} -{" "}
          {shift.shiftname} ({shift.starttime} - {shift.endtime}){" "}
        </p>
      ),
      dialogAcceptButton: t("Yes delete"),
      dialogDismissButton: t("No close"),
      dialogUnderActionsTitle: null,
      dialogUnderActionsSub: null,
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        deleteReservation(shift, date)
      },
    };
    dispatch(getDialogMessage(dialog));
  };


  const deleteReservation = (shifts, date) => {
    const pks: number[] = [];
    const currentRoomShift = roomReservations.find((rr) => rr.pkshifts === shifts.pkshifts);
    currentRoomShift.reservations.forEach((cs) => {
      if(moment(cs.reservationdate).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") && cs.fkshifts === shifts.pkshifts )
        pks.push(cs.pkreservations);
    });
    dispatch(deleteUserReservation(pks));
    const temp = moment(data, "DD/MM/YYYY").toDate();
    setData(temp);
  };

  function removeRoomFromFavorites(pkroom: number) {
    dispatch(RemoveRoomFromFavorites(user.pkusers, pkroom))
  }

  function addRoomToFavorites(pkroom: number) {
    dispatch(AddRoomToFavorites(user.pkusers, pkroom))
  }

  //Caricamento delle immagini all'interno di reserve-seat
  useEffect(()=>{
    if(!imageHasLoaded){
      dispatch(GetUserRoomsImage());
    }
  },[dispatch, room])

  useEffect(()=>{
    dispatch(getCustomerSettings(user.fkcustomers))
    dispatch(getCustomerSettingsProfiles(user.fkcustomers))
    dispatch(getUsersUsrGroups())
  },[dispatch])

  useEffect(()=> {
    if(user.ispremium){
      if(customerSettingsProfiles != null && usersusrgroups != undefined){
        let setting = customerSettingsProfiles.find((csp) => csp.fkusrgroups == usersusrgroups.fkusrgroups);
        if(setting != undefined){
          setDayLimit(  setting.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                        setting.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                        moment().add(setting.limitdays,'d'));
        }else if(customerSettings != null){
          setDayLimit(customerSettings.limitisactive ?
                  customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                  customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                         moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
        }else{
          setDayLimit(moment().add(100,'y'));
        }
      }else if(customerSettings != null){
        setDayLimit(customerSettings.limitisactive ?
                customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                       moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
      }else{
        setDayLimit(moment().add(100,'y'));
      }
    }else{
      setDayLimit(moment().add(100,'y'));
    }
  },[customerSettings,customerSettingsProfiles])

  useEffect(()=>{
    if(userSites.length > 0 && room){
      const newRoom = userSites.find(us=> us.pksites === room.fksites).rooms.find(r=> r.pkrooms === room.pkrooms);
      dispatch(getRoomSuccess(newRoom));
      dispatch(GetNotAvailableSeatsNumber(newRoom.pkrooms))
    }
  },[dispatch, room, userSites])

  useEffect(() => {
    if(userSites.length > 0 && allRooms.length === 0 && selectedTab === "0") {
      let rooms = []
      userSites.forEach(us => rooms.push(...us.rooms))
      setAllRooms(rooms)
    }
  }, [userSites, selectedTab])

  useEffect(() => {
    const firstDate = moment(data, "DD/MM/YYYY").format("DD/MM/YYYY");
    const lastDate = moment(data, "DD/MM/YYYY")
      .add(6, "d")
      .format("DD/MM/YYYY");

    const starttime = moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
    const endtime = moment(data, "DD/MM/YYYY").add(6, "d").format("YYYY-MM-DD");

    dispatch(GetReservationByWeek(room.pkrooms, starttime, endtime));
    setValue(firstDate + " - " + lastDate);
  }, [data, room, dispatch]);

  const reserveDayShifts = (date, isLink, shifts: Shift[]) => {
    const names = manageAlsoFindNames(shifts, date);
    let dialog: DialogMessageType = {
      dialogMsg:
        isLink === true ? null : (
          <p className="mb-0">
            {moment(date).format("DD/MM/YYYY")} <br /> {room.roomname}{" "}
          </p>
        ),
      dialogSubmsg: (
        <p className="mb-0">
          {t("Book all shifts on day") +
            " " +
            moment(date).format("DD/MM/YYYY") +
            "?" +
            "\n" +
            t("Room Name") +
            ": " +
            room.roomname}
        </p>
      ),
      dialogAcceptButton: t("Confirm prenotation buttton"),
      dialogDismissButton: t("Dismiss prenotation buttton"),
      dialogUnderActionsTitle: names.length === 0 ? null : t("Also find"),
      dialogUnderActionsSub: names.length === 0 ? null : names.join(", "),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        let s = [];
        shifts.forEach(shift=>{
          const dateReservations = shift.reservations.filter(r=>moment(r.reservationdate).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY"));
          if(dateReservations.length < room.roomseats){
            if(dateReservations.findIndex(r=>r.pkreservations !== 0) === -1){//non c'è una mia prenotazione
              s.push(shift.pkshifts);
            }
          }
        });
        if(s.length > 0){
          dispatch(addDayReservation(s, moment(date), shifts[0].fkrooms, 1, moment(data)));
        }
      },
    };
    dispatch(getDialogMessage(dialog));
  };

  const manageAlsoFindNames = (s: Shift[], date) => {
    const tmp: string[] = [];
    s.forEach((shift) => {
      const currentRoomShift: Shift = roomReservations.find(
        (rr) => rr.pkshifts === shift.pkshifts
      );
      currentRoomShift.reservations.forEach((cs) => {
        if (
          moment(cs.reservationdate).format("DD-MM-YYYY") ===
            moment(date).format("DD-MM-YYYY") &&
          cs.fkshifts === shift.pkshifts
        ) {
          const cu = tmp.find((n) => n === cs.utenti);
          if (cu === undefined && cs.utenti !== user.username) {
            tmp.push(cs.utenti);
          }
        }
      });
    });
    return tmp;
  };

  const getRoom = (isNext: boolean) =>{
    let r
    if(selectedTab !== "0") {
      r = (!isNext)? (userSites.find((us) => us.pksites === room.fksites).rooms.findIndex((r) => r.pkrooms === room.pkrooms) === 0
      ? userSites.find((us) => us.pksites === room.fksites).rooms[
            userSites.find((us) => us.pksites === room.fksites)
            .rooms.length - 1
        ]
      : userSites.find((us) => us.pksites === room.fksites).rooms[
          userSites
          .find((us) => us.pksites === room.fksites)
          .rooms.findIndex(
            (r) => r.pkrooms === room.pkrooms
          ) - 1
      ]): (userSites.find((us) => us.pksites === room.fksites).rooms.findIndex((r) => r.pkrooms === room.pkrooms) ===
      userSites.find((us) => us.pksites === room.fksites).rooms.length - 1
        ? userSites.find((us) => us.pksites === room.fksites)
            .rooms[0]
        : userSites.find((us) => us.pksites === room.fksites)
            .rooms[
              userSites
              .find((us) => us.pksites === room.fksites)
              .rooms.findIndex(
                (r) => r.pkrooms === room.pkrooms
              ) + 1
      ]);
    }
    else {
      const pos = allRooms.findIndex((rr) => rr.pkrooms === room.pkrooms)
      if(isNext) {
        if(pos === allRooms.length - 1)
          r = allRooms[0]
        else
          r = allRooms[pos + 1]
      } else {
        if(pos === 0)
          r = allRooms[allRooms.length - 1]
        else
          r = allRooms[pos - 1]
      }
    }
    dispatch(getRoomSuccess(r))
    dispatch(getInfoCheckRoomIntersectingTimeSlot(r.pkrooms));
  }

  return (
    <>
      <div>
        <Container
          className={cn(style["header-box"])}
          maxWidth={false}
          sx={
            (room.roomattachment !== undefined) ?
              {backgroundImage:"url('data:image/jpeg;base64,"+room.roomattachment+"') !important"} :
              {}
          }
        >
          <Button
            className={style["fav-btn"]}
            onClick={() => room.isfavorite ? removeRoomFromFavorites(room.pkrooms) : addRoomToFavorites(room.pkrooms)}>
            <FontAwesomeIcon icon={room.isfavorite ? starFull : starEmpty} size="lg" style={{color: room.isfavorite ? "yellow" : "white"}} />
            &nbsp; {room.isfavorite ? t("Remove from favorites") : t("Add to favorites")}
          </Button>
          <Button className={style["close-btn"]}>
            <Link
              to={{ pathname: "/home" }}
              style={{ textDecoration: "none", color: "white" }}
            >
              <FontAwesomeIcon icon={faXmark} size="lg" />
              &nbsp; {t("Close")}
            </Link>
          </Button>
          <div
            className={
              "row d-flex align-items-center justify-content-center w-100 " +
              (!room.iconname ? "mt-4" : "")
            }
          >
            <div
              className={
                "row w-100 " + (!room.iconname ? "mt-4 mt-xxl-5" : "mt-4")
              }
            >
              <p className={cn(style["tit-1"], "mt-5 position-relative")}>
                {" "}
                {room.iconname && (
                  <FontAwesomeIcon
                    icon={[
                      room.iconname.split(" ")[0] as IconPrefix,
                      room.iconname.split(" ")[1] as IconName,
                    ]}
                  />
                )}{" "}
              </p>
              <p className={cn(style["tit-2"],"mb-1 position-relative")}> {room.roomname} </p>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <p className={cn(style["sub-1"],"position-relative", "me-1", !room.roomdescription ? "mb-5" : "")}>
                  {" "}
                  {t("Maximum capacity")}: {room.roomseats}{" "}
                  {room.roomseats > 1 ? t("People") : t("Person")}
                  {room.roomdescription?"  |  ":""}
                </p>
                {room.roomdescription && <CustomWidthTooltip
                  disableInteractive
                  placement='right-start'
                  enterDelay={1000}
                  title={
                    (room && room.roomdescription && room.roomdescription.length > 0) ? (
                      <div className="ps-2 pe-2 pt-1 pb-1" style={{ width: "auto", height: "auto", backgroundColor: "#FFFFFF", borderRadius: "8px", maxWidth: "200px", boxShadow: "0px 3px 4px #00000042" }}>
                        <p className="p-0 m-0" style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{room.roomdescription}</p>
                      </div>
                    ) : ""
                  }>
                    <div style={{  position: "relative" }}>
                      <p className={cn(style["more-details"],"mb-5 position-relative")}>
                        {t("More details")}
                      </p>
                    </div>
                </CustomWidthTooltip>}
              </div>
              <p className="m-0 p-0 col-6">
                <Link
                  className={style["slide-caret"]}
                  to={{
                    pathname: "/reserve-seat",
                  }}
                >
                  <IconButton className="caret">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="white"
                      onClick={()=>{getRoom(false)}}
                    />
                  </IconButton>
                </Link>
              </p>
              <p className="m-0 p-0 col-6" style={{ textAlign: "end" }}>
                <Link
                  className={style["slide-caret"]}
                  to={{
                    pathname: "/reserve-seat",
                  }}
                >
                  <IconButton className="caret">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      onClick={()=>getRoom(true)}
                    />
                  </IconButton>
                </Link>
              </p>
            </div>
          </div>
        </Container>
      </div>
      <Grid
        className="mt-3"
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ paddingBottom: "0.4%", paddingTop: "2%", color: "#0C5468" }}
      >
        <h5>{t("Week navigation")}</h5>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ color: "#0C5468" }}
      >
        <Grid item>
          <button
            style={{ color: "#0C5468" }}
            className="bg-transparent border-0"
            onClick={ChangeWeekBackards}
          >
            {" "}
            <FontAwesomeIcon icon={faAngleLeft} className="primary" />{" "}
            {t("Back")}
          </button>
        </Grid>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              toolbarPlaceholder="Select Week"
              value={data}
              onChange={(newValue) => {
                setData(newValue);
              }}
              renderInput={({ inputRef, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    sx={{ width: "80%" }}
                    value={value}
                    ref={inputRef}
                    variant="standard"
                    inputProps={{ readOnly: true }}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item>
          <button
            style={{ color: "#0C5468" }}
            className="bg-transparent border-0"
            onClick={ChangeWeekForward}
          >
            {t("Next")}{" "}
            <FontAwesomeIcon icon={faAngleRight} className="primary" />{" "}
          </button>
        </Grid>
      </Grid>
      <Grid
        container
        flexWrap="nowrap"
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "2%", marginBottom: "4.1%" }}
      >
        <Grid container className={cn(style["grid-box"])}>
          <div
            className={cn("table-responsive-xl", style["reserve-table"])}
            style={{ display: "flex" }}
          >
            <table
              className={cn("table ", style["reserve-table"])}
              style={{ verticalAlign: "bottom" }}
            >
              <tr style={{ verticalAlign: "bottom" }}>
                <th></th>
                {roomReservations.length > 0 &&
                  Array.from(Array(7)).map((_, index) => {
                    const date = moment(data, "DD/MM/YYYY").add(index, "d");

                    return (
                      <th className={style["sticky"]} scope="col">
                        {date.isSame(data) ? (
                          <div>
                            <Typography
                              fontWeight="light"
                              fontStyle={"normal"}
                              letterSpacing={"none"}
                              fontFamily="Segoe UI"
                              className={style["todayLabel"]}
                            >
                              {date.isSame(moment(), "day") ? t("Today") : ""}
                            </Typography>
                          </div>
                        ) : null}
                        <Grid
                          item
                          lg={12}
                          md={12}
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ paddingTop: "8%" }}
                          className={style["borderforscroll"]}
                        >
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontStyle={"normal"}
                            letterSpacing={"none"}
                            fontFamily="Segoe UI"
                          >
                            {date.format("DD")}&nbsp;
                            {date.format("MMMM").charAt(0).toUpperCase()}
                            {date.format("MMMM").slice(1)}
                          </Typography>
                          <Typography
                            fontWeight="light"
                            fontStyle={"normal"}
                            letterSpacing={"none"}
                            fontFamily="Segoe UI"
                          >
                            {date.format("dddd")}
                          </Typography>
                        </Grid>
                      </th>
                    );
                  })}
              </tr>

              <tbody className={cn(style["table-body"])}>
                {roomReservations.map((shift) => (
                  <tr>
                    <td
                      className="px-md-1 px-lg-4"
                    >
                      <Typography
                        variant="h6"
                        align="left"
                        fontWeight="bold"
                        fontStyle={"normal"}
                        letterSpacing={"none"}
                        fontFamily="Segoe UI"
                      >
                        {shift.shiftname}
                      </Typography>
                      <p style={{ fontWeight: "200", alignItems: "left" }}>
                        {shift.starttime} - {shift.endtime}
                      </p>
                    </td>

                    {roomReservations.length > 0 &&
                      Array.from(Array(7)).map((_, index) => {
                        const date = moment(data, "DD/MM/YYYY").add(index, "d");
                        var posti = room.roomseats;
                        var isReservedByUser = false;
                        let bgc = "";
                        if (shift.reservations.length > 0) {
                          shift.reservations.forEach((reservation) => {
                            if (
                              date.format("YYYY-MM-DD") ===
                              moment(
                                reservation.reservationdate,
                                "YYYY-MM-DD"
                              ).format("YYYY-MM-DD")
                            ) {
                              posti = posti - reservation.prenotati;
                              if(reservation.pkreservations !== 0){
                                isReservedByUser = true;
                              }
                            }
                          });
                        }
                        const changeBgColor = (canResev) => {
                          if (isReservedByUser === true) {
                            bgc = "rgba(72,151,201,255)";
                            return bgc;
                          }
                          if (posti === 0) {
                            bgc = "rgba(212,90,92,255)";
                            return bgc;
                          }
                          if (posti > 0) {
                            bgc = "rgba(181,225,188,255)";
                            return bgc;
                          }
                        };

                        const changeTextColor = () => {
                          let ret: string = "";
                          if (posti === 0 || isReservedByUser) {
                            ret = "#FFFFFF !important";
                            return ret;
                          } else {
                            ret = "#363636";
                            return ret;
                          }
                        };

                        const getNamesTooltip = (shift) => {
                          const tmp: Shift[] = [shift];
                          let names = manageAlsoFindNames(tmp, date);
                          return names && names.length ? names.join(", ") : "";
                        };

                        let canResev =
                          (posti <= 0 && !isReservedByUser) ||
                          date.isBefore(moment(), "day") ||
                          date.isAfter(dayLimit, "day")
                            ? false
                            : true;
                        return (
                          <td
                            className="px-lg-4 px-xxl-5"
                            onMouseOver={() => {
                              const indexes = [0,1,2,3,4,5,6].filter(i => i!== index);
                              indexes.forEach(i=> document.getElementById("r-all-"+i).style.visibility = "hidden" );
                              document.getElementById("r-all-"+index).style.visibility = "visible";
                              document.getElementById("r-all-"+index).getElementsByTagName("p")[0].getElementsByTagName("span")[0].style.backgroundColor = "#f1f1f1";
                            }}
                            onMouseOut={()=> document.getElementById("r-all-"+index).getElementsByTagName("p")[0].getElementsByTagName("span")[0].style.backgroundColor = "#faf9f8" }
                          >
                            <Grid
                              className={cn(style["reserve-btn"])}
                              item
                              lg={12}
                              md={12}
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Tooltip title={getNamesTooltip(shift)}>
                                <Button
                                  className={cn(
                                    date.isBefore(moment(), "day") ||
                                    (date.isAfter(dayLimit, "day") && !isReservedByUser)
                                      ? style["past"]
                                      : isReservedByUser
                                      ? style["booked"]
                                      : posti > 0
                                      ? style["free"]
                                      : style["full"]
                                  )}
                                  sx={{
                                    color: changeTextColor(),
                                    height: "62px",
                                    width: "62px",
                                    borderRadius: "50%",
                                    backgroundColor: date.isBefore(moment(),"day") ||
                                                    (date.isAfter(dayLimit, "day") && !isReservedByUser)
                                      ? "#bdbdbd"
                                      : changeBgColor(canResev),
                                  }}
                                  onClick={() =>
                                    isReservedByUser && !(date.isBefore(moment(),"day"))
                                    ?
                                    openDeleteDialog(date, shift)
                                    :
                                    canResev &&
                                    openReserveDialog(date, shift)
                                  }
                                >
                                  {posti < 0 ? 0 : posti}
                                </Button>
                              </Tooltip>
                            </Grid>
                          </td>
                        );
                      })}
                  </tr>
                ))}
              </tbody>
              <tfoot style={{ border: "hidden" }}>
                <td></td>
                {roomReservations.length > 0 &&
                  Array.from(Array(7)).map((_, index) => {
                    const date = moment(data, "DD/MM/YYYY").add(index, "d");
                    var posti = room.roomseats*roomReservations.length;
                    var reservedByUser = roomReservations.length;
                    roomReservations.forEach(rr => {
                      rr.reservations.forEach(r => {
                        if (
                          date.format("YYYY-MM-DD") ===
                          moment(
                            r.reservationdate,
                            "YYYY-MM-DD"
                          ).format("YYYY-MM-DD")
                        ) {
                          posti = posti - r.prenotati;
                          if(r.pkreservations !== 0){
                            reservedByUser--;
                          }
                        }
                      })
                    });
                    if(!checkRoomIntersectingTimeSlots){
                    return (
                      <td
                        className="px-lg-4 text-center"
                        id={"r-all-"+index}
                        style={{visibility: "hidden"}}
                      >
                        <p className="mb-1 d-flex justify-content-center">
                          <hr
                            className={cn(
                              "position-absolute",
                              style["separator"]
                            )}
                          />
                          <span
                            className={cn(
                              "position-relative",
                              style["separator-text"]
                            )}
                          >
                            {" "}
                            {t("Or")}{" "}
                          </span>
                        </p>
                        <span
                          className={cn(
                            "d-flex flex-column",
                            style["reserve-all"]
                          )}
                        >

                        {date.isBefore(moment(), "day") ||
                         date.isAfter(dayLimit, "day")?
                        <></>
                        :
                        (posti > 0 && reservedByUser > 0) ?
                          <>
                          <p

                              className={cn(
                                "",
                                style["bookall-text"]
                              )}
                              onClick={() =>
                                reserveDayShifts(
                                  date,
                                  true,
                                  roomReservations
                                )
                              }
                            >
                              {t("Book all")}
                            </p>
                            <p
                              className={cn(
                                  "",
                                style["bookall-text"]
                              )}
                              onClick={() =>
                                reserveDayShifts(
                                  date,
                                  true,
                                  roomReservations
                                )
                              }
                            >
                              {t("Available shifts")}
                            </p>
                            </>
                    :
                    <>
                    <p

                      className={cn(
                        style["disabled"]
                      )}
                      onClick={() =>
                        null
                      }
                    >
                      {t("Book all")}
                    </p>
                    <p
                      className={cn(
                        style["disabled"]
                      )}
                      onClick={() =>
                        null
                      }
                    >
                      {t("Available shifts")}
                    </p>
                    </>

                    }
                          </span>
                        </td>
                      );
                    }
                  })}
              </tfoot>
            </table>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ReserveSeat;
