import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        visible: false,
        counter: 0
    },
    reducers: {
        toggleLoader: (state) => {state.visible = !state.visible},
        increaseCounter: (state) => {
            state.counter += 1
            if (state.counter > 0){
                state.visible = true
            }
        },
        decreaseCounter: (state) => {
            state.counter -= 1
            if (state.counter <= 0){
                state.visible = false
            }
        }
    }
})

export const {toggleLoader, increaseCounter, decreaseCounter} = loaderSlice.actions