import Event, { EventProps } from '../../../pages/home/nextReservations/event';

export type BoxCardProps = {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  footer?: React.ReactElement;
  action?: React.ReactElement;
  boxStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subTitle1Style?: React.CSSProperties;
  subTitle2Style?: React.CSSProperties;
  includeAction?: boolean;
  event?: EventProps;
  fullWidth?: boolean;
  mobile?: boolean;
};

const BoxCard: React.FC<BoxCardProps> = ({
  title,
  subtitle1,
  subtitle2,
  action,
  footer,
  boxStyle,
  titleStyle,
  subTitle1Style,
  subTitle2Style,
  includeAction,
  event,
  fullWidth,
  mobile
}) => {
  const footerSection = footer ? (
    <div className={action && includeAction ? "footer" : "footer-static"}>{footer}</div>
  ) : null;
  const actionSection = !mobile && action && includeAction ? (
    <div className="action-container">{action}</div>
  ) : null;

  return (
    <div className={(fullWidth === true)?"ms-1 card-container-fullwidth bgc-primary": "ms-1 card-container bgc-primary"} style={boxStyle}>
      <div className="row">
        <div className="col">
          <div className="card-title-container">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title" style={titleStyle}>{title}</h5>
              </div>
              {event && <div className="col d-flex justify-content-end">
                <Event room={event.room} date={event.date} shift={event.shift} startTime={event.startTime} endTime={event.endTime} white={true}></Event>
              </div>}
              {mobile && <div className="col d-flex justify-content-end">
                {action}
              </div>}
            </div>
          </div>
        </div>
      </div>
      {subtitle1 ? (
        <div className="card-subtitle-container">
          <h5 className="card-subtitle1" style={subTitle1Style}>{subtitle1}</h5>
        </div>
      ) : null}
      {subtitle2 ? (
        <div className="card-subtitle-container">
          <h5 className="card-subtitle2" style={subTitle2Style}>{subtitle2}</h5>
        </div>
      ) : null}
      {footerSection}
      {actionSection}
    </div>
  );
};

export default BoxCard;
