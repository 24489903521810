import { Close } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { TabPanel } from "@mui/lab";
import TabContext from '@mui/lab/TabContext';
import { Box, Button, Checkbox, Divider, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import cn from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import { manageAzureUsersSync } from "../../../core/store/users/users.actions";
import { updateUsersToRemove } from "../../../core/store/users/users.store";
import { User, UsersCompered } from '../../../models/user';
import css from './syncmodal.module.scss';

interface SyncModalProps{
    showModal: boolean;
    onCloseModal: () => void;
}

interface UserData {
    username: string;
    email: string;
    type: number;
    active: boolean;
    useridentify: string;
}

const SyncModal: React.FC<SyncModalProps> = ({ showModal, onCloseModal }) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [index, setIndex] = useState("0")
    const [nameFilter, setNameFilter] = useState<string>("")
    const [usersData, setUsersData] = useState<UserData[]>([])
    const [usersToActive, setUsersToActive] = useState<UserData[]>([])
    const [licenseLabel, setLicenseLabel] = useState<{text: string, valid: boolean}>({text: "", valid: false})
    const { tenantUsers, usersFromAzureAD, usersLicense } = useSelector((state: RootState) => state.users)

    function onCloseAlert(){
        let message : DialogMessageType = {
            dialogMsg :(<div>{t("Close From Alert")}</div>),
            dialogTitle : t("Attention"),
            dialogAcceptButton: t("Yes"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                onClose();
            }
        }
        dispatch(getDialogMessage(message));
    }

    function onClose(){
        let udata: UserData[] = []
        setUsersToActive(udata)
        onCloseModal();
    }

    function changeUserType(email: string, value: number) {
        let udata: UserData[] = []
        udata.push(...usersData)
        if(udata.findIndex(u => u.email === email) !== -1)
            udata.find(u => u.email === email).type = value
        setUsersData(udata)
    }

    function changeUserActive(email: string) {
        let udata: UserData[] = []
        if(usersToActive.findIndex(u => u.email === email) !== -1){
            usersToActive.splice(usersToActive.findIndex(u => u.email === email),1)
            udata.push(...usersToActive)
        }else if(usersData.findIndex(u => u.email === email) !== -1){
            udata.push(...usersToActive)
            udata.push(usersData.find(u => u.email === email))
            udata.find(u => u.email === email).active = true
        }
        setUsersToActive(udata);
    }

    function selectAllUsers() {
        let udata: UserData[] = [];
        if(usersData.length !== usersToActive.length) {
            udata.push(...usersData)
            udata.forEach(u => u.active = true)
        }
        setUsersToActive(udata)
    }

    function excludeFromElimination(user: User) {
        dispatch(updateUsersToRemove(usersFromAzureAD.userstoremove.filter((u: User)=>{return u.pkusers != user.pkusers})));
    }

    function synchronizeData() {
        let usersForSync: UsersCompered = {
            userstoremove: usersFromAzureAD.userstoremove,
            userstoremovelength: usersFromAzureAD.userstoremovelength,
            userstoadd: [],
            userstoaddlength: 0
        }
        usersToActive.forEach(ud => {
            const user: User = {
                pkusers: 0,
                fkcustomers: 0,
                fklanguages: 0,
                useridentify: ud.useridentify,
                username: ud.username,
                email: ud.email,
                manager: "",
                emailmanager: "",
                group: "",
                firstlogindate: moment(),
                lastlogindate: moment(),
                isactive: ud.active,
                isadmin: ud.type === 2,
                isdemouser: false,
                isdeleted: false,
                doi: moment(),
                dou: moment(),
                userrooms: [],
                functionalities: [],
                showstartuppopup: true
            }
            usersForSync.userstoadd.push(user)
        })
        usersForSync.userstoaddlength = usersForSync.userstoadd.length
        dispatch(manageAzureUsersSync(usersForSync))
        onClose()
    }


    useEffect(() => {
        let usersData: UserData[] = []
        if(usersFromAzureAD && usersFromAzureAD.userstoaddlength > 0)
            usersFromAzureAD.userstoadd.forEach(u => {
                const ud: UserData = {
                    username: u.username,
                    email: u.email,
                    type: 1,
                    active: false,
                    useridentify: u.useridentify
                }
                usersData.push(ud)
            })
        setUsersData(usersData)
        if(usersLicense && usersFromAzureAD) {
             const licenses = usersLicense-(tenantUsers.filter((u)=>u.isactive && !u.isdeleted).length)
            if(licenses < 0) setLicenseLabel({text: t("Licenses not enough"), valid: false})
            else setLicenseLabel({text: licenses.toString() + '/' + usersLicense.toString(), valid: true})
        }
    }, [usersFromAzureAD, t, tenantUsers, usersLicense])

    useEffect(() => {
        if(usersFromAzureAD) {
            const licenses = usersLicense-(tenantUsers.filter((u)=>u.isactive && !u.isdeleted).length)-usersToActive.length
            if(licenses < 0) setLicenseLabel({text: t("Licenses not enough"), valid: false})
            else setLicenseLabel({text: licenses.toString() + '/' + usersLicense.toString(), valid: true})
        }
    }, [usersToActive,usersData, t, tenantUsers, usersFromAzureAD, usersLicense])

    return(
        <Modal
        open={showModal}
        onClose={()=>onCloseAlert()}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "55%",
                height:"85%",
                bgcolor: "white",
                borderRadius:0.5,
                boxShadow: 10,
                p:4,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div className="row">
                    <div className="col">
                        <h4 className={cn(css["title"])}> {t("Complete Sync")} </h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                        <IconButton onClick={() => onCloseAlert()}>
                            <Close fontSize="small" className="primary" />
                        </IconButton>
                    </div>
                </div>
                <TabContext value={index}>
                    <div className={cn(css["tab-content"])}>
                        <div className={cn("w-100 mt-3", css["no-flex"])}>
                            <Tabs
                            indicatorColor="secondary"
                            variant="fullWidth"
                            value={index}
                            onChange={(e,newValue)=>setIndex(newValue)}
                            >
                                <Tab value="0" label={t("New Users Configuration")} style={(index==="0")?{width:"50%", color:"black"}:{width:"50%", color:"gray"}}/>
                                <Tab value="1" label={t("Disabled Users Summary")} style={(index==="1")?{width:"50%", color:"black"}:{width:"50%", color:"gray"}} />
                            </Tabs>
                        </div>

                        <div className={cn("w-100 overflow-hidden", css["auto-flex"])}>
                            <TabPanel value="0" sx={{paddingLeft:0, paddingRight:0, paddingTop:0, height:"100%", display:"flex", flexDirection:"column"}}>
                                <div className="row mt-3">
                                    <div className="col">
                                        <p className="m-0">{t("Users Found")} <strong>{usersFromAzureAD && usersFromAzureAD.userstoaddlength}</strong> {t("New Users")}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className={cn("m-0", css["subtitle"])}>{t("Sync Modal Check Message")}</p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col d-flex align-items-center ps-4 ms-1">
                                        <Checkbox checked={usersData.length === usersToActive.length} onClick={() => selectAllUsers()}/>
                                        <span>{t("Select All")}</span>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                        <TextField
                                            id="search-user-input"
                                            value={nameFilter}
                                            onChange={(e) => setNameFilter(e.target.value)}
                                            label={t("Search User")}
                                            InputProps={{
                                                endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
                                            }}
                                            size="small"
                                        />
                                    </div>
                                </div>
                                <div className={cn("row", css["auto-flex"])}>
                                    <div className={cn("col", css["table-col"])}>
                                        <TableContainer style={{height: "170px", overflow: "unset"}}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {usersFromAzureAD && usersFromAzureAD.userstoaddlength > 0 && usersFromAzureAD.userstoadd.filter((u) => u.username.toLowerCase().includes(nameFilter.toLowerCase())).map((u) => (
                                                        <TableRow
                                                        className="table-hover-user"
                                                        key={u.pkusers}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" style={{width: "5%"}}>
                                                                <Checkbox checked={usersToActive.findIndex(ud => ud.email === u.email) >= 0} onClick={() => changeUserActive(u.email)}/>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <b>{u.username}</b>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <p className="m-0">{u.email}</p>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Select
                                                                    labelId="typeLabel"
                                                                    label={t("User Type")}
                                                                    value={usersData.find(ud => ud.email === u.email) ? usersData.find(ud => ud.email === u.email).type : 1}
                                                                    onChange={(e)=>changeUserType(u.email, e.target.value as number)}
                                                                    size="small"
                                                                >
                                                                    {
                                                                        <MenuItem key={1} value={1}>{"Utente base"}</MenuItem>
                                                                    }
                                                                    {
                                                                        <MenuItem key={2} value={2}>{"Admin"}</MenuItem>
                                                                    }
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel value="1" sx={{paddingLeft:0, paddingRight:0, paddingTop:0, height:"100%", display:"flex", flexDirection:"column"}}>
                                <div className="row">
                                    <div className="col">
                                        <p className="m-0">{t("Removing Users Message")} <strong>{usersFromAzureAD && usersFromAzureAD.userstoremovelength}</strong> {t("users")}:</p>
                                    </div>
                                </div>
                                <div className="row mt-2" style={{overflow: "auto", flex: "auto"}}>
                                    <div className="col" style={{height: "100px"}}>
                                        <List sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                            {usersFromAzureAD && usersFromAzureAD.userstoremove && usersFromAzureAD.userstoremove.map((u) => (
                                                <>
                                                    <ListItem
                                                        sx={{paddingLeft: 0}}>
                                                            <ListItemText primary={u.username} secondary={u.email}/>
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            ))}
                                        </List>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>

                    </div>
                    <div className={cn("mb-0 d-flex gap-3", css["footer"])}>
                        <div className="row w-100">
                            <div className="col-10">
                                <div className="row">
                                    <div className="col">
                                        <p className={cn("m-0", css["subtitle"])}><strong>{t("New Users")}:</strong> {usersFromAzureAD && usersFromAzureAD.userstoaddlength} ({usersToActive.length} {t("Actives")}) - <strong>{t("Remaining Licenses")}:</strong> {licenseLabel.text} - <strong>{t("Disabled Users")}:</strong> {usersFromAzureAD && usersFromAzureAD.userstoremovelength} </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className={cn("m-0", css["subtitle"])}>{t("Sync Confirm Message")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <Button variant="contained" className={cn(css["sync-button"])} disableElevation onClick={() => synchronizeData()} disabled={!licenseLabel.valid || usersToActive.length==0}>
                                    {t("Sync")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </TabContext>

            </Box>
        </Modal>
    )
}

export default SyncModal;