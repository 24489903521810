import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import cn from "classnames";
import React, { useEffect, } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { toggleToolbarMenu } from "../../core/components/toolbar/store/toolbar.store";
import { getChangelog, getChangelogFromPk } from "../../core/store/popup/popup.actions";
import { GetUserRoomsImage, GetUserSites, GetUserSitesChanged } from "../../core/store/room/user-room/user-room.actions";
import { RootState } from '../../core/store/store';
import { getAllGuides, getAllGuidesCategories } from "../../core/store/tutorials/guide.actions";
import { getUserReservations } from "../../core/store/users/users.actions";
import { Reservation } from "../../models/reservation";
import RoomModal from "../Room/RoomModal";
import ExploreRoomsMobile from "./ExploreRooms-Mobile/ExploreRoomsMobile";
import QuickReservationStepper from "./FastReservation-Mobile/quick-reservation-stepper/Quick-reservation-stepper";
import NextReservationMobile from "./NextReservations-Mobile/NextReservationMobile";
import ExploreRooms from "./exploreRooms/exploreRooms";
import FastReservation from "./fastReservation/fastReservations";
import css from "./home.module.scss";
import NextReservations from "./nextReservations/nextReservations";

export type HomePage = {
  userReservation: Reservation[];
};

const Home: React.FC= () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { visible } = useSelector((state: RootState) => state.modal.roomModal)
  const { token, user } = useSelector((state: RootState) => state.auth)
  const { userSites, userSitesChanged, imageHasLoaded } = useSelector((state:RootState) => state.room.userRoom)
  const { guidesList, guidesCategories } = useSelector((state:RootState) => state.guide)
  const { showpopupguide } = useSelector((state: RootState) => state.startup)


  useEffect(() => {
    if(token !== "" && token != null) {
      (userSites.length === 0)?dispatch(GetUserSites()):dispatch(GetUserSitesChanged(userSites));
      dispatch(getUserReservations())
      if(user.functionalities.findIndex(f => f.nome === "User_guides") >= 0) {
        if(guidesList.length === 0) dispatch(getAllGuides())
        if(guidesCategories.length === 0) dispatch(getAllGuidesCategories())
      }
      if(showpopupguide) {
        dispatch(getChangelogFromPk(3)) //SOSTITUIRE CON PK CHANGELOG DI "SCOPRI COME UTILIZZARE JUSTSEAT"
      } else {
        dispatch(getChangelog(user.pkusers))
      }
    }
  }, [token, dispatch])

  useEffect(()=>{
    if(token !== "" && token != null) {
      if(userSites.length > 0 && !imageHasLoaded ){
        dispatch(GetUserRoomsImage());
      }
      if(userSitesChanged){
        dispatch(GetUserSites());
      }
    }
  },[userSitesChanged])

  useEffect(() => {
    let touchPos;

    document.body.ontouchstart = function (e) {
      touchPos = e.changedTouches[0].clientY;
    };

    document.body.ontouchmove = function (e) {
      let newTouchPos = e.changedTouches[0].clientY;
      if (newTouchPos > touchPos) {
        dispatch(toggleToolbarMenu(true));
      }
      if (newTouchPos < touchPos) {
        dispatch(toggleToolbarMenu(true));
      }
    };
  }, [dispatch]);

  return (
    <div className="container-fluid mt-2">

      {visible &&
        <RoomModal></RoomModal>
      }

      {isMobile && user.functionalities.findIndex(f => f.nome === "Book_shift_in_room") >= 0 &&
        <div className="row mb-3">
          <div className="col">
            <Accordion className="mt-2" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h5 className={cn(css["AccordionTitle"], "mb-0")}>{t("Search a seat")}</h5>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <QuickReservationStepper></QuickReservationStepper>
              </AccordionDetails>
            </Accordion>
            <Accordion className="mt-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h5 className={cn(css["AccordionTitle"], "mb-0")}>{t("Next reservations")}</h5>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <NextReservationMobile></NextReservationMobile>
              </AccordionDetails>
            </Accordion>
            <Accordion className="mt-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h5 className={cn(css["AccordionTitle"], "mb-0")}>{t("Explore")}</h5>
              </AccordionSummary>
              <AccordionDetails className="pt-0">
                <ExploreRoomsMobile></ExploreRoomsMobile>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      }
      {!isMobile && user.functionalities.findIndex(f => f.nome === "Book_shift_in_room") >= 0 &&
        <div className="row mt-4 mb-5">
          <div className="col-6">
            <FastReservation />
            <NextReservations />
          </div>
          <div className="col-6" >
            <ExploreRooms />
          </div>
        </div>
      }
    </div>
  );
};

export default Home;
