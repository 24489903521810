import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { dialogMessageSlice } from '../components/dialog/store/dialogMessage.store';
import { loaderSlice } from "../components/loader/store/loader.store";
import { alertMessageSlice } from "../components/message-bar/store/alertMessage.store";
import { toolbarSlice } from "../components/toolbar/store/toolbar.store";
import { authSlice } from "./auth/auth.store";
import { customerSlice } from "./customer/customer.store";
import { customerSettingsSlice } from "./customer/settings-customer/settings-customer.store";
import { customerSettingsProfilesSlice } from "./customer/settings-profiles-customer/settings-profiles-customer.store";
import { dialogsSlice } from "./dialogs/dialogs.store";
import { felogicSlice } from "./felogic/felogic.store";
import { modalReducer } from "./modal/modal.store";
import { popupSlice } from "./popup/popup.store";
import { reportSlice } from "./report/report.store";
import { groupsSlice } from "./room/administration-group/administration-group.store";
import { roomReducer } from "./room/room.store";
import { startupSlice } from "./startup/startup.store";
import { superAdminSlice } from './superadmin/superadmin.store';
import { guideSlice } from "./tutorials/guide.store";
import { uiSlice } from "./ui/ui.store";
import { usersSlice } from "./users/users.store";

const rootReducer = combineReducers({
    toolbar: toolbarSlice.reducer,
    auth: authSlice.reducer,
    loader: loaderSlice.reducer,
    alertMessage: alertMessageSlice.reducer,
    room: roomReducer,
    modal : modalReducer,
    users:  usersSlice.reducer,
    dialogMessage: dialogMessageSlice.reducer,
    report: reportSlice.reducer,
    superadmin: superAdminSlice.reducer,
    customer: customerSlice.reducer,
    felogic: felogicSlice.reducer,
    groups: groupsSlice.reducer,
    dialogs: dialogsSlice.reducer,
    guide: guideSlice.reducer,
    popup: popupSlice.reducer,
    startup: startupSlice.reducer,
    ui: uiSlice.reducer,
    customerSettings: customerSettingsSlice.reducer,
    customerSettingsProfiles: customerSettingsProfilesSlice.reducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

// trick to use async thunk (as AppDispatch)
export type AppDispatch = typeof store.dispatch