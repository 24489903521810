import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import css from "./toolbar.module.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import cn from "classnames";
import ToolbarDesktop from "./ToolbarDesktop";
import { toggleToolbarMenu } from "./store/toolbar.store";
import ToolbarMobile from "./ToolbarMobile";
import { IconButton } from '@mui/material';
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import moment from "moment";


const Toolbar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const location = useLocation();
  const [ displayReservation, setDisplayReservation ] = useState(false);
  const { submenu, isCollapsed } = useSelector((state: RootState) => state.toolbar);
  const { user } = useSelector((state: RootState) => state.auth);
  const { currentLicense } = useSelector((state: RootState)=> state.customer);

  useEffect(() => {
    setDisplayReservation(["/home", "/settings", "/me/reservations"].indexOf(location.pathname) >= 0);
  }, [location.pathname]);

  const toggleMenu = () => {
    dispatch(toggleToolbarMenu(!isCollapsed));
  };
  
  function getTextLicenseExpire(){
    let daysBeforeExpire = moment(currentLicense.enddate).diff(moment(),'days');
    switch (daysBeforeExpire) {
      case 0:
        return t("Today").toLowerCase();

      case 1:
        return `1 ${t("Day").toLowerCase()}`;

      default:
        return `${daysBeforeExpire} ${t("Days").toLowerCase()}`;
    }
  }

  return (
    <>
      {!isMobile && user.isactive && 
      <>
        <div className="container-fluid p-0">
          <div className={cn("row", css.toolbar)}>
            <div className={cn("col-12 d-flex justify-content-end align-items-center", css["p-20-custom"])}>
              <div className={cn(css["alignLeft"])}>
                <div className="col-1">
                  {
                    (user && user.customerlogo && user.customerlogo.length > 0) ? 
                    <img src={`data:image/png;base64,${user.customerlogo}`} className={cn(css["logo-toolbar"])} alt="logo" /> : ""
                  }
                </div>
              </div>
              <ToolbarDesktop submenu={submenu} displayReservation={displayReservation} />
            </div>
          </div>
        </div>
        {currentLicense && currentLicense.note !== undefined && user.functionalities.findIndex(f => f.nome === "Admin") >= 0  && currentLicense.note==="Trial" && 
          <div className={cn(css['messageContainer'])} >
              <p className="mb-0 text-center">
                {`${t("Trial message 1")} `} 
                <strong> {getTextLicenseExpire()} </strong> 
                {`${t("Trial message 2")} `} 
                <strong><a href="mailto:supporto@mminformatica.it">supporto@mminformatica.it</a></strong>
              </p>
          </div>
        }
      </>
      }

      {isMobile && user.isactive &&
        <div className="row">
          <div className="d-flex flex-row justify-content-between px-4 pt-3">
            {
              (user && user.customerlogo && user.customerlogo.length > 0) ? 
              <img src={`data:image/png;base64,${user.customerlogo}`} className={cn(css["logo-toolbar-mobile"])} alt="logo" /> : ""
            }
            <IconButton onClick={() => toggleMenu()}>
              <FontAwesomeIcon icon={faBars} size="lg"  />
            </IconButton>        
          </div>
        </div>
      }
      {!isCollapsed && isMobile && <ToolbarMobile />}

    </>
  );
};

export default Toolbar;