import { createSlice } from "@reduxjs/toolkit"

interface RoomModalStore {
    visible: boolean
}

export const roomModalSlice = createSlice({
    name: "room-modal",
    initialState:{
        visible: false      
    } as RoomModalStore,
    reducers:{
        toggleModalSuccess: (state) => {
            state.visible = !state.visible
        }
    }
})

export const {toggleModalSuccess} = roomModalSlice.actions
