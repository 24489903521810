import { faArrowsRotate, faCloudArrowDown, faCrown, faUserCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close, Edit } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Button, ButtonGroup, ClickAwayListener, FormControlLabel, Grow, IconButton, InputAdornment, MenuItem, MenuList, Paper, Popper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import cn from 'classnames';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";
import { getHomePage } from "../../core/store/felogic/felogic.action";
import { RootState } from '../../core/store/store';
import { updateUIState } from "../../core/store/ui/ui.store";
import { deleteUser, enableUser, getAllUsers, getClientLicense, getLanguages, getLastSyncDate, getUsersFromAD } from '../../core/store/users/users.actions';
import { User } from "../../models/user";
import { confirmNotification } from "../../utils";
import style from "./UsersManagement.module.scss";
import SyncModal from "./components/SyncModal";
import UserForm from "./user-form/UserForm";

const UsersManagement: React.FC= () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [nameFilter, setNameFilter] = useState<string>("")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [formType, setFormType] = useState<"Edit" | "Single" | "Massive">("Single")
  const { usersLicense, tenantUsers, lastSyncDate } = useSelector((state: RootState) => state.users)
  const { homePage } = useSelector((state:RootState)=>state.felogic);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [onlyNotDeleted, setOnlyNotDeleted] = useState(true)
  const { user } = useSelector((state: RootState) => state.auth)
  const [options, setOptions] = useState([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [syncing, setSyncing] = useState<boolean>(false)

  const handleModalOpen = () => {
    if(!user.isdemouser){
      dispatch(getUsersFromAD());
      setSyncing(true);
      setModalOpen(true);
    } else {
      Swal.fire({
        html: t("Sync not allowed"),
        icon: "warning",
        iconColor: "#0C5468",
        showCancelButton: false
      });
      setSyncing(false);
    }
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  };

  const handleDelete = (email: string, pkusers: number, pkcustomer: number, username:string) => {
    let message : DialogMessageType = {
      dialogMsg :(<div>{t("User remove confirm")}</div>),
      dialogTitle : t("RemovingUser"),
      dialogAcceptButton: t("Yes, remove"),
      dialogDismissButton: t("No cancel"),
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(deleteUser(email, pkusers, pkcustomer, username));
        dispatch(getHomePage());
      }
  }
  dispatch(getDialogMessage(message));

  }

  const handleEdit = (user: User) => {
    setFormType("Edit")
    setSelectedUser(user)
    setIsFormOpen(true)
    setTimeout(() => {
      dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 14 }))
    }, 500);
  }

  const handleClose = (e) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }

    setIsMenuOpen(false);
  }

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setIsMenuOpen(false)
    if(index === 0) {
      setFormType("Single")
    } else if (index === 1) {
      setFormType("Massive")
    }
    //metto in pausa il tutorial aspettando che l'utente inserisca un utente
    dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 10 }))
    setIsFormOpen(true)
  };

  const handleCloseForm = () => {
    setSelectedUser(null)
    setIsFormOpen(false)
    //ripristino il tutorial
    dispatch(updateUIState({ type: "SET_RUN", run: true }))
  }

  const handleReactivate = (u: User) => {
    confirmNotification({
      title: t("User enable"),
      html: `<p>${t("User activation message")} ${u.username}?</p>`,
      confirmButtonText: t("Activate"),
      cancelButtonText: t("Cancel"),
      reverseButtons: true
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(enableUser(u.email))
      }
    });
  }

  const closeModal = () => {
    setModalOpen(false)
    setSyncing(false)
  }

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getLanguages())
    dispatch(getClientLicense())
    let menu: string[] = []
    if(user.functionalities.findIndex(f => f.nome === "Add_single_user") >= 0) {
      menu.push("Single insertion")
    }
    if(user.functionalities.findIndex(f => f.nome === "Add_multiple_users") >= 0) {
      menu.push("Massive insertion")
    }
    setOptions(menu)
    if(!user.isdemouser){
      dispatch(getLastSyncDate())
    }
    setTimeout(() => {
      dispatch(updateUIState({ type: "SET_RUN", run: true }))
    }, 500);
  }, [])

  return (
    <>
      <div className="container-fluid mt-4 ps-4 pe-4 mb-3">
        <div className="row">
          <div className="col-md-4 pe-0">
            <TextField
              id="search-user-input"
              className="w-100"
              value={nameFilter}
              onChange={handleNameFilterChange}
              label={t("Search User")}
              InputProps={{
                endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
              }}
              size="small"
            />
          </div>
          {user.functionalities.findIndex(f => f.nome === "Add_users") >= 0 &&
            <div className="col">
              <ButtonGroup ref={anchorRef} data-joyride="btn-add-user">
                <Button
                  className="bgc-primary"
                  variant="contained"
                  onClick={handleToggle}>
                  {t("Insert")}
                </Button>
                <Button
                  size="small"
                  className="bgc-primary"
                  variant="contained"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={isMenuOpen}
                transition
                anchorEl={anchorRef.current}
                disablePortal
                placement={"bottom-start"}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {t(option)}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          }
          <div className="col">
            <div className="row d-flex justify-content-end">
              <div className="col d-flex justify-content-end align-items-center">
                <p className={cn("m-0 me-3", style["syncText"])}>{t("Last sync")}: {lastSyncDate ? moment(lastSyncDate).format('DD/MM/YYYY, h:mm') : t("never")}</p>
                <Button className={cn(!syncing ? style["syncButton"] : style["syncClicked"])} onClick={() => {handleModalOpen()}}  data-joyride="sync-ad">
                  {
                    syncing ?
                      <>
                        {t("Syncing")}
                        <FontAwesomeIcon icon={faArrowsRotate} size="sm" className="ms-2" />
                      </>
                      :
                      <>
                        {t("Sync Now")}
                        <FontAwesomeIcon icon={faCloudArrowDown} size="sm" className="ms-2" />
                      </>
                  }
                </Button>
              </div>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="col-6 d-flex justify-content-end me-0">
                <FormControlLabel control={
                  <Switch
                    checked={!onlyNotDeleted}
                    onChange={() => setOnlyNotDeleted(!onlyNotDeleted)}
                  />
                }
                label={t("Show only active").toString()}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 pb-4">
          <div className="col">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><b>{t("User")}</b></TableCell>
                    <TableCell align="left"><b>Email</b></TableCell>
                    <TableCell align="left"><b>{t("Active")}</b></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tenantUsers.length > 0 && tenantUsers.filter((u) => onlyNotDeleted ? u.isdeleted === false && u.username.toLowerCase().includes(nameFilter.toLowerCase()) : u.username.toLowerCase().includes(nameFilter.toLowerCase())).map((u) => (
                    <TableRow
                      className="table-hover-user"
                      key={u.pkusers}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {u.username}
                        {u.functionalities?.findIndex(f => f.nome === "Admin") >= 0 ? <FontAwesomeIcon icon={faCrown} size="1x" className="primary ms-2" /> : ''}
                      </TableCell>
                      <TableCell align="left">{u.email}</TableCell>
                      <TableCell align="left" data-joyride="user-active">{u.isactive ? t("Yes") : t("No")}</TableCell>
                      <TableCell align="right">
                        {
                          u.isdeleted === false &&
                          <>
                            {(homePage==="01" || user.functionalities.findIndex(f => f.nome === "Modify_user") >= 0) &&
                              <IconButton style={{ padding: 0 }} onClick={() => handleEdit(u)} className="me-1" data-joyride="user-edit">
                                <Edit fontSize="small" className="primary" />
                              </IconButton>
                            }
                            {(homePage==="01" || user.functionalities.findIndex(f => f.nome === "Delete_user") >= 0) &&
                              <IconButton style={{ padding: 0 }} onClick={() => handleDelete(u.email, u.pkusers,u.fkcustomers,u.username)}>
                                <Close fontSize="small" className="primary" />
                              </IconButton>
                            }
                          </>
                        }
                        {u.isdeleted && user.functionalities.findIndex(f => f.nome === "Enable_user") >= 0 &&
                          <FontAwesomeIcon icon={faUserCheck} size="1x" className="primary m-0 p-0 pointer" onClick={() => handleReactivate(u)}/>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <UserForm
          type={formType}
          selectedUser={selectedUser}
          open={isFormOpen}
          handleClose={handleCloseForm}
        />
      </div>
      <div className={cn("w-100 ps-3 pt-1", style["users-footer"])} >
        <h6>{t("Active users")}: {tenantUsers.filter((u)=>u.isactive && !u.isdeleted).length}/{usersLicense ? usersLicense : "--"}</h6>
      </div>
      { !user.isdemouser && <SyncModal showModal={modalOpen} onCloseModal={closeModal} ></SyncModal>}
    </>
  );
};

export default UsersManagement;