import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { ReportFilter } from '../../../models/report';
import { RequestResponse } from '../../../models/requestResponse';
import { AppThunk } from "../store";
import { getReportSuccess } from './report.store';

export const getReport = (report: ReportFilter): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `Report/v1/generatereport`,
    dispatch,
    auth: true,
    baseurl: false,
    httpOptions: {
      data: report
    }
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getReportSuccess(res.report));
    });
  } catch (err) {}
};