import { IconName, IconPack, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faX, far } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, MenuItem, Modal, Typography, createFilterOptions } from '@mui/material';
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { ControlledAutocomplete, ControlledSelect, ControlledSelectFile, ControlledTextField } from "../../../../core/components/mui-input/MuiInput";
import { removeAttachmentRoomForUpdate } from "../../../../core/store/room/administration-room/administration-room.actions";
import { RootState } from "../../../../core/store/store";
import { updateUIState } from "../../../../core/store/ui/ui.store";
import style from "../room-modal.module.scss";
library.add(far);

export type IconOption = {
    value: [IconPrefix, IconName];
    label: string;
};

type GroupOption = {
    options:IconOption[];
}

var options:IconOption[] = [];

Object.entries<IconPack>(
    //@ts-ignore
    library.definitions
  ).reduce((acc: GroupOption[], [prefix, iconPack]: [string, IconPack]): GroupOption[] => {
      const groupedOption: GroupOption = {
        options: Object.keys(iconPack).map(
          (icon: string): IconOption => {
            return {
              label: icon,
              value: [prefix as IconPrefix, icon as IconName]
            };
          }
        )
      };
      acc.push(groupedOption);
      options = groupedOption.options;
      return acc;
    },
    []
);

interface RoomDataFormProps{
    control: any;
    defaultValue: IconOption | undefined;
    setDefaultValue: React.Dispatch<React.SetStateAction<IconOption>>;
    fileName: string;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    IsShared: boolean;
    setIsShared: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface RoomFormDataFields {
    fksites: number;
    roomname: string;
    roomdescription: string;
    roomseats: number;
    attachment:FileList;
    icon: IconOption;
}

export const RoomDataForm: FC<RoomDataFormProps>= ({control, defaultValue, setDefaultValue, fileName, setFileName,IsShared,setIsShared}) =>{
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom);
    const { roomForUpdate } = useSelector((state: RootState)=> state.room.administrationRoom);
    const [attachmentOpen, setAttachmentOpen] = useState<boolean>(false);
    const iconFilter = createFilterOptions({
        matchFrom: 'start',
        limit: 150,
        stringify:(options: IconOption)=>options.label
    });
    const { user } = useSelector((state: RootState) => state.auth);

    const handleDeleteAttachment = () =>{
        dispatch(removeAttachmentRoomForUpdate());
    }

    const [numberOfSeats, setNumberOfSeats] = useState(0);
    const ChangeIsShared = () =>{
        if(!IsShared){
            setNumberOfSeats(1);
        }
        setIsShared(!IsShared);
    }


    useEffect(()=>{
        dispatch(updateUIState({type: "SET_RUN", run: true, stepIndex: 3}))
    }, [])

    return(
        //Form
        <Grid container spacing={1} sx={{padding:"1.5% 1.5%", height:"100%", overflow:"auto"}} >
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="w-100" data-joyride="belonging-site">
                    <ControlledSelect
                        name="fksites"
                        size="small"
                        required
                        label={t("Belonging Site")}
                        fullWidth
                        control={control}
                        options={adminSites.map((site)=>(
                            <MenuItem key={site.pksites} value={site.pksites}>
                                {site.parent === 0 ? site.sitename : adminSites.find(as => as.pksites === site.parent)?.sitename + " - " + site.sitename}
                            </MenuItem>
                        ))}
                        rules={{required: {value: true, message: t("Field Required")}}}
                    />
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="w-100" data-joyride="room-icon">
                    <ControlledAutocomplete
                        name="icon"
                        size="small"
                        control={control}
                        options={options}
                        defaultValue={defaultValue}
                        getOptionLabel={option => option.label}
                        setDefaultValue={setDefaultValue}
                        renderOption={(e,option)=>(
                            <Box component="li" {...e}>
                                <div className='p-2 bd-highlight align-middle h-100'>
                                    <FontAwesomeIcon icon={option.value}/>
                                </div>
                                <div className='p-2 bd-highlight align-middle h-100'>
                                    <Typography>{option.label}</Typography>
                                </div>
                            </Box>)
                        }
                        label={t("Icon")}
                        filterOptions={iconFilter}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                {
                    (roomForUpdate && roomForUpdate.room && roomForUpdate.room.fkattachments !== 0) ?
                    <div className="d-flex flex-row gap-2">
                        <Button
                            variant="contained"
                            fullWidth
                            className={cn(style["button-primary"])}
                            onClick={()=>setAttachmentOpen(true)}
                            disabled={(!roomForUpdate && !roomForUpdate.room && roomForUpdate.room.roomattachment === undefined)? true : false}
                        >
                            {t("View attachment")}
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            className={cn(style["button-primary"])}
                            onClick={handleDeleteAttachment}
                            style={{display: user.functionalities.findIndex(f => f.nome === "Delete_Room_Attachment") === -1 ? "none" : "block"}}
                            disabled={(!roomForUpdate && !roomForUpdate.room && roomForUpdate.room.roomattachment === undefined)? true : false}
                        >
                            {t("Delete attachment")}
                        </Button>
                    </div> :
                    <div style={{display: user.functionalities.findIndex(f => f.nome === "Add_Room_Attachment") === -1 ? "none" : "flex", flexDirection:"column"}} data-joyride="room-image">
                        <ControlledSelectFile
                            name="attachment"
                            size="small"
                            control={control}
                            label={t("Room Image")}
                            fileName={fileName}
                            setFileName={setFileName}
                        />
                        <p className={cn(style["img-desc"])}>{t("Raccomended resolution")} 800 x 600</p>
                    </div>
                }

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="w-100" data-joyride="room-name">
                    <ControlledTextField
                        name="roomname"
                        size="small"
                        label={t("Name")}
                        fullWidth
                        control={control}
                        required
                        rules={{required: {value: true, message: t("Field Required")},maxLength:50}}
                    />
                </div>
            </Grid>
            {
                user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ?
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="w-100" data-joyride="room-seats">
                        <ControlledTextField
                        name="roomseats"
                        label={t("Number of bookable seats")}
                        fullWidth
                        type="number"
                        control={control}
                        size="small"
                        rules={IsShared || user.ispremium ? {required: false} : {required: {value: true, message: t("Field Required")},min:0}}
                        disabled = {IsShared}
                        required={IsShared || user.ispremium ? false : true}
                        style = {{display: IsShared || user.ispremium  ? "none" : "block"}}
                        value = {numberOfSeats}
                        onChange={(e) => setNumberOfSeats(parseInt(e.target.value))}
                        />
                        <ControlledTextField
                            name="sharedroomseats"
                            label={t("Number of bookable seats")}
                            fullWidth
                            type="number"
                            control={control}
                            size="small"
                            required
                            rules={{required: {value: true, message: t("Field Required")},min:0}}
                            disabled = {IsShared}
                            style = {{display: IsShared ? "block" : "none"}}
                            defaultValue = {1}
                        />
                        {
                            user.ispremium ?
                            <><input checked={IsShared} type="checkbox" onClick={ChangeIsShared} /> {t("Shared resource")}</>
                            :
                            <></>
                        }
                    </div>
                </Grid>
                :
                <></>
            }
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="w-100" data-joyride="room-description">
                    <ControlledTextField
                        name="roomdescription"
                        size="small"
                        label={t("Description")}
                        multiline
                        rows={3}
                        fullWidth
                        control={control}
                        rules={{maxLength:250}}
                    />
                </div>
            </Grid>
            <Modal
                open={attachmentOpen}
                onClose={()=>setAttachmentOpen(false)}
            >
                <div className={cn(style["img-center"], "p-3")}>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row-reverse ">
                            <button className={cn(style["font-button"])}>
                                <FontAwesomeIcon icon={faX} onClick={()=>setAttachmentOpen(false)}/>
                            </button>
                        </div>
                        {roomForUpdate && roomForUpdate.room && roomForUpdate.room.roomattachment !== undefined && <img src={"data:image/jpeg;base64,"+roomForUpdate.room.roomattachment} style={{maxWidth:"90%"}} key={Date.now()} />}
                    </div>
                </div>
            </Modal>
        </Grid>

    )
}