import Webpage from "../pages/webpage/Webpage";

type MsTeamsRouteProps = {
    isMsTeams: boolean
}

const MsTeamsRoute: React.FC<MsTeamsRouteProps> = ({children, isMsTeams}) => {
    return (
        <>
        {isMsTeams? 
            children
            : 
            <Webpage/>
        }
        </>
    )
}

export default  MsTeamsRoute