import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerSettingsProfiles } from "../../../../models/customerSettingsProfiles";

interface CustomerSettingsProfilesStore {
  customerSettingsProfiles: CustomerSettingsProfiles[];
}

export const customerSettingsProfilesSlice = createSlice({
  name: "customer/settings",
  initialState: {
    customerSettingsProfiles: null
  } as CustomerSettingsProfilesStore,
  reducers: {
    getCustomerSettingsProfilesSuccess: (state, action: PayloadAction<CustomerSettingsProfiles[] | null>) => {
      state.customerSettingsProfiles = action.payload;
    }
  },
});

export const {
  getCustomerSettingsProfilesSuccess
} = customerSettingsProfilesSlice.actions;
