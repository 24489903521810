import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MultipleReservation } from "../../../../models/multipleReservation";
import { ReservationInfo } from "../../../../models/reservation";
import { Room, RoomSeat } from "../../../../models/room";
import { Shift } from "../../../../models/shift";
import { Site } from '../../../../models/site';
export interface Attachment{
  pkattachment: number;
  attachment: string;
}
export interface UserRoomStore {
  selectedRoom: Room | null;
  roomReservations: Shift[];
  reservationsBydate: Shift[];
  userSites: Site[];
  availableRoomsByDate: Room[];
  shiftsAvailableFromDateToDate: MultipleReservation[]
  AllRoomsAndShifts: Room[];
  userSitesChanged: boolean;
  imageHasLoaded: boolean;
  selectedTab: string | null;
  availableRoomSeats: RoomSeat[];
  roomSeatsList: RoomSeat[];
  unavailableRoomSeatsNumber: number;
  reservationInfo: ReservationInfo | null;
  checkRoomIntersectingTimeSlots: boolean;
  roomUserSelected: Room[];
}

export const userRoomSlice = createSlice({
  name: "room/user",
  initialState: {
    selectedRoom: null,
    roomReservations: [],
    reservationsBydate: [],
    attachment: [],
    userSites: [],
    availableRoomsByDate: [],
    shiftsAvailableFromDateToDate: [],
    AllRoomsAndShifts: [],
    userSitesChanged: false,
    imageHasLoaded: false,
    selectedTab: null,
    availableRoomSeats: [],
    roomSeatsList: [],
    unavailableRoomSeatsNumber: 0,
    reservationInfo: null,
    checkRoomIntersectingTimeSlots: false,
    roomUserSelected: []
  } as UserRoomStore,
  reducers: {
    getRoomSuccess: (state, action: PayloadAction<Room | null>) => {
      state.selectedRoom = action.payload;
    },
    getRoomReservations: (state, action: PayloadAction<Shift[]>) =>{
      state.roomReservations = action.payload;
    },
    getReservationsByDate : (state, action: PayloadAction<Shift[]>) => {
      state.reservationsBydate = action.payload;
    },
    getUserSitesSuccess: (state, action: PayloadAction<Site[]>) => {
      state.userSites = action.payload;
    },
    getAvailableRoomsAndShiftsByDateSuccess: (state, action: PayloadAction<Room[]>) => {
      state.availableRoomsByDate = action.payload;
    },
    getShiftsAvailableFromDateToDateSuccess: (state, action: PayloadAction<MultipleReservation[]>) => {
      state.shiftsAvailableFromDateToDate = action.payload;
    },
    getAllRoomsAndShiftsSuccess: (state, action: PayloadAction<Room[]>) => {
      state.AllRoomsAndShifts = action.payload;
    },
    getUserSitesChangedSuccess: (state, action: PayloadAction<boolean>) => {
      state.userSitesChanged = action.payload;
    },
    getUserRoomsImageSuccess: (state, action: PayloadAction<Site[]>) => {
      let newSites = state.userSites;
      action.payload.forEach(s=>{
        s.rooms.forEach(r=>{
          newSites.find(ns=> ns.pksites === s.pksites)?.rooms?.forEach((nr) => {
            if(nr.pkrooms === r.pkrooms) {
              nr.roomattachment = r.roomattachment;
              nr.mapattachment = r.mapattachment;
            }
          })
        });
      });

      state.userSites = newSites;
    },
    getRoomMapSuccess: (state, action: PayloadAction<{pksite:number, pkroom:number, roommap:string}>) =>{
      state.userSites
        .find(s=>s.pksites === action.payload.pksite).rooms
        .find(r=>r.pkrooms === action.payload.pkroom)
        .mapattachment = action.payload.roommap;
    },
    getImageHasLoadedSuccess: (state, action: PayloadAction<boolean>) => {
      state.imageHasLoaded = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    getAvailableRoomSeatsSuccess: (state, action: PayloadAction<RoomSeat[]>) => {
      state.availableRoomSeats = action.payload;
    },
    getRoomseatsForRoomSuccess: (state, action: PayloadAction<RoomSeat[]>) => {
      state.roomSeatsList = action.payload;
    },
    getNotAvailableSeatsNumberSuccess: (state, action: PayloadAction<number>) => {
      state.unavailableRoomSeatsNumber = action.payload;
    },
    getReservationInfoSuccess: (state, action: PayloadAction<ReservationInfo>) =>{
      state.reservationInfo = action.payload;
    },
    getCheckRoomIntersectingTimeSlots: (state, action: PayloadAction<boolean>) =>{
      state.checkRoomIntersectingTimeSlots = action.payload;
    },
    getRoomUserSelectedSuccess:(state, action: PayloadAction<Room[]>) =>{
      state.roomUserSelected = action.payload;
    },
  }
});

export const {
  getRoomReservations,
  getRoomSuccess,
  getReservationsByDate,
  getUserSitesSuccess,
  getAvailableRoomsAndShiftsByDateSuccess,
  getShiftsAvailableFromDateToDateSuccess,
  getAllRoomsAndShiftsSuccess,
  getUserSitesChangedSuccess,
  getUserRoomsImageSuccess,
  getRoomMapSuccess,
  getImageHasLoadedSuccess,
  setSelectedTab,
  getAvailableRoomSeatsSuccess,
  getRoomseatsForRoomSuccess,
  getNotAvailableSeatsNumberSuccess,
  getReservationInfoSuccess,
  getCheckRoomIntersectingTimeSlots,
  getRoomUserSelectedSuccess
} = userRoomSlice.actions;
