import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Moment } from "moment";
import { License } from "../../../models/license";

export interface ICustomer {
    nextLicenceStartdate: Moment | string | undefined;
    currentLicense: License;
    emailState: "email-not-valid"|"valid-email"|"link-sent"|"validated";
}

export const customerSlice = createSlice({
    name: "customer",
    initialState: {
        nextLicenceStartdate: undefined,
        currentLicense: undefined,
        emailState: "email-not-valid"
    } as ICustomer,

    reducers: {
        getNextLicenseStartdateSuccess:(state, action: PayloadAction<Moment | string>) =>{
            state.nextLicenceStartdate = action.payload;
        },
        getCurrentLicenseSuccess:(state, action: PayloadAction<License>) =>{
            state.currentLicense = action.payload;
        },
        setEmailState:(state, action:PayloadAction<"email-not-valid"|"valid-email"|"link-sent"|"validated">) =>{
            state.emailState = action.payload;
        }
    }
});

export const {
    getNextLicenseStartdateSuccess,
    getCurrentLicenseSuccess,
    setEmailState
} = customerSlice.actions;
