import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersUsrGroups, UsrGroup, UsrGroupsRoom } from "../../../../models/user";
import { TeamGroups } from '../../../../models/teamgroups';

interface GroupsStore {
    groupsList: UsrGroup[]
    groupsUsers: UsersUsrGroups[]
    groupsRooms: UsrGroupsRoom[]
    teamGroups: TeamGroups[]
}

export const groupsSlice = createSlice({
    name: "group",
    initialState: {
        groupsList: [],
        groupsUsers: [],
        groupsRooms: [],
        teamGroups: []
    } as GroupsStore,
    reducers: {
        getGroupsListSuccess: (state, action: PayloadAction<UsrGroup[]>) => {
            state.groupsList = action.payload;
        },
        getUsersUsrGroupsListSuccess: (state, action: PayloadAction<UsersUsrGroups[]>) => {
            state.groupsUsers = action.payload;
        },
        getGroupsRoomsSuccess: (state, action: PayloadAction<UsrGroupsRoom[]>) => {
            state.groupsRooms = action.payload;
        },
        getTeamGroupsSuccess: (state, action: PayloadAction<TeamGroups[]>) => {
            state.teamGroups = action.payload;
        }
    },
})

export const { 
    getGroupsListSuccess,
    getUsersUsrGroupsListSuccess,
    getGroupsRoomsSuccess,
    getTeamGroupsSuccess
} = groupsSlice.actions;