import { faMagnifyingGlass, faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import {
  DisableLicense,
  EnableLicense,
  UpdateLicense,
  getAllCustomersPkAndName,
  getAlllicenses,
  getCountLicenses,
} from "../../../core/store/superadmin/superadmin.actions";
import {
  getAllUsers,
  getClientLicense,
} from "../../../core/store/users/users.actions";
import { License } from "../../../models/license";
import LicenseForm from "./LicenseForm";
import style from "./SALicensesManagement.module.scss";

export const SALicensesManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nameFilter, setNameFilter] = useState<string>("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [formType, setFormType] = useState<"Edit" | "Add">("Add");
  const { allLicenses,allCustomersPkAndName,numberLicensesPages } = useSelector((state: RootState) => state.superadmin);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [onlyActive, setOnlyActive] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);

  const handleDisable = (L: License) => {
        setSelectedLicense(L);
        dispatch(DisableLicense(L,false,onlyActive,nameFilter));
  };

  const handleEdit = (Licenza: License) => {
    let message: DialogMessageType = {
      dialogMsg: (
        <div className="msg">
          {t("Si sta cercando di modifcare la licenza per") +
            (allCustomersPkAndName.length > 0) ? allCustomersPkAndName.find(c => c.pkcustomers == Licenza.fkcustomers).customername : ""}
        </div>
      ),
      dialogTitle: t("Attention"),
      dialogAcceptButton: t("Yes"),
      dialogDismissButton: t("Cancel"),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(UpdateLicense(onlyActive,Licenza,nameFilter));
        dispatch(getDialogMessage(message));
      },
    };
    setFormType("Edit");
    setSelectedLicense(Licenza);
    setIsFormOpen(true);
  };

  const handleToggle = () => {
    setFormType("Add");
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedLicense(null);
    setIsFormOpen(false);
  };

  const handleEnable = (L: License) => {
    let message: DialogMessageType = {
      dialogMsg: (
        <div className="msg">
          {t("Si sta cercando di reattivare la licenza per ") +
            (allCustomersPkAndName.length > 0) ? allCustomersPkAndName.find(c => c.pkcustomers == L.fkcustomers).customername : ""}
          <br />
        </div>
      ),
      dialogTitle: t("Attention"),
      dialogAcceptButton: t("Yes"),
      dialogDismissButton: t("Cancel"),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        // setSelectedLicense(L);
        dispatch(EnableLicense(L,onlyActive,nameFilter));
      },
    };

    dispatch(getDialogMessage(message));
  };

  useEffect(() => {
    setCurrentPage(1);
    dispatch(getAlllicenses(onlyActive,1,nameFilter));
    dispatch(getCountLicenses(onlyActive,nameFilter));
  }, [onlyActive]);

  useEffect(() => {
    dispatch(getAllCustomersPkAndName());
    dispatch(getAlllicenses(onlyActive,currentPage,nameFilter));
    dispatch(getCountLicenses(onlyActive,nameFilter));
    dispatch(getAllUsers());
    dispatch(getClientLicense());
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    dispatch(getAlllicenses(onlyActive,value,nameFilter));
  };

  const handleSearch = () =>{
    setCurrentPage(1);
    dispatch(getAlllicenses(onlyActive,1,nameFilter));
    dispatch(getCountLicenses(onlyActive,nameFilter));
  };

  return (
    <>
      <div className="container-fluid mt-4 ps-4 pe-4 mb-3">
        <div className="row">
          <div className="col-md-4 pe-0">
            <TextField
              id="search-user-input"
              className="w-100"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              label={t("Search Customer")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize={"small"} />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </div>
          <div className="col">
            <IconButton onClick={handleSearch} className={cn(style["search-btn-desktop"])}>
                <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
            </IconButton>
            {user.functionalities.findIndex(
              (f) => f.nome === "SA_Add_License"
            ) >= 0 && (
              <ButtonGroup className="h-100" ref={anchorRef}>
                <Button
                  className="bgc-primary"
                  variant="contained"
                  onClick={handleToggle}
                >
                  {t("Insert")}
                </Button>
              </ButtonGroup>
            )}
          </div>
          <div className="col-3 d-flex justify-content-end">
            <FormControlLabel
              control={
                <Switch
                  checked={onlyActive}
                  onChange={() => setOnlyActive(!onlyActive)}
                />
              }
              label={t("only licenses active").toString()}
            />
          </div>
        </div>
        <div  className={cn(style["divPageMargin"])}>
            <Pagination  className={cn(style["paginationCenter"])} count={numberLicensesPages} page={currentPage} defaultPage={1} showFirstButton showLastButton onChange={handleChange} />
        </div>
        <div className="row mt-4 pb-4">
          <div className="col">
            <TableContainer>
              <Table size="small" className={cn("table-responsive-xl")}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>{t("Customer Name")}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{t("Number Users")}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{t("Start Date")}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{t("Expiration date")}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{t("Status License")}</b>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLicenses.length > 0 &&
                    allLicenses
                      .map((u) => (
                        <TableRow
                          className="table-hover-user"
                          key={u.pklicenses}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <div style={{ display: "inline-block" }}>
                              {(allCustomersPkAndName.length > 0) ? allCustomersPkAndName.find(c => c.pkcustomers == u.fkcustomers).customername : ""}&nbsp;
                            </div>{" "}
                            <div
                              style={{ display: "inline-block" }}
                              className={cn(style["note"])}
                            >
                              {" "}
                              {u.note}{" "}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {u.numusers === 9999999
                              ? "Nessun Limite"
                              : u.numusers}
                          </TableCell>
                          <TableCell align="left">
                            {moment(u.startdate).format("D/M/YYYY")}
                          </TableCell>
                          <TableCell align="left">
                            {moment(u.enddate).format("D/M/YYYY") === "1/1/0001"
                              ? "N/D"
                              : moment(u.enddate).format("D/M/YYYY")}
                          </TableCell>
                          <TableCell align="left">
                            {!u.isdeleted && (moment(u.startdate) < moment(Date.now()) && (moment(u.enddate) > moment(Date.now()) || u.enddate == null)) ? t("Attivo") : t("Disattivato")}
                          </TableCell>
                          <TableCell align="right">
                            {u.isdeleted ? (
                              <>
                                {user.functionalities.findIndex(
                                  (f) => f.nome === "SA_Modify_License"
                                ) >= 0 && moment(u.enddate) >= moment(Date.now()) && (
                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title="Edit"
                                  >
                                    <IconButton
                                      style={{ padding: 0 }}
                                      onClick={() => handleEdit(u)}
                                      className="me-1"
                                    >
                                      <Edit
                                        fontSize="small"
                                        className="primary"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  disableFocusListener
                                  disableTouchListener
                                  title="Activate"
                                >
                                  <FontAwesomeIcon
                                    icon={faPlugCircleCheck}
                                    onClick={() => handleEnable(u)}
                                    className="primary"
                                    fontSize="small"
                                  />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {user.functionalities.findIndex(
                                  (f) => f.nome === "SA_Modify_License"
                                ) >= 0 && moment(u.enddate) >= moment(Date.now()) && (
                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={t("Edit")}
                                  >
                                    <IconButton
                                      style={{ padding: 0 }}
                                      onClick={() => handleEdit(u)}
                                      className="me-1"
                                    >
                                      <Edit
                                        fontSize="small"
                                        className="primary"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {user.functionalities.findIndex(
                                  (f) => f.nome === "SA_Delete_License"
                                ) >= 0 && moment(u.enddate) >= moment(Date.now()) &&(
                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={t("Deactivate")}
                                  >
                                    <PowerSettingsNewIcon
                                      className="primary pointer"
                                      fontSize="small"
                                      onClick={() => handleDisable(u)}
                                    ></PowerSettingsNewIcon>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  {/* )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div  className={cn(style["divPageMargin"])}>
            <Pagination  className={cn(style["paginationCenter"])} count={numberLicensesPages} page={currentPage} defaultPage={1} showFirstButton showLastButton onChange={handleChange} />
        </div>
        <LicenseForm
          type={formType}
          selectedLicense={selectedLicense}
          open={isFormOpen}
          handleClose={handleCloseForm}
          onlyActive={onlyActive}
          nameFilter={nameFilter}
        />
      </div>
    </>
  );
};

export default SALicensesManagement;
