import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import cn from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import svgImage from "../../../asset/img/email_validation.svg";
import { CheckIfEmailIsValidated, SendLinkEmail } from "../../../core/store/customer/customer.action";
import { setEmailState } from "../../../core/store/customer/customer.store";
import { RootState } from "../../../core/store/store";
import css from "./AlertPendingCustomer.module.scss";

const AlertPendingCustomer: React.FC = () => {
    const dispatch = useDispatch();
    const [contactEmail, setContactEmail] = useState<string>("");
    const [isEmailComunicationAccepted, setIsEmailComunicationAccepted] = useState<boolean>(false);
    const { t } = useTranslation();
    const { emailState } = useSelector((state: RootState) => state.customer);
    const { user }= useSelector((state: RootState) => state.auth);

    const sendEmail = () => {
        dispatch(SendLinkEmail(contactEmail));
    }


    /**
     * gestisce il comportamento del pulsante send link (enabled, disabled)
     * @param e input mail
     */
    const mailChange = (e) => {
        const value = e.target.value.trim();
        setContactEmail(value);

        var mailformat = /(.+)@(.+){2,}\.(.+){2,}/;

        if (value === undefined) {
            dispatch(setEmailState("email-not-valid"))
        } else if (!mailformat.test(value)) {
            dispatch(setEmailState("email-not-valid"))
        } else {
            dispatch(setEmailState("valid-email"))
        }
    }

    const resetEmail = () => {
        setContactEmail("");
        dispatch(setEmailState("email-not-valid"));
    }

    const btnClick = () =>{
        if (emailState === "valid-email") {
            sendEmail();
        } else if (emailState === "link-sent") {
            dispatch(CheckIfEmailIsValidated(contactEmail, user.fkcustomers, isEmailComunicationAccepted));
        }
    }

    return (
        <div className='container-fluid d-grid justify-content-center my-4'>
            {/* Image */}
            <div className="row">
                <div className='col text-center'>
                    <img
                        className={cn(css["icon-image"])}
                        src={svgImage}
                        alt=""
                    />
                </div>
            </div>

            {/* TEXT */}
            <div className='row mt-3 mb-4'>
                <h2 className={cn(css["title"], "text-center primary mb-2")}>{t("Almost there!")}</h2>
                <h4 className={cn(css["subtitle"], "text-center")}>{t("Pending customer msg")}</h4>
            </div>

            {/* FORM */}
            <div className="row justify-content-center">
                <div className="col-8">
                    <div className="row mb-3">
                        <TextField
                            sx={{ width: "100%" }}
                            label={t("Contact email")}
                            disabled={emailState === "validated" || emailState === "link-sent"}
                            required
                            onChange={mailChange}
                            value={contactEmail}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CloseIcon onClick={resetEmail} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <div className="row mb-3">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isEmailComunicationAccepted}
                                    onChange={(e)=>setIsEmailComunicationAccepted(e.target.checked)}
                                />
                            }
                            label={t("Customer email comunication msg")}
                        />

                    </div>

                    <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                            <Button
                                variant="contained"
                                className="mb-1"
                                onClick={btnClick}
                                disabled={emailState === "email-not-valid" || emailState === "validated"}
                            >
                                {(emailState === "link-sent") ? t("Validate") : t("Send link")}
                            </Button>
                            {emailState === "link-sent" &&
                                <small
                                    className={cn(css["pointer"], "primary")}
                                    onClick={sendEmail}
                                >{t("Send Again")}</small>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AlertPendingCustomer
