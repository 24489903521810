import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import css from "./toolbar.module.scss";
import { ToolbarSubmenu } from "./store/toolbar.store";
import { useTranslation } from "react-i18next/";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRightFromBracket, faGraduationCap, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { saveLogout } from "../../store/auth/auth.actions";
import { setRelevantCategory, setShowGuides } from "../../store/tutorials/guide.store";
import { setPopupState, setPopupUserGuide, setPopupVariant } from "../../store/popup/popup.store";
import { getGuideFile } from "../../store/tutorials/guide.actions";
import { updateUIState } from "../../store/ui/ui.store";

interface ToolbarDesktopProps {
  submenu: ToolbarSubmenu[];
  displayReservation: boolean;
}

const ToolbarDesktop: React.FC<ToolbarDesktopProps> = ({
  submenu,
  displayReservation,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showGuides, guidesList, relevantCategory } = useSelector((state: RootState) => state.guide)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item?) => {
    if(item && item.title){
      if((item.title as string).toLowerCase() == "users management"){
        dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 10 }))
      }
      else if((item.title as string).toLowerCase() == "groups management"){
        dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 17 }))
      }
    }
    setAnchorEl(null);
  };

  const handleLogout = () =>{
    dispatch(saveLogout());
  }

  const changeShowGuidesState = () => {
    dispatch(setShowGuides(!showGuides))
  }

  const openUserGuide = (pk) => {
    dispatch(setPopupVariant("userGuide"))
    const index = guidesList.findIndex(g => g.pkuserguides === pk)
    dispatch(getGuideFile(guidesList[index].filename))
    dispatch(setPopupUserGuide(guidesList[index]))
    dispatch(setPopupState(true))
  }

  const handleLinkClick = (submenu: ToolbarSubmenu) => {
    dispatch(setRelevantCategory(submenu.guideCategory ? submenu.guideCategory : 0));
    if(submenu.title.toLowerCase() === "admin"){
      dispatch(updateUIState({type: "SET_RUN", run: false, stepIndex: 1}))
    }
  }

  return (
    <>
      {/* Guide utente consigliate */}
      <div className={cn(css["guideList"], "container-fluid p-3", showGuides ? "d-block" : "d-none")}>
        {
          guidesList.filter(g => g && (relevantCategory != 0 ? g.fkguidescategories == relevantCategory : 1)).slice(0, 4).map((g, i) => 
            <div className="row py-2" key={"suggestedRow" + i}>
              <div className={cn(css["listElement"], "col-auto")} onClick={() => openUserGuide(g.pkuserguides)}>
                <span>{g.name}</span>
              </div>
            </div>
          )
        }
        <hr />
        <div className="row">
          <div className="col-auto" onClick={changeShowGuidesState}>
            <Link to={"/tutorials"} className={css["tutorialsLink"]}>
              {t("Show all user guides")} &nbsp; <FontAwesomeIcon icon={faArrowRight}/>
            </Link>
          </div>
        </div>
      </div>
      {submenu.map((s, index) => (
        <span className="d-flex" key={s.title}>
          {!s.dropDownMenu.isDropDown && (
            <>
              <Link
                to={s.path}
                className={cn(css["submenu-link"], s.path === document.location.pathname ? css.active : "")}
                onClick={() => handleLinkClick(s)}
              >
                <h4 className={cn("h-100 pt-1 pb-1 mb-0 d-flex align-items-center", css["menu-link"])} data-joyride={s.title.toLowerCase()}>
                  {t(s.title)}
                </h4>
              </Link>
            </>
          )}
          {s.dropDownMenu.isDropDown && (
            <>
              <h4 onClick={handleClick} className={cn(css["txtmenu-link"], "mb-0")} data-joyride={s.title.toLowerCase()}>
                  <span className={cn("h-100 pt-1 pb-1 mb-0 d-flex align-items-center", css["submenu-link"])} >
                    {t(s.title)}&nbsp;
                    <KeyboardArrowDownIcon />
                  </span>
              </h4>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {s.dropDownMenu.menuItems.map((i) => (
                  <Link
                    to={i.path}
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    className={cn( css["submenu-link"], i.path === document.location.pathname ? css.active : "")}
                  >
                    <h4>
                      <MenuItem onClick={() => handleClose(i)}>{t(i.title)}</MenuItem>
                    </h4>
                  </Link>
                ))}
              </Menu>
            </>
          )}

          <div className={cn(css["icon-section"])}>
            {index === submenu.length ? null : (<span className={cn(css["submenu-separator"], (index == submenu.length-1 ? css["last-separator"] : ""))} />)}
          </div>

        </span>
      ))}
      {user.functionalities.findIndex((f) => f.nome === "View_settings") >=
        0 && (
          <Tooltip id="settings" title={t("Settings")}>
            <div className={cn(css["icon-section"], "pb-1")}>              
              <Link to="/settings">
                <IconButton>
                    <FontAwesomeIcon
                      icon={faGear}
                      className={cn(css["settingIcon"])}
                    />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )
      }
      {user.functionalities.findIndex(f => f.nome === "User_guides") >= 0 && 
        <Tooltip id="tutorial" title={t("Tutorials")}>
          <div className={cn(css["icon-section"], "pb-1")}>
            <IconButton
              onClick={changeShowGuidesState}>
              <FontAwesomeIcon 
                icon={faGraduationCap}
                className={cn(css["settingIcon"])}
              />
            </IconButton>
          </div>
        </Tooltip>
      }
      {user && 
        <Tooltip id="logout" title={t("Logout")}>
          <div className={cn(css["icon-section"], "pb-1")}>
            <IconButton onClick={handleLogout}>
                <FontAwesomeIcon 
                  icon={faRightFromBracket}
                  className={cn(css["settingIcon"])}
                />
            </IconButton>
          </div>
        </Tooltip>
      }
    </>
  );
};

export default ToolbarDesktop;
