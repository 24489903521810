
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import loader from '../../../asset/img/logo-loader.svg'
import { toggleLoader } from './store/loader.store'
import { RootState } from '../../store/store'
import css from './loader.module.scss'
import cn from 'classnames'

const Loader: React.FC = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {visible, counter} = useSelector((state: RootState) => state.loader)

    useEffect(() => {
        if (counter){
            dispatch(toggleLoader)
        }
    }, [counter, dispatch])
    
    const load = (
        <>
        <div className={css["bg-loader"]}></div>
        <div className={cn("col-3", css.loader)}>
            <img src={loader} width="50%" alt={t("Loading") + "..."}/>
        </div>
        </>
    )

    return (
        <>
        {visible && load}
        </>  
    )
}

export default Loader