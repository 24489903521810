import { useTranslation } from "react-i18next";
import bg from "../../asset/img/openinteams.svg";
import css from "./webpage.module.scss";
import cn from "classnames";

const Webpage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row mt-3 d-flex justify-content-center">
        <img
          className={cn(css["background-image"], "mt-4")}
          src={bg}
          alt={t("Couple flying away with a jetpack")}
        />
      </div>
      <div className="row mt-4 d-flex justify-content-center">
        <h2 className={cn("text-center", css["redirect-text"])}>
          {t("Sorry, this app is only available on Microsoft Teams")}
        </h2>
      </div>
      <div className="row mt-2">
        <div className="col d-flex justify-content-center">
         
        <a href="https://teams.microsoft.com/" target="_blank" className={cn("btn", css["teams-purple"])}>
          
            {t("Open in Teams")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Webpage;
