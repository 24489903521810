import { TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "./HookFormProps";

export const ControlledTextField: FC<HookFormProps & TextFieldProps> = (props)=>{
    return(
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => (
            <TextField
            {...props}
            defaultValue={props.defaultValue}
            error={error && true}
            helperText={error && error.message}
            onChange={(e) => {
                onChange(e);
                if(props.onChange){
                    props.onChange(e);
                }
            }}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            />
        )}
        />
    )
}