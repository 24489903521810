import { Button, TextField } from "@mui/material";
import * as React from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "./HookFormProps";
import cn from "classnames";
import style from "../../../pages/rooms-management/rooms-management.module.scss";
import { useTranslation } from 'react-i18next';

interface ControlledSelectFileProps{
    label: string,
    size?:"small" | "medium",
    fileName: string,
    setFileName: React.Dispatch<React.SetStateAction<string>>
}

export const ControlledSelectFile: React.FC<HookFormProps & ControlledSelectFileProps> = (props)=>{
    
    const { t } = useTranslation()
    return(
        <div className="d-flex flex-row gap-1 h-100 w-100"> 
            <TextField
                size={props.size}
                fullWidth
                disabled
                label={props.label}
                value={props.fileName}
            />
            <label htmlFor="upload-photo">

            <Controller
                name={props.name}
                control={props.control}
                render={({
                    field: { onChange, onBlur, name: fieldName, value },
                    fieldState: { error }
                }) => (
                        <input 
                            id="upload-photo"
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={(elem)=>{
                                props.setFileName(elem.target.value.replace("C:\\fakepath\\",""));
                                onChange(elem.target.files)
                            }}
                            
                        />
                )}
            />
            
            <Button variant="contained" component="span" className={cn(style["button-primary"])}> {t("Browse")} </Button>
            </label>
        </div>

            
    )
}