import { TabContext, TabPanel } from "@mui/lab";
import { Tabs, Tab, IconButton, Button } from "@mui/material";
import { FC, useState } from "react"
import { WhereaboutsUser } from "../../models/whereabouts";
import cn from "classnames";
import style from "./whereabouts.module.scss";
import moment, { Moment } from "moment";
import { momentToDateString } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { useDispatch, useSelector } from "react-redux";
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";
import { AddUserReservation } from "../../core/store/room/user-room/user-room.actions";
import { RootState } from "../../core/store/store";

interface WhereaboutsTabProps{
    userReservation: WhereaboutsUser;
    days: string[]
    pkuser: number;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRoomImage: React.Dispatch<React.SetStateAction<string>>;
    mobile: boolean;
}

export const WhereaboutsTabs: FC<WhereaboutsTabProps> = (props) =>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("0");
    const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom);

    const handleReserve = (pkshifts: number, reservationDate: Moment, roomname: string, shiftname: string, pkuser:number) =>{
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Do you want to reserve this seat?") + "\n"+ moment(reservationDate).format("DD/MM/YYYY") + "\n" + roomname + " - " + shiftname}</div>),
            dialogTitle : t("Reservation summary"),
            dialogAcceptButton: t("Reserve"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                dispatch(AddUserReservation(pkshifts, reservationDate, pkuser, 3));
            }
        }
        dispatch(getDialogMessage(message))
    }

    const handleClickImage = (pksites: number, pkrooms: number) =>{
        const roomImage = adminSites.find(a=> a.pksites === pksites).rooms.find(room=> room.pkrooms === pkrooms).roomattachment;
        props.setRoomImage((roomImage)? roomImage: "");
        props.setIsModalOpen(true);
    }

    return(
        <>
        <TabContext value={value}>
            <Tabs
                value={value}
                onChange={(e, newValue)=>setValue(newValue)}
                variant="scrollable"
                orientation={(props.mobile)?"horizontal": "vertical"}
                sx={(props.mobile)?{}:{ borderRight: 1, borderColor: 'divider' }}
            >
                {
                    props.days.map((_day, index)=>{
                        const day = moment(_day, "DD-MM-YYYY")
                        return(
                            <Tab label={day.format("DD/MM")} value={index.toString()}/>
                        )
                    })
                }
            </Tabs>
            {
                props.days.map((_day, index)=>{
                    const day = moment(_day, "DD-MM-YYYY");
                    const dayRes = props.userReservation.reservations.filter(r=>moment(r.reservationdate).format("DD-MM-YYYY") === _day);
                    return (
                        <TabPanel value={index.toString()} className={(props.mobile)?"px-2": "w-100 pt-0"} style={!props.mobile? {"overflowY": "auto", "overflowX": "hidden"} : {}}>
                            <div className="col-12 w-100">
                                <h5 className={cn(style["title"], "mb-3")}>
                                    {momentToDateString(day).join(" ")}
                                </h5>
                                <div className="row">
                                    {(dayRes && dayRes.length > 0)?
                                    <>
                                        {dayRes.map((r, index, tot) => 
                                        <>
                                            <div className={"d-flex justify-content-center "+((props.mobile)?"col-2 ":"col-1")}>
                                                <IconButton 
                                                    className={cn(style["tab-btn"])} 
                                                    onClick={()=> handleClickImage(r.fksites, r.pkrooms) }
                                                >
                                                    <FontAwesomeIcon icon={faCamera} />
                                                </IconButton>
                                            </div>
                                            <div className={"d-flex flex-column justify-content-center "+((props.mobile)?"col-7":"col-10")}>
                                                <p className={cn(style["room-name"])}>{r.roomname}</p>
                                                <p className={cn(style["shift-name"])}>{r.shiftname}</p>
                                            </div>
                                            <div className={"d-flex justify-content-center "+((props.mobile)?"col-3":"col-1")}>
                                                {
                                                    (r.alreadybooked)?
                                                        <Button variant="text" disabled>{t("Already Reserved")}</Button> : 

                                                        (r.seatavailable === true)?
                                                            <Button className={cn(style["tab-btn"])} variant="text" onClick={() => handleReserve(r.fkshifts, moment(r.reservationdate), r.roomname, r.shiftname, props.pkuser)}> {t("Reserve")} </Button>
                                                            :
                                                            <Button variant="text" disabled> {t("Not available")} </Button>
                                                        
                                                }
                                                
                                            </div>
                                            {
                                                (tot[index+1])? 
                                                    <div className={cn(style["divider"],"mt-1 mb-1")}></div>
                                                : ""
                                            }
                                        </>
                                        )} 
                                    </>: <h6 className={cn(style["info-text"])}>{t("No reservations")}</h6>
                                    }
                                    
                                </div>
                            </div>
                        </TabPanel>
                    )
                })
            }
        </TabContext>

        </>
    );
}