import { useState, useEffect, FC } from "react";
import style from './CustomizeApp.module.scss';
import cn from "classnames"
import { Box, Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import imageLogo from "../../asset/img/images.png"
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomerAttachment, saveCustomerAttachment } from "../../core/store/customer/customer.action";
import { RootState } from "../../core/store/store";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";


export const CustomizeApp: FC = () =>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [image, setImage] = useState("");
    const [ selectedImage, setSelectedImage ] = useState<FileList | undefined>(undefined);
    const { user } = useSelector((state: RootState) => state.auth);
    const [ pageCase, setPageCase ] = useState<"none" | "setted" | "update" >("none");

    // Cast file to base64 (for displaying it into the page)
    async function readImage(e, func) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function(e) {
          let binaryData = e.target.result;
          let base64String = window.btoa(String(binaryData));
          func(base64String);
        };
        let image = reader.readAsBinaryString(file);    
        return image;
    }

    const handleInputImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        readImage(event, setImage);
        setSelectedImage(event.target.files);
        setPageCase("setted");
    }

    // Read if a logo already exists
    useEffect(()=>{
        if(user && user.customerlogo && user.customerlogo.length > 0){
            setImage(user.customerlogo);
            setPageCase("update");
        }else{
            setPageCase("none");
            setImage("");
            setSelectedImage(undefined);
        }
    },[dispatch, user])


    return(
        <>
            {!image? 
            <>
                <div className={cn(style["container"])}>
                    <img src={imageLogo} alt="Two images placeholder" className="mb-5 mt-3" height={300}/>
                </div>
            
                <p className={cn(style["mainText"])}>
                    {t("Upload your custom logo")}
                </p>

                <h4 className={cn(style["SecondaryText"])}>
                    {t("Displays your brand logo in the top navigation bar")}
                        
                </h4>

                <Box textAlign='center' marginTop="2%">
                    <Button variant="contained" component="label">
                        {t("SELECT IMAGE")}
                        <input
                            hidden
                            name="upload-image"
                            type="file"
                            accept="image/*"
                            onChange={handleInputImage}
                        />
                    </Button>
                </Box>

                <p  className={cn(style["fstTxtDimensions"])}>
                    {t("Recommended dimensions: minimum 200x200 pixels")}
                </p>

                <p className={cn(style["scndTxtDimensions"])}>  
                    {t("(only image formats are allowed: .jpg, .jpeg and .png)")}
                </p>

                </> : 

                <Box textAlign='center' marginTop="6%">
                    <img width={200} height={200} src={`data:image/jpeg;base64,${image}`} alt="uploaded"  />
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '30vh' }}
                        marginBottom="2.5%"
                    >

                        <Grid item xs={3}>
                            <Grid container justifyContent="flex-start" className={cn(style["txtAntiprima"])}>
                                <p>{t("Preview of navbar")}</p>
                            </Grid>
                            <Card sx={{ minWidth: 1000 }}>
                                <CardContent sx={{ justifyContent:"space-between", marginTop:"0.8%" }}>
                                    <Grid sx={{ display:"inline-block" }}>
                                        <img width={40} height={40} src={`data:image/jpeg;base64,${image}`} alt="uploaded"  />
                                    </Grid>
                                    <Grid sx={{ display:"inline-block", marginLeft: "85%", paddingTop:"0.5%"  }}>
                                        <h5>{t("Menu")}</h5>
                                    </Grid>
                                    
                                </CardContent>                            
                            </Card>
                        </Grid>      
                    </Grid>

                    <p className={cn(style["mainText"])}>
                        {(pageCase === "setted")?t("Preview"):t("Manage logo")}
                    </p>

                    <h4 className={cn(style["SecondaryText"])}>
                        {(pageCase === "setted")? t("Set logo subtitle"): t("Manage logo subtitle")}
                    </h4>

                    <Stack direction="row" justifyContent="center" marginTop="2%" marginBottom="3%" spacing={4}>
                        {(pageCase === "setted")?
                            <Button
                                className={cn(style["blue-btn"])}
                                variant="contained"
                                size="large"
                                onClick={()=>{
                                    const formData = new FormData();
                                    formData.append("file", selectedImage[0]);
                                    dispatch(saveCustomerAttachment(user.fkcustomers, formData));
                                    setPageCase("setted");
                                }}
                            >
                                {t("CONFIRM")}
                            </Button>
                        :
                            <Button 
                                className={cn(style["blue-btn"])}
                                variant="contained" 
                                component="label"
                            >
                                {t("Change image")}
                                <input
                                    hidden
                                    name="upload-image"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleInputImage}
                                />
                            </Button>   
                        }
                        {(pageCase === "setted")?
                            <Button
                                className={cn(style["grey-btn"])}
                                size="large"
                                onClick={() => {
                                    if(user.customerlogo && user.customerlogo.length > 0){
                                        setPageCase("update");
                                        setImage(user.customerlogo);
                                    }else{
                                        setImage("");
                                        setSelectedImage(undefined);
                                        setPageCase("none");
                                    }
                                }}
                            >
                                {t("BACK")}
                            </Button>
                        :
                            <Button
                                className={cn(style["grey-btn"])}
                                size="large"
                                onClick={() => {
                                    let message : DialogMessageType = {
                                        dialogTitle : t("Remove logo title"),
                                        dialogMsg :(<div className="d-flex flex-column"><p>{t("Remove logo subtitle 1")}</p><p className="mb-0">{t("Remove logo subtitle 2")}</p></div>),
                                        dialogDismissButton: t("Cancel"),
                                        dialogAcceptButton: t("Remove"),
                                        isOpen : true,
                                        onDismiss: () => {},
                                        onAccept: () => {
                                            dispatch(deleteCustomerAttachment(user.fkcustomers));
                                        }
                                      }
                                      dispatch(getDialogMessage(message));
                                }}
                            >
                                {t("Remove image")}
                            </Button>
                        }
                        
                        
                    </Stack>
                </Box>    
            }
        </>
    );
}