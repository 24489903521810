import { interceptor } from "../../../../interceptors/.interceptor";
import { HttpHandler } from "../../../../models/http-interceptor";
import { RequestResponse } from '../../../../models/requestResponse';
import { AppThunk } from "../../store";
import { getCustomerSettingsSuccess } from "./settings-customer.store";


export const getCustomerSettings = (fkcustomers:number): AppThunk => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/CustomerSettings/v1/GetCustomerSettings/${fkcustomers}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getCustomerSettingsSuccess(res.customerSettings))
      });
    } catch {}
}

export const getCustomerSettingsByPk = (pkcustomerssettings:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/CustomerSettings/v1/GetCustomerSettingsByPk/${pkcustomerssettings}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCustomerSettingsSuccess(res.customerSettings))
    });
  } catch {}
}

export const createCustomerSettings = (fkcustomers:number,isactive:boolean): AppThunk => (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettings/v1/CreateCustomerSettings/${fkcustomers}/${isactive}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCustomerSettings(fkcustomers));
    });
  } catch {}
}

export const updateActiveCustomerSettings = (pkcustomersettings:number,isactive:boolean): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettings/v1/UpdateActiveCustomerSettings/${pkcustomersettings}/${isactive}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
    });
  } catch {}
}

export const updateLimitIdentifyCustomerSettings = (pkcustomersettings:number,limitidentify:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettings/v1/UpdateLimitIdentifyCustomerSettings/${pkcustomersettings}/${limitidentify}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
    });
  } catch {}
}

export const updateLimitDaysCustomerSettings = (pkcustomersettings:number,limitdays:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettings/v1/UpdateLimitDaysCustomerSettings/${pkcustomersettings}/${limitdays}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCustomerSettingsByPk(pkcustomersettings));
    });
  } catch {}
}