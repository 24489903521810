import { faFileExcel } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from "classnames";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next/';
import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../../core/store/report/report.actions";
import { getTeamGroups } from "../../core/store/room/administration-group/administration-group.actions";
import { RootState } from '../../core/store/store';
import { getTenantUsers } from "../../core/store/users/users.actions";
import { ReportFilter } from "../../models/report";
import { Room } from "../../models/room";
import { Shift } from "../../models/shift";
import { Site } from "../../models/site";
import { XlsxReport } from "./XlsxReport";
import style from './report.module.scss';

export const Report: React.FC = () =>{

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [site, setSite] = useState<Site | null>(null);
    const [room, setRoom] = useState<Room | null>(null);
    const [users, setUsers] = useState<number[]> ([]);
    const [groups, setGroups] = useState<number[]> ([]);
    const [shift, setShift] = useState<Shift | null>(null);
    const [fromdate, setFromdate] = useState<Date | null>(null);
    const [todate, setTodate] = useState<Date | null>(null);
    const [valid, setValid] = useState<boolean>(false);
    const [availableRooms, setAvailableRooms] = useState<Room[]>([]);
    const [availableShifts, setAvailableShifts] = useState<Shift[]>([]);
    const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom);
    const { tenantUsers } = useSelector((state: RootState) => state.users);
    const { report } = useSelector((state: RootState)=> state.report);
    const [ reportRooms, setReportRooms] = useState<Room[]>([]);
    const { user } = useSelector((state: RootState) => state.auth)
    const meUser = useSelector((state: RootState) => state.auth.user)
    const { teamGroups } = useSelector((state: RootState) => state.groups)

    useEffect(()=>{
        dispatch(getTenantUsers());
        dispatch(getTeamGroups(meUser.fkcustomers))
    },[dispatch]);

    useEffect(()=>{
        const _rooms:Room[] = [];
        if(report && report.length){
            report.forEach(elem=> {
                const _room:Room = availableRooms.find(r => r.pkrooms === elem.fkrooms);
                if(_room){
                    if(_rooms.findIndex(r => r.pkrooms=== _room.pkrooms) === -1){_rooms.push(_room)}
                }
            });
        }
        setReportRooms(_rooms);
    },[report]);

    useEffect(()=>{
        if(fromdate>todate){setTodate(fromdate)};
        if(fromdate && todate){
            setValid(true);

            const reportFilter: ReportFilter = {
                pksite: (site) ? site.pksites : 0,
                pkroom: (room)? room.pkrooms : 0,
                pkshift: (shift)? shift.pkshifts : 0,
                pkusers: (users)? users : [],
                pkgroups: (groups)? groups : [],
                fromdate: moment(fromdate).format("yyyy-MM-DD"),
                todate: moment(todate).format("yyyy-MM-DD")
            }
            dispatch(getReport(reportFilter));
        }else{
            setValid(false);
        }
    },[users,groups, site, fromdate, todate, shift, room])

    useEffect(() => {
        if(site){
            const rooms: Room[] = adminSites.find(s=>s.pksites === site.pksites).rooms;
            setAvailableRooms(rooms);
        }else{
            setAvailableRooms([]);
        }

        setRoom(null);
    }, [adminSites, site]);

    useEffect(()=>{
        const shifts: Shift[] = [];
        availableRooms.forEach((r)=>{
            if(r === room){
                shifts.push(...r.shifts)}
        });
        setAvailableShifts(shifts);
        setShift(null);
    },[room]);

    const handleExcelDownload = () =>{
        if(report.length>0){
            XlsxReport(report, adminSites);
        }
    }
    //multiSelect dropdown styles (users)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 6;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 230
          },
        },

    };

    const handleUsersSelectAll = () => {
        if(users.length == tenantUsers.length){
          setUsers([])
        }else{
          const selectAll = [];
          tenantUsers.map(u =>{
            selectAll.push(u.pkusers)
          })
          setUsers(selectAll);
        }
    }

    const handleGroupsSelectAll = () => {
        if(groups.length == teamGroups.length){
          setGroups([])
        }else{
          const selectAll = [];
          teamGroups.map(g =>{
            selectAll.push(g.pkteamgroups)
          })
          setGroups(selectAll);
        }
    }

    return(
        <>
        <div className="d-flex flex-column w-100 h-100 position-relative">
            <div className="d-flex flex-row mt-4 mb-4 gap-3 px-5">

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5} md={2}>
                    <FormControl sx={{width:"100%"}}>
                        <Autocomplete
                            sx={{width:"100%"}}
                            options={adminSites}
                            getOptionLabel={(option: Site)=> option.parent === 0 ? option.sitename : adminSites.find(as => as.pksites === option.parent).sitename + " - " + option.sitename}
                            value={site}
                            renderInput={(props)=><TextField {...props} label={t("Site")}/>}
                            onChange={(e, value)=> setSite(value as Site)}
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5} md={2}>
                    <Autocomplete
                        sx={{width:"100%"}}
                        options={availableRooms}
                        getOptionLabel={(option)=>(option as Room).roomname}
                        value={room}
                        renderInput={(props)=><TextField {...props} label={t("Room")}/>}
                        onChange={(e, value)=> setRoom(value as Room)}
                    />
                    </Grid>
                    <Grid item xs={12} sm={5} md={2}>

                    <FormControl sx={{width:"100%"}}>
                        <InputLabel id="userLabel">{t("Users")}</InputLabel>
                        <Select
                            multiple
                            labelId="userLabel"
                            label={t("Users")}
                            value={users}
                            onChange={(e)=> setUsers(e.target.value as number[])}
                            renderValue={(selected) => selected.map((s,index) => (
                                (selected.length > 1 && index > 0 ? ',' : '') + tenantUsers.find(u => u.pkusers == s).username
                            ))}
                            MenuProps={MenuProps}
                        >
                            {<MenuItem onClick={() => handleUsersSelectAll()}><Checkbox className={style["user-check"]} checked={tenantUsers.length == users.length} /><ListItemText primary={t("Select All")} /></MenuItem>}
                            {
                                tenantUsers
                                .map((user)=>

                                    <MenuItem key={user.pkusers} value={user.pkusers}>
                                        <Checkbox className={style["user-check"]} checked={users.indexOf(user.pkusers) > -1} />
                                        {user.username}
                                    </MenuItem>
                                )

                            }
                        </Select>
                    </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={5} md={2}>

                    <FormControl sx={{width:"100%"}}>
                        <InputLabel id="groupLabel">{t("Groups")}</InputLabel>
                        <Select
                            multiple
                            labelId="groupLabel"
                            label={t("Groups")}
                            value={groups}
                            onChange={(e)=> setGroups(e.target.value as number[])}
                            renderValue={(selected) => selected.map((s,index) => (
                                (selected.length > 1 && index > 0 ? ',' : '') + teamGroups.find(g => g.pkteamgroups == s).teamname
                            ))}
                            MenuProps={MenuProps}
                        >
                            {<MenuItem  onClick={() => handleGroupsSelectAll()}><Checkbox checked={teamGroups.length == groups.length} /><ListItemText primary={t("Select All")} /></MenuItem>}
                            {
                                teamGroups
                                .map((group)=>

                                    <MenuItem key={group.pkteamgroups} value={group.pkteamgroups}>
                                        <Checkbox className={style["user-check"]} checked={groups.indexOf(group.pkteamgroups) > -1} />
                                        {group.teamname}
                                    </MenuItem>
                                )

                            }
                        </Select>
                    </FormControl>

                    </Grid>


                    <Grid item xs={12} sm={5} md={2}>
                    <FormControl sx={{width:"100%"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label={t("From day")+"*"}
                                value={fromdate}
                                onChange={(date)=> setFromdate(date)}
                                inputFormat="DD/MM/yyyy"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5} md={2}>
                    <FormControl sx={{width:"100%"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label={t("To day")+"*"}
                                value={todate}
                                onChange={(date)=> setTodate(date)}
                                inputFormat="DD/MM/yyyy"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5} md={2}>
                    <Autocomplete
                        sx={{width:"100%"}}
                        options={availableShifts}
                        getOptionLabel={(option)=>(option as Shift).shiftname}
                        value={shift}
                        onChange={(e,value)=>setShift(value as Shift)}
                        renderInput={(props)=><TextField {...props} label={t("Shift")}/>}
                    />


                    </Grid>


                </Grid>
                <Grid item xs={12} sm={3} md={1}>
                    <div className={cn(style["excelIcon"])} style={{display: user.functionalities.findIndex(f => f.nome === "Export_Report") === -1 ? "none" : "block"}}>
                        <button className={cn(style["excelButton"])}><FontAwesomeIcon icon={faFileExcel} size="3x" onClick={()=> handleExcelDownload()}/></button>
                    </div>
                </Grid>
            </div>

            <div className="px-5 pb-5 h-100" >
            {!valid && <div className="mt-3 d-flex justify-content-center text-center text-muted"> <p>{t("Fill the form")}</p></div>}
            {valid &&
                <Box
                component="div"
                sx={{
                  whiteSpace: 'nowrap',
                  overflowX: 'auto',
                  my: 2,
                  p: 1,
                  }}
                >
                { report && report.length ? (
                    <table className={cn(style["table-responsive"],"table table-hover table-responsive")}>
                        <thead>
                            <tr>
                                <th scope="col"><h5>{t("Room Name")}</h5></th>
                                <th scope="col"><h5>{t("Shift")}</h5></th>
                                <th scope="col"><h5>{t("Date")}</h5></th>
                                <th scope="col"><h5>{t("From Time")}</h5></th>
                                <th scope="col"><h5>{t("To Time")}</h5></th>
                                <th scope="col"><h5>{t("Seats")}</h5></th>
                                <th scope="col"><h5>{t("Users")}</h5></th>
                            </tr>
                        </thead>

                        <tbody>
                            {report.map((r)=>(
                            <tr>
                                <td><p>{r.roomname}</p></td>
                                <td><p>{r.shiftname}</p></td>
                                <td><p>{r.reservationdate.replaceAll('-', '/')}</p></td>
                                <td><p>{r.starttime}</p></td>
                                <td><p>{r.endtime}</p></td>
                                <td><p>{r.postiprenotati}</p></td>
                                <td><p>{r.utenti}</p></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>)
                    :
                    (<div>
                        <p className="text-muted">{t("No report")}</p>
                    </div>)
                }
                </Box>
            }
            </div>
            {valid && report.length &&
            <div className={cn(style["footer"])}>
                <div className="d-flex flex-row h-100">
                    {reportRooms.length >= 1 && <h5 className={cn(style["footer-text"])}>{reportRooms[0].roomname}</h5>}
                    {reportRooms.length > 1 &&
                        <Tooltip placement="top" title={reportRooms.map((r, i)=> i>0 ? (i===reportRooms.length-1 ? r.roomname : r.roomname+", ") :"")}>
                            <h5 className={cn(style["footer-text"])}> e altre {(reportRooms.length-1).toString()} stanze</h5>
                        </Tooltip>
                    }
                    {report.length > 1 && <h5 className={cn(style["footer-text"],"ms-2")}> dal {moment(fromdate).format("DD/MM/yyyy")} al {moment(todate).format("DD/MM/yyyy")}</h5>}
                </div>
            </div>
            }
        </div>

    </>
    );
}