import FileSaver from "file-saver";
import { t } from "i18next";
import Swal from "sweetalert2";
import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from "../../../models/requestResponse";
import { countUser, SA_User } from "../../../models/sa_users";
import { addAlertMessage } from "../../components/message-bar/store/alertMessage.store";
import { AppThunk } from "../store";
import { License } from "./../../../models/license";
import { getAllCustomersPkAndNameSuccess, getAllCustomersSuccess, getAllLicensesSuccess, getAllUsersCountXClientSuccess, getAllUsersXClientsSuccess, getAllUsersXClientSuccess, getCountLicensesSuccess, getCountUsersXClientsSuccess, getSAUserSitesSuccess, getUserXClientSuccess, } from "./superadmin.store";

export const getClientExcel = (clienttenantid: string) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/SuperAdmin/v1/getclientdataexcel/${clienttenantid}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<File | RequestResponse>(httpHandler).then((res) => {
      FileSaver.saveAs(res);
    });
  } catch {}
};

export const deleteCustomer = (pkCustomer: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `SuperAdmin/v1/deletecustomer/${pkCustomer}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Customer deleted") }));
      dispatch(getAllCustomers());
    });
  } catch (err) {}
};

export const updateCustomer = (pkcustomer: number, name: string, pklanguage: number, setdemotofalse: boolean ) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PUT",
    url: `SuperAdmin/v1/updatecustomer/${pkcustomer}/${name}/${pklanguage}/${setdemotofalse}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Customer updated") }));
      dispatch(getAllCustomers());
    });
  } catch (err) {}
  };

export const addNewCustomer = (name: string, tenantid: string, isdemo: boolean, idlanguage: number) => async (dispatch) => {
  const addNewCustomer: HttpHandler = {
    method: "HTTP_POST",
    url: `SuperAdmin/v1/addnewcustomer/${name}/${tenantid}/${isdemo}/${idlanguage}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  const validaCustomertenantIdAsync: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/ValidaCustomerTenantIDAsync/${tenantid}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(validaCustomertenantIdAsync).then((res1) => {
      interceptor<RequestResponse>(addNewCustomer).then((res2) => {
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("New customer added")}) );
        dispatch(getAllCustomers());
      });
    });
  } catch (err) {}
};
export const getAllCustomers = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `Customer/v1/GetAllCustomers`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllCustomersSuccess(res.customers));
    });
  } catch (err) {}
}

export const getAllCustomersPkAndName = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `Customer/v1/getAllCustomersPkAndName`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllCustomersPkAndNameSuccess(res.customersPkAndName));
    });
  } catch (err) {}
}

export const getAllUsersXSA = (onlyactives: boolean,numberpage: number,username: string, customername:string) => async(dispatch) => {
  if(username.trim() == ""){
    username = "TheFilterNameIsEmpty"
  }
  if(customername.trim() == ""){
    customername = "TheFilterNameIsEmpty"
  }
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getAllUsersXSA/${onlyactives}/${numberpage}/${username}/${customername}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllUsersXClientsSuccess(res.sa_users));
    });
  } catch (err) {}
};

export const getCountUsersXSA = (onlyactives: boolean,username: string, customername:string) => async(dispatch) => {
  if(username.trim() == ""){
    username = "TheFilterNameIsEmpty"
  }
  if(customername.trim() == ""){
    customername = "TheFilterNameIsEmpty"
  }
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getCountUsersXSA/${onlyactives}/${username}/${customername}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCountUsersXClientsSuccess(res.num));
    });
  } catch (err) {}
};

//OnlyOneCustomer:
// = 0 quando viene effettuata la call alla funzione getAllCustomers
// = 1 quando viene chiamata la funzione per ottenere la somma dei vari dei users
// = 2 quando si vuole ottenere la lista degli users di un customer
export const getAllUsersXClient = (tenant: string, onlyActive: number, OnlyOneCustomer: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getAllUsersXClient/${tenant}/${onlyActive}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (OnlyOneCustomer === 0) dispatch(getAllUsersXClientsSuccess(res.sa_users));
      else if (OnlyOneCustomer === 1) {
        const newCountUser: countUser = {
          tenant,
          count: res.sa_users.filter((r) => r.isactive === true).length,
        };
        dispatch(getAllUsersCountXClientSuccess(newCountUser));
      } else if (OnlyOneCustomer === 2)
        dispatch(getAllUsersXClientSuccess(res.sa_users));
    });
  } catch (err) {}
};

export const getUserXClient = (tenant: string, email: string) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getUserXClient/${tenant}/${email}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserXClientSuccess(res.sa_user));
    });
  } catch (err) {}
};

export const addUserXClient =
  (u: Omit<SA_User, "pkusers">, pkcustomer: number) => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_POST",
      url: `SuperAdmin/v1/addUserXClient/${pkcustomer}/${u.username}/${u.email}/${
        u.isactive ? 1 : 0
      }/${u.isadmin ? 1 : 0}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        if (res.outcome === "Success") {
          dispatch(addAlertMessage({ type: "success", htmlMessage: res.msg }));
          //dispatch(getUserXClient(tenantID, u.email));
        } else if (res.outcome === "Warning") {
          dispatch(addAlertMessage({ type: "warning", htmlMessage: res.msg }));
        }
      });
    } catch (err) {}
  };

export const SADeactivateUser = (email: string) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PATCH",
    url: `/SuperAdmin/v1/deactivateuser/${email}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("User deactivated") }));
    });
  } catch {}
};

export const SAActivateUser = (email: string) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PATCH",
    url: `/SuperAdmin/v1/activateuser/${email}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch( addAlertMessage({ type: "success", htmlMessage: t("User activated") }) );
    });
  } catch (err) {}
};

export const getUserExcel = (pkuser: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/SuperAdmin/v1/getuserdataexcel/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<any>(httpHandler).then((res) => {
      var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      var anchor = document.createElement('a');
      anchor.download = res.file.fileDownloadName;
      anchor.href = mediaType+res.file.fileContents;
      anchor.click();
    });
  } catch {}
};

export const hardDeleteUser = (pkuser: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/SuperAdmin/v1/harddeleteuser/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: res.msg }));
    });
  } catch {}
};

export const getAlllicenses =(onlyactives: boolean,numberpage: number,customername: string): AppThunk => async (dispatch) => {
  if(customername.trim() == ""){
    customername = "TheFilterNameIsEmpty"
  }
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getalllicenses/${onlyactives}/${numberpage}/${customername}`,
    dispatch,
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllLicensesSuccess(res.licenses));
    });
  } catch (err) {}
};

export const getCountLicenses = (onlyactives: boolean,customername: string): AppThunk => async (dispatch) => {
  if(customername.trim() == ""){
    customername = "TheFilterNameIsEmpty"
  }
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getcountlicenses/${onlyactives}/${customername}`,
    dispatch,
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCountLicensesSuccess(res.num));
    });
  } catch (err) {}
};

export const AddNewLicense = (license: License,customername: string,onlyActive: boolean,nameFilter: string ): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `SuperAdmin/v1/addnewlicense/${customername}`,
    dispatch,
    httpOptions: {
      data: license,
    },
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAlllicenses(onlyActive,1,nameFilter));
      dispatch(getCountLicenses(onlyActive,nameFilter));
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("New license added") }));
    });
  } catch (err) {}
};

export const DisableLicense = (license: License,proceed: boolean,onlyActive: boolean,nameFilter: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `SuperAdmin/v1/disablelicense/${proceed}`,
    dispatch,
    httpOptions: {
      data: license,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome == "Success"){
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("License disabled") }));
        dispatch(getAlllicenses(onlyActive,1,nameFilter));
        dispatch(getCountLicenses(onlyActive,nameFilter));
      }else{
        let msg = t(res.msg).split('X')
        Swal.fire({
          title: msg[0] + res.num + msg[1],
          icon: "error",
          iconColor: "#0C5468",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: t("Confirm"),
          cancelButtonText: t("Cancel")
        }).then(r=> {
          if(r.isConfirmed){
            dispatch(DisableLicense(license,true,onlyActive,nameFilter))
          }
        })
      }
    });
  } catch (err) {}
};

export const EnableLicense = (license: License,onlyActive: boolean,nameFilter: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PUT",
    url: `SuperAdmin/v1/enablelicense/`,
    dispatch,
    httpOptions: {
      data: license,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("License enabled") }));
      dispatch(getAlllicenses(onlyActive,1,nameFilter));
      dispatch(getCountLicenses(onlyActive,nameFilter));
    });
  } catch (err) {}
};

export const UpdateLicense = (onlyActive: boolean,license: License, nameFilter: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `SuperAdmin/v1/updatelicense`,
    dispatch,
    httpOptions: {
      data: license,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome == "Success"){
        dispatch(addAlertMessage({ type: "success", htmlMessage: t(res.msg) }));
        dispatch(getAlllicenses(onlyActive,1,nameFilter));
        dispatch(getCountLicenses(onlyActive,nameFilter));
      }else{
        let msg = t(res.msg).split('X')
        dispatch(addAlertMessage({ type: "warning", htmlMessage: msg[0] + res.num + msg[1]}));
      }
    });
  } catch (err) {}
};

export const getSAUserSites = (pkuser: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `SuperAdmin/v1/getsiteslistuser/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getSAUserSitesSuccess(res.sites));
    });
  } catch (err) {}
};
