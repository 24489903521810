import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Changelog } from "../../../models/changelog"
import { Guide } from "../../../models/guide"

interface popupModel {
    isOpen: boolean
    variant: "changelog" | "userGuide" | ""
    userGuide?: Guide
    changelog?: Changelog
}

export const popupSlice = createSlice({
    name: "popup",

    initialState: {
        isOpen: false,
        variant: "",
        userGuide: null,
        changelog: null
    } as popupModel,

    reducers: {
        setPopupState: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
        setPopupVariant: (state, action: PayloadAction<("changelog" | "userGuide" | "")>) => {
            state.variant = action.payload
        },
        setPopupUserGuide: (state, action: PayloadAction<Guide>) => {
            state.userGuide = action.payload
        },
        setPopupChangelog: (state, action: PayloadAction<Changelog>) => {
            state.changelog = action.payload
        }
    }
})

export const {
    setPopupState,
    setPopupVariant,
    setPopupUserGuide,
    setPopupChangelog
} = popupSlice.actions