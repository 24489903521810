import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from "../../../models/requestResponse";
import { getAllGuidesCategoriesSuccess, getAllGuidesSuccess, getGuideFileSuccess } from "./guide.store";

export const getAllGuides = () => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: '/UserGuide/v1/GetUserGuides',
      dispatch,
      auth: true,
      baseurl: false,
    }

    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getAllGuidesSuccess(res.userGuides))
      })
    } catch {}
}

export const getAllGuidesCategories = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: '/GuideCategory/v1/GetGuidesCategories',
    dispatch,
    auth: true,
    baseurl: false
  }

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllGuidesCategoriesSuccess(res.guidesCategories))
    })
  } catch {}
}

export const getGuideFile = (filename) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/UserGuide/v1/GetUserGuideFile/${filename}`,
    dispatch,
    auth: true,
    baseurl: false
  }

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getGuideFileSuccess(res.userGuideFile))
    })
  } catch {}
}