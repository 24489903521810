import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomseatsMapDialogType } from '../../components/roomseats-map/RoomseatsMapDialog';
import { SeatsDialogType } from '../../components/SeatsDialog/SeatsDialog';

interface DialogsSlice {
    roomseatsMap: RoomseatsMapDialogType,
    seatsDialog: SeatsDialogType
}

export const dialogsSlice = createSlice({
    name: "dialogs",
    initialState: {
        roomseatsMap: {
            isOpen: false,
        },
        seatsDialog:{
            isOpen: false
        }
    } as DialogsSlice,
    reducers: {
        getRoomseatsMap: (state, action: PayloadAction<RoomseatsMapDialogType>) => {
            state.roomseatsMap = action.payload
        },
        getSeatsDialog: (state, action: PayloadAction<SeatsDialogType>) => {
            state.seatsDialog = action.payload;
        }
    }
})

export const {
    getRoomseatsMap,
    getSeatsDialog
} = dialogsSlice.actions