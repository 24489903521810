import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { startupContent } from '../../../models/startupContent';

interface startupModel {
    content: startupContent[]
    showpopupguide: boolean
}

export const startupSlice = createSlice({
    name: "startup",

    initialState: {
        content: [],
        showpopupguide: false
    } as startupModel,

    reducers: {
        addStartupImage: (state, action: PayloadAction<startupContent>) => {
            state.content.push(action.payload)
        },
        addStartupImageBulk: (state, action: PayloadAction<startupContent[]>) => {
            if(action.payload){
                action.payload.forEach(image => {
                    state.content.push(image)
                })
            }
        },
        setShowPopupGuide: (state, action: PayloadAction<boolean>) => {
            state.showpopupguide = action.payload
        }
    }
})

export const {
    addStartupImage,
    addStartupImageBulk,
    setShowPopupGuide
} = startupSlice.actions