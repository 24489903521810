import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from 'classnames';
import moment, { Moment } from "moment";
import { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ControlledDatePicker } from "../../core/components/mui-input/MuiInput";
import { GetAdminRoomsImage, getAdminSites, getAdminSitesChanged } from "../../core/store/room/administration-room/administration-room.actions";
import { RootState } from "../../core/store/store";
import { getTenantUsers, getUsersReservationsByDateRangeAndRoomSelected } from "../../core/store/users/users.actions";
import { ReservationsManagmentFilter } from "../../models/reservationsManagment";
import { Room } from "../../models/room";
import { User } from "../../models/user";
import style from "./reservations-management.module.scss";
import { ReservationsManagementTab } from "./reservations-managementTab";

interface ReservationsManagementForm{
    users: User[];
    fromDate?: Moment | string;
    toDate?: Moment | string;
    rooms?: Room[];
}

const drawerBleeding = 50;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
        width: 450,
        minWidth: 450,
      },
    },
};

export const ReservationsManagement: FC = () =>{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [ pkUsersChecked, setPkUsersChecked ] = useState<number[]>([]);
    const [ pkRoomsChecked, setPkRoomsChecked ] = useState<number[]>([]);
    const [ startDate, setStartDate ] = useState<Moment | null>(null);
    const [ endDate, setEndDate ] = useState<Moment | null>(null);
    const [ searchStarted, setSearchStarted ] = useState<boolean>(false);
    const [ isRoomImageModalOpen, setIsRoomImageModalOpen ] = useState<boolean>(false);
    const [ selectedRoomImage, setSelectedRoomImage ] = useState<string>("");
    const [nameFilter, setNameFilter] = useState<string>("")
    const [allRooms, setAllRooms] = useState<Room[]>([]);
    const { userSites } = useSelector((state: RootState) => state.room.userRoom)
    const { user } = useSelector((state: RootState) => state.auth)
    const { tenantUsers, usersReservations } = useSelector((state:RootState)=> state.users);
    const { adminSites, imageHasLoaded, adminSitesChanged } = useSelector((state: RootState) => state.room.administrationRoom);

    const { control, handleSubmit }  = useForm<ReservationsManagementForm>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });
    const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameFilter(event.target.value)
      };

    useEffect(()=>{
        dispatch(getTenantUsers());
        let rooms = []
        userSites.forEach(us => rooms.push(...us.rooms))
        setAllRooms(rooms)
        setPkRoomsChecked([]);
        setPkUsersChecked([]);
        (adminSites.length === 0)?dispatch(getAdminSites()): dispatch(getAdminSitesChanged(adminSites));
    }, [dispatch]);

    //Manage to get adminRooms (for the roomsImages)
    useEffect(()=>{
        if(adminSites.length > 0 && !imageHasLoaded ){
            dispatch(GetAdminRoomsImage());
        }
        if(adminSitesChanged){
          dispatch(getAdminSites());
        }
    },[adminSites])

    const handleSearch = handleSubmit(data=>{
        if(pkUsersChecked.length> 0){
            const castedFromDate = (data.fromDate) ? (data.fromDate as Moment) : moment();
            const castedToDate = (data.toDate) ? (data.toDate as Moment) : moment().add(100,'y');
            const filter: ReservationsManagmentFilter = {
                usersPk: pkUsersChecked,
                roomsPk: pkRoomsChecked,
                dataDa: castedFromDate.format("YYYY-MM-DDTHH:mm:ss"),
                dataA: castedToDate.format("YYYY-MM-DDTHH:mm:ss"),
            };
            dispatch(getUsersReservationsByDateRangeAndRoomSelected(filter));
            setSearchStarted(true);

        }
    });

    function GetReservationsDate(reservations): string[] {
        const resultdates: string[] = [];
        reservations.map(r =>(
            resultdates.push(moment(r.reservationdate,"YYYY-MM-DD").format("DD-MM-YYYY"))//Mostro solo le date per cui c'è una prenotazione
        ))
        const uniqueResultdates = Array.from(new Set(resultdates));//Rimuovo date duplicate
        return uniqueResultdates;
    }

    function handleChangeCheckedUsers(pkuser:number){
        let pkusers = pkUsersChecked;
        if(pkuser == 9999999){//significa che è stato premuto select all
            pkusers = []//Se non entra nell'if deseleziona tutto
            if(pkUsersChecked.length != tenantUsers.length){//Se vero significa che non è stato selezionato tutto
                tenantUsers.map((t) => {
                    pkusers.push(t.pkusers);
                })
            }
        }else if(pkusers.findIndex(pk => pk === pkuser) >= 0){
            pkusers = pkusers.filter((pk) => pk !== pkuser)
        }else{
            pkusers.push(pkuser)
            pkusers = pkusers.filter((pk) => pk !== null)
        }
        setPkUsersChecked(pkusers);
    }


    const usersForm =  <>
                            <Card sx={{ height: '87vh',overflowY: "scroll"}} className="ms-1 me-2 ">
                                <CardContent>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <TextField
                                                id="search-user-input"
                                                className="w-100 sizecard"
                                                value={nameFilter}
                                                onChange={handleNameFilterChange}
                                                label={t("Search Users")}
                                                InputProps={{
                                                    endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
                                                }}
                                                size="small"
                                            />
                                        </div>
                                        <div>
                                        <div className={cn(style["borderforselectall"])}>
                                            <Checkbox style={{ paddingLeft: 0}} value={9999999} onChange={() => handleChangeCheckedUsers(9999999)} checked={pkUsersChecked.length == tenantUsers.length} />
                                            {<><b>{t("Select All")}</b><br /></>}
                                        </div>
                                            {tenantUsers.length > 0 && tenantUsers.filter((u) => u.isdeleted === false && u.username.toLowerCase().includes(nameFilter.toLowerCase())).map((u,index) => (
                                                <FormGroup>
                                                    <FormControlLabel
                                                    value={u.pkusers}
                                                    control={
                                                            <Checkbox value={u.pkusers} onChange={(e) => handleChangeCheckedUsers(Number(e.target.value))} checked={pkUsersChecked.findIndex(lu => lu === u.pkusers) >= 0} />
                                                        }
                                                        label={<><b>{u.username}</b><br /><div className={cn(style["emailText"])}>{u.email}</div></>}
                                                        className={cn(style["borderforusers"])}
                                                    />
                                                </FormGroup>
                                            ))}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </>;

    const desktopSearchForm =  <>
                                    <Card style={{overflowY: "scroll"}} sx={{ height: '87vh'}} className="ms-1 me-2 ">
                                        <CardContent>
                                            <ControlledDatePicker
                                                name="fromDate"
                                                control={control}
                                                label={t("From day")}
                                                value={startDate}
                                                onChange={(value)=> setStartDate(value as Moment)}
                                                renderInput={(params) => <TextField {...params} />}
                                                minDate={moment()}
                                                className="mr-2"
                                            />
                                            <a className={cn(style["divseparatedatapicker"])}></a>
                                            <ControlledDatePicker
                                                name="toDate"
                                                control={control}
                                                label={t("To day")}
                                                value={endDate}
                                                onChange={(value)=> setEndDate(value as Moment)}
                                                renderInput={(params) => <TextField {...params} />}
                                                minDate={(startDate)?startDate:null}
                                                className="mr-2"
                                            />
                                            <a className={cn(style["divseparatedatapicker"])}></a>
                                            <FormControl sx={{ width: 450 }}>
                                                <InputLabel id="selectRoomLabel">{t("Select a room")}</InputLabel>
                                                <Select
                                                labelId="selectRoomLabel"
                                                label={t("Select a seat")}
                                                multiple
                                                onChange={(e) => (e.target.value as number[]).length >= 0 ? setPkRoomsChecked(e.target.value as number[]) : null}
                                                input={<OutlinedInput label={t("Select a seat")} />}
                                                MenuProps={MenuProps}
                                                data-joyride="user-profile"
                                                renderValue={() => allRooms.filter(ar => pkRoomsChecked.findIndex(prc => prc === ar.pkrooms) >= 0).map(r => r.roomname).join(", ")}
                                                value={pkRoomsChecked}>
                                                {
                                                    allRooms.map((r) => (
                                                    <MenuItem key={r.pkrooms} value={r.pkrooms}>
                                                        <Checkbox checked={pkRoomsChecked.findIndex(lr => lr === r.pkrooms) >= 0} />
                                                        {r.roomname}
                                                    </MenuItem>
                                                    ))
                                                }
                                                </Select>
                                            </FormControl>
                                            <a className={cn(style["divseparatedatapicker"])}></a>
                                            <IconButton onClick={handleSearch} className={cn(style["search-btn-desktop"])}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
                                            </IconButton>
                                            { searchStarted ?
                                                    (usersReservations.length === 0)?
                                                        <p>{t("No results")}</p>
                                                    : usersReservations.map(ur=>(
                                                        <Accordion className="mt-3">
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                            >
                                                                <h4 className={cn(style["title"], "px-2")}>{ur.user.username}</h4>
                                                            </AccordionSummary>
                                                            <>{
                                                                (ur.reservations.length === 0)? <p className="px-2 ms-3">{t("No reservations found")}</p> :
                                                                <AccordionDetails className="d-flex" style={{"maxHeight": "200px"}}>
                                                                    <ReservationsManagementTab days={GetReservationsDate(ur.reservations)} userReservation={ur} pkuser={ur.user.pkusers} setIsModalOpen={setIsRoomImageModalOpen} setRoomImage={setSelectedRoomImage} mobile={false} />
                                                                </AccordionDetails>
                                                            }</>
                                                        </Accordion>
                                                ))
                                                : <></>
                                            }
                                        </CardContent>
                                    </Card>
                                </>;

    return(
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {!isMobile &&
                <div className="row mt-2">
                    <div style={{ paddingRight: 0}} className="col-3">
                        {usersForm}
                    </div>
                    <div style={{ paddingLeft: 0}} className="col-9" >
                        {desktopSearchForm}
                    </div>
                </div>
            }
       </LocalizationProvider>
    );
}


