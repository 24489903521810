
import { FC } from "react";
import { isMobile } from 'react-device-detect';
import UserReservationDesktop from "./UserReservation-Desktop/UserReservationDesktop";
import UserReservationMobile from "./UserReservation-mobile/UserReservationMobile";

const UserReservation: FC= () => {
  return (
    <>
      {!isMobile &&
        <UserReservationDesktop></UserReservationDesktop>
      }
      {isMobile &&
        <div className="container-fluid justify-content-center">
          <UserReservationMobile></UserReservationMobile>
        </div>
        
      }
    </>
  );
};

export default UserReservation;
