import { t } from "i18next"
import { interceptor } from "../../../interceptors/.interceptor"
import { HttpHandler } from "../../../models/http-interceptor"
import { RequestResponse } from "../../../models/requestResponse"
import { addAlertMessage } from "../../components/message-bar/store/alertMessage.store"
import { login } from "../auth/auth.actions"
import { getCustomerLogoSuccess } from "../auth/auth.store"
import { getHomePage } from "../felogic/felogic.action"
import { getMe } from "../room/user-room/user-room.actions"
import { AppThunk } from "../store"
import { getCurrentLicenseSuccess, getNextLicenseStartdateSuccess, setEmailState } from "./customer.store"


export const addNewCustomerAndUser=(tenantid:string, customerName: string, iduser:string, username:string, useremail:string):AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_POST",
    url:`NewCustomer/v1/manageaddnewcustomer/${tenantid}/${customerName}/${iduser}/${username}/${useremail}`,
    dispatch,
    auth:true,
    baseurl:false,
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(addAlertMessage({type:"success", htmlMessage:t("New Customer Success")}));
      dispatch(login());
    })
  }catch(err){}
}

export const getNextLicenseStartdate=():AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_GET",
    url:`License/v1/getclientfirstfuturelicense/`,
    dispatch,
    auth:true,
    baseurl:false,
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      if(res.outcome === "Success"){
        dispatch(getNextLicenseStartdateSuccess(res.license.startdate));
      }
    })
  }catch(err){}
}

export const saveCustomerAttachment=(pkcustomer:number, formdata:FormData):AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_POST",
    url:`Attachment/v1/saveattachment/customer/0/${pkcustomer}`,
    dispatch,
    auth:true,
    baseurl:false,
    httpOptions:{
      data: formdata
    }
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Image saved succesfully") }));
      dispatch(getMe());
    })
  }catch(err){}
}

export const deleteCustomerAttachment=(pkcustomer:number):AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_DELETE",
    url:`Customer/v1/deleteCustomerAttachment/${pkcustomer}`,
    dispatch,
    auth:true,
    baseurl:false
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Image removed succesfully") }));
      dispatch(getCustomerLogoSuccess(""));
    })
  }catch(err){}
}

export const getCurrentLicense=():AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_GET",
    url:`License/v1/GetCurrentLicense`,
    dispatch,
    auth:true,
    baseurl:false
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(getCurrentLicenseSuccess(res.license));
    })
  }catch(err){}
}

export const SendLinkEmail=(email:string):AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_POST",
    url:`EmailValidation/v1/SendValidateEmailLink/${email}/${(process.env.REACT_APP_ENVIRONMENT)?process.env.REACT_APP_ENVIRONMENT:"dev"}`,
    dispatch,
    auth:true,
    baseurl:false
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res)=>{
      dispatch(setEmailState("link-sent"));
      dispatch(addAlertMessage({type: "success", htmlMessage:t("Link sent")}))
    })
  }catch(err){}
}

export const CheckIfEmailIsValidated=(email:string, fkcustomer: number, isEmailComunicationAccepted: boolean):AppThunk =>(dispatch)=>{
  const checkIfEmailIsValidatedHttpHandled : HttpHandler = {
    method: "HTTP_GET",
    url:`EmailValidation/v1/CheckIfEmailIsValidated/${email}`,
    dispatch,
    auth:true,
    baseurl:false
  }

  const SetCustomerAsNotPendingHttpHandler : HttpHandler = {
    method: "HTTP_PATCH",
    url:`Customer/v1/SetCustomerAsNotPending/${fkcustomer}/${email}/${isEmailComunicationAccepted}`,
    dispatch,
    auth:true,
    baseurl:false
  }
  try
  {
    interceptor<RequestResponse>(checkIfEmailIsValidatedHttpHandled).then((res)=>{
      if(res.boolean){
        dispatch(setEmailState("validated"));
        dispatch(addAlertMessage({type: "success", htmlMessage:t("Email validated")}));

        //DATO CHE L'EMAIL E' STATA VALIDATA SETTO IL CUSTOMER COME NON PENDING
        interceptor<RequestResponse>(SetCustomerAsNotPendingHttpHandler).then((r)=>{
          dispatch(getHomePage());
        });
      }else{
        dispatch(addAlertMessage({type: "warning", htmlMessage:t("Email not already validated")}))
      }
    })
  }catch(err){}
}

