import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Guide } from "../../../models/guide";
import { GuideCategory } from "../../../models/guideCategory";

export interface GuidesStore {
    guidesList: Guide[],
    guidesCategories: GuideCategory[],
    showGuides: boolean,
    relevantCategory: number,
    userGuideFile: string
}

export const guideSlice = createSlice({
    name: "guides",

    initialState: {
        guidesList: [],
        guidesCategories: [],
        showGuides: false,
        relevantCategory: 0,
        userGuideFile: ""
    } as GuidesStore,

    reducers: {
        getAllGuidesSuccess: (state, action: PayloadAction<Guide[]>) => {
            state.guidesList = action.payload
        },
        getAllGuidesCategoriesSuccess: (state, action: PayloadAction<GuideCategory[]>) => {
            state.guidesCategories = action.payload
        },
        setShowGuides: (state, action: PayloadAction<boolean>) => {
            state.showGuides = action.payload
        },
        setRelevantCategory: (state, action: PayloadAction<number>) => {
            state.relevantCategory = action.payload
        },
        getGuideFileSuccess: (state, action: PayloadAction<string>) => {
            state.userGuideFile = action.payload
        },
    }
})

export const {
    getAllGuidesSuccess,
    getAllGuidesCategoriesSuccess,
    setShowGuides,
    setRelevantCategory,
    getGuideFileSuccess
} = guideSlice.actions