import { Modal, Button, Tabs, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React, {useEffect, useState} from "react";
import {SA_User} from "../../../models/sa_users";
import cn from "classnames"
import style from './SAUsersManagement.module.scss'
import { useTranslation } from "react-i18next/";
import { TabContext, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import { getSAUserSites, getUserExcel, hardDeleteUser } from "../../../core/store/superadmin/superadmin.actions";
import { getLastUserReservation } from "../../../core/store/users/users.actions";

import moment from "moment";

interface SAUsersManagementModalProps{
    open: boolean;
    onClose: ()=>void;
    user: SA_User  | undefined;
}

export const SAUsersManagementModal: React.FC<SAUsersManagementModalProps> = ({open, onClose, user}) => {
    const {t} = useTranslation();
    const [index, setIndex] = useState<string>("0");
    const dispatch = useDispatch();
    const { myReservations } = useSelector((state: RootState) => state.users)
    const { SAUserSites } = useSelector((state: RootState) => state.superadmin)

    useEffect(()=>{
        if(user){
            dispatch(getLastUserReservation(user.pkusers));
            dispatch(getSAUserSites(user.pkusers));
        }
    },[user, dispatch])

    const handleDelete = () =>{
        dispatch(hardDeleteUser(user.pkusers))
        onClose();
    }
    return(
        <>
            {user &&
                <Modal
                open={open}
                onClose={onClose}
                >
                    <div className={cn(style["modal-dialog"])}>
                        <div className="col h-100 d-flex flex-column">
                            {/* Title */}
                            <div className="row">
                                <p className={cn(style['modal-title'])}>{t("Delete User")}</p>
                            </div>
                            {/* Content */}
                            <div className={cn(style["modal-content"])}>
                                <p>{t("Delete User paragraph 1")} <b>{user.username+" ("+user.email.trim()+")"}</b> {t("Delete User paragraph 2")} <b>{user.customername}</b>.</p>
                                <p>{t("Delete User paragraph 3")}</p>

                                {/* TABS */}
                                <TabContext value={index}>
                                    <Tabs 
                                    indicatorColor="secondary"
                                    value={index}
                                    onChange={(e,newValue)=>setIndex(newValue)} 
                                    variant="fullWidth"
                                    centered>
                                        <Tab value="0" label={t("Associated rooms")} style={(index==="0")?{width:"50%", color:"black"}:{width:"50%", color:"gray"}}/>
                                        <Tab value="1" label={t("Reservations made")} style={(index==="1")?{width:"50%", color:"black"}:{width:"50%", color:"gray"}} />
                                    </Tabs>

                                    {/* TABS CONTENT (OVERFLOW) */}
                                    <div className={cn(style["tab-container"], "row")}>
                                        <TabPanel value="0" className={cn(style["tab"])}>

                                            {SAUserSites && SAUserSites.map(s=>(
                                                <>
                                                    <p className={cn(style["content-title"])}>{s.sitename}</p>
                                                    <ul className={cn(style["list-dashed"])}>
                                                        {s.rooms.map(r=>(
                                                            <li>{r.roomname}</li>
                                                        ))}
                                                    </ul>
                                                </>

                                            ))}

                                        </TabPanel>

                                        <TabPanel value="1" className={cn(style["tab"])}>
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><b>{t("Date")}</b></TableCell>
                                                            <TableCell><b>{t("Site")}</b></TableCell>
                                                            <TableCell><b>{t("Room")}</b></TableCell>
                                                            <TableCell><b>{t("Shift")}</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {myReservations.length>0 && myReservations.map(r=>{
                                                            return(
                                                                <TableRow
                                                                    key={r.pkreservations}
                                                                >
                                                                    <TableCell>{moment(r.reservationdate).format("DD/MM/YYYY")}</TableCell>
                                                                    <TableCell>{r.sitename}</TableCell>
                                                                    <TableCell>{r.roomname}</TableCell>
                                                                    <TableCell>{r.shiftname}</TableCell>

                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <div className="row mt-3">
                                                <p>{t("Last 100 reservations")}</p>
                                            </div>  
                                        </TabPanel>
                                    </div>
                                    
                                </TabContext>
                                 {/* Buttons */}
                                <div className="d-flex flex-row justify-content-between mt-2">
                                    <div className="group">
                                        <Button style={{borderColor:"#0000004D",color:"black"}} variant="outlined" onClick={()=>dispatch(getUserExcel(user.pkusers))}>{t("Download user details")}</Button>
                                    </div>
                                    <div className="d-flex flex-row gap-3">
                                        <Button style={{borderColor:"#0000004D",color:"black"}} variant="outlined" onClick={()=>onClose()}>{t("Cancel")}</Button>
                                        <Button variant="contained" onClick={handleDelete}>{t("Delete")}</Button>
                                    </div>
                                </div>
                            </div>
                            
                           
                            
                            
                        </div>
                        
                    </div>
                </Modal>
            }
            
        </>
    );
}