import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import {
  addNewCustomer,
  getAllCustomers,
  getAllUsersXClient,
  updateCustomer,
} from "../../../core/store/superadmin/superadmin.actions";
import { getLanguages } from "../../../core/store/users/users.actions";
import { Customer } from "../../../models/customer";
import style from "./CustomerForm.module.scss";

export type CustomerFormProps = {
  selectedCustomer: Customer | null;
  type: "Edit" | "Add";
  open: boolean;
  handleClose: () => void;
};

const CustomerForm: React.FC<CustomerFormProps> = ({
  selectedCustomer,
  type,
  open,

  handleClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [NameClient, setNameClient] = useState("");
  const [TenantId, setTenantId] = useState("");
  const [isdemo, setIsdemo] = useState<boolean>(true);
  const [nameLanguage, setNameLanguage] = useState("");
  const { allCustomers } = useSelector((state: RootState) => state.superadmin);
  const { languages } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (!allCustomers) {
      dispatch(getAllCustomers());
    }
  }, [allCustomers]);

  useEffect(() => {
    dispatch(getLanguages());
  }, []);
  const handleNameClientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameClient(event.target.value);
  };

  const handleTenantIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenantId(event.target.value);
  };

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setNameLanguage(event.target.value);
  };


  const handleSave = () => {
    if (type === "Edit") {
      let message: DialogMessageType = {
        dialogMsg: (
          <div className="msg">
            {t(
              "Si sta cercando di modifcare il cliente per " +
                selectedCustomer.customername
            )}
          </div>
        ),
        dialogTitle: t("Attention"),
        dialogAcceptButton: t("Yes"),
        dialogDismissButton: t("Cancel"),
        isOpen: true,
        onDismiss: () => {},
        onAccept: () => {
          dispatch(
            updateCustomer(
              selectedCustomer.pkcustomers,
              NameClient,
              parseInt(nameLanguage),
              !isdemo //setDemoFalse
            )
          );
          dispatch(getAllUsersXClient(selectedCustomer.tenantid, 0, 1));
          closeForm();
        },
      };

      dispatch(getDialogMessage(message));
    }

    if (type === "Add") {
      let message: DialogMessageType = {
        dialogMsg: <div>{t("New customer msg")}?</div>,
        dialogTitle: t("New Customer insertion"),
        dialogAcceptButton: t("Yes create"),
        dialogDismissButton: t("No cancel"),
        isOpen: true,
        onDismiss: () => {},
        onAccept: () => {
          dispatch(
            addNewCustomer(NameClient, TenantId, isdemo, parseInt(nameLanguage))
          );
          dispatch(getAllUsersXClient(TenantId, 0, 1));
          closeForm();
        },
      };
      dispatch(getDialogMessage(message));
    }
  };

  const cancelClicked = () => {
    let message: DialogMessageType = {
      dialogMsg: <div>{t("Close From Alert")}</div>,
      dialogTitle: t("Attention"),
      dialogAcceptButton: t("Yes close"),
      dialogDismissButton: t("No go back"),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        closeForm();
      },
    };
    dispatch(getDialogMessage(message));
  };

  const closeForm = () => {
    setNameClient("");
    setTenantId("");
    setNameLanguage("");
    // setLanguage(null);
    handleClose();
  };

  useEffect(() => {
    if (selectedCustomer !== null) {
      setNameClient(selectedCustomer.customername);
      setTenantId(selectedCustomer.tenantid);
      setIsdemo(selectedCustomer.isdemo);
      setNameLanguage(selectedCustomer.fklanguages.toString());
    } else if (selectedCustomer === null) {
      setNameClient("");
      setTenantId("");
      setIsdemo(false);
      setNameLanguage("");
    }
  }, [selectedCustomer, allCustomers]);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={type === "Add" ? cancelClicked : closeForm}
        className={style["MuiPaper-root"]}
      >
        <div className="container-fluid ps-4">
          <div className="row mt-4 mb-4">
            <div className="col">
              {type === "Edit" && (
                <h3 className="primary">{t("Edit Customer")}</h3>
              )}
              {type === "Add" && (
                <h3 className="primary">{t("New Customer")}</h3>
              )}
            </div>
          </div>
          <div className="row mb-4 w-100">
            <div className="col">
              {(type === "Add" || type === "Edit") && (
                <TextField
                  value={NameClient}
                  onChange={handleNameClientChange}
                  size="small"
                  label={t("Customer Name")}
                  fullWidth
                  required
                />
              )}
            </div>
          </div>
          <div className="row w-100">
            {type === "Add" ? (
              <div className="col-12 mb-4">
                <TextField
                  value={TenantId}
                  onChange={handleTenantIdChange}
                  size="small"
                  label={t("TenantId")}
                  fullWidth
                  required
                />
              </div>
            ) : (
              <div className="col-12 mb-4">
                <TextField
                  value={TenantId}
                  onChange={handleTenantIdChange}
                  size="small"
                  label={t("TenantId")}
                  fullWidth
                  disabled
                />
              </div>
            )}
          </div>
          {(type === "Add" || type === "Edit") && (
            <div className="row w-100">
              <div className="col-6 mb-3 d-flex">
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">{t("Language")}</InputLabel>

                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={nameLanguage}
                    label={t("Language")}
                    onChange={handleLanguageChange}
                  >
                    {languages.map((l) => {
                      return (
                        <MenuItem value={l.pklanguages}>{l.language}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-6 mb-3 d-flex">
              </div>
              <div className="col-12 mb-4">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isdemo}
                      onChange={() => setIsdemo(!isdemo)}
                    />
                  }
                  label={t("cliente demo").toString()}
                />
              </div>
            </div>
          )}
          <div className={cn("row", style["buttons"])}>
            <div className="col">
              <Button
                variant="contained"
                onClick={type === "Add" ? cancelClicked : closeForm}
                color="secondary"
                className={style["secondaryBtn"]}
              >
                {t("Cancel")}
              </Button>
              <Button
                color={"primary"}
                variant="contained"
                onClick={handleSave}
                className="ms-2"
              >
                {type === "Edit" && t("Save")}
                {type === "Add" && t("Insert")}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CustomerForm;
