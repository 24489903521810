import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertMessageType } from "../AlertMessage";

interface AlertMessageSliceType {
    alertMessage: AlertMessageType[]
}

export const alertMessageSlice = createSlice({
    name: "alert-message",
    initialState: {
        alertMessage: []
    } as AlertMessageSliceType,
    reducers: {
        addAlertMessage: (state, action: PayloadAction<AlertMessageType>) => {
            state.alertMessage.push(action.payload)
        },
        removeAlertMessage: (state, action: PayloadAction<number>) => {
            state.alertMessage.splice(action.payload, 1)
        }
    }
})

export const {addAlertMessage, removeAlertMessage} = alertMessageSlice.actions