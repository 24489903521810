import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, InputAdornment, List, ListItem, styled, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, Tooltip, tooltipClasses, TooltipProps, ListItemText, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from "react-i18next"
import style from './groups-management.module.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import cn from "classnames"
import { useEffect, useState } from "react"
import { RootState } from '../../core/store/store'
import { useDispatch, useSelector } from "react-redux"
import { deleteRoomFromTeamGroups, deleteTeamGroups, deleteUserFromTeamGroups, getTeamGroups } from "../../core/store/room/administration-group/administration-group.actions"
import { TabContext, TabPanel } from "@mui/lab"
import { DialogMessageType } from '../../core/components/dialog/dialog-message'
import { Close } from "@mui/icons-material"
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store"
import GroupForm from "./components/GroupForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/pro-solid-svg-icons"
import { TeamGroups } from '../../models/teamgroups';
import { GetAdminRoomsImage } from '../../core/store/room/administration-room/administration-room.actions';
import { Room } from '../../models/room';
import { updateUIState } from '../../core/store/ui/ui.store';

interface AggregatedRooms{
    sitename: string;
    rooms: Room[]
}

export const GroupsManagement: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [index, setIndex] = useState<string>("0")
    const [groupFilter, setGroupFilter] = useState<string>("")
    const [expanded, setExpanded] = useState<number>(0)
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
    const [formType, setFormType] = useState<"New" | "Edit">("New")
    const [selectedGroup, setSelectedGroup] = useState<TeamGroups>(null)
    const { teamGroups } = useSelector((state: RootState) => state.groups)
    const { user } = useSelector((state: RootState) => state.auth)
    const { tenantUsers } = useSelector((state: RootState) => state.users)
    const { adminSites, imageHasLoaded } = useSelector((state: RootState) => state.room.administrationRoom);

    const renameGroup = (pkusrgroups: number) => {
        setSelectedGroup(teamGroups.find(tg => tg.pkteamgroups === pkusrgroups))
        setFormType("Edit")
        setIsFormOpen(true)
    }

    const deleteGroup = (pkteamgroups: number, nome: string) => {
        let message: DialogMessageType = {
            dialogMsg: (<div>{t("Delete Group Confirm")}  <b>"{nome}"</b>?</div>),
            dialogTitle: t("Delete Group"),
            dialogSubmsg: <>{t("Delete Group Note")}</>,
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen: true,
            onDismiss: () => { },
            onAccept: () => {
                dispatch(deleteTeamGroups(pkteamgroups, user.fkcustomers))
            }
        }
        dispatch(getDialogMessage(message));
    }

    const deleteUserFromTeamGroupsClick = (pkuser: number, pkteamgroups: number) => {
        let message: DialogMessageType = {
            dialogMsg: (<div>{t("Delete User From Group Confirm")} <b>"{tenantUsers.find(u => !u.isdeleted && u.pkusers === pkuser)?.username}"</b> {t("From Group")} <b>"{teamGroups.find(tg => tg.pkteamgroups === pkteamgroups)?.teamname}"</b> ?</div>),
            dialogTitle: t("Delete User From Group"),
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen: true,
            onDismiss: () => { },
            onAccept: () => {
                dispatch(deleteUserFromTeamGroups(pkuser, pkteamgroups, user.fkcustomers))
            }
        }
        dispatch(getDialogMessage(message));
    }

    const deleteRoomFromTeamGroupsClick = (pkroom: number, pkteamgroups: number) => {
        let rooms = []
        teamGroups.forEach(tg => rooms.push(...tg.rooms))
        let message: DialogMessageType = {
            dialogMsg: (<div>{t("Delete Room From Group Confirm")} <b>"{rooms.find(r => r.pkrooms === pkroom)?.roomname}"</b> {t("From Group")} <b>"{teamGroups.find(tg => tg.pkteamgroups === pkteamgroups)?.teamname}"</b> ?</div>),
            dialogTitle: t("Delete Room From Group"),
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen: true,
            onDismiss: () => { },
            onAccept: () => {
                dispatch(deleteRoomFromTeamGroups(pkroom, pkteamgroups, user.fkcustomers))
            }
        }
        dispatch(getDialogMessage(message));
    }

    const handlePanelChange = (pkusrgroups: number) => {
        expanded === pkusrgroups ? setExpanded(0) : setExpanded(pkusrgroups);
    }

    const getRoomAttachment = (pkroom: number) => {
        let attach = ""
        adminSites.forEach(s => {
            s.rooms.forEach(r => {
                if(r.pkrooms === pkroom && r.roomattachment !== undefined) attach = r.roomattachment 
            })
        })
        return attach
    }

    useEffect(() => {
        dispatch(getTeamGroups(user.fkcustomers))
        if(adminSites.length > 0 && !imageHasLoaded) {
            dispatch(GetAdminRoomsImage())
        }
        setTimeout(() => {
            dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 17 }))
        }, 500);
    }, [dispatch, adminSites, imageHasLoaded])

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            backgroundColor: 'transparent',
            padding: '0'
        },
    });

    const aggregateGroupRooms = (rooms: Room[]): AggregatedRooms[] =>{
        const tmpArr: AggregatedRooms[] = [];
        
        rooms.forEach(r=>{
            const roomSite = adminSites.find(s=>s.pksites === r.fksites);
            if(roomSite){
                const index = tmpArr.findIndex(t=>t.sitename === roomSite.sitename);  
                if(index === -1){
                    if(roomSite.parent === 0)
                        tmpArr.push({sitename: roomSite.sitename, rooms: [r]});
                    else
                        tmpArr.push({sitename: adminSites.find(as => as.pksites === roomSite.parent).sitename + " - " + roomSite.sitename, rooms: [r]});
                }else{
                    let elemAtIndex = tmpArr[index];
                    elemAtIndex.rooms.push(r);
                    tmpArr.splice(index,1);
                    tmpArr.push(elemAtIndex);
                }
            } 
        });
        
        return tmpArr;
    }

    const openGroupForm = () => { 
        setFormType("New"); 
        setSelectedGroup(null); 
        setIsFormOpen(true); 
        setTimeout(() => {
            dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 18 }))
        }, 200);
    }

    return (
        <div className="container-fluid mt-4 ps-4 pe-4 mb-3">
            <div className="row">
                <div className="col-md-2 pe-0">
                    <TextField
                        id="search-user-input"
                        className="w-100"
                        value={groupFilter}
                        onChange={(e) => setGroupFilter(e.target.value)}
                        label={t("Search Group")}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon fontSize={"small"} /></InputAdornment>
                        }}
                        size="small"
                    />
                </div>
                {user.functionalities.findIndex(f => f.nome === "Add_new_group") >= 0 &&
                    <div className="col">
                        <Button
                            variant="contained"
                            onClick={() => openGroupForm()}
                            className={cn(style["button-primary"])}
                            data-joyride="add-group"
                        >
                            {t("New Group")}
                        </Button>
                    </div>
                }
            </div>
            <div className="row">
                <div className="col">
                    {teamGroups && teamGroups.length > 0 && teamGroups.filter(tg => tg.teamname.toLowerCase().includes(groupFilter.toLowerCase())).map((tg) => (
                        <Accordion className="mt-3" sx={{ width: "100%" }} elevation={1} expanded={expanded === tg.pkteamgroups} key={tg.pkteamgroups} onChange={() => handlePanelChange(tg.pkteamgroups)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className={cn(style["accordion-title"], "d-flex flex-row w-100 gap-2")}>
                                    <h5 className="me-auto mb-0">{tg.teamname}</h5>
                                    {user.functionalities.findIndex(f => f.nome === "Modify_group") >= 0 &&
                                        <p className="mb-0" onClick={(e) => { e.stopPropagation(); renameGroup(tg.pkteamgroups) }}> {t("Edit")} </p>
                                    }
                                    {user.functionalities.findIndex(f => f.nome === "Modify_group") >= 0 && user.functionalities.findIndex(f => f.nome === "Delete_group") >= 0 &&
                                        <p className="mb-0" style={{ color: "gray" }}> | </p>
                                    }
                                    {user.functionalities.findIndex(f => f.nome === "Delete_group") >= 0 &&
                                        <p className="mb-0 me-2" onClick={(e) => { e.stopPropagation(); deleteGroup(tg.pkteamgroups, tg.teamname) }}> {t("Delete")} </p>
                                    }
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <div className="row">
                                    <div className="col">
                                        <TabContext value={index}>
                                            <div className="d-flex flex-column h-100">
                                                <div className={cn("w-100 mt-2")}>
                                                    <Tabs
                                                        indicatorColor="secondary"
                                                        value={index}
                                                        onChange={(e, newValue) => setIndex(newValue)}>
                                                        <Tab value="0" label={t("Group Users")} style={{ width: "15%" }} />
                                                        <Tab value="1" label={t("Group Rooms")} style={{ width: "15%" }} />
                                                    </Tabs>
                                                </div>

                                                <div className={cn("w-100 overflow-hidden")}>
                                                    <div className="w-100 h-100">
                                                        <TabPanel value="0" sx={{ paddingLeft: 0, paddingRight: 0, height: "100%" }}>
                                                            {tg.users && tg.users.length > 0 && tg.users
                                                            .slice()
                                                            .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()))
                                                            .map((gu) => (
                                                                gu.username && (
                                                                <List sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                                    <ListItem
                                                                        secondaryAction={
                                                                            user.functionalities.findIndex(f => f.nome === "Delete_user_from_group") >= 0 &&
                                                                            <IconButton style={{ padding: 0 }} onClick={() => deleteUserFromTeamGroupsClick(gu.pkusers, tg.pkteamgroups)}>
                                                                                <Close fontSize="small" className="primary" />
                                                                            </IconButton>
                                                                        }>
                                                                        <ListItemText>{gu.username}</ListItemText>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </List>)
                                                            ))}
                                                        </TabPanel>

                                                        <TabPanel value="1" sx={{ paddingLeft: 0, paddingRight: 0, height: "100%" }}>                                                            
                                                            <TableContainer>
                                                                <Table size="small">
                                                                    <TableBody>
                                                                        {tg.rooms && tg.rooms.length > 0 && aggregateGroupRooms(tg.rooms).map((ag, i)=>(
                                                                            <>
                                                                                <TableRow key={ag.sitename} >
                                                                                    <TableCell component="th" scope="row" className={cn(style["siteCell"])} >
                                                                                        <p className={cn((i===0)?style["siteNameFirst"]:style["siteName"])}>{ag.sitename}</p>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                {ag.rooms.map(gr=>(
                                                                                    <TableRow
                                                                                        className="table-hover-user"
                                                                                        key={gr.pkrooms}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <TableCell component="th" scope="row" className={style["user-name"]}>
                                                                                            <div className="d-flex flex-row gap-3 ms-3 align-items-center" >
                                                                                                {gr.roomname}
                                                                                                <CustomWidthTooltip
                                                                                                    disableInteractive
                                                                                                    placement='left-start'
                                                                                                    enterDelay={1000}
                                                                                                    title={(getRoomAttachment(gr.pkrooms) !== "") ?
                                                                                                        (<>
                                                                                                            <div style={{ width: "400px", height: "300px", borderRadius: "8px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64," + getRoomAttachment(gr.pkrooms) + "')" }}></div>
                                                                                                        </>) : ""}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faCamera} />
                                                                                                </CustomWidthTooltip>
                                                                                            </div>
                                                                                           
                                                                                        </TableCell>

                                                                                        <TableCell align="right">
                                                                                            {
                                                                                                <>
                                                                                                    {user.functionalities.findIndex(f => f.nome === "Delete_room_from_group") >= 0 &&
                                                                                                        <IconButton style={{ padding: 0 }} onClick={() => deleteRoomFromTeamGroupsClick(gr.pkrooms, tg.pkteamgroups)}>
                                                                                                            <Close fontSize="small" className="primary" />
                                                                                                        </IconButton>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}

                                                                            </>
                                                                        )) }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </TabPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabContext>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
            <GroupForm selectedGroup={selectedGroup} type={formType} open={isFormOpen} handleClose={() => { setSelectedGroup(null); setIsFormOpen(false) }}></GroupForm>
        </div>
    )
}