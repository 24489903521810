import * as React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { HookFormProps } from "./HookFormProps";

interface ControlledSelectProps{
    options: JSX.Element[],
}

export const ControlledSelect: React.FC<HookFormProps & TextFieldProps & ControlledSelectProps> = (props)=>{
    return(
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules} 
        defaultValue={props.defaultValue || ""}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => (
            <TextField
            {...props}
            select={true}
            error={error&& true}
            helperText={error && error.message}
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            >
                {props.options}
            </TextField>
        )}
        />
    )
}