import { IconButton } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment';
import React, {Component} from 'react';
import style from './nextReservations.module.scss';
import cn from "classnames";
import FileSaver from "file-saver";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';



export interface EventProps {
    room: string;
    shift: string;
    date: string;
    startTime: string;
    endTime: string;
    showText?: boolean;
    white?: boolean;
  }

  const saveCalInvite = (props: EventProps, summary: string, description: string) => {
    // Create the .ics URL
    let url = [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        "METHOD:PUBLISH",
        "X-MS-OLK-FORCEINSPECTOROPEN:TRUE",
        "BEGIN:VEVENT",
        "CLASS:PUBLIC",
        "DESCRIPTION:"+ description,
        "DTEND:" + moment(props.date + "T" + props.endTime).toISOString(),
        "DTSTART:" + moment(props.date + "T" + props.startTime).toISOString(),
        "LOCATION:" + props.room,
        "PRIORITY:5",
        "SEQUENCE:0",
        "SUMMARY:"+summary,
        "TRANSP:TRANSPARENT",
        "X-MICROSOFT-CDO-BUSYSTATUS:FREE",
        "X-MICROSOFT-CDO-IMPORTANCE:1",
        "BEGIN:VALARM",
        "TRIGGER:-PT15M",
        "ACTION:DISPLAY",
        "DESCRIPTION:Reminder",
        "END:VALARM",
        "END:VEVENT",
        "END:VCALENDAR"
    ].join("\n");

    let blob = new Blob([url], {type: 'text/calendar;charset=utf-8'});
    FileSaver.saveAs(blob, props.room + ".ics")
    
    //window.open(encodeURI("data:text/calendar;charset=utf8," + url));
}

const Event: React.FC<EventProps> = ({ room, shift, date, startTime, endTime, showText, white }) => {
    const { user } = useSelector((state: RootState) => state.auth)
    const { t } = useTranslation()
    let summary: string = t("JustSeat reservation") + " - " + room;
    let description: string = t("Booked shift") + ": " + shift;
    return (
        <>
        <IconButton className={cn(!white ? style["icon-primary"] : "white", showText && "pt-3")} onClick={() => saveCalInvite({room, shift, date, startTime, endTime}, summary, description)} style={{display: user.functionalities.findIndex(f => f.nome === "Download_ics") === -1 ? "none" : "block"}}>
           <FontAwesomeIcon className={cn(!white ? "primary" : "white", showText && "me-2")} icon={faCalendar} size="sm" />{showText && <h6 className="primary mb-0"> {t("Save")}</h6>}
        </IconButton>
        </>
    )

}

export default Event;