import { ReportRow } from './../../../models/report';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Report {
    report: ReportRow[];
}

export const reportSlice = createSlice({
    name: "room/administration/report",
    initialState: {
      report: [],
    } as Report,

    reducers: {
        getReportSuccess: (state, action: PayloadAction<ReportRow[]>) => {
            state.report = action.payload;
        }
    }
});

export const { getReportSuccess } = reportSlice.actions; 