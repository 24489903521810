import { Button } from "@mui/material";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import svgImage from "../../../asset/img/logo.svg";
import { saveLogin } from "../../../core/store/auth/auth.actions";
import css from "./AlertUserLoggedOut.module.scss";

const AlertUserLoggedOut: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <div className={cn(css["container"],'container-fluid d-grid justify-content-center')}>
            <div className="row">
                <div className='col text-center'>
                    <img
                        className={cn(css["icon-image"])}
                        src={svgImage}
                        alt="JustSeat logo"
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className="col-12">
                    <h2 className={cn(css["title"], "text-center")}>
                        {t("Sign-in")}
                    </h2>
                </div>
                <div className="col-12 mb-5">
                    <p className='text-center'>
                        {t("You're logged out")}
                    </p>
                </div>
                <div className="col-12 text-center">
                    <Button
                        variant="contained"
                        onClick={()=>dispatch(saveLogin("Login"))}
                    >
                        {t("Sign-in")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AlertUserLoggedOut
