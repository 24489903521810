import { ToolbarSubmenu } from "../../core/components/toolbar/store/toolbar.store";

export const submenu: ToolbarSubmenu[] = [
  {
    path: "/home",
    title: "Home",
    visibleOnlyInPath: ["/home", "/reservations", "/whereabouts", "/settings", "/tutorials", "/administration-groups", "/administration-rooms", "/administration-users", "/administration-report", "/administration-customize"],
    onlyAdmin: false,
    guideCategory: 0,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/reservations",
    title: "My reservations",
    visibleOnlyInPath: ["/home", "/reservations", "/whereabouts", "/settings", "/tutorials"],
    onlyAdmin: false,
    guideCategory: 2,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path:"/whereabouts",
    title: "Whereabouts",
    visibleOnlyInPath: ["/home", "/reservations", "/whereabouts", "/settings", "/tutorials"],
    onlyAdmin: false,
    guideCategory: 3,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: null,
    title: "ManagePremium",
    visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users","/administration-report", "/administration-customize"],
    onlyAdmin: true,
    guideCategory: 1,
    dropDownMenu: {
      isDropDown: true,
      menuItems:[
        {
          path: "/administration-rooms",
          title: "Rooms Management",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "Room",
          onlyAdmin: true,
          functionalities: "Sites_management"
        },
        {
          path: "/administration-users",
          title: "Users Management",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "PlayerSettings",
          onlyAdmin: true,
          functionalities: "User_management"
        },
        {
          path: "/administration-customize",
          title: "Customize App",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "AnalyticsReport",
          onlyAdmin: true,
          functionalities: "Customize_App"
        },
        {
          path: "/administration-groups",
          title: "Groups Management",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "AnalyticsReport",
          onlyAdmin: true,
          functionalities: "Groups_management"
        },
        {
          path: "/reservations-management",
          title: "Reservations Management",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: null,
          onlyAdmin: true,
          functionalities: "Mangage_Reservation"
        },
        {
          path: "/reservations-management-limits",
          title: "Manage reservations limits",
          visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: null,
          onlyAdmin: true,
          functionalities: "Manage_Reservation_limits"
        },
      ]
    }
  },
  {
    path: null,
    title: "Manage",
    visibleOnlyInPath: ["/administration-groups", "/administration-rooms", "/administration-users","/administration-report", "/administration-customize"],
    onlyAdmin: true,
    guideCategory: 1,
    dropDownMenu: {
      isDropDown: true,
      menuItems:[
        {
          path: "/administration-rooms",
          title: "Rooms Management",
          visibleOnlyInPath: ["/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "Room",
          onlyAdmin: true,
          functionalities: "Sites_management"
        },
        {
          path: "/administration-users",
          title: "Users Management",
          visibleOnlyInPath: ["/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "PlayerSettings",
          onlyAdmin: true,
          functionalities: "User_management"
        },
        {
          path: "/administration-customize",
          title: "Customize App",
          visibleOnlyInPath: ["/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "AnalyticsReport",
          onlyAdmin: true,
          functionalities: "Customize_App"
        },
        {
          path: "/administration-groups",
          title: "Groups Management",
          visibleOnlyInPath: ["/administration-groups", "/administration-rooms", "/administration-users", "/administration-customize"],
          icon: "AnalyticsReport",
          onlyAdmin: true,
          functionalities: "Groups_management"
        },
      ]
    }
  },
  {
    path: "/administration-rooms",
    title: "Admin",
    visibleOnlyInPath: ["/home", "/reservations", "/whereabouts", "/settings", "/tutorials"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/administration-report",
    title: "Report",
    visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/administration-rooms", "/administration-users","/administration-report", "/administration-customize"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/superadmin-users",
    title: "Superadmin",
    visibleOnlyInPath: ["/reservations-management-limits","/reservations-management","/administration-groups", "/home", "/reservations", "/whereabouts", "/settings", "/administration-rooms", "/administration-users", "/administration-report", "/administration-customize", "/superadmin"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/superadmin-users",
    title: "Gestione Utenti",
    visibleOnlyInPath: ["/superadmin", "/superadmin-customers", "/superadmin-licenses", "/superadmin-users"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/superadmin-customers",
    title: "Gestione Clienti",
    visibleOnlyInPath: ["/superadmin", "/superadmin-users", "/superadmin-licenses", "/superadmin-customers"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
  {
    path: "/superadmin-licenses",
    title: "Gestione Licenze",
    visibleOnlyInPath: ["/superadmin", "/superadmin-customers", "/superadmin-users", "/superadmin-licenses"],
    onlyAdmin: true,
    dropDownMenu: {
      isDropDown: false,
      menuItems : null
    }
  },
];
