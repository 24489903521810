import { faPen, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import cn from "classnames";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../../core/components/dialog/store/dialogMessage.store";
import { ControlledTextField } from "../../../../core/components/mui-input/MuiInput";
import { RootState } from "../../../../core/store/store";
import { RoomSeat } from "../../../../models/room";
import style from "../../rooms-management.module.scss";

interface RoomSeatFormProps{
    isRoomNew: any
    seats: RoomSeat[]
    setSeats: React.Dispatch<React.SetStateAction<RoomSeat[]>>
}

interface NewSeat{
    seatname: string;
    coordinates: string;
    available: boolean;
}

// export interface RoomFormSeatFields {
//     roommap: FileList
// }

export const RoomSeatForm: FC<RoomSeatFormProps> = ({ isRoomNew, seats, setSeats }) => { // , mapName, setMapName, setIsNew
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [action, setAction] = useState<"New" | "Edit">("New");
    const [prevState, setPrevState] = useState<RoomSeat>();
    const [isAvailable, setIsAvailable] = useState<boolean>(true)
    const { user } = useSelector((state: RootState) => state.auth)
    const { roomForUpdate } = useSelector((state: RootState) => state.room.administrationRoom);

    const { control, handleSubmit, reset}
    = useForm<NewSeat>({
        mode: "all",
        reValidateMode: "onSubmit",
        defaultValues: {
            seatname: "",
            coordinates: "0, 0, 0, 0",
            available: true
        }
    })

    const onSubmit = handleSubmit(data => {
        if(action === "New"){
            setSeats(prevState=>([...prevState, {...data, pkroomseats:0}]));
        }else if(action ==="Edit"){
            setSeats(seats.filter(item=> item !== prevState));
            if(prevState.pkroomseats === 0){
                setSeats(prevState=>([...prevState, {...data, pkroomseats:0}]));
            }else{
                const newSeat: RoomSeat = {
                    seatname: data.seatname,
                    coordinates: data.coordinates,
                    available: isAvailable,
                    pkroomseats: prevState.pkroomseats,
                    fkrooms: prevState.fkrooms,
                    doi: prevState.doi,
                    dou: prevState.dou,
                }
                setSeats(prevState=>([...prevState, newSeat]));
            }
        }
        reset({seatname:"", coordinates:"0, 0 ,0 ,0", available: true});
        setAction("New");
    });

    const editSeat = (seat: RoomSeat)=>{
        setIsAvailable(seat.available)
        reset({seatname: seat.seatname, coordinates: seat.coordinates, available: seat.available});
        setAction("Edit");
        setPrevState(seat);
    }

    function onDeleteAlert(seatToDelete: RoomSeat){
        let message : DialogMessageType = {
            dialogMsg :(<div>{t("Delete seat confirm")}: <b>{seatToDelete.seatname}</b>?</div>),
            dialogTitle : t("Attention"),
            dialogAcceptButton: t("Yes"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => { },
            onAccept: () => {
                setSeats(seats.filter(item => item !== seatToDelete))
            }
        }
        dispatch(getDialogMessage(message));
    }

    // const handleMapChange = (mapname: string) => {
    //     setIsNew(true)
    //     setMapName(mapname)
    // }
    const handleAvailableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAvailable(event.target.checked);
    }

    // const deleteMap = () => {
    //     setMapName("")
    //     dispatch(deleteRoomMap(roomForUpdate.room.pkrooms))
    // }
    return(
        <Grid container sx={{ height:"37vh", overflow:"hidden", paddingTop:"1%"}}>
        {/* Form */}
            <Grid
                container
                spacing={2}
                style={{visibility: user.functionalities.findIndex(f => f.nome === "Add_shift" || f.nome === "Modify_shift") === -1 ? "hidden" : "visible"}}
                sx={{height: "fit-content"}}>
                {/* <Grid item xs={5} sm={5} md={5}>
                    <ControlledSelectFile
                        name="roommap"
                        size="small"
                        control={formControl}
                        label={t("Room Map")}
                        fileName={mapName}
                        setFileName={handleMapChange}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        sx={{height:"fit-content", padding: "8px 25px"}}
                        variant="contained"
                        onClick={() => roomForUpdate && roomForUpdate.room ? triggerBase64Download(roomForUpdate.room.mapname.split(".")[1], roomForUpdate.room.mapattachment, roomForUpdate.room.mapname.split(".")[0]) : null}
                        className={cn(style["button-primary"])}
                        disabled={roomForUpdate && roomForUpdate.room && !roomForUpdate.room.mapattachment}>
                        {t("Download Map")}
                    </Button>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} sx={{display: "flex", justifyContent: "end"}}>
                    <Button color="secondary" sx={{height:"fit-content", padding: "8px 25px"}} variant="contained" onClick={() => deleteMap()} className={cn(style["button-secondary"])}>
                        {t("Delete Map")}
                    </Button>
                </Grid>
                <hr /> */}
                <Grid item xs={4} sm={4} md={4}>
                    <div  data-joyride="seat-description">
                        <ControlledTextField
                            size="small"
                            label={t("Seat Name")}
                            fullWidth
                            required
                            control={(control as any)}
                            name={"seatname"}
                            rules={{required: {value: true, message: t("Field Required")}, maxLength:150}}
                        />
                    </div>
                </Grid>
                {/* Is seat available section */}
                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <FormControlLabel control={ <Checkbox name="available" checked={isAvailable} onChange={handleAvailableChange} />} label={t("Is seat available")} />
                </Grid>
                {/* button */}
                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "end"}}>
                    <Button
                        sx={{height:"fit-content", padding: "8px 15px"}}
                        variant="contained"
                        onClick={() => onSubmit()}
                        className={cn(style["button-primary"])}
                        disabled={!((action === "New" && roomForUpdate && roomForUpdate.room && user.functionalities.findIndex(f => f.nome === "Add_Room_Seat") >= 0) || (user.functionalities.findIndex(f => f.nome === "Edit_Room_Seat") >= 0 && action === "Edit") || (isRoomNew && action === "New" && user.functionalities.findIndex(f => f.nome === "Add_Room_Seat") >= 0))}>
                        {action === "New" ? t("Add Seat") : t("Edit Seat")}
                    </Button>
                </Grid>
            </Grid>

            {/* Table */}
            <Box
                component="div"
                width="100%"
                sx={{height:"17vh", overflow:"auto"}}
            >
                <table className="table table-hover table-responsive">
                    <thead>
                        <tr>
                            <th scope="col"><Typography fontWeight={600}> {t("Seat Name")} </Typography></th>
                            <th scope="col"><Typography fontWeight={600}> {t("Actions")} </Typography></th>
                        </tr>
                    </thead>

                    <tbody>
                        {seats.slice().sort((a, b) => a.seatname.localeCompare(b.seatname)).map((rs: RoomSeat)=>(
                            <tr key={rs.pkroomseats}>
                                <td><Typography> {rs.seatname} </Typography></td>
                                <td>
                                    <FontAwesomeIcon icon={faPen} style={{ cursor: "pointer", visibility: user.functionalities.findIndex(f => f.nome === "Edit_Room_Seat") === -1 ? "hidden" : "visible" }} onClick={()=>editSeat(rs)}/> &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: "pointer", visibility: user.functionalities.findIndex(f => f.nome === "Delete_Room_Seat") === -1 ? "hidden" : "visible" }} onClick={()=>{onDeleteAlert(rs)}}/>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </Box>
        </Grid>
    );
}
