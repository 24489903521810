import cn from "classnames";
import { useTranslation } from "react-i18next/";
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';
import svgImage from "../../../asset/img/user-not-active.svg";
import css from "./AlertUserNotActive.module.scss";

const AlertUserNotActive: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <div className='container-fluid d-grid justify-content-center mt-4'>
      <div className="row">
        <div className='col text-center'>
          <img
            className={cn(css["icon-image"])}
            src={svgImage}
            alt={t("Man placing a red circled X on a screen")}
          />
        </div>  
      </div>  
      <div className='row mt-4'>
        <div className="col-12">
          <h2 className='text-center primary'>{user.username}</h2>
        </div>
        <div className="col-12">
          <h2 className="text-center">{t("User not active")}</h2>  
        </div>
        <div className="col-12">
          <p className='text-center'>{t("User not active subtitle")}</p>  
        </div>
      </div>
    </div>
  );
}

export default AlertUserNotActive
