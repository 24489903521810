import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, Step, StepButton, Stepper, Switch, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import cn from "classnames"
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import { useDispatch, useSelector } from 'react-redux'
import { DialogMessageType } from '../../../core/components/dialog/dialog-message'
import { getDialogMessage } from '../../../core/components/dialog/store/dialogMessage.store'
import { getCustomerSettings } from '../../../core/store/customer/settings-customer/settings-customer.action'
import { getCustomerSettingsProfiles } from '../../../core/store/customer/settings-profiles-customer/settings-profiles-customer.action'
import { AddMultipleUserReservation, AddUserReservation, getAllRoomsAndShifts, getAvailableRoomSeats, getAvailableRoomsAndShiftsByDate, getRoomSeats, getShiftsAvailableFromDateToDate } from '../../../core/store/room/user-room/user-room.actions'
import { RootState } from '../../../core/store/store'
import { getUserReservations, getUsersUsrGroups } from '../../../core/store/users/users.actions'
import style from './fastReservation.module.scss'


const FastReservation: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userSites, availableRoomsByDate, AllRoomsAndShifts, availableRoomSeats, shiftsAvailableFromDateToDate } = useSelector((state: RootState) => state.room.userRoom)
  const { user } = useSelector((state: RootState) => state.auth)
  const [reservationDate, setReservationDate] = useState<Moment | null>(null)
  const [reservationFromDate, setReservationFromDate] = useState<Moment | null>(null)
  const [reservationToDate, setReservationToDate] = useState<Moment | null>(null)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [completed, setCompleted] = useState<{ [k: number]: boolean  }>({})
  const [room, setRoom] = useState<string>("")
  const [isShared, setIsShared] = useState<boolean>(false)
  const [shift, setShift] = useState<string[]>([])
  const [roomSeat, setRoomSeat] = useState<string>("")
  const [isActive, setIsActive] = useState(false)
  const [alternativesAreExcluded, setAlternativesAreExcluded] = useState<boolean>(false)
  const { customerSettings } = useSelector((state:RootState)=> state.customerSettings);
  const { customerSettingsProfiles } = useSelector((state:RootState)=> state.customerSettingsProfiles);
  const { usersusrgroups } = useSelector((state: RootState) => state.users);
  const [dayLimit, setDayLimit] = useState<Moment>(moment().add(100,'y'));    const ITEM_HEIGHT = 48;
  const [alternativeReservationsSelected, setAlternativeReservationsSelected] = useState <string[]>([])
  const [daysSelected, setDaysSelected] = useState <string[]>(["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"])

  const weekDays = [
    {day:t("Monday"), value: "Monday"},
    {day:t("Tuesday"), value: "Tuesday"},
    {day:t("Wednesday"), value: "Wednesday"},
    {day:t("Thursday"), value: "Thursday"},
    {day:t("Friday"), value: "Friday"},
    {day:t("Saturday"), value: "Saturday"},
    {day:t("Sunday"), value: "Sunday"}
  ]
  const handleChangeDays = (event: SelectChangeEvent) => {
    if(daysSelected.find((d) => d == event.target.value)){
      setDaysSelected(daysSelected.filter((d) => d != event.target.value))
    }else{
      let addDay = daysSelected;
      addDay.push(event.target.value)
      setDaysSelected(addDay.filter((d) => d != ""))
    }
  }

  const steps = (user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? ["When", "Where", "Which shift", "Which seat", "Done"] : ["When", "Where", "Which shift", "Done"])

  const handleNext = () => {
    if(activeStep === 2 && !(user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0))
      setActiveStep(activeStep + 2)
    else
      setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    switch(activeStep) {
      case 1:
        setReservationDate(null)
        setReservationFromDate(null)
        setReservationToDate(null)
        setRoom(null)
        setIsShared(false)
        break
      case 2:
        setRoom(null)
        setIsShared(false)
        setShift([])
        break
    }
    setActiveStep(activeStep - 1)
    const newCompleted = completed
    newCompleted[activeStep] = false
    setCompleted(newCompleted)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext();
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
    setReservationDate(null)
    setDaysSelected(["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"])
    setReservationFromDate(null)
    setReservationToDate(null)
    setAlternativesAreExcluded(false)
    setAlternativeReservationsSelected([])
    setRoom(null)
    setIsShared(false)
    setShift([])
    setRoomSeat("")
  }

  useEffect(()=>{
    dispatch(getUserReservations());
    dispatch(getUsersUsrGroups())
    dispatch(getCustomerSettings(user.fkcustomers))
    dispatch(getCustomerSettingsProfiles(user.fkcustomers))
  },[dispatch])

  useEffect(()=> {
    if(user.ispremium){
      if(customerSettingsProfiles != null && usersusrgroups != undefined){
        let setting = customerSettingsProfiles.find((csp) => csp.fkusrgroups == usersusrgroups.fkusrgroups);
        if(setting != undefined){
          setDayLimit(  setting.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                        setting.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                        moment().add(setting.limitdays,'d'));
        }else if(customerSettings != null){
          setDayLimit(customerSettings.limitisactive ?
                  customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                  customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                         moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
        }else{
          setDayLimit(moment().add(100,'y'));
        }
      }else if(customerSettings != null){
        setDayLimit(customerSettings.limitisactive ?
                customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                       moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
      }else{
        setDayLimit(moment().add(100,'y'));
      }
    }else{
      setDayLimit(moment().add(100,'y'));
    }
  },[customerSettings,customerSettingsProfiles,usersusrgroups])

  useEffect(()=>{
    setReservationDate(null)
    setReservationFromDate(null)
    setReservationToDate(null)
    setAlternativesAreExcluded(false)
    setDaysSelected(["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"])
  },[isActive])

  const openConfirmationDialogMultipleBooking = () => {
    let pkroom = AllRoomsAndShifts.filter((r) => r.roomname === room)[0].pkrooms
    let pkseat = availableRoomSeats.findIndex(rs => rs.seatname == roomSeat) >= 0 ? availableRoomSeats.find(rs => rs.seatname == roomSeat).pkroomseats : 0
    let pkshifts = "";
    shift.forEach((s) => {
      if(pkshifts == ""){
        pkshifts += (AllRoomsAndShifts.filter((r) => r.roomname === room)[0].shifts.filter((s2) => s2.shiftname === s)[0].pkshifts)
      }else{
        pkshifts += "-" + (AllRoomsAndShifts.filter((r) => r.roomname === room)[0].shifts.filter((s2) => s2.shiftname === s)[0].pkshifts)
      }
    })
    let ListOfDaysSelected = daysSelected.reduce(
      (accumulator, currentValue) => accumulator + "-" + currentValue,
    );
    dispatch(getShiftsAvailableFromDateToDate(reservationFromDate,reservationToDate,pkshifts,pkroom,pkseat,ListOfDaysSelected))
  }

  useEffect(() =>{
    if(roomSeat != "" && roomSeat != null){
      let coveredDays = t("CoveredDays").split("-") //Messaggio che mostrerà quanti giorni sono stati coperti sul totale
      let counterDaysCovered = 0;//Serve a mostrare in interfaccia quanti giorni sono stati coperti
      let counterDaysCanBeCovered = 0;//Serve a togliere il pulsante Prenota nel caso ci siano 0 giorni da poter prenotare
      let daysCanBeCovered = false;//Flag che controlla se nel caso una seat non sia disponibile, se c'è la possibilità di selezionare un'altra seat
      let ListOfDaysNotCovered = [];
      let shiftToPick = alternativeReservationsSelected;
      shiftsAvailableFromDateToDate.forEach(Sa => {
        let fkShiftAndDateRes =  Sa.fkshift.toString()+'/'+moment(Sa.dateReservation).format("yyyy-MM-DD").toString();
          if(Sa.canBeReserved){
            counterDaysCovered++;
            counterDaysCanBeCovered++;
          }else{
            if(Sa.roomSeatAvaible != null && Sa.roomSeatAvaible.length > 0 ){
              daysCanBeCovered = true;
              if(!alternativesAreExcluded){
                counterDaysCanBeCovered++;
                shiftToPick = alternativeReservationsSelected;
                shiftToPick[fkShiftAndDateRes] = Sa.roomSeatAvaible[0].seatname;
                setAlternativeReservationsSelected(shiftToPick);//Se ci sono alternative disponibili di default gli metto la prima disponibile
              }
            }
            ListOfDaysNotCovered.push(<FormControlLabel
                                      value={Sa.dateReservation}
                                      control={Sa.roomSeatAvaible != null && Sa.roomSeatAvaible.length > 0  && !alternativesAreExcluded ?
                                              <Select
                                                value={alternativeReservationsSelected[fkShiftAndDateRes]}
                                                renderValue={() => alternativeReservationsSelected[fkShiftAndDateRes]}
                                                className={cn(style["sizeinput"])}>
                                                {
                                                  Sa.roomSeatAvaible.map((option) => (
                                                    <MenuItem onClick={() => handleRoomSeatAlternative(fkShiftAndDateRes,option.seatname)} key={option.pkroomseats} value={option.seatname}>
                                                      {option.seatname}
                                                    </MenuItem>
                                                  ))
                                                }
                                              </Select>
                                              :
                                                <></>
                                      }
                                      label={<Typography className={cn(style["labelmarginright"])}>
                                              <b>{moment(Sa.dateReservation).format("DD/MM/YYYY")}
                                              {" - " + AllRoomsAndShifts.filter((r) => r.roomname === room)[0].shifts.filter((s2) => s2.pkshifts === Sa.fkshift)[0].shiftname}
                                              </b>
                                              <br />
                                              {Sa.roomSeatAvaible != null && Sa.roomSeatAvaible.length > 0 ?
                                                <div className='d-flex'><a className={cn(style["circleYellow"])}/><a>{alternativesAreExcluded ? t("SeatNotAvailable") : t("AlternativeAvailable")}</a></div>
                                                :
                                                Sa.roomSeatAvaible == null ?
                                                  <div className='d-flex'><a className={cn(style["circleRed"])}/><a>{t("AlreadyHaveReservation")}</a></div>
                                                  :
                                                  <div className='d-flex'><a className={cn(style["circleRed"])}/><a>{t("SeatNotAvailable")}</a></div>
                                              }
                                            </Typography>}
                                      labelPlacement = "start"
                                      className={cn(style["borderforreservations"])}
                                      />
            )
          }
      })
      let msgtxt =< div></div>;
      let dialogMsgtxt = <div></div>;
      if(shiftsAvailableFromDateToDate.length == 0){
        msgtxt  = <div><strong>{t("NotDaysToCover")}</strong></div>
      }else if(counterDaysCovered == shiftsAvailableFromDateToDate.length){
        msgtxt  = <div><strong>{t("AllDaysCovered")}</strong></div>
      }else{
        msgtxt = <div>
                    <div><strong>{coveredDays[0]}</strong><strong className={cn(style["DaysCovered"])}>{coveredDays[1].replace("X",""+counterDaysCovered)}</strong><strong>{coveredDays[2].replace("X",""+shiftsAvailableFromDateToDate.length)}</strong></div>
                    { daysCanBeCovered ?
                          <div style={{justifyContent:'flex-end'}}> <p className={cn(style["belowDays"])}>{t("BelowDays")}</p>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={alternativesAreExcluded}
                                  onChange={() => setAlternativesAreExcluded(!alternativesAreExcluded)}
                                />
                              }
                              label={<a style={{fontSize: 12}}>{t("ExcludeAlternatives")}</a>}
                              style={{float: 'right'}}
                            />
                          </div>
                       :
                       <></>
                      }
                    <FormControl className={cn(style["form"])}>{ListOfDaysNotCovered}</FormControl>
                  </div>
      }
      dialogMsgtxt = <p style={{fontSize: 12}} >
                              <strong>{t("Period")}</strong>{": "+t("Dal") + " " + reservationFromDate.format("DD-MM-YYYY") + " " + t("Al") + " " + reservationToDate.format("DD-MM-YYYY") + (daysSelected.length != 7 ? "("+t("OnlyThe")+": "+ daysSelected.map((d) => { return " "+t(d)}) +")": "")
                      + " | "}<strong>{t("Room")}</strong>{ ": " + room
                      + " | "}<strong>{t("Shift")}</strong>{ ": " + shift
                      + " | "}<strong>{t("Seat")}</strong>{ ": " + roomSeat}
                      </p>;
      let dialog : DialogMessageType;
      if(counterDaysCanBeCovered >= 1){
        dialog = {
          dialogTitle : t("Reservation summary"),
          dialogMsg :  dialogMsgtxt,
          dialogSubmsg: msgtxt,
          dialogAcceptButton: t("Confirm prenotation buttton"),
          dialogDismissButton: t("Dismiss prenotation buttton"),
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {handleDialogConfirmationMultipleBooking()}
        }
      }else{
         dialog = {
          dialogTitle : t("Reservation summary"),
          dialogMsg :  dialogMsgtxt,
          dialogSubmsg: msgtxt,
          dialogAcceptButton: t("Dismiss prenotation buttton"),
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {}
        }
      }
      dispatch(getDialogMessage(dialog))
    }
  },[shiftsAvailableFromDateToDate,alternativesAreExcluded])

  const openConfirmationDialog = () => {
    let dialog : DialogMessageType = {
      dialogTitle : t("Reservation summary"),
      dialogDate : moment(reservationDate).format("DD/MM/YYYY"),
      dialogMsg :  <p className="mb-0" style={{fontWeight:"600"}}>{room + " - " + shift.join(', ') + (user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? ", " + t("Seat") + ": " + roomSeat : "")}</p>,
      dialogSubmsg: <p className="mb-0">{t("Confirm prenotation")}</p>,
      dialogAcceptButton: t("Confirm prenotation buttton"),
      dialogDismissButton: t("Dismiss prenotation buttton"),
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {handleDialogConfirmation()}
    }
    dispatch(getDialogMessage(dialog))
  }

  const handleDialogConfirmationMultipleBooking = () => {
    let reservationsToReserve =  structuredClone(shiftsAvailableFromDateToDate);
    const pkroomseat = availableRoomSeats.findIndex((rs) => rs.seatname == roomSeat) >= 0 ? availableRoomSeats.find((rs) => rs.seatname == roomSeat).pkroomseats : 0;
    let fkShiftAndDateRes;
      if(alternativesAreExcluded){
        dispatch(AddMultipleUserReservation(reservationsToReserve,pkroomseat,room,shift.join(', '),reservationFromDate,reservationToDate,daysSelected));
      }else{
        shiftsAvailableFromDateToDate.forEach((s) => {
          fkShiftAndDateRes = s.fkshift.toString()+'/'+moment(s.dateReservation).format("yyyy-MM-DD").toString();
            if(alternativeReservationsSelected[fkShiftAndDateRes]){
              reservationsToReserve.find((r) => r.fkshift == s.fkshift && r.dateReservation == s.dateReservation).canBeReserved = true;
              reservationsToReserve.find((r) => r.fkshift == s.fkshift && r.dateReservation == s.dateReservation).roomSeatAvaible = availableRoomSeats.filter((a) =>  a.seatname == alternativeReservationsSelected[fkShiftAndDateRes]);
            }
        })
        dispatch(AddMultipleUserReservation(reservationsToReserve,pkroomseat,room,shift.join(', '),reservationFromDate,reservationToDate,daysSelected));
      }
    handleComplete()
  }

  const handleDialogConfirmation = () => {
    shift.forEach((s) => {
      const pkshift = availableRoomsByDate.filter((r) => r.roomname === room)[0].shifts.filter((s2) => s2.shiftname === s)[0].pkshifts
      const pkroom = availableRoomsByDate.filter((r) => r.roomname === room)[0].pkrooms
      dispatch(AddUserReservation(pkshift, moment(reservationDate), pkroom, 2, user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 ? (availableRoomSeats.findIndex(rs => rs.seatname == roomSeat) >= 0 ? availableRoomSeats.find(rs => rs.seatname == roomSeat).pkroomseats : 0) : 0))
    })
    handleComplete()
  }


  const handleRoomChange = (event: SelectChangeEvent) => {
    let stop = false
    userSites.forEach((us) => {
      if(event.target.value === us.pksites.toString()){
        stop = true
      }
    })

    if(!stop) {
      userSites.forEach((us) => {
        if(us.rooms.findIndex((r) => event.target.value == r.roomname) >= 0){
          setIsShared(us.rooms.find((r) => event.target.value == r.roomname).isshared);
        }
      })
      setRoom(event.target.value as string)
      handleComplete()
    }
  };

  const handleShiftChange = (event: SelectChangeEvent<typeof shift>) => {
    const {
      target: { value },
    } = event
    setShift(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRoomSeatChange = (event: SelectChangeEvent) => {
    setRoomSeat(event.target.value.toString())
  };
  const handleRoomSeatAlternative = (fkShiftAndDateRes: string,seatname: string) => {
    let altSelect = alternativeReservationsSelected;
    altSelect[fkShiftAndDateRes] = seatname;
    setAlternativeReservationsSelected(altSelect)
  };

  const onKeyDown = (e) => {
    e.preventDefault();
 };

  const getStepContent = () => {
    switch(activeStep) {
      case 0:
        return (
          <>
          {isActive ?
            <div>
              <div className="d-flex flex-row gap-3">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label={t("From day")}
                    value={reservationFromDate}
                    inputFormat="DD/MM/yyyy"
                    onChange={(date) => {
                      setReservationFromDate(date)
                    }}
                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                    minDate={moment()}
                    maxDate={dayLimit}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label={t("To day")}
                    value={reservationToDate}
                    inputFormat="DD/MM/yyyy"
                    onChange={(date) => {
                      setReservationToDate(date)
                    }}
                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                    minDate={moment(reservationFromDate)}
                    maxDate={dayLimit}
                  />
                </LocalizationProvider>

                <Button
                  variant="contained"
                  onClick={()=>{
                    dispatch(getAllRoomsAndShifts());
                    handleComplete();
                  }}
                  disabled={daysSelected.length == 0 || reservationFromDate === null || !reservationFromDate.isValid()|| reservationFromDate.startOf('day') < moment().startOf('day') || reservationToDate === null || !reservationToDate.isValid()|| reservationToDate.startOf('day') < moment(reservationFromDate).startOf('day')}
                  className="mt-2">
                  {t("Continue")}
                </Button>
              </div>
              <div>
                <ul className="d-flex flex-row p-0 m-0">
                  {weekDays.map(days => {
                    let test = structuredClone(daysSelected.findIndex((d) => d === days.value))
                    return (
                      <>
                        <FormControlLabel
                          value={days.value}
                          control={<Checkbox onChange={handleChangeDays} style={{padding:3,paddingTop:6}} checked={test >= 0 ? true : false } />}
                          label={days.day.charAt(0)}
                          labelPlacement="bottom"
                          style={{margin:0}}
                        />
                      </>
                    );
                  })}
                </ul>
              </div>

            </div>
          :
            <div className="d-flex flex-row gap-3">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label={t("Reservation date")}
                  value={reservationDate}
                  inputFormat="DD/MM/yyyy"
                  onChange={(date) => {
                    setReservationDate(date)
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                  minDate={moment()}
                  maxDate={dayLimit}
              />
              </LocalizationProvider>

              <Button
                variant="contained"
                onClick={()=>{
                  dispatch(getAvailableRoomsAndShiftsByDate(moment(reservationDate)));
                  handleComplete();
                }}
                disabled={reservationDate === null || !reservationDate.isValid()|| reservationDate.startOf('day') < moment().startOf('day')}
                className="mt-2">
                {t("Continue")}
              </Button>
            </div>
          }
          {user.functionalities.findIndex(f => f.nome === "Multiple_reservation") >= 0 && user.ispremium  ?
            <div>
              <FormControlLabel control={
                <Switch
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
              }
              label={t("BookMultipleDays").toString()}/*TODO ALTRE LINGUE*/
              labelPlacement="end"/>
            </div>
            :
            <></>
          }
          </>
        )
      case 1:
        return (
          <div className="row">
            <div className="col-5">
              <FormControl sx={{ m: 1, width: 250 }}>
                <InputLabel id="selectRoomLabel">{t("Select a room")}</InputLabel>
                <Select
                  labelId="selectRoomLabel"
                  label={t("Select a seat")}
                  value={room}
                  onChange={handleRoomChange}>
                  {
                    userSites.findIndex(us => us.rooms.length > 0) >= 0 ?
                    userSites.map((us) => {
                      var print = [];
                      if(us.rooms && us.rooms.length > 0){
                        //SITE NAME
                        print.push(<ListSubheader className={cn(style["site-label"])} value={us.pksites.toString()}>{us.parent === 0 ? us.sitename : userSites.find(u => u.pksites === us.parent).sitename + " - " + us.sitename}</ListSubheader>);
                        (reservationDate ? availableRoomsByDate : AllRoomsAndShifts).filter((r)=> r.fksites === us.pksites).forEach((r) => {
                          print.push(<MenuItem key={r.pkrooms} value={r.roomname}>{r.roomname}</MenuItem>);
                        });
                      }

                      return print
                    })
                    :
                    <MenuItem key="no-result" disabled>
                      {t("No results found")}
                    </MenuItem>
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        )
      case 2:
        let counterMenuItem = 0;
        return(
          <div className="d-flex flex-row gap-3">
            <FormControl className="mb-0" sx={{ m: 1, width: 250 }}>
              <InputLabel id="shiftSelectLabel">{t("Select shifts")}</InputLabel>
              <Select
                labelId="shiftSelectLabel"
                label={t("Select shifts")}
                value={shift}
                onChange={handleShiftChange}
                multiple
                renderValue={(selected) => selected.join(', ')}>
                {
                  (reservationDate ? availableRoomsByDate : AllRoomsAndShifts).find((r) => r.roomname === room).shifts.length > 0 ? (reservationDate ? availableRoomsByDate : AllRoomsAndShifts).find((r) => r.roomname === room).shifts.map((s) =>(
                    <MenuItem className="padding-custom item-shift" key={s.pkshifts} value={s.shiftname}
                    style={
                       (shift.map( (shiftAlreadyReserved,index) => (
                                    (
                                      shiftAlreadyReserved == s.shiftname ? true: //Se hanno lo stesso nome significa che starebbe per confrontarsi con se stesso, skippo con true
                                      (
                                        (reservationDate ? availableRoomsByDate : AllRoomsAndShifts).find((r) => r.roomname === room).shifts.find(shToEqual => shToEqual.shiftname == shiftAlreadyReserved).starttime >= s.endtime
                                        &&
                                        s.endtime  != "00:00" //Controllo se non è 00 in quanto nel caso endtime sia 00:00 significa che è la mezzanotte del giorno dopo e quindi in realtà è più grande dello StartTime
                                      )
                                      ||
                                      (reservationDate ? availableRoomsByDate : AllRoomsAndShifts).find((r) => r.roomname === room).shifts.find(shToEqual => shToEqual.shiftname == shiftAlreadyReserved).endtime <= s.starttime
                                    ) ?//Se torna false significa che ci sono shift già selezionati con cui questo menuItem si accavalla, aumento counterMenuItem così che lo renda non cliccabile
                                      (index > 0 ? counterMenuItem : counterMenuItem = 0) : (index>0 ? counterMenuItem++ : counterMenuItem = 1)//Se index è 0 significa che ha appena iniziato a confrontare un'altro MenuItem, resetto la variabile
                      )) && counterMenuItem ? {pointerEvents:"none",opacity:0.6} : {pointerEvents:"auto"})
                    }
                    >
                      <Checkbox checked={shift.indexOf(s.shiftname) > -1}/>
                      {s.shiftname}&nbsp;
                      ({s.starttime} - {s.endtime})
                    </MenuItem>
                  ))
                  :
                  <MenuItem key="no-result" disabled>
                    {t("No results found")}
                  </MenuItem>
                }
              </Select>
            </FormControl>
            {reservationDate ?
            <Button
              variant="contained"
              onClick={()=>{
                dispatch(getAvailableRoomSeats(encodeURIComponent(availableRoomsByDate.filter((r) => r.roomname === room)[0].shifts.filter((s) => shift.includes(s.shiftname)).map(s => s.pkshifts).join(',')), availableRoomsByDate.find(r => r.roomname === room).pkrooms, reservationDate));
                handleComplete();
              }}
              disabled={shift.length === 0}
              className="mt-2"
              hidden={!(user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0)}>
              {t("Continue")}
            </Button>
            :
            <Button
              variant="contained"
              onClick={()=>{
                dispatch(getRoomSeats(AllRoomsAndShifts.find(r => r.roomname === room).pkrooms));
                handleComplete();
              }}
              disabled={shift.length === 0}
              className="mt-2"
              hidden={!(user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0)}>
              {t("Continue")}
            </Button>
            }
            <Button
              variant="contained"
              onClick={()=>{
                openConfirmationDialog()
              }}
              disabled={shift.length === 0}
              className="mt-2"
              hidden={user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0}>
              {t("Confirm shifts")}
            </Button>
          </div>
        )
      case 3:
        return(
          <div className="d-flex flex-row gap-3">
            <FormControl sx={{ m: 1, width: 250 }}>
              <InputLabel id="selectRoomSeat">{t("Select a seat")}</InputLabel>
              <Select
                labelId="selectRoomSeat"
                label={t("Select a seat")}
                value={roomSeat}
                onChange={handleRoomSeatChange}>
                {
                  isShared ?
                    <MenuItem key={0} value={room}>
                      {room}
                    </MenuItem>
                  :
                    availableRoomSeats.length > 0 ?
                      availableRoomSeats.map((rs) => (
                        <MenuItem key={rs.pkroomseats} value={rs.seatname}>
                          {rs.seatname}
                        </MenuItem>
                      ))
                    :
                      <MenuItem key="no-result" disabled>
                        {t("No results found")}
                      </MenuItem>
                }
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={reservationDate ? openConfirmationDialog : openConfirmationDialogMultipleBooking}
              disabled={roomSeat === ""}
              hidden={activeStep !== 3}
              className="mt-2">
              {t("Confirm seat")}
            </Button>
          </div>
        )
      case 4:
        return(
          <div className="row">
            <div className="col">
              <p>{t("Booking successful")}</p>
            </div>
          </div>
        )
    }
  }

  const getShiftsConcat = () =>{
    let label = "";
    shift.forEach(s => {
      if(shift.length === 1){
        label += s;
      } else{
        if(shift.indexOf(s) === (shift.length -1)){
          label += s;
        } else{
          label += s+", ";
        }
      }
    });
    return label;
  }

  return (
    <Card className="ms-3" sx={{mb: 2}}>
      <CardContent>
        <div className="row mt-1 ms-1">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h5 className="primary">{t("Find a seat")}</h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]} className={label === "When" ? "ps-0" : ""}>
                      <StepButton color="inherit">
                        {label === "When" ? ((reservationDate !== null && reservationDate.isValid()) ? moment(reservationDate).format("DD/MM/YYYY") : ((reservationFromDate !== null && reservationFromDate.isValid() && reservationToDate !== null && reservationToDate.isValid()) ?  moment(reservationFromDate).format("DD/MM/YYYY")+" - "+moment(reservationToDate).format("DD/MM/YYYY") : t(label))) : ""}
                        {label === "Where" ? (room !== ""  && room != null ?  room : t(label)) : ""}
                        {label === "Which shift" ? (shift.length > 0 ? getShiftsConcat() : t(label)) : ""}
                        {
                          user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 &&
                          label === "Which seat" ? (roomSeat.length > 0 ? roomSeat : t("Which seat")): ""
                        }
                        {label === "Done" ? t(label) : ""}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <>
                  <Box className="mt-4">
                    <div className="row">
                      <div className="col">
                        <Button
                          color="inherit"
                          onClick={handleBack}
                          hidden={activeStep === 0 || activeStep === 4}
                          className={cn(style["borderButton"], "ms-2")}>
                          {t("Back")}
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {getStepContent()}
                        <Button onClick={handleReset} hidden={activeStep !== 4}>{t("Again")}</Button>
                      </div>
                    </div>
                  </Box>
                </>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default FastReservation