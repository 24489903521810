import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ToolbarSubmenu = {
  title: string;
  path: string | null;
  visibleOnlyInPath: string[];
  onlyAdmin: boolean;
  guideCategory?: number;
  //Dropdown menu section
  dropDownMenu: {
    isDropDown: boolean,
    menuItems: {
      title: string;
      path: string;
      visibleOnlyInPath: string[];
      icon: string;
      onlyAdmin: boolean;
      functionalities: string;
    } []
  };
};

interface ToolbarSliceState {
  submenu: ToolbarSubmenu[];
  //proprietà che "renderizza" o meno l'elemento nella pagina
  renderToolbar: boolean;
  isCollapsed: boolean;
}

export const toolbarSlice = createSlice({
  name: "toolbar",
  initialState: {
    submenu: [],
    renderToolbar: false,
    isCollapsed: true,
  } as ToolbarSliceState,
  reducers: {
    setToolbarSubmenu: (state, action: PayloadAction<ToolbarSubmenu[]>) => {
      state.submenu = action.payload;
    },
    toggleRenderToolbar: (state, action: PayloadAction<boolean>) => {
      state.renderToolbar = action.payload;
    },
    toggleToolbarMenu: (state, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload;
    },
  },
});

export const { setToolbarSubmenu, toggleRenderToolbar, toggleToolbarMenu } = toolbarSlice.actions;
