import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { removeAlertMessage } from './store/alertMessage.store'
import { RootState } from '../../store/store'
import { useEffect } from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'


export type AlertMessageType = {
    htmlMessage: string
    type: AlertColor,
    params?: any
}

const AlertMessage: React.FC = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {alertMessage} = useSelector((state: RootState) => state.alertMessage)
    const vertical = "top";
    const horizontal = "center";

    useEffect(() => {
        let timer: NodeJS.Timeout[] = []
        alertMessage.forEach((a, i) => {
            timer.push(
                setTimeout(() => {
                    dispatch(removeAlertMessage(i))
                }, 20000)
            )
        })

        return () => {
            timer.forEach(t => {
                clearTimeout(t)
            })
        }
    }, [alertMessage])

    return (
        <>
            {alertMessage.map((mess, i) => (
                    <Snackbar 
                        key={i}
                        anchorOrigin={{vertical, horizontal}}
                        open={true} 
                        autoHideDuration={6000} 
                        onClose={() => {dispatch(removeAlertMessage(i))}}>
                    <Alert 
                        onClose={() => {dispatch(removeAlertMessage(i))}} 
                        severity={mess.type} 
                        sx={{ width: '100%' }}>
                      {t(mess.htmlMessage, mess.params || undefined)}
                    </Alert>
                  </Snackbar>
                )
            )}
        </>
    )
}

export default AlertMessage