import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import cn from 'classnames';
import { t } from 'i18next';
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reservation } from "../../../models/reservation";
import { Room, RoomSeat } from "../../../models/room";
import { Shift } from "../../../models/shift";
import { AddUserReservation, changeRoomSeat } from "../../store/room/user-room/user-room.actions";
import { RootState } from "../../store/store";
import style from './seatsdialog.module.scss';

export type SeatsDialogVariant = "desktop" | "mobile" | "next-reservations";

export type SeatsDialogType = {
    isOpen: boolean
    shift: Shift
    room: Room,
    variant: SeatsDialogVariant,
    selectedDate: Moment,
    startDate: Moment
}

const SeatsDialog: React.FC = () => {
    const { seatsDialog } = useSelector((state: RootState) => state.dialogs)
    const { roomReservations, reservationsBydate, roomSeatsList } = useSelector((state: RootState) => state.room.userRoom)
    const meUser = useSelector((state: RootState) => state.auth.user)

    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [open, setOpen] = useState<boolean>(seatsDialog.isOpen);
    const [soartedRoomSeats, setSoartedRoomSeats] = useState<RoomSeat[]>([]);
    const dispatch = useDispatch();


    const handleClose = () => setOpen(false);

    const handleMakeReservation = (roomSeat: RoomSeat) => {
        if(roomSeat.available && getColClassName(roomSeat) === "free"){
            //Controllo se ho già prenotato un posto (e nel caso lo cambio)
            const oldReservation = reservations.find(r=>r.fkusers === meUser.pkusers);

            if(oldReservation){
                dispatch(changeRoomSeat(
                    seatsDialog.variant,
                    oldReservation.pkreservations,
                    seatsDialog.shift,
                    seatsDialog.selectedDate,
                    seatsDialog.room.pkrooms,
                    roomSeat,
                    seatsDialog.startDate
                ));
            }else{
                dispatch(AddUserReservation(
                    seatsDialog.shift.pkshifts,
                    moment(seatsDialog.selectedDate),
                    seatsDialog.shift.fkrooms,
                    (seatsDialog.variant === "desktop")?1:0,
                    roomSeat.pkroomseats,
                    moment(seatsDialog.selectedDate)
                ));
            }

            handleClose();

        }
    }

    /// Metodo che ordina le postazioni in base alla loro disponibilità
    // Prima quelle non prenotate, poi quelle prenotate e in fine quelle non disponibili
    useEffect(()=>{
        let allRoomsSeats = [];
        if(roomSeatsList && reservations && roomSeatsList.length > 0){
            const availableRoomSeats = [];
            const notAvailableRoomSeats = [];
            const reservedRoomSeats = [];

            roomSeatsList.forEach((rs)=>{
                if(!rs.available){
                    notAvailableRoomSeats.push(rs);
                }else{
                    const reservationIsFound = reservations.findIndex(r => r.fkroomseats === rs.pkroomseats ) !== -1;

                    if(reservationIsFound){
                        reservedRoomSeats.push(rs);
                    }else{
                        availableRoomSeats.push(rs);
                    }
                }
            });

            allRoomsSeats = availableRoomSeats.concat(reservedRoomSeats).concat(notAvailableRoomSeats);
        }

        setSoartedRoomSeats(allRoomsSeats);
    },[roomSeatsList, reservations]);


    //Salvo solo le prenotazioni in quella data, in quel turno e in quella stanza
    useEffect(() => {
        let res = [];
        if(seatsDialog.shift){
            if(seatsDialog.variant === "desktop" && roomReservations.length > 0){
                res = roomReservations
                    .find(rbd => rbd.pkshifts === seatsDialog.shift.pkshifts)?.reservations
                    .filter(r=> moment(r.reservationdate).format("DD/MM/YYYY") === moment(seatsDialog.selectedDate).format("DD/MM/YYYY"));

            }else if((seatsDialog.variant === "mobile" || seatsDialog.variant === "next-reservations") && reservationsBydate.length > 0){
                res = reservationsBydate.find(rbd => rbd.pkshifts === seatsDialog.shift.pkshifts)?.reservations
            }

            setReservations(res);
        }
    }, [roomReservations, reservationsBydate, seatsDialog.shift, seatsDialog.selectedDate, seatsDialog.variant]);


    const getColClassName = (roomSeat: RoomSeat) => {
        if (
          reservations &&
          reservations.length > 0 &&
          reservations.findIndex((r) => r.fkroomseats === roomSeat.pkroomseats) >= 0
        ) {
            if(reservations.find(r => r.fkroomseats === roomSeat.pkroomseats).fkusers === meUser.pkusers){
                return 'reserved-me'
            }
            else {
                return 'reserved'
            }
        } else {
            return 'free'
        }
    }

    useEffect(() => setOpen(seatsDialog.isOpen), [seatsDialog]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
                    <DialogTitle className='d-flex flex-row mt-1 justify-content-between align-items-center gap-2'>
                        <h5 className={cn(style["title"], "mb-0 text-center ")}>{t("Choose your seat in")} <b>{(seatsDialog?.room?.roomname)}</b></h5>
                        <IconButton onClick={()=> handleClose()} >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                    {
                        soartedRoomSeats &&
                        soartedRoomSeats.length > 0 &&
                        soartedRoomSeats.map((rs, i) => (
                            <div className="row" title={getColClassName(rs) == 'free' ? t("Click to reserve") : ''} onClick={() => handleMakeReservation(rs)}>
                                <div className={cn("col-12 text-center", style["seat-col"], style[getColClassName(rs)])}>
                                    <div className="row mt-3">
                                        <div className={cn("col-12", style["seat-name"])}>
                                            <p className="mb-0">{rs.seatname}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={cn("col-12", style["reservation-subtitle"])}>
                                            {
                                                rs.available ?
                                                (
                                                    reservations &&
                                                    reservations.length > 0 &&
                                                    reservations.findIndex(r => r.fkroomseats === rs.pkroomseats && moment(r.reservationdate).isSame(moment(seatsDialog.selectedDate).format("YYYY-MM-DD"))) >= 0 ?
                                                        <p>
                                                            {t("Reserved by")} <span className={style["reserved-by"]}>{reservations.find(r => r.fkroomseats === rs.pkroomseats).utenti}</span>
                                                        </p>
                                                            :
                                                        <p>
                                                            {t("Free to reserve")}
                                                        </p>
                                                )
                                                :
                                                <p className={style["gray"]}>
                                                    {t("Not available")}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    i !== (roomSeatsList.length - 1) &&
                                    <hr className="mb-0" />
                                }
                            </div>
                        ))
                    }
                    </DialogContent>
        </Dialog>
    )
}

export  default SeatsDialog;