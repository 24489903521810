import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { faExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, Modal, Tab, Tabs } from "@mui/material";
import cn from "classnames";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { addAlertMessage } from "../../../core/components/message-bar/store/alertMessage.store";
import { addRoom, addRoomAndAttachment, deleteReservationsFromDate, resetOldPkAttachment, updateRoom, updateRoomAndAttachment } from '../../../core/store/room/administration-room/administration-room.actions';
import { resetRoomForUpdateSuccess } from "../../../core/store/room/administration-room/administration-room.store";
import { RootState } from "../../../core/store/store";
import { updateUIState } from "../../../core/store/ui/ui.store";
import { NewRoom, RoomForUpdate, RoomSeat } from "../../../models/room";
import { Shift } from "../../../models/shift";
import { TeamGroups } from "../../../models/teamgroups";
import { User } from "../../../models/user";
import { IconOption, RoomDataForm, RoomFormDataFields } from "./components/RoomDataForm";
import { RoomSeatForm } from "./components/RoomSeatForm";
import { RoomShiftForm } from "./components/RoomShiftForm";
import { RoomFormUserFields, RoomUserForm } from "./components/RoomUserForm";
import css from "./room-modal.module.scss";
// RoomFormSeatFields

interface StationModalProps{
    showModal: boolean;
    onCloseModal: ()=>void;
    type: "New" | "Duplicate" | "Update";
}


const StationModal: FC<StationModalProps> = ({ showModal, onCloseModal, type }) => {
    const [index, setIndex] = useState("0");
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [newShifts, setNewShifts] = useState<Shift[]>([]);
    const { roomForUpdate, oldPkAttachment } = useSelector((state: RootState) => state.room.administrationRoom);
    const { tenantUsers } = useSelector((state: RootState) => state.users);
    const { teamGroups } = useSelector((state: RootState)=> state.groups);
    const { user } = useSelector((state:RootState) => state.auth)
    const [ users, setUsers] = useState<User[]>([]);
    const [ IsShared, setIsShared] = useState<boolean>(false);
    const [ groups, setGroups] = useState<TeamGroups[]>([]);
    const [ seats, setSeats ] = useState<RoomSeat[]>([])
    const [ defaultIcon, setDefaultIcon] = useState<IconOption | undefined>(undefined);
    const [error, setError] = useState<Boolean>(false);
    const { control, handleSubmit, reset, getValues }
    = useForm<RoomFormDataFields & RoomFormUserFields>({ //& RoomFormSeatFields
        mode: "all",
        reValidateMode: "onSubmit",
    });
    const [fileName, setFileName] = useState<string>("");
    // const [mapName, setMapName] = useState<string>("");
    // const [isMapNew, setIsMapNew] = useState<boolean>(false)

    // constant used to make tabs section more readable
    const width = user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ? "25%" : "33%";

    useEffect(() =>{
        console.log(index)
        if(index == "0"){
            dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 3 }))
            setTimeout(() => {
                dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 3 }))
            }, 800);
        }else if(index == "1"){
            dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 21 }))
            setTimeout(() => {
                dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 21 }))
            }, 800);
        }else if(index == "2"){
            dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 22 }))
            setTimeout(() => {
                dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 22 }))
            }, 800);
        }else if(index == "3"){
            dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 23 }))
            setTimeout(() => {
                dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 23 }))
            }, 800);
        }
    },[index]);

    useEffect(()=>{
        if(roomForUpdate && roomForUpdate.room){
            if(type==="Duplicate" || type ==="Update"){
                if(roomForUpdate.room.iconname !== null){
                    const value = roomForUpdate.room.iconname.split(" ");
                    setDefaultIcon({value:[value[0] as IconPrefix, value[1] as IconName], label: value[1]});
                }

                setNewShifts(roomForUpdate.room.shifts);
                setSeats(roomForUpdate.seats)
                setIsShared(roomForUpdate.room.isshared)
                // setMapName(roomForUpdate.room.mapname)
            }else{
                setNewShifts([]);
                setDefaultIcon(undefined);
                // setMapName("")
                setSeats([])
                setIsShared(false)
            }
        }
    },[roomForUpdate, type]);

    useEffect(()=>{
        if((type==="Duplicate" || type ==="Update") && roomForUpdate && roomForUpdate.room){
            //Get users
            const _users:User[] = [];
            roomForUpdate.roomusers.forEach((u)=>{
                const _user = tenantUsers.find((tu)=> tu.pkusers === u.fkusers);
                if(_user !== undefined){_users.push(_user)}
            })
            setUsers(_users);

            //Get groups
            const _groups = teamGroups.filter(t=> t.rooms.findIndex(r=> r.pkrooms === roomForUpdate.room.pkrooms) !== -1);
            setGroups(_groups);
        }
    },[roomForUpdate, tenantUsers, type, teamGroups])

    useEffect(()=>{
        reset({...getValues(), users: users })
    },[users, getValues, reset]);

    useEffect(()=>{
        if(roomForUpdate && roomForUpdate.room){

            if(type === "Update"){
                reset({
                    fksites: roomForUpdate.room.fksites,
                    roomname: roomForUpdate.room.roomname,
                    roomdescription: roomForUpdate.room.roomdescription,
                    roomseats: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") ? roomForUpdate.seats.length : roomForUpdate.room.roomseats
                });
            }else if (type === "Duplicate"){
                reset({
                    fksites: roomForUpdate.room.fksites,
                    roomname: "",
                    roomdescription: roomForUpdate.room.roomdescription,
                    roomseats: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") ? roomForUpdate.seats.length :  roomForUpdate.room.roomseats
                })
            }else if(type==="New"){
                setUsers([]);
                reset({})
            }
        }
    },[roomForUpdate, type, reset])

    const editRoomseatsnumberAlert = (data) => {
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Delete map message pt.1")} <br/> {t("Delete map message pt.2")}</div>),
            dialogTitle : t("Map update"),
            dialogAcceptButton: t("Continue"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                confirmUpdateRoom(data)
            }
        }
        dispatch(getDialogMessage(message))
    }

    const confirmUpdateRoom = (data) => {
        const roomToUpdate: RoomForUpdate = {
            room: {
                pkrooms: roomForUpdate.room.pkrooms,
                fksites: data.fksites,
                roomname: data.roomname,
                roomdescription: data.roomdescription,
                roomavailable: roomForUpdate.room.roomavailable,
                fkattachments: roomForUpdate.room.fkattachments,
                shifts: newShifts.map((shift) => ({ ...shift, fkrooms: roomForUpdate.room.pkrooms })),
                isdeleted: roomForUpdate.room.isdeleted,
                doi: roomForUpdate.room.doi,
                dou: roomForUpdate.room.dou,
                iconname: !data.icon ? undefined : data.icon.value[0].toString() + " " + data.icon.value[1].toString(),
                // mapname: mapName,
                roomseats: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ?  0 : data.roomseats,
                isshared: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ? IsShared : false
            },
            allcustomerusers: users,
            roomusers: undefined,
            teamgroups: groups.map(g => (g.pkteamgroups)),
            seats: seats.map((s) => ({ ...s, fkrooms: roomForUpdate.room.pkrooms }))
        }

        // Cancellazione delle prenotazioni dopo la modifica dei posti disponibili nella stanza (cancella dal giorno successivo)
        const m = moment()
        if(seats.length < roomForUpdate.seats.length || (data.roomseats > 0 && data.roomseats < roomForUpdate.seats.length)) {
            dispatch (deleteReservationsFromDate(roomForUpdate.room.pkrooms, m))
        }
        // const formData1 = new FormData()
        // if(isMapNew)
        //     formData1.append("file", data.roommap[0])
        if(roomToUpdate.room.fkattachments === 0){
            let hadAttachment = false;
            if(oldPkAttachment!==0){
                hadAttachment = true;
            }

            if(data.attachment && data.attachment.length>0){
                const formData2 = new FormData()
                formData2.append("file", data.attachment[0]);
                dispatch(updateRoomAndAttachment(roomToUpdate, formData2, hadAttachment)); //, formData1
            }else{
                dispatch(updateRoom(roomToUpdate, hadAttachment)); //, formData1
            }
        }else{
            dispatch(updateRoom(roomToUpdate, false)); //, formData1
        }
    }

    const onSave = handleSubmit(data => {
        const reserve = () =>{
            let msg = ""
            if(data.users.length == 0 && groups.length==0){
                msg = "WarningNoUsers"
            }else if(newShifts.length == 0){
                msg = "WarningNoShifts"
            }else if(!IsShared && user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ? seats.length == 0 : data.roomseats == 0){
                msg = "WarningNoSeats"
            }

            if(msg != ""){
                console.log(msg)
                dispatch(addAlertMessage({ type: "warning", htmlMessage: t(msg) }));
            }
            else if(type==="New" || type ==="Duplicate"){
                const newRoom: NewRoom={
                    pksite: data.fksites,
                    roomname: data.roomname,
                    roomdescription: data.roomdescription,
                    pkusers: data.users.map((user)=>user.pkusers),
                    shifts: newShifts,
                    iconname: !data.icon ? undefined : data.icon.value[0].toString()+" "+data.icon.value[1].toString(),
                    // mapname: mapName,
                    roomseats: seats.map((s) => ({...s, fkrooms: 0})),
                    nseats: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ? 0 : data.roomseats,
                    isshared: user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ? IsShared : false
                }

                // const formData1 = new FormData()
                // if(isMapNew)
                //     formData1.append("file", data.roommap[0])

                if(data.attachment && data.attachment.length>0){
                    const formData = new FormData();
                    formData.append("file", data.attachment[0]);
                    dispatch(addRoomAndAttachment(newRoom, formData)); //, formData1
                }else{
                    dispatch(addRoom(newRoom)); // , formData1
                }
            }else if(type==="Update"){
                if(seats.length < roomForUpdate.seats.length || seats.length > roomForUpdate.seats.length)
                editRoomseatsnumberAlert(data)
            else
            confirmUpdateRoom(data)
            }
            if(msg == ""){
                dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 9 }))
                setTimeout(() => {
                    dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 9 }))
                }, 800);
                onClose();
            }
        }

        if(data.fksites && data.roomname && ((data.roomseats || data.roomseats === 0 || IsShared) || (user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0))){
            setError(false);
            /*let msg: JSX.Element;

            if(newShifts.length <= 0 && users.length > 0){
                msg = (<div>{t("Room no shifts")}</div>);
            }else if(newShifts.length > 0 && users.length <= 0){
                msg = (<div>{t("Room no users")}</div>);
            }else if(newShifts.length <= 0 && users.length <= 0){
                msg = (<div>{t("Room no shifts and users")}</div>);
            }
            if(msg){
                let message : DialogMessageType = {
                    dialogMsg :msg,
                    dialogTitle : t("Save Room"),
                    dialogAcceptButton: t("Confirm"),
                    dialogDismissButton: t("Cancel"),
                    isOpen : true,
                    onDismiss: () => {},
                    onAccept: () => {
                        reserve();
                    }
                }
                dispatch(getDialogMessage(message));
            } else {
                reserve()
            }*/
            reserve()
        }else{
            dispatch(addAlertMessage({ type: "warning", htmlMessage: t("CompleteGeneralData") }));
            setError(true);
        }
    }, e=>{
        setError(true);
    });

    function onCloseAlert(){
        let message : DialogMessageType = {
            dialogMsg :(<div>{t("Close From Alert")}</div>),
            dialogTitle : t("Attention"),
            dialogAcceptButton: t("Yes"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                dispatch(updateUIState({ type: "SET_RUN", run: false, stepIndex: 2 }))
                setTimeout(() => {
                    dispatch(updateUIState({ type: "SET_RUN", run: true, stepIndex: 2 }))
                }, 800);
                onClose();
            }
        }
        dispatch(getDialogMessage(message));
    }

    function onClose(){
        dispatch(resetRoomForUpdateSuccess());
        dispatch(resetOldPkAttachment());
        setNewShifts([]);
        reset({});
        setUsers([]);
        setSeats([])
        onCloseModal();
    }

    return(
            <Modal
            disableEnforceFocus
            open={showModal}
            onClose={()=>onClose()}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "55%",
                    height:"65%",
                    bgcolor: "white",
                    borderRadius:0.5,
                    boxShadow: 10,
                    p:4
                }}>
                    {type === "New" && <h4 className={cn(css["title"])}> {t("New Room")} </h4>}
                    {type === "Duplicate" && <h4 className={cn(css["title"])}> {t("Duplicate Room")} </h4>}
                    {type === "Update" && <h4 className={cn(css["title"])}> {t("Edit Room")} </h4>}
                    <TabContext value={index}>
                        <div className="d-flex flex-column h-100">
                            <div className={cn("w-100 mt-2",css["menu-header"])}>
                                <Tabs
                                indicatorColor="secondary"
                                value={index}
                                onChange={(e,newValue)=>setIndex(newValue)}
                                centered>
                                    <Tab value="0" label={(error)?<div className="d-flex flex-row gap-2"><FontAwesomeIcon icon={faExclamation} color="red"/>{t("General Data")}</div>:t("General Data")} style={(index==="0")?{width:width, color:"black"}:{width:width, color:"gray"}}/>
                                    <Tab value="1" label={t("Users")} style={(index==="1")?{width:width, color:"black"}:{width:width, color:"gray"}} />
                                    <Tab value="2" label={t("Shifts")} style={(index==="2")?{width:width, color:"black"}:{width:width, color:"gray"}} />
                                    {
                                        !IsShared && user.ispremium &&
                                        user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 &&
                                        <Tab value="3" label={t("Seats")} style={(index==="3")?{width:"25%", color:"black"}:{width:"25%", color:"gray"}} />
                                    }
                                </Tabs>
                            </div>

                            <div className={cn("w-100 overflow-hidden",css["menu-body"])}>
                                <div className="w-100 h-100">
                                    <TabPanel value="0" sx={{paddingLeft:0, paddingRight:0, height:"100%"}}>
                                        <RoomDataForm control={control} defaultValue={defaultIcon} setDefaultValue={setDefaultIcon} fileName={fileName} setFileName={setFileName} IsShared={IsShared} setIsShared={setIsShared}/>
                                    </TabPanel>

                                    <TabPanel value="1" sx={{paddingLeft:0, paddingRight:0, height:"100%"}}>
                                        <RoomUserForm control={control} users={users} setUsers={setUsers} groups={groups} setGroups={setGroups} typeRoom={type}/>
                                    </TabPanel>

                                    <TabPanel value="2" sx={{paddingLeft:0, paddingRight:0, height:"100%"}}>
                                        <RoomShiftForm shifts={newShifts} setShifts={setNewShifts} typeRoom={type}/>
                                    </TabPanel>

                                    {
                                        !IsShared && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 &&
                                        <TabPanel value="3" sx={{paddingLeft:0, paddingRight:0, height:"100%"}}>
                                            <RoomSeatForm isRoomNew={type === "New"} seats={seats} setSeats={setSeats} />
                                            {/* mapName={mapName} setMapName={setMapName} setIsNew={setIsMapNew} */}
                                        </TabPanel>
                                    }
                                </div>
                            </div>

                            <div className="mb-0 d-flex justify-content-end gap-3">
                                <Button color="secondary" variant="contained" onClick={onCloseAlert} className={cn(css["button-secondary"], css["button-overlay"])} disableElevation>
                                    {t("Cancel")}
                                </Button>
                                { !IsShared && user.ispremium && user.functionalities.findIndex(f => f.nome === "Admin_Room_Seat") >= 0 ?
                                        index == "3" ?
                                        <Button  variant="contained" onClick={()=>onSave()} className={cn(css["button-primary"], css["button-overlay"])} disableElevation>
                                            {t("Save")}
                                        </Button>
                                        :
                                        <Button  variant="contained" onClick={()=>setIndex(String(parseInt(index)+1))} className={cn(css["button-primary"], css["button-overlay"])} disableElevation>
                                            {t("Next")}
                                        </Button>
                                    :
                                        index == "2" ?
                                        <Button  variant="contained" onClick={()=>onSave()} className={cn(css["button-primary"], css["button-overlay"])} disableElevation>
                                            {t("Save")}
                                        </Button>
                                        :
                                        <Button  variant="contained" onClick={()=>setIndex(String(parseInt(index)+1))} className={cn(css["button-primary"], css["button-overlay"])} disableElevation>
                                            {t("Next")}
                                        </Button>
                                }
                            </div>
                        </div>
                    </TabContext>

                </Box>
            </Modal>
    )
}

export default StationModal;