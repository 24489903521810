import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import environment from "../environment";
import { isDev } from "../utils";

export interface User {
    upn: string | undefined,
    uid: string | undefined
}

const useMsTeams = () => {

    const [isMsTeams, setIsMsTeams] = useState<boolean>(isDev)
    const [userMS, setUser] = useState<User>()


    useEffect(() => {
        // Initialize the Microsoft Teams SDK
        //if(!window.location.href.includes("teams")) window.open("https://teams.microsoft.com/", "_self").focus()
        if (!isDev) {
            microsoftTeams.initialize(() => {
                setIsMsTeams(true)
                microsoftTeams.getContext((context: microsoftTeams.Context) => {
                    setUser({ upn: context.userPrincipalName, uid: context.userObjectId })
                    sessionStorage.setItem(environment.UPN, context.userPrincipalName || "")
                    sessionStorage.setItem(environment.UID, context.userObjectId || "")


                })
            });
        }

    }, [])

    return {
        isMsTeams,
        userMS
    }

}

export default useMsTeams