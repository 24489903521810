import { t } from "i18next";
import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from '../../../models/requestResponse';
import { ReservationsManagmentFilter } from "../../../models/reservationsManagment";
import { User, UserForUpdate, UsersCompered } from '../../../models/user';
import { WhereaboutsFilter } from "../../../models/whereabouts";
import { DialogMessageType } from "../../components/dialog/dialog-message";
import { getDialogMessage } from "../../components/dialog/store/dialogMessage.store";
import { addAlertMessage } from "../../components/message-bar/store/alertMessage.store";
import { getHomePage } from "../felogic/felogic.action";
import { getTeamGroups } from "../room/administration-group/administration-group.actions";
import { getMe, getUserRoomsSelectedUser } from "../room/user-room/user-room.actions";
import { AppThunk } from "../store";
import { addUserReservationsByDateSuccess, getClientLicenseSuccess, getLanguagesSuccess, getLastSyncDateSuccess, getTenantUsersSuccess, getUserLanguageSuccess, getUserReservationsSuccess, getUsersFromAzureADSuccess, getUsersReservationsSuccess, getUsersUsrGroupsSuccess, getUsrGroupsSuccess } from "./users.store";

export const getTenantUsers = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/User/v1/getUsersFilteredByName`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getTenantUsersSuccess(res.users))
    })
  } catch (err) { }
};

export const enableUser = (email: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PATCH",
    url: `/User/v1/enableuser/${email}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllUsers())
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("User enabled") }));
    })
  } catch (err) { }
}

export const getClientLicense = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/License/v1/getclientlicenses`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getClientLicenseSuccess(res.num))
    })
  } catch (err) { }
};


export const getUserSettings = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Me/v1/GetUserSettings`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserLanguageSuccess(res.language));
    });
  } catch (err) {}
};

export const setUserLanguage = (languageId: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PATCH",
    url: `/Me/v1/SetUserLanguage/${languageId}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserSettings());
      dispatch(addAlertMessage({ type: "success", htmlMessage: "Language Saved Successfully" }));
    });
  } catch (err) {}
};

export const getUserReservations = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Reservation/v1/getmyreservations`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserReservationsSuccess(res.myReservations));
    });
  } catch {}
}

export const getUserReservationsByDate = (date: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Reservation/v1/GetMyReservationsByDate/${date}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addUserReservationsByDateSuccess(res.myReservations));
    });
  } catch {}
}

export const getLastUserReservation = (pkuser: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Reservation/v1/getlastuserreservations/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserReservationsSuccess(res.myReservations));
    });
  } catch {}
}

export const getUsersReservationsByDateRange = (filter: WhereaboutsFilter): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/Reservation/v1/getusersreservationsbydaterange`,
    dispatch,
    auth: true,
    baseurl: false,
    httpOptions:{
      data: filter
    }
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUsersReservationsSuccess(res.usersreservations));
    });
  } catch {}
}
export const getUsersReservationsByDateRangeAndRoomSelected = (filter: ReservationsManagmentFilter): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/Reservation/v1/getusersreservationsbydaterangeandroomselected`,
    dispatch,
    auth: true,
    baseurl: false,
    httpOptions:{
      data: filter
    }
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUsersReservationsSuccess(res.usersreservations));
    });
  } catch {}
}

export const deleteUserReservation = (pkreservation: number[]): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Reservation/v1/deleteReservation`,
    dispatch,
    httpOptions: {
      data: pkreservation,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Reservation removed correctly") }));
      dispatch(getUserReservations())
    });
  } catch {}
}

export const getAllUsers = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/User/v1/getAllUsers`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getTenantUsersSuccess(res.users));
    });
  } catch {}
}

export const deleteUser = (email: string, pkusers: number, pkcustomer: number, username:string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/User/v1/deleteuser/${email}/${pkusers}/${pkcustomer}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        dispatch(getAllUsers())
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("User Removed") }));
      } else if(res.msg ==="Last admin"){
        let message : DialogMessageType = {
          dialogTitle: t("Warning"),
          dialogMsg: t("Last admin delete").replace("X",username),
          dialogSubmsg: t("Last admin delete msg").replace("X",username),
          dialogAcceptButton: "OK",
          onAccept: ()=>{},
          onDismiss: ()=>{},
          isOpen: true
        }
        dispatch(getDialogMessage(message));
      }
    });
  } catch {}
}

export const addUser = (user: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "fklanguages" | "userrooms">): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/User/v1/AddUserAsync/`,
    dispatch,
    httpOptions: {
      data: user,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("User added") }))
        dispatch(getAllUsers())
      }else{
        let message : DialogMessageType = {
          dialogMsg :(t(res.msg)),
          dialogTitle : t("Warning"),
          dialogAcceptButton: t("Ok"),
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {}
        }
        dispatch(getDialogMessage(message));
      }
    });
  } catch {}
}

export const addUsers = (users: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "isdeleted" | "fklanguages">[]): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/User/v1/AddUsersAsync/`,
    dispatch,
    httpOptions: {
      data: users,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        dispatch(getAllUsers())
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Users added") }))
      }else{
        if(res.msg == "UsersMaximumLicensesExceeded"){
          let message : DialogMessageType = {
            dialogMsg :(t(res.msg)),
            dialogTitle : t("Warning"),
            dialogAcceptButton: t("Ok"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {}
          }
          dispatch(getDialogMessage(message));
        }else{
          let ListOfUsers = res.msg.split("-")
          //Creo il messaggio di warning con la lista di: ListOfUsers[0] utenti non presenti nel tenant, ListOfUsers[1] email già presenti, ListOfUsers[2] utenti inseriti
          //ListOfUsers[3/4/5] sono rispettivamente il numero totale di utenti per ognuna delle 3 liste
          let warningMsg =  (ListOfUsers[0].length > 0 ? t("NotPossibleAdd") + ListOfUsers[3] + t("UsersNotOnTenant") + ListOfUsers[0]: "")
                          + (ListOfUsers[1].length > 0 ? t("NotPossibleAdd") + ListOfUsers[4] +  t("UsersEmailAlreadyExists")  + ListOfUsers[1]: "")
                          + (ListOfUsers[2].length > 0 ? ListOfUsers[5] + t("ListUsersAdded") + ListOfUsers[2] : "");
          let message : DialogMessageType = {
            dialogTitle : t("Warning"),
            dialogMsg: warningMsg,
            dialogAcceptButton: t("Ok"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {}
          }

          if(ListOfUsers[1].length > 0 || ListOfUsers[2].length > 0){
            dispatch(getAllUsers())
          }
          dispatch(getDialogMessage(message));
        }
      }
    });
  } catch {}
}

export const editUser = (user: UserForUpdate, me: User): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PATCH",
    url: `/User/v1/updateuserdata/`,
    dispatch,
    httpOptions: {
      data: user,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        dispatch(getTeamGroups(me.fkcustomers))
        dispatch(getAllUsers())
        dispatch(getUsrGroups())
        dispatch(getUsersUsrGroups())
        dispatch(getUserRoomsSelectedUser(user.user.pkusers))
        if(me.pkusers === user.user.pkusers)
          dispatch(getMe())
        dispatch(addAlertMessage({ type: "success", htmlMessage: `${t("User edited correctly")}` }));
      }
      else if(res.msg ==="Last admin"){
        let message : DialogMessageType = {
          dialogTitle: t("Warning"),
          dialogMsg: t("Last admin edit").replace("X",user.user.username),
          dialogSubmsg: t("Last admin edit msg").replace("X", user.user.username),
          dialogAcceptButton: "OK",
          onAccept: ()=>{},
          onDismiss: ()=>{},
          isOpen: true
        }
        dispatch(getDialogMessage(message));
      } else if(res.outcome === "Warning"){
        dispatch(addAlertMessage({ type: "warning", htmlMessage: res.msg }));
      }
    });
  } catch {}
}

export const getLanguages = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Language/v1/getlanguageslist/`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getLanguagesSuccess(res.languages))
    });
  } catch {}
}

export const setLoginDate = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/Login/v1/managelogindate/`,
    dispatch,
    httpOptions: {
      data: 'TEST',
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        console.info("Login successful")
      } else {
        console.error("Login error: " + res.msg)
      }
    });
  } catch {}
}

export const getUsersFromAD = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/User/v1/getUsersCompared/`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUsersFromAzureADSuccess(res.usersCompered))
    });
  } catch {}
}

export const getLastSyncDate = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/AzureADUsersSyncHistory/v1/getCustomerLastSync/`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getLastSyncDateSuccess(res.lastSync.syncdata))
    });
  } catch {}
}

export const manageAzureUsersSync = (users: UsersCompered) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/User/v1/manageAzureUsersSync/`,
    dispatch,
    httpOptions: {
      data: users
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success") {
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Sync Completed") }));
        dispatch(getLastSyncDate())
        dispatch(getTenantUsers())
        dispatch(getAllUsers())
      }else{
        if(res.msg == "UsersMaximumLicensesExceeded"){
          let message : DialogMessageType = {
            dialogMsg :(t(res.msg)),
            dialogTitle : t("Warning"),
            dialogAcceptButton: t("Ok"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {}
          }
          dispatch(getAllUsers())
          dispatch(getDialogMessage(message));
        }else{
          let ListOfUsers = res.msg.split("-")
          //Creo il messaggio di warning con la lista di: ListOfUsers[0] email già presenti, ListOfUsers[1] utenti inseriti
          //ListOfUsers[2/3] sono rispettivamente il numero totale di utenti per ognuna delle 2 liste
          let warningMsg =  (ListOfUsers[0].length > 0 ? t("NotPossibleAdd") + ListOfUsers[2] +  t("UsersEmailAlreadyExists")  + ListOfUsers[0]: "")
                          + (ListOfUsers[1].length > 0 ? ListOfUsers[3] + t("ListUsersAdded") + ListOfUsers[1] : "");
          let message : DialogMessageType = {
            dialogTitle : t("Warning"),
            dialogMsg: warningMsg,
            dialogAcceptButton: t("Ok"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {}
          }

          dispatch(getAllUsers())
          dispatch(getDialogMessage(message));
        }
      }
    });
  } catch {}
}

export const getDefaultCustomerLanguage = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Customer/v1/GetCustomerDefaultLanguage`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserLanguageSuccess(res.language));
    });
  } catch {}
}

export const getUsrGroups = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/UsrGroups/v1/getUsrGroups`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUsrGroupsSuccess(res.usrgroups));
    });
  } catch {}
}

export const getUsersUsrGroups = () => async (dispatch) => {
    const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/UsersUsrGroups/v1/getUsersUsrGroups`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUsersUsrGroupsSuccess(res.usersusrgroups));
    });
  } catch {}
}

export const getUsersUsrGroupsAdmin = (userId:string) => async (dispatch) => {
  const httpHandler: HttpHandler = {
  method: "HTTP_GET",
  url: `/UsersUsrGroups/v1/getUsersUsrGroups/${userId}`,
  dispatch,
  auth: true,
  baseurl: false,
};
try {
  interceptor<RequestResponse>(httpHandler).then((res) => {
    dispatch(getUsersUsrGroupsSuccess(res.usersusrgroups));
  });
} catch {}
}

export const hideStartupPopup = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PUT",
    url: `/User/v1/HideStartupGuide`,
    dispatch,
    auth: true,
    baseurl: false
  }
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getMe())
      dispatch(getHomePage())
    });
  } catch {}
}

export const hideAppTour = () => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_PUT",
    url: `/User/v1/HideAppTour`,
    dispatch,
    auth: true,
    baseurl: false
  }
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getMe())
    });
  } catch {}
}