import { t } from 'i18next';
import moment from 'moment';
import { Moment } from 'moment';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'

export function confirmNotification(options: SweetAlertOptions): Promise<SweetAlertResult> {
  return Swal.fire({
    ...options,
    showCancelButton: !!options.cancelButtonText,
    showCloseButton: true
  })
}

export const abortController = (() => {
  let controller = new AbortController();
  let signal = controller.signal;

  const abortPendingRequest = () => {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;
  };

  const getSignalToken = () => signal;

  return {
    abortPendingRequest,
    getSignalToken,
  };
})();

//Trasform a moment date object to a string like Saturnday 11 October 2022
export const momentToDateString = (myMoment: Moment) => {
  const completeDate:String[] = [];
  myMoment.locale(moment.locale());
  //Get day name
  let dayName = myMoment.format("dddd");
  completeDate.push(t(dayName[0].toUpperCase() + dayName.substring(1).toLowerCase()));

  completeDate.push(myMoment.format("DD"), myMoment.format("MMMM"), myMoment.format("YYYY"));
  return (completeDate);
}

export const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');