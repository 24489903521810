import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFELogic {
    homePage: string;
}

export const felogicSlice = createSlice({
    name: "felogic",
    initialState: {
        homePage: ""
    } as IFELogic,

    reducers: {
        getHomePageSuccess:(state, action: PayloadAction<string>) =>{
            state.homePage = action.payload;
        }
    }
});

export const { getHomePageSuccess } = felogicSlice.actions; 
