import { Button } from "@mui/material";
import cn from "classnames";
import { useTranslation } from "react-i18next/";
import svgImage from "../../../asset/img/license-waiting.svg";
import css from "./AlertLicenseExpiredRenewal.module.scss";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNextLicenseStartdate } from "../../../core/store/customer/customer.action";
import { RootState } from "../../../core/store/store";
import moment from "moment";

const AlertLicenseExpiredRenewal: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {nextLicenceStartdate} = useSelector((state:RootState)=>state.customer)

    const handleContactUs = () => {
        window.location.href = 'mailto:supporto@mminformatica.it'
    }

    useEffect(()=>{
        dispatch(getNextLicenseStartdate());
    },[])

  return (
    <div className='container-fluid d-grid justify-content-center mt-4'>
      <div className="row">
        <div className='col text-center'>
        <img
          className={cn(css["icon-image"])}
          src={svgImage}
          alt={t("Man placing a red circled X on a screen")}
        /></div>  
      </div>  
      <div className='row mt-4'>
        <div className="col-12">
            <h2 className={cn(css["title"],'text-center primary')}>
                {t("License Expired Renewal")}
            </h2>
        </div>
        <div className="col-12">
            {nextLicenceStartdate && 
                <h2 className={cn(css["subtitle"],"text-center")}>{t("License Expired Renewal subtitle")+" "+moment(nextLicenceStartdate).format("DD/MM/YYYY")}</h2>  
            }
        </div>
        <div className="col-12 mt-2">
            <div className="d-flex flex-row justify-content-center">
                <Button 
                    variant="text"
                    onClick={handleContactUs}
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    className={cn(css["text-btn"])}
                >
                    {t("Contact Us")}
                </Button>
            </div>
        </div>
        
      </div>
    </div>);
}

export default AlertLicenseExpiredRenewal;
