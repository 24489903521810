import { t } from "i18next";
import { Moment } from "moment";
import { interceptor } from "../../../../interceptors/.interceptor";
import { HttpHandler } from "../../../../models/http-interceptor";
import { RequestResponse } from '../../../../models/requestResponse';
import { NewRoom, RoomForUpdate } from "../../../../models/room";
import { Site } from "../../../../models/site";
import { abortController } from "../../../../utils";
import { addAlertMessage } from "../../../components/message-bar/store/alertMessage.store";
import { AppThunk } from "../../store";
import { deleteRoomSuccess, deleteSiteSuccess, editSiteNameSuccess, getAdminRoomsImageSuccess, getAdminSitesChangedSuccess, getAdminSitesSuccess, getCustomerAllRoomsSuccess, getImageHasLoadedSuccess, getRoomForUpdateSuccess, insertRoomSuccess, insertSiteSuccess, removeAttachmentSuccess, resetOldPkAttachmentSuccess, setOldPkAttachmentSuccess, updateRoomSuccess } from "./administration-room.store";

// #region Room
export const getRoomForUpdate = (pkroom: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/getroomforupdate/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getRoomForUpdateSuccess(res.roomForUpdate));
    });
  } catch (err) {}
};

export const getRoomForUpdateWithoutAttachment = (pkroom: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/getroomforupdate/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      res.roomForUpdate.room.fkattachments = 0;
      res.roomForUpdate.room.roomattachment = undefined;
      dispatch(getRoomForUpdateSuccess(res.roomForUpdate));
      dispatch(setOldPkAttachmentSuccess(res.roomForUpdate.room.fkattachments));
    });
  } catch (err) {}
};

export const removeAttachmentRoomForUpdate = (): AppThunk => async (dispatch) => {
  dispatch(removeAttachmentSuccess());
}

export const resetOldPkAttachment = (): AppThunk => async (dispatch) => {
  dispatch(resetOldPkAttachmentSuccess());
}

export const updateRoom = (room: RoomForUpdate, hadAttachment: boolean, mapImage?: FormData): AppThunk =>
  async (dispatch) => {
    const deleteRoomAttachmentHttpHandler: HttpHandler = {
      method: "HTTP_DELETE",
      url: `/Room/v1/deleteroomattachment/${room.room.pkrooms}`,
      dispatch,
      auth: true,
      baseurl: false,
    };

    const updateRoomHttpHandler: HttpHandler = {
      method: "HTTP_PATCH",
      url: `/Room/v1/updateroom`,
      httpOptions:{
        data: room
      },
      dispatch,
      auth: true,
      baseurl: false,
    };

    try {
      if(hadAttachment){
        interceptor<RequestResponse>(deleteRoomAttachmentHttpHandler).then((res) => {
          interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
            dispatch(getRoom(room.room.pkrooms, "Update"))
          })
        })
      }else{
        interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
          dispatch(getRoom(room.room.pkrooms, "Update"))
        })
      }
    } catch{}
};

export const updateRoomAndAttachment = (room: RoomForUpdate, roomImage:FormData, hadAttachment:boolean, mapImage?: FormData): AppThunk =>
  async (dispatch) => {
    const deleteRoomAttachmentHttpHandler: HttpHandler = {
      method: "HTTP_DELETE",
      url: `/Room/v1/deleteroomattachment/${room.room.pkrooms}`,
      dispatch,
      auth: true,
      baseurl: false,
    };

    const updateRoomHttpHandler: HttpHandler = {
      method: "HTTP_PATCH",
      url: `/Room/v1/updateroom`,
      httpOptions:{
        data: room
      },
      dispatch,
      auth: true,
      baseurl: false,
    };

    const saveAttachmentHttpHandler: HttpHandler = {
      method: "HTTP_POST",
      url: `/Attachment/v1/saveattachment/room/${room.room.pkrooms}/0`,
      dispatch,
      httpOptions:{
        data: roomImage
      },
      auth: true,
      baseurl: false,
    };

    try {
      if(hadAttachment){
        interceptor<RequestResponse>(deleteRoomAttachmentHttpHandler).then((res) => {
          interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
            interceptor<RequestResponse>(saveAttachmentHttpHandler).then((res) => {
              dispatch(getRoom(room.room.pkrooms, "Update"))
            });
          });
        });

      }else{
        interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
          interceptor<RequestResponse>(saveAttachmentHttpHandler).then((res) => {
            dispatch(getRoom(room.room.pkrooms, "Update"))
          });
        });
      }
    } catch{}
};

export const updateRoomAndDeleteAttachment = (room: RoomForUpdate, formData:FormData, hadAttachment:boolean): AppThunk =>
  async (dispatch) => {
    const deleteRoomAttachmentHttpHandler: HttpHandler = {
      method: "HTTP_DELETE",
      url: `/Room/v1/deleteroomattachment/${room.room.pkrooms}`,
      dispatch,
      auth: true,
      baseurl: false,
    };

    const updateRoomHttpHandler: HttpHandler = {
      method: "HTTP_PATCH",
      url: `/Room/v1/updateroom`,
      httpOptions:{
        data: room
      },
      dispatch,
      auth: true,
      baseurl: false,
    };

    const saveAttachmentHttpHandler: HttpHandler = {
      method: "HTTP_POST",
      url: `/Attachment/v1/saveattachment/room/${room.room.pkrooms}/0`,
      dispatch,
      httpOptions:{
        data: formData
      },
      auth: true,
      baseurl: false,
    };

    try {
      if(hadAttachment){
        interceptor<RequestResponse>(deleteRoomAttachmentHttpHandler).then((res) => {
          interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
            interceptor<RequestResponse>(saveAttachmentHttpHandler).then((res) => {
              dispatch(getRoom(room.room.pkrooms, "Update"));
            });
          });
        });

      }else{
        interceptor<RequestResponse>(updateRoomHttpHandler).then((res) => {
          interceptor<RequestResponse>(saveAttachmentHttpHandler).then((res) => {
            dispatch(getRoom(room.room.pkrooms, "Update"));
          });
        });
      }
    } catch{}
};

export const addRoomAndAttachment = (newRoom:NewRoom, formdata:FormData, mapdata?: FormData): AppThunk => async (dispatch) => {
  const addRoom: HttpHandler = {
    method: "HTTP_POST",
    url: `/Room/v1/addnewroom`,
    dispatch,
    httpOptions: {
      data: newRoom,
    },
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(addRoom).then((res1)=>{
      const saveRoomAttachment: HttpHandler = {
        method: "HTTP_POST",
        url: `/Attachment/v1/saveattachment/room/${res1.pk}/0`,
        dispatch,
        httpOptions:{
          data: formdata
        },
        auth: true,
        baseurl: false,
      };

      interceptor<RequestResponse>(saveRoomAttachment).then((res2) => {
        dispatch(getRoom(res1.pk, "Insert"));
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Attachment Added Successfully") }))
      });
    });
  } catch (err) {}
};

export const addRoom = (newRoom: NewRoom, mapData?: FormData): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/Room/v1/addnewroom`,
    dispatch,
    httpOptions: {
      data: newRoom,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then(res=>{
      dispatch(getRoom(res.pk, "Insert"));
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Attachment Added Successfully") }))
    });
  } catch (err) {}
};

export const GetCustomersAllRooms = (pkcustomer:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/getcustomerallrooms/${pkcustomer}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCustomerAllRoomsSuccess(res.roomSites));
    });
  } catch {}
}

export const deleteRoom = (pkroom: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Room/v1/deleteroom`,
    dispatch,
    httpOptions:{
      data: [pkroom]
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(deleteRoomSuccess(pkroom));
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room deleted") }))
    });
  } catch {}
}

export const getRoom = (pkroom:number, type:"Insert"|"Update"): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/getroomfrompk/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(type === "Insert"){
        dispatch(insertRoomSuccess(res.room));
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room Saved Successfully") }));
      }else{
        dispatch(updateRoomSuccess(res.room));
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room updated successfully") }));
      }
    });
  } catch {}
}
// #endregion Room

// #region Site

export const addNewSite = (site: Pick<Site, 'sitename' | 'parent'>): AppThunk =>
  async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_POST",
      url: `/Site/v1/addnewsite`,
      dispatch,
      auth: true,
      baseurl: false,
      httpOptions: {
        data: site
      }
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        if (res.outcome === "Success") {
          dispatch(getSiteForInsert(res.pk));
        }else if(res.outcome === "Warning"){
          dispatch(addAlertMessage({type:"warning", htmlMessage:t("Site Same Name")}));
        }
      });
    } catch{}
};

export const getSiteForInsert = (pksite:number): AppThunk =>
  async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/Site/v1/getsitefrompk/${pksite}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(insertSiteSuccess(res.site));
        dispatch(getAdminSites());
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Site Added Successfully") }));
      });
    } catch{}
};

export const editSiteName = (pksite:number, name:string): AppThunk =>
  async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_PATCH",
      url: `/Site/v1/UpdateSiteName/${pksite}/${name}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getSiteForRename(pksite));
      });
    } catch{}
};

export const getSiteForRename = (pksite:number): AppThunk =>
  async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/Site/v1/getsitefrompk/${pksite}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(editSiteNameSuccess(res.site));
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Site Renamed Successfully") }));
      });
    } catch{}
};

export const deleteSite = (pksite: number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Site/v1/deletesite/${pksite}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(deleteSiteSuccess(pksite));
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Site Deleted Successfully") }))
    });
  } catch {}
}

// #endregion Room


export const getAdminSites = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getadminsitesroomsshifts`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAdminSitesSuccess(res.sites));
      dispatch(getImageHasLoadedSuccess(false));
      dispatch(GetAdminRoomsImage());
      dispatch(getAdminSitesChangedSuccess(false));
    });
  } catch {}
}

export const deleteReservationsFromDate = (pk_room: number, startDate: Moment): AppThunk =>
  async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_DELETE",
      url: `/Reservation/v1/deleteReservationFromDate?pk_room=${pk_room}&startDate=${startDate?.format("YYYY-MM-DD")}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(addAlertMessage({ type: "success", htmlMessage: t("Reservations removed correctly") }));
      });
    } catch (err) {}
};

export const getAdminSitesChanged = (oldSites: Site[]) : AppThunk => async (dispatch) => {
  let oldSitesMap = oldSites.map( s => {
    let _rooms;
    (s.rooms === null || s.rooms === undefined)? _rooms = [] :
    _rooms = s.rooms.map(r=>{
      return {
        pkrooms: r.pkrooms,
        dou: r.dou,
        shifts: r.shifts.map(sh => {
          return {
            pkshifts: sh.pkshifts,
            dou: sh.dou
          }
        })
      }
    });
    return{
      pksites: s.pksites, dou: s.dou, rooms: _rooms
    }
  });
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/FELogics/v1/AdminSitesRoomsShiftsChanged`,
    dispatch,
    httpOptions: {
      data: oldSitesMap,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAdminSitesChangedSuccess(res.boolean));
    });
  } catch (err) {}
}

export const GetAdminRoomsImage = () : AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getAdminRoomsImage`,
    dispatch,
    httpOptions:{
      signal: abortController.getSignalToken()
    },
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAdminRoomsImageSuccess(res.sites));
      dispatch(getImageHasLoadedSuccess(true));
      dispatch(getAdminSitesChangedSuccess(false));
    });
  } catch (err) {}
}

export const deleteRoomMap = (pkroom: number): AppThunk => async(dispatch) => {
  const deleteRoomMap: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Room/v1/deleteroommap/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(deleteRoomMap).then((res) => {
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Map deleted succesfully") }));
    });
  } catch (err) {}
}