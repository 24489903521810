import cn from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { getLanguages, setUserLanguage } from "../../core/store/users/users.actions";
import environment from "../../environment";
import css from "./settings.module.scss";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLanguage = useSelector((state :RootState) => state.users.language)
  const { user } = useSelector((state: RootState) => state.auth)
  const { languages } = useSelector((state: RootState) => state.users);

  function changeLanguage(pklanguages: number) {
    dispatch(setUserLanguage(pklanguages))
  }

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  return (
    <div className={cn("mt-4", css["settings-container"])}>
      <h2 className="mb-5">
        {t("Settings")}
        <hr />
      </h2>
      <div className={cn("row align-items-center", css["settings-row"])}>
        <div className="col-md-6 col-sm-8">
          <h5 className={css["setting-title"]}>{t("Selected language")}</h5>
        </div>
        <div className="col-2 col-sm-4 d-flex">
          {languages.length > 0 && languages.map((l) =>
             <div className={cn(css["lang-box"], userLanguage && userLanguage.pklanguages === l.pklanguages ? css.active : "", user.functionalities.findIndex(f => f.nome === "Update_app_language") > -1 ? css.pointer : "")} onClick={() => user.functionalities.findIndex(f => f.nome === "Update_app_language") > -1 ? changeLanguage(l.pklanguages) : null}>
                { l.language }
             </div>
          )}
        </div>
      </div>
      <div className="row">
        <h6 className={cn("primary", css["version"])}>v {environment.VERSION}</h6>
      </div>
    </div>
  );
};

export default Settings;
