import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import environment  from "../environment";

// the translations
// (tip move them in a JSON file and import them)
const resources: Resource = {
  en: {
    translation: require('./locale/en.json')
  },
  it: {
    translation: require('./locale/it.json')
  },
  es: {
    translation: require('./locale/es.json')
  },
  fr: {
    translation: require('./locale/fr.json')
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ["en", "it"],
    resources,
    lng: sessionStorage.getItem(environment.LANG)? (sessionStorage.getItem(environment.LANG) as string) : "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;