import { TabContext, TabPanel } from "@mui/lab"
import { Box, Button, Card, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../core/store/store"
import style from './ExploreRoomsMobile.module.scss'
import cn from "classnames"
import { Room } from "../../../models/room"
import { getRoomSuccess } from "../../../core/store/room/user-room/user-room.store"
import { toggleModalSuccess } from "../../../core/store/modal/room-modal/room-modal.store"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { faStar as starEmpty} from '@fortawesome/pro-regular-svg-icons'
import { faStar as starFull} from '@fortawesome/pro-solid-svg-icons'
import { AddRoomToFavorites, RemoveRoomFromFavorites } from "../../../core/store/room/user-room/user-room.actions"

const ExploreRoomsMobile: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { userSites } = useSelector((state: RootState) => state.room.userRoom)
    const meUser = useSelector((state: RootState) => state.auth.user)
    const [tab, setTab] = useState<string>("0")
    const [allRooms, setAllRooms] = useState<Room[]>([])

    const handleTabChange = (event, value) => {
      setTab(value)
    }

    const onSelectRoom = (room : Room) => {
        dispatch(getRoomSuccess(room))
        toggleRoomModal()
    }

    function toggleRoomModal() {
        dispatch(toggleModalSuccess())
    }

    function removeRoomFromFavorites(pkroom: number) {
        dispatch(RemoveRoomFromFavorites(meUser.pkusers, pkroom))
    }

    function addRoomToFavorites(pkroom: number) {
        dispatch(AddRoomToFavorites(meUser.pkusers, pkroom))
    }

    function roomCompare (a: Room, b: Room) {
        if(a.isfavorite && !b.isfavorite) return -1;
        if(a.isfavorite && b.isfavorite) return 0;
        return 1;
    }

    useEffect(() => {
        setAllRooms([])
        let rooms = []
        userSites.forEach(us => rooms.push(...us.rooms))
        setAllRooms(rooms)
    }, [userSites])

    return (
        <>
            <TabContext value={tab}>
                <div>
                    <div>
                        <Tabs 
                        variant="scrollable"
                        scrollButtons="auto"
                        value={tab} 
                        onChange={handleTabChange}>
                            <Tab key={0} value={"0"} label={t("All sites")} />
                            {userSites.filter(us => us.parent === 0).map((s) => (
                                <Tab key={s.pksites} value={s.pksites.toString()} label={s.sitename} />
                            ))}
                        </Tabs>
                    </div>
                    <TabPanel className="ps-0 pe-0" key={0} value={"0"}>
                        <Box className="d-inline-flex flex-wrap w-100">
                            {allRooms.length > 0 && allRooms.sort((a, b) => roomCompare(a, b)).map((r) => (
                                <Card                       
                                    key={r.pkrooms} 
                                    className={cn(style["roomCard"], "me-2 mb-2",((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                    sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                >                  
                                    <Button 
                                        className={cn(style["fav-btn"], "pe-2 pt-2 pb-4")} 
                                        style={{color: r.isfavorite ? "yellow" : "white"}}
                                        disableRipple 
                                        disableFocusRipple
                                        onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                            <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="lg" className={style["star-icon"]} />
                                    </Button>                              
                                    <div 
                                        onClick={() => onSelectRoom(r)} 
                                        className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                        <div className={cn("col d-flex ps-2 pe-2 pb-3 pt-2", style["bg-room"])}>

                                            {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                            <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                            </button>}
                                            <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </Box>
                    </TabPanel>
                    {userSites.filter(us => us.parent === 0).map((s) => ((s.rooms && s.rooms.length > 0) || userSites.findIndex(us => us.parent === s.pksites && us.rooms && us.rooms.length > 0) >= 0) ? (
                        <TabPanel className="ps-0 pe-0" key={s.pksites} value={s.pksites.toString()}>
                            <Box className="d-flex flex-wrap">
                                {s.rooms.slice().sort((a, b) => roomCompare(a, b)).map((r) => (
                                    <Card 
                                        key={r.pkrooms} 
                                        className={cn(style["roomCard"], "me-2 mb-2", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                        sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                    >
                                        <Button 
                                            className={cn(style["fav-btn"], "pe-2 pt-2 pb-4")} 
                                            style={{color: r.isfavorite ? "yellow" : "white"}}
                                            disableRipple 
                                            disableFocusRipple
                                            onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                                <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="lg" className={style["star-icon"]} />
                                        </Button> 
                                        <div 
                                            onClick={() => onSelectRoom(r)}
                                            className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                            <div className={cn("col d-flex ps-2 pe-2 pb-3 pt-2", style["bg-room"])}>

                                                {r.iconname && 
                                                    <button className={cn(style["font-button-no-action"], "me-2")} >
                                                        <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                                    </button>
                                                }
                                                <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                            </div>
                                        </div>
                                    </Card>
                                ))}
                                {
                                    userSites.filter(us => us.parent === s.pksites).map((us) => (
                                        <div className="row w-100">
                                            <div className="col-12 pe-0">
                                                <h5 className="primary">{us.sitename}</h5>
                                                <hr className={style["divider"]} />
                                                {
                                                    us.rooms.map(r => (
                                                        <Card                                                          
                                                            key={r.pkrooms} 
                                                            className={cn(style["roomCard"], "me-2 mb-2", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                                            sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                                        >
                                                            <Button 
                                                                className={cn(style["fav-btn"], "pe-2 pt-2 pb-4")} 
                                                                style={{color: r.isfavorite ? "yellow" : "white"}}
                                                                disableRipple 
                                                                disableFocusRipple
                                                                onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                                                    <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="lg" className={style["star-icon"]} />
                                                            </Button> 
                                                            <div 
                                                                onClick={() => onSelectRoom(r)} 
                                                                className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                                                <div className={cn("col d-flex ps-2 pe-2 pb-3 pt-2", style["bg-room"])}>

                                                                    {r.iconname && 
                                                                        <button className={cn(style["font-button-no-action"], "me-2")} >
                                                                            <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                                                        </button>
                                                                    }
                                                                    <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                                                </div>
                                                            </div>
                                                        </Card>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </Box>
                        </TabPanel>
                    ) : null)}
                </div>
            </TabContext>
        </>
    )
}
export default ExploreRoomsMobile