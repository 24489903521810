import { t } from "i18next";
import { Dispatch } from "redux";
import { decreaseCounter, increaseCounter } from "../core/components/loader/store/loader.store";
import { addAlertMessage } from "../core/components/message-bar/store/alertMessage.store";
import environment from "../environment";
import { HttpHandler } from "../models/http-interceptor";
import { http } from "./http-handler.interceptor";


export const interceptor = async <T>(handler: HttpHandler): Promise<T> => {
  handler.baseurl = handler.baseurl !== null && handler.baseurl !== undefined ? handler.baseurl : false;
  handler.auth = handler.auth !== null && handler.auth !== undefined ? handler.auth : true;
  if (!handler.httpOptions) {
    handler.httpOptions = {};
  }
  handler.httpOptions.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...handler.httpOptions.headers,
  };
  if (handler.auth) {
    handler.httpOptions.headers = {
      ...handler.httpOptions.headers,
      Authorization: `Bearer ${sessionStorage.getItem(environment.COOKIE_TOKEN_KEY)}`,
    };
  }

  if(handler.ignoreCounter === null || handler.ignoreCounter === undefined)
    handler.ignoreCounter = false;

  (!handler.ignoreCounter) ? handler.dispatch(increaseCounter()) : null;
    try {
      let response: T = await http<T>(handler);
      (!handler.ignoreCounter) ? handler.dispatch(decreaseCounter()) : null;
      return response;
    } catch (err) {
      (!handler.ignoreCounter) ? handler.dispatch(decreaseCounter()) : null;
      handleError(handler.dispatch, err);
      throw err;
    }
};

const handleError = (dispatch: Dispatch, error: any): void => {
  if (error) {
    switch (error.status) {
      case 400:
        const errorData = error.data;
        dispatch(addAlertMessage({ type: "error", htmlMessage: errorData }));
        break;
      case 401:
        dispatch(addAlertMessage({ type: "error", htmlMessage: t("Session expired") }));
        break;
      case 403:
        dispatch(addAlertMessage({ type: "error", htmlMessage: t("Access denied") }));
        break;
      case 474:
        dispatch(addAlertMessage({ type: "error", htmlMessage: t(error.data.detail) }));
        break;
      case 500:
        dispatch(addAlertMessage({ type: "error", htmlMessage: t("Oh no! something went wrong!") }));
        break;
      case 504:
        dispatch(addAlertMessage({ type: "error", htmlMessage: t("Check your internet connection and reload page.") }));
        break;
    }
  }
};
