import { Delete, Edit } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import {
  getAllCustomers,
  getAllUsersXClient,
  getAlllicenses,
  updateCustomer
} from "../../../core/store/superadmin/superadmin.actions";
import { Customer } from "../../../models/customer";
import CustomerForm from "./CustomerForm";
import { SACustomerManagementModal } from "./SACustomerManagementModal";

export const SACustomersManagement: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formType, setFormType] = useState<"Edit" | "Add">("Add");
  const [NameFilter, setNameFilter] = useState<string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const { allCustomers, allLicenses, CustomerUsersCount } = useSelector((state: RootState) => state.superadmin);

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getAlllicenses(true,999999999,""));
  }, []);

  useEffect(() => {
    allCustomers.forEach((c) => {
      dispatch(getAllUsersXClient(c.tenantid, 1, 1));
    });
  }, [allCustomers]);

  const closeForm = () => {
    setIsFormOpen(false);
    setSelectedCustomer(null);
  };

  function getMaxUsersXCustomer(pkcustomer) {
    let max = 0;
    allLicenses.map((l) => {
      if (l.numusers === 999999) return null;
      else if (l.fkcustomers === pkcustomer) max += l.numusers;
    });

    return max;
  }


  const handleEdit = (Client: Customer) => {
    let message: DialogMessageType = {
      dialogMsg: (
        <div className="msg">
          {t("Si sta cercando di modifcare il customer per") +
            allCustomers
              .filter((x) => x.pkcustomers === Client.pkcustomers)
              .map((y) => y.customername)}
        </div>
      ),
      dialogTitle: t("Attention"),
      dialogAcceptButton: t("Yes"),
      dialogDismissButton: t("Cancel"),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(getDialogMessage(message));
        dispatch(
          updateCustomer(
            Client.pkcustomers,
            Client.customername,
            Client.fklanguages,
            Client.isdemo
          )
        );
        dispatch(getAllCustomers());
      },
    };

    setSelectedCustomer(Client);
    dispatch(getAllUsersXClient(Client.tenantid, 1, 1));
    setFormType("Edit");
    setIsFormOpen(true);
  };

  const handleDelete = (c: Customer) => {
    setSelectedCustomer(c);
    setIsModalOpen(true);
  };

  function getUsersXCustomer(tenantID) {
    var currentCount = CustomerUsersCount.find((c) => c.tenant === tenantID);
    if (currentCount) {
      return currentCount.count;
    } else {
      return 0;
    }
  }
  return (
    <>
      <div className="container-fluid mt-4 ps-4 pe-4 mb-3">
        <div className="row">
          <div className="col-md-4 pe-0">
            <TextField
              id="search-user-input"
              className="w-100"
              value={NameFilter}
              onChange={handleNameFilterChange}
              label={t("Search Customer")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize={"small"} />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </div>
          {user.functionalities.findIndex(
            (f) => f.nome === "SA_Add_Customer"
          ) >= 0 && (
            <div className="col">
              <Button
                className="bgc-primary"
                variant="contained"
                onClick={() => {
                  setFormType("Add");
                  setIsFormOpen(true);
                }}
              >
                {t("Insert")}
              </Button>
            </div>
          )}
        </div>
        <div className="row mt-4 pb-4">
          <div className="col">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>{t("Customer")}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Tenant ID</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{t("Registered Users")}</b>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCustomers
                    .filter((u) =>
                      u.customername
                        .toLowerCase()
                        .includes(NameFilter.toLowerCase())
                    )
                    .map((c, index) => (
                      <TableRow
                        className="table-hover-user"
                        key={c.pkcustomers}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {c.customername}
                          {c.isdemo ? (
                            <p>
                              <small>
                                <b>TRIAL</b>
                              </small>
                            </p>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="left">{c.tenantid}</TableCell>
                        <TableCell
                          align="left"

                        >
                          {c.pkcustomers === 1
                            ? getUsersXCustomer(c.tenantid)
                            : getUsersXCustomer(c.tenantid) +
                              "/" +
                              getMaxUsersXCustomer(c.pkcustomers)}
                        </TableCell>

                        <TableCell align="right">
                          {user.functionalities.findIndex(
                            (f) => f.nome === "SA_Modify_Customer"
                          ) >= 0 && (
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() => handleEdit(c)}
                              className="me-1"
                            >
                              <Edit fontSize="small" className="primary" />
                            </IconButton>
                          )}
                          {user.functionalities.findIndex(
                            (f) => f.nome === "SA_Delete_Customer"
                          ) >= 0 && (
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() => handleDelete(c)}
                              className="me-1"
                            >
                              <Delete fontSize="small" className="primary" />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <CustomerForm
          type={formType}
          selectedCustomer={selectedCustomer}
          open={isFormOpen}
          handleClose={closeForm}
        />
        <SACustomerManagementModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          Customer={selectedCustomer}
        />
      </div>
    </>
  );
};
