import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faStar as starEmpty } from "@fortawesome/pro-regular-svg-icons";
import { IconName, IconPrefix, faArrowCircleLeft, faCalendar, faUser, faStar as starFull } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import cn from "classnames";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SeatsDialogType } from "../../../core/components/SeatsDialog/SeatsDialog";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { closeDialogMessage, getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { getCustomerSettings } from '../../../core/store/customer/settings-customer/settings-customer.action';
import { getCustomerSettingsProfiles } from "../../../core/store/customer/settings-profiles-customer/settings-profiles-customer.action";
import { getSeatsDialog } from "../../../core/store/dialogs/dialogs.store";
import { toggleModalSuccess } from "../../../core/store/modal/room-modal/room-modal.store";
import { AddRoomToFavorites, AddUserReservationWithoutSeat, GetReservationsByDate, RemoveRoomFromFavorites, addDayReservation, deleteUserReservation2, getRoomseatsForRoom } from "../../../core/store/room/user-room/user-room.actions";
import { getRoomSuccess } from "../../../core/store/room/user-room/user-room.store";
import { RootState } from "../../../core/store/store";
import { getUsersUsrGroups } from "../../../core/store/users/users.actions";
import { RoomSeat } from "../../../models/room";
import { Shift } from "../../../models/shift";
import style from "./RoomModalMobile.module.scss";

const RoomModalMobile: React.FC = () => {
    const { visible } = useSelector((state: RootState) => state.modal.roomModal);
    const { reservationsBydate, selectedRoom , userSites} = useSelector((state: RootState) => state.room.userRoom)
    const [date, setDate] = useState<Moment>(moment());
    const [utentiNome, setUtentiNome] = useState<string[]>([])
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state: RootState) => state.auth);
    const [currentAttachment, setCurrentAttachment] = useState<string>("");
    const { customerSettings } = useSelector((state:RootState)=> state.customerSettings);
    const { customerSettingsProfiles } = useSelector((state:RootState)=> state.customerSettingsProfiles);
    const { usersusrgroups } = useSelector((state: RootState) => state.users);
    const [dayLimit, setDayLimit] = React.useState<Moment>(moment().add(100,'y'));
    // const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    // const [selectedShift, setSelectedShift] = useState<Shift>(null)

    function toggleRoomModal() {
        dispatch(toggleModalSuccess());
        dispatch(getRoomSuccess(null))
    }

    const handleChange = (newDate: Moment) => {
        setDate(newDate);
    };

    const weekDays: string [] = [
        t("Monday"),
        t("Tuesday"),
        t("Wednesday"),
        t("Thursday"),
        t("Friday"),
        t("Saturday"),
        t("Sunday")
    ]

    function removeRoomFromFavorites(pkroom: number) {
        dispatch(RemoveRoomFromFavorites(user.pkusers, pkroom))
    }

    function addRoomToFavorites(pkroom: number) {
        dispatch(AddRoomToFavorites(user.pkusers, pkroom))
    }

    const toggleDrawer = (newOpen: boolean, users: string[]) => () => {
        setDrawerOpen(newOpen);
        setUtentiNome(users)
    };

    const reserveDay = () => {
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Book all shifts on day") + "\n"+ moment(date).format("DD/MM/YYYY") + "?"}</div>),
            dialogTitle : t("Reservation summary")+" - "+selectedRoom.roomname,
            dialogAcceptButton: t("Reserve"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                let okShifts = [];
                selectedRoom.shifts.forEach(s => {
                    let alreadyReserved = false;
                    const shiftReservations = reservationsBydate.find(ns => ns.pkshifts === s.pkshifts);
                    if(shiftReservations && shiftReservations.reservations.find(r=> r.pkreservations !== 0)){ //check if user alrady reserved shift
                        alreadyReserved = true;
                    }

                    if(selectedRoom.roomseats <= s.booked) alreadyReserved = true; //check if no seats left
                    if(!alreadyReserved){
                        okShifts.push(s.pkshifts);
                    }
                });
                dispatch(addDayReservation(okShifts, date, selectedRoom.pkrooms, 0));
            }
        }
        dispatch(getDialogMessage(message))
    }

    const onDeleteReservation = (s: Shift) =>{
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Do you want to cancel this reservation?") + "\n"+ moment(date).format("DD/MM/YYYY") + "\n" + selectedRoom.roomname + " - " + s.shiftname}</div>),
            dialogTitle : t("Delete reservation"),
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
              onAccept: () => {
                const pks: number[] = [];
                const shiftReservations = reservationsBydate.find(ns=> ns.pkshifts === s.pkshifts);
                pks.push(shiftReservations.reservations.find(r=> r.pkreservations !== 0).pkreservations);
                dispatch(deleteUserReservation2(pks, selectedRoom.pkrooms, date));
            }
          }
        dispatch(getDialogMessage(message));
    }

    const goBackClick = () => {
        dispatch(getRoomSuccess(userSites.find(us => us.pksites === selectedRoom.fksites).rooms.findIndex(r => r.pkrooms === selectedRoom.pkrooms) === 0 ? userSites.find(us => us.pksites === selectedRoom.fksites).rooms[userSites.find(us => us.pksites === selectedRoom.fksites).rooms.length - 1] : userSites.find(us => us.pksites === selectedRoom.fksites).rooms[userSites.find(us => us.pksites === selectedRoom.fksites).rooms.findIndex(r => r.pkrooms === selectedRoom.pkrooms) - 1]))
    }

    const goForwardClick = () => {
        dispatch(getRoomSuccess(userSites.find(us => us.pksites === selectedRoom.fksites).rooms.findIndex(r => r.pkrooms === selectedRoom.pkrooms) === userSites.find(us => us.pksites === selectedRoom.fksites).rooms.length - 1 ? userSites.find(us => us.pksites === selectedRoom.fksites).rooms[0] : userSites.find(us => us.pksites === selectedRoom.fksites).rooms[userSites.find(us => us.pksites === selectedRoom.fksites).rooms.findIndex(r => r.pkrooms === selectedRoom.pkrooms) + 1]))
    }

    const checkIfBookDay = function (): boolean {

        if(date.isBefore(moment(),'day') ||
           date.isAfter(dayLimit, "day")){
            return false;
        }

        let numReservableShifts = 0;

        reservationsBydate.forEach(s=>{
            if(s.reservations.length < selectedRoom.roomseats && s.reservations.findIndex(r=>r.pkreservations !== 0) === -1){ //c'è posto && non sono prenotato
                numReservableShifts++;
            }
        });
        return (numReservableShifts > 0)? true : false;
    }

    /**
     * Metodo che effettua la prenotazione e, successivamente, apre la dialog tramite la callback
     * @param shift
     * @param canReserve
     */
    const handleOpenDialog = (shift: Shift, canReserve: boolean) => {
        if(canReserve){
            dispatch(AddUserReservationWithoutSeat(shift.pkshifts, date, selectedRoom.pkrooms, moment(), "mobile", reservationCompletedCallback));
        }
    }

    /**
     * Apre la dialog per di conferma prenotazione e, solo se si è PREMIUM, permette il cambio della postazione
     * @param date
     * @param shift
     * @param roomSeat
     */
    const reservationCompletedCallback = (date: Moment, shift: Shift, roomSeat: RoomSeat) =>{
        //Calcolo il numero di prenotazioni effetuate nel turno selezionato
        const reservations = reservationsBydate.find(rr=>rr.pkshifts === shift.pkshifts).reservations;
        const numAvailableSeats = selectedRoom.roomseats - (reservations.length + 1); //+1 in quanto non contiene la prenotazione fatta ora

        //Controllo se l'utente è PREMIUM
        const isUserPremium = (user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0);

        let message : DialogMessageType = {
          dialogAcceptButton: t("Close"),
          dialogCenterButtons: true,
          dialogMsgStyle: "pb-0",
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {} ,
          dialogMsg: <div className="text-center">

            {/* Icona stile sweetalert */}
            <div className={cn(style["sa"], "mb-0")}>
              <div className={cn(style["sa-success"])}>
                <div className={cn(style["sa-success-tip"])}></div>
                <div className={cn(style["sa-success-long"])}></div>
                <div className={cn(style["sa-success-placeholder"])}></div>
                <div className={cn(style["sa-success-fix"])}></div>
              </div>
            </div>

            {/* Titolo */}
            <p className="mb-0 fw-bold" style={{fontSize:"24px"}}>{t("Booking reserved")}</p>

            {/* Dettagli prenotazione */}
            <p className="mb-3">{t("Reservation details")}:</p>
            <div className="mb-3">
              <p className="mb-1"><b>{t("Date")}:</b> {moment(date).format("DD/MM/YYYY")} </p>
              <p className="mb-1"><b>{t("Shift")}:</b> {shift.shiftname}</p>
              {isUserPremium && <p className="mb-0"><b>{t("Seat")}:</b> {selectedRoom.isshared ? selectedRoom.roomname : roomSeat.seatname} </p>}
            </div>

            {/* Cambia postazione */}
            {numAvailableSeats > 0 && isUserPremium && <><hr className="hr" />
            <p>
              {t("Change seat?")} <span
                onClick={() => {
                    //Prima di cambiare posto chiudo la dialog di successo prenotazione
                    dispatch(closeDialogMessage());

                    //Apro la dialog per il cambio della postazione
                    let seatsDialog: SeatsDialogType = {
                        isOpen: true,
                        shift,
                        selectedDate: date,
                        room: selectedRoom,
                        startDate: moment(),
                        variant:"mobile"
                    }
                    dispatch(getSeatsDialog(seatsDialog));
                }}
                className={cn(style["link-text"])}
              >{t("Click here")}</span>
            </p>
            <hr className="hr" /> </>}
          </div>
        }

        dispatch(getDialogMessage(message));
    }
    useEffect(() => {
        dispatch(getCustomerSettings(user.fkcustomers))
        dispatch(getCustomerSettingsProfiles(user.fkcustomers))
        dispatch(getUsersUsrGroups())
    }, [dispatch])

    useEffect(()=> {
        if(user.ispremium){
          if(customerSettingsProfiles != null && usersusrgroups != undefined){
            let setting = customerSettingsProfiles.find((csp) => csp.fkusrgroups == usersusrgroups.fkusrgroups);
            if(setting != undefined){
              setDayLimit(  setting.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                            setting.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                            moment().add(setting.limitdays,'d'));
            }else if(customerSettings != null){
              setDayLimit(customerSettings.limitisactive ?
                      customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                      customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                             moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
            }else{
              setDayLimit(moment().add(100,'y'));
            }
          }else if(customerSettings != null){
            setDayLimit(customerSettings.limitisactive ?
                    customerSettings.limitidentify === 1 ? moment().add(7,'d').endOf('week'):
                    customerSettings.limitidentify === 2 ? moment().add(1,'M').endOf('month'):
                                                           moment().add(customerSettings.limitdays,'d') : moment().add(100,'y'));
          }else{
            setDayLimit(moment().add(100,'y'));
          }
        }else{
          setDayLimit(moment().add(100,'y'));
        }
      },[customerSettings,customerSettingsProfiles])

    useEffect(() => {
        dispatch(getRoomSuccess(userSites.find(us => us.pksites === selectedRoom.fksites).rooms[userSites.find(us => us.pksites === selectedRoom.fksites).rooms.findIndex(r => r.pkrooms === selectedRoom.pkrooms)]))
    }, [userSites])

    useEffect(() => {
        dispatch(GetReservationsByDate(selectedRoom.pkrooms, date))
        dispatch(getRoomseatsForRoom(selectedRoom.pkrooms))
    },[date, selectedRoom, dispatch])

    useEffect(()=>{
        userSites.forEach(s=>{
            s.rooms.forEach(r=>{
                if(r.pkrooms === selectedRoom.pkrooms && r.roomattachment !== undefined){
                    setCurrentAttachment(r.roomattachment);
                }
            })
        })

    },[userSites, selectedRoom])

    return (
        <>
            <Dialog
            fullScreen
            open={visible}
            onClose={toggleRoomModal}
            className={cn("no-overflow")}
            sx={{overflowX: "hidden"}}
            >
                <div className="container-fluid h-100">
                    <div
                        className={cn("row", style["modal-header"], ((selectedRoom.fkattachments && selectedRoom.fkattachments !== 0) && (!currentAttachment || currentAttachment === ""))?style["shimmer"]:"", "position-relative")}
                        style={(currentAttachment !== "" )?{backgroundImage:"url('data:image/jpeg;base64,"+currentAttachment+"')"}:{backgroundColor: "#0C5468"}}
                    >
                        <div className="d-flex justify-content-between align-items-start">
                            <IconButton className="ms-1 mt-2"  onClick={toggleRoomModal} style={{zIndex: "999"}}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} size="lg" color="white"  />
                            </IconButton>
                            <IconButton
                                className={style["fav-btn"]}
                                onClick={() => selectedRoom.isfavorite ? removeRoomFromFavorites(selectedRoom.pkrooms) : addRoomToFavorites(selectedRoom.pkrooms)}>
                                <FontAwesomeIcon icon={selectedRoom.isfavorite ? starFull : starEmpty} style={{color: selectedRoom.isfavorite ? "yellow" : "white"}} />
                            </IconButton>
                        </div>
                        <div className={cn("col d-flex justify-content-center align-items-center", style["close-btn"], selectedRoom?.iconname ?? "mt-4")}>
                                <div className="row w-100 mt-5 mb-2">
                                    <p className={cn(style["slide-caret"], style["left-caret"])} onClick={() => goBackClick()}>
                                        <IconButton className="caret">
                                            <FontAwesomeIcon icon={faChevronLeft} color="white" />
                                        </IconButton>
                                    </p>
                                    <span className="d-flex justify-content-center mb-1">
                                        {selectedRoom.iconname && <FontAwesomeIcon className="position-relative" icon={[selectedRoom.iconname.split(" ")[0] as IconPrefix, selectedRoom.iconname.split(" ")[1] as IconName]} size="3x" color="white"/> }
                                    </span>
                                    <h1 className={cn(style["header-title"], "position-relative text-center")}>{selectedRoom.roomname}</h1>
                                    <p className={cn(style["slide-caret"], style["right-caret"])} onClick={() => goForwardClick()}>
                                        <IconButton className="caret">
                                            <FontAwesomeIcon icon={faChevronRight} color="white" />
                                        </IconButton>
                                    </p>
                                </div>
                        </div>

                    </div>
                    <div className="row d-flex mt-2">
                        <div className="col d-flex justify-content-end">
                            <IconButton className={cn(style["icon-btn"])}  onClick={() => setDate(moment(date).add(-1, 'days'))} disabled={date.isSameOrBefore(moment(), 'day')} >
                                <FontAwesomeIcon icon={faChevronLeft} size="lg"   />
                            </IconButton>
                        </div>
                        <div className="col d-flex justify-content-center flex-column">
                            <div className="d-flex justify-content-center">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDatePicker
                                            open={open}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            inputFormat="DD/MM/yyyy"
                                            value={date}
                                            onChange={handleChange}
                                            minDate={moment()}
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <IconButton className="pb-0" ref={inputRef} onClick={() => setOpen(!open)} >
                                                        <input ref={inputRef} {...inputProps} className={cn(style["input-field"], "p-0")} />
                                                        <FontAwesomeIcon icon={faCalendar} size="lg" className="me-1 primary"   />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        />

                                </LocalizationProvider>
                            </div>
                            <div className="d-flex justify-content-center">
                                            <h4 className="bold primary">{date.format('DD') + " " + date.format('MMMM')}</h4>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-start">
                            <IconButton className={cn(style["icon-btn"])} onClick={() => setDate(moment(date).add(1, 'days'))} >
                            <FontAwesomeIcon icon={faChevronRight} size="lg"   />
                            </IconButton>
                        </div>
                    </div>
                    <div className="row d-flex mb-3">
                        <div className="col">
                            <div className={cn(style["carousel-dots"])}>
                                <ul className={cn(style["dot-wrapper"])}>
                                    <div className="row w-100">
                                    <div className="col ps-4 d-flex justify-content-center">
                                        {weekDays.map((item, x) => {
                                            return (
                                            <li
                                                key={x}
                                                className={cn("me-3", date.format('dddd').toUpperCase() === item.toUpperCase() ? cn(style["selected"], cn("d-flex flex-column")) : cn("d-flex flex-column"))}

                                            >
                                                <span className="bold text-center">{t(item).charAt(0)}</span>
                                                <div className={cn(style["dot"])} onClick={() => setDate(moment(date).isoWeekday(x+1))}></div>
                                            </li>
                                            );
                                        })}
                                    </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3" >
                        <div className="col d-flex justify-content-center" >
                            <div className={cn("w-100 ps-2 pe-2 d-flex align-items-center justify-content-center",(checkIfBookDay())?style["reserveWeekBtn"]:style["reserveWeekBtnUnavailable"])} onClick={(checkIfBookDay())? reserveDay : null}>
                               <span className={cn(style["reserveWeekText"])}>{t("Reserve all")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col ">
                            {selectedRoom.shifts.map(s => {
                                const item = reservationsBydate.find(sh => sh.pkshifts === s.pkshifts)
                                let availableSeats = selectedRoom.roomseats
                                let isBookedByUser = false
                                let bgc = ""
                                let users: string[] = []
                                if(item != null) {
                                    if(item.reservations != null) {
                                        availableSeats = selectedRoom.roomseats - item.reservations.length
                                        availableSeats === 0 ? bgc = "rgba(212,90,92,255)" : bgc = "rgba(181,225,188,255)";

                                        if(item.reservations.length > 0) {
                                            if(item.reservations.find(r => r.pkreservations !== 0)){
                                                isBookedByUser = true;
                                                bgc = "rgba(72,151,201,255)";
                                            }

                                            item.reservations.forEach(res => {
                                                users.push(res.utenti)
                                            })
                                        }
                                    }
                                }
                                let canReserve = (isBookedByUser === true || availableSeats === 0) ||
                                                 date.isBefore(moment(), "day") ||
                                                 date.isAfter(dayLimit, "day")
                                ? false : true;

                                //Manage graphic in case date is before today
                                if(date.isBefore(moment(),'day') ||
                                  (date.isAfter(dayLimit, "day") && !isBookedByUser)){
                                    bgc="darkgrey";
                                }

                                return(
                                    <div className="container mt-4">
                                    <div className="row">
                                        <div className="col-9 ps-0 d-flex flex-column ms-0">
                                            <h4 className="mb-0">{s.shiftname}</h4>
                                            <span className={style["shift-text"]}>{s.starttime + " - " + s.endtime}</span>
                                            {users.length > 0 &&
                                                <Button className="d-flex justify-content-start p-0 primary-f" onClick={toggleDrawer(true, users)}><span>{t("See reservations")}</span></Button>
                                            }


                                        </div>
                                        <div className="col-3 d-flex justify-content-end">
                                            <div className={cn("me-4 d-flex justify-content-center align-items-center",style["btnCircle"])} style={{backgroundColor: bgc}} onClick={() => {
                                                if(!date.isBefore(moment(),'day')){
                                                    isBookedByUser? onDeleteReservation(s) : handleOpenDialog(s, canReserve) //onReserve(s,canReserve)
                                                }
                                            }}>
                                                <span style={{color: (availableSeats > 0 && !isBookedByUser ? "#242424": "#FFFFFF")}}>{availableSeats < 0 ? 0 : availableSeats}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedRoom.shifts[selectedRoom.shifts.length - 1] !== s &&
                                        <div className="row mt-4">
                                            <Divider />
                                        </div>
                                    }

                                    </div>
                                )

                            })}
                        </div>

                    </div>
                </div>
                <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={toggleDrawer(false, [])}
                className={cn(style["drawerClass"])}
                sx={{
                    '& .MuiModal-root': {
                        zIndex: '1300'
                    },

                }}
                >
                    <Box>
                        <List>
                        {utentiNome.map((u => (
                            <ListItem>
                                <ListItemIcon>
                            <FontAwesomeIcon icon={faUser} />
                            </ListItemIcon>
                            <ListItemText
                                primary={u}
                            />
                            </ListItem>
                        )))
                        }
                        </List>
                    </Box>
                    </Drawer>
            </Dialog>
        </>
    )
}

export  default RoomModalMobile;