import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from "moment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserReservation, getUserReservations, getUserReservationsByDate } from "../../../core/store/users/users.actions";
import { MyReservation } from "../../../models/reservation";
import { confirmNotification, momentToDateString } from "../../../utils";
import { Button, TextField } from "@mui/material";
import style from "./user-reservation-desktop.module.scss";
import cn from "classnames";
import BoxCard from "../../../core/components/box-card/BoxCard";
import { EventProps } from "../../home/nextReservations/event";
import { RootState } from "../../../core/store/store";

const UserReservationDesktop: React.FC = () => {
  const [reservation, setReservation] = useState<{ [key: string]: MyReservation[] }>({});
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState<Moment | null>(null)
    const {myReservations: reservations} = useSelector((state:RootState)=> state.users)
  
    useEffect(() => {
      const res = reservations
        .reduce((reservations, res) => {
          const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
            if (!reservations[dateKey]) {
                reservations[dateKey] = []
              }
              reservations[dateKey].push(res)
              return reservations
        }, {});
      setReservation(res)
    }, [reservations]);

    useEffect(() => {
      if(filter){
        dispatch(getUserReservationsByDate(filter.format("YYYY-MM-DD")))
      }
      else {
        dispatch(getUserReservations())
      }
      filterReservation(filter)
    }, [filter, dispatch])
  
    function deleteReservation(reservation: MyReservation) {
      confirmNotification({
        title: t("Delete reservation"),
        html: `<p>${reservation.shiftname} (${reservation.starttime} ~ ${reservation.endtime}) ${t("on date")} ${moment(reservation.reservationdate).format("DD/MM/YYYY")}? <br> ${t("Do you confirm")}?</p>`,
        confirmButtonText: t("Yes delete"),
        cancelButtonText: t("No close"),
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          const pks: number[] = [reservation.pkreservations] 
          dispatch(deleteUserReservation(pks))
        }
      });
    }
  
    function deleteAllReservation(reservations: MyReservation[]) {
      confirmNotification({
        title: t("Delete reservations"),
        html: `<p>${moment(reservations[0].reservationdate).format("DD/MM/YYYY")} <br> ${t("Do you confirm")}?</p>`,
        confirmButtonText: t("Yes delete"),
        cancelButtonText: t("No close"),
        reverseButtons: true
      }).then((res) => {
        if (res.isConfirmed) {
          const pks: number[] = []
          reservations.forEach((res) => {
            pks.push(res.pkreservations)
          })
          dispatch(deleteUserReservation(pks))
        }
      });
    }
  
    function filterReservation(date: Moment) {
      if(date) {
        const res = reservations
          .filter((res) => moment(res.reservationdate).isSame(moment(date).format("YYYY-MM-DD")))
          .reduce((reservations, res) => {
            const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
            if (!reservations[dateKey]) {
                  reservations[dateKey] = []
                }
                reservations[dateKey].push(res);
                return reservations
          }, {})
        setReservation(res)
      }
      else {
        const res = reservations
        .reduce((reservations, res) => {
          const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
            if (!reservations[dateKey]) {
                reservations[dateKey] = [];
              }
              reservations[dateKey].push(res);
              return reservations;
        }, {});
        setReservation(res);
      }
    }
  
    const handleChange = (newDate: Moment) => {
      setFilter(newDate)
    };

    const clearFilter = () => {
      setFilter(null);
    }
    
    return (
    <>
      <div className="container-fluid ps-4">
        <div className="row ms-2 mt-4 mb-4">
          <div className="col ps-1">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker 
                label={t("Search in date")}
                value={filter} 
                renderInput={(params) => <TextField {...params} />}
                onChange={handleChange}
              />      
              </LocalizationProvider>
              <Button 
                variant="contained" 
                onClick={clearFilter}
                hidden={!filter}
                className="ms-2 align-bottom">
                {t("See next reservations")}
              </Button>
          </div>
        </div>
      </div>
      {reservations.length > 0 ? Object.keys(reservation)
        .sort()
        .map((date) => (
          <div key={date} className="container-fluid ps-4 mt-3 mb-4">
          <div className="row ps-3 pe-3">
            <div className="col-12 d-flex">
              <h4 className={style["section-title"]}>
                {<span className={style["day-text"]}>{momentToDateString(moment(date)).splice(0,2).join(" ") + " "}</span>}
                <span> {momentToDateString(moment(date)).splice(2).join(" ")} </span>
                <span className="submenu-separator" />
              </h4>
              <h5 className={cn("ps-1", style["text-aligned-middle"], style["link-text"])} onClick={() => deleteAllReservation(reservation[date])}>
                {t("Cancel All Reservation").toLowerCase()}
              </h5>
            </div>
          </div>
          <div className="row ms-2 mt-1">
            {reservation[date].sort((a, b) => a.starttime > b.starttime ? 1 : -1).map((r) => {
              let ics: EventProps = {
                room: r.roomname,
                date: moment(date).format("YYYY-MM-DD"),
                shift: r.shiftname,
                startTime: r.starttime,
                endTime: r.endtime
              };
              return (
                (!filter || filter.isSameOrAfter(moment(),"date")) ?
                <BoxCard
                  includeAction={true}
                  key={r.pkreservations}
                  title={r.roomname}
                  subtitle2={t("Site") + " " + r.sitename}
                  footer={
                    <h5 className="card-subtitle1">
                      {r.shiftname}
                      <br />
                      {`${r.starttime} ~ ${r.endtime}`}
                    </h5>
                  }
                  action={<div className="w-100 d-flex" style={{"justifyContent": "center"}} onClick={() => deleteReservation(r)}>{t("Cancel Reservation")}</div>}
                  event={ics}
                />
                :
                <BoxCard
                  includeAction={false}
                  key={r.pkreservations}
                  title={r.roomname}
                  subtitle2={t("Site") + " " + r.sitename}
                  footer={
                    <h5 className="card-subtitle1">
                      {r.shiftname}
                      <br />
                      {`${r.starttime} ~ ${r.endtime}`}
                    </h5>
                  }
                  event={ics}
                />
              )}
            )
            }
          </div>
        </div>
      ))
      : 
      <div className="container-fluid ms-4 pb-5">
        <h4>{t("No reservations found")}</h4>
      </div>
    }
    </>
  )
}
export default UserReservationDesktop