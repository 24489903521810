import { IconName, IconPrefix } from "@fortawesome/pro-light-svg-icons";
import { faChairOffice } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, IconButton } from '@mui/material';
import cn from "classnames";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRoomseatsMap, getSeatsDialog } from "../../../core/store/dialogs/dialogs.store";
import { GetReservationByWeek, GetReservationInfoByPk, GetReservationsByDate, changeRoomSeat, getRoomMapByPkRoom, getRoomseatsForRoom } from '../../../core/store/room/user-room/user-room.actions';
import { getReservationInfoSuccess, getRoomSuccess } from "../../../core/store/room/user-room/user-room.store";
import { RootState } from '../../../core/store/store';
import { deleteUserReservation } from '../../../core/store/users/users.actions';
import { MyReservation } from '../../../models/reservation';
import { RoomSeat } from "../../../models/room";
import { confirmNotification, momentToDateString } from '../../../utils';
import Event from './event';
import style from './nextReservations.module.scss';

interface iconType{
  room: string,
  prefix: IconPrefix,
  name:IconName
}

const NextReservations: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [reservationsList, setReservationsList] = useState<{[key: string]: MyReservation[]}>({})
  const [icons, setIcons] = useState<iconType[]>([]);
  const { userSites } = useSelector((state: RootState) => state.room.userRoom);
  const { user } = useSelector((state: RootState) => state.auth);
  const { myReservations } = useSelector((state: RootState) => state.users)
  const { reservationInfo} = useSelector((state: RootState)=> state.room.userRoom);

  useEffect(()=>{
      const iconsTmp = [];
      userSites.forEach(s => {
        s.rooms.forEach(r => {
          if(r.iconname){
            iconsTmp.push({room: r.roomname, prefix: r.iconname.split(" ")[0] as IconPrefix, name: r.iconname.split(" ")[1] as IconName})
          }
        })
      })
      setIcons(iconsTmp);
  },[userSites])

  const deleteReservationClick = (reservation: MyReservation) => {
    confirmNotification({
      title: t("Delete reservation"),
      html: `<p>${moment(reservation.reservationdate).format("DD/MM/YYYY")} <br> ${reservation.roomname} - ${reservation.shiftname} (${reservation.starttime} - ${reservation.endtime}) <br> ${t("Do you confirm")}?</p>`,
      confirmButtonText: t("Yes delete"),
      cancelButtonText: t("No close"),
      reverseButtons: true
    }).then((res) => {
      if (res.isConfirmed) {
        const pks: number[] = [reservation.pkreservations]
        dispatch(deleteUserReservation(pks))
      }
    });
  }

  const handleChangeSeat = (pkreservations: number) =>{
    //recupero le informazioni aggiuntive della prenotazioni (come stanza, postazione e turno)
    dispatch(GetReservationInfoByPk(pkreservations));
  }

  useEffect(() => {
    const res = myReservations
      .reduce((reservations, res) => {
        const dateKey = moment(res.reservationdate).format("YYYY-MM-DD")
        if (!reservations[dateKey]) {
          reservations[dateKey] = []
        }
        reservations[dateKey].push(res);
        return reservations
      }, {});
    setReservationsList(res)
  }, [myReservations]);

  useEffect(()=>{
    if(reservationInfo){
      const date = moment(myReservations.find(r=> r.pkreservations === reservationInfo.pkreservation).reservationdate);
      dispatch(GetReservationsByDate(reservationInfo.room.pkrooms, date));
      dispatch(getRoomseatsForRoom(reservationInfo.room.pkrooms));

      //Mostro la pagina generale o la mappa (se la ha)
      if(
        reservationInfo.room.mapname &&
        reservationInfo.room.mapname !== ""
      ){
        //Controllo che abbia caricato la mappa
        const tmpSelectedRoom = userSites
            .find(s => s.pksites === reservationInfo.room.fksites).rooms
            .find(r => r.pkrooms === reservationInfo.room.pkrooms);

        if(!tmpSelectedRoom.mapattachment || tmpSelectedRoom.mapattachment === ""){
          //NON HA CARICATO L'ALLEGATO
          //prendo la mappa da BE (solo quella della stanza selezionata)
          dispatch(getRoomMapByPkRoom(tmpSelectedRoom.pkrooms, tmpSelectedRoom.fksites));
        }else{
          //HA CARICATO L'ALLEGATO
          //Prendo le prenotazioni (sono necessarie alla dialog)
          const dateOfReservation = date.format("YYYY-MM-DD");
          dispatch(GetReservationByWeek(reservationInfo.room.pkrooms, dateOfReservation, dateOfReservation));

          //Setto la stanza selezionata (necessaria alla dialog)
          dispatch(getRoomSuccess(tmpSelectedRoom));

          //Mostro la mappa
          dispatch(getRoomseatsMap({
            shift: reservationInfo.shift,
            date: date,
            isOpen: true,
            onDismiss: () => {},
            onAccept: (newRoomSeat: RoomSeat, pkOldReservation: number) => {
              dispatch(changeRoomSeat(
                "next-reservations",
                pkOldReservation,
                reservationInfo.shift,
                date,
                reservationInfo.room.pkrooms,
                newRoomSeat,
                date
              ))
            },
          }));

          //Rimuovo le informazioni sulla prenotazione
          dispatch(getReservationInfoSuccess(null));
        }
      }else{
        //Mostro la dialog semplice
        dispatch(getSeatsDialog({
          isOpen: true,
          shift: reservationInfo.shift,
          room: reservationInfo.room,
          selectedDate: date,
          startDate: moment(),
          variant: "next-reservations"
        }));

        //Rimuovo le informazioni sulla prenotazione
        dispatch(getReservationInfoSuccess(null));
      }
    }
  }, [reservationInfo, userSites])

  return(
    <Card className="ms-3 mb-3 pb-3">
      <CardContent className="pb-0">
        <div className="row mt-1 ms-1 mb-1">
          <div className="col-12">
            <h5 className="primary">{t("Next reservations")}</h5>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {Object.keys(reservationsList)
              .sort()
              .slice(0, 3)
              .map((date) => (
                <div key={date} className="container-fluid">
                  <div className="row">
                    <h6 className={cn("p-0 ps-1 primary")}>
                      <span>{momentToDateString(moment(date)).join(" ")}</span>
                    </h6>
                  </div>
                  <div className="row me-2 mb-3">
                    <div className="col p-0 ps-1">
                      {
                        reservationsList[date].sort((a, b) => a.starttime > b.starttime ? 1 : -1).map((r) => (
                          <div key={r.pkreservations} className={cn("row pt-1 pb-1", style["reservationRow"])}>
                            <div className="row">
                              <div className="col-9 d-flex align-items-center">
                                { icons.find(i=> i.room === r.roomname) !== undefined &&
                                  <FontAwesomeIcon className="me-2" icon={[icons.find(i=> i.room === r.roomname).prefix, icons.find(i=> i.room === r.roomname).name]} size="1x" />
                                }
                                <div className="row">
                                  <p className="m-0"><b>{r.roomname} {user.ispremium && user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 && !r.isshared ? "- " + r.seatname : null}</b></p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-9 d-flex align-items-center">
                                <p className="m-0">{r.shiftname} ({t("From") + " " + r.starttime  + " " + t("To") + " " + r.endtime})</p>
                              </div>
                              <div className="col-3 d-flex justify-content-end">
                                <IconButton className={cn(style["icon-primary"])} style={{display: user.functionalities.findIndex(f => f.nome === "Delete_reservation") === -1 ? "none" : "block"}} onClick={() => deleteReservationClick(r)}>
                                  <CloseIcon />
                                </IconButton>
                                <Event room={r.roomname} shift={r.shiftname} date={date} startTime={r.starttime} endTime={r.endtime}></Event>

                                <IconButton
                                  className={cn(style["icon-primary"])}
                                  style={{display: (user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 && user.ispremium && !r.isshared) ? "block" : "none"}}
                                  onClick={() => handleChangeSeat(r.pkreservations)}
                                >
                                    <FontAwesomeIcon icon={faChairOffice}  size='sm' />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="ms-3 mb-1">
              <Link to="/reservations" className={cn(style["link-text"])}>
                {Object.keys(reservationsList).length >= 3 && (Object.keys(reservationsList).length - 3) > 0 ? `${t("See other")} ${Object.keys(reservationsList).length - 3} ${t("Reservations").toLowerCase()}` : ""}
              </Link>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default NextReservations