import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../../models/customer";
import { CustomerPkAndName } from "../../../models/CustomerPkAndName";
import { License } from "../../../models/license";
import { countUser, SA_User } from '../../../models/sa_users';
import { Site } from "../../../models/site";

export interface SuperAdmin {
    allCustomers: Customer[]
    allCustomersPkAndName: CustomerPkAndName[]
    allCustomersUsers: SA_User[]
    numberCustomersUsersPages: number
    selectedLicense: License
    allLicenses: License[]
    numberLicensesPages: number,
    CustomerUsersCount: countUser[]
    CustomerUsers: SA_User[]
    selectedCustomer :Customer
    SAUserSites: Site[]
}

export const superAdminSlice = createSlice({
    name: "superadmin",
    initialState: {
      allCustomers: [],
      allCustomersPkAndName: [],
      allCustomersUsers: [],
      numberCustomersUsersPages: 0,
      allLicenses: [],
      numberLicensesPages: 0,
      selectedLicense: null,
      CustomerUsersCount: [],
      CustomerUsers: [],
      selectedCustomer:null,
      SAUserSites: []
    } as SuperAdmin,

    reducers: {

        getselectedCustomerSuccess :(state, action: PayloadAction<Customer>)=>{
            state.selectedCustomer=action.payload       },

        getselectedLicenseSuccess:(state, action: PayloadAction<License>) =>{
            state.selectedLicense=action.payload
        },
        getAllLicensesSuccess:(state, action:PayloadAction<License[]>) =>{
            state.allLicenses = action.payload
        },
        getCountLicensesSuccess:(state, action:PayloadAction<number>) =>{
            state.numberLicensesPages = action.payload % 100 <= 49 ? Math.round(action.payload / 100) + 1 : Math.round(action.payload / 100);
        },
        getAllCustomersSuccess: (state, action: PayloadAction<Customer[]>) => {
            state.allCustomers = action.payload
        },
        getAllCustomersPkAndNameSuccess: (state, action: PayloadAction<CustomerPkAndName[]>) => {
            state.allCustomersPkAndName = action.payload
        },
        getAllUsersXClientsSuccess: (state, action: PayloadAction<SA_User[]>) => {
            state.allCustomersUsers = action.payload
        },
        getCountUsersXClientsSuccess:(state, action:PayloadAction<number>) =>{
            state.numberCustomersUsersPages = action.payload % 100 <= 49 ? Math.round(action.payload / 100) + 1 : Math.round(action.payload / 100);
        },
        getAllUsersCountXClientSuccess: (state, action: PayloadAction<countUser>) => {
            state.CustomerUsersCount.push(action.payload)
        },
        getAllUsersXClientSuccess: (state, action: PayloadAction<SA_User[]>) => {
            state.CustomerUsers = action.payload;
        },
        getUserXClientSuccess: (state, action: PayloadAction<SA_User>) => {
            state.allCustomersUsers = state.allCustomersUsers.filter(u => u.email !== action.payload.email || (u.email === action.payload.email && u.customername !== action.payload.customername))
            state.allCustomersUsers.push(action.payload)
        },
        getSAUserSitesSuccess: (state, action:PayloadAction<Site[]>) => {
            state.SAUserSites = action.payload;
        }
    }
});

export const {
    getAllLicensesSuccess,
    getCountLicensesSuccess,
    getAllCustomersSuccess,
    getCountUsersXClientsSuccess,
    getAllCustomersPkAndNameSuccess,
    getAllUsersXClientSuccess,
    getselectedLicenseSuccess,
    getUserXClientSuccess,
    getAllUsersXClientsSuccess,
    getAllUsersCountXClientSuccess,
    getSAUserSitesSuccess
} = superAdminSlice.actions;

