import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerSettings } from "../../../../models/customerSettings";

interface CustomerSettingsStore {
  customerSettings: CustomerSettings;
}

export const customerSettingsSlice = createSlice({
  name: "customer/settings",
  initialState: {
    customerSettings: null
  } as CustomerSettingsStore,
  reducers: {
    getCustomerSettingsSuccess: (state, action: PayloadAction<CustomerSettings | null>) => {
      state.customerSettings = action.payload;
    }
  },
});

export const {
  getCustomerSettingsSuccess
} = customerSettingsSlice.actions;
