import { isMobile } from 'react-device-detect';
import RoomModalMobile from './RoomModalMobile/RoomModalMobile';
const RoomModal: React.FC = () => {
    return (
        <>
        {isMobile &&
        <RoomModalMobile></RoomModalMobile>
        }
        </>
    )
}
export default RoomModal