import { t } from "i18next";
import { interceptor } from "../../../../interceptors/.interceptor";
import { HttpHandler } from "../../../../models/http-interceptor";
import { RequestResponse } from '../../../../models/requestResponse';
import { TeamGroups } from "../../../../models/teamgroups";
import { addAlertMessage } from "../../../components/message-bar/store/alertMessage.store";
import { getTeamGroupsSuccess } from "./administration-group.store";

export const getTeamGroups = (pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_GET",
        url: `/TeamGroups/v1/GetAllTeamGroupsForCustomer/${pkcustomer}`,
        dispatch,
        auth: true,
        baseurl: false,
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            if(res.outcome === "Success"){
                dispatch(getTeamGroupsSuccess(res.team_groups));
            }
        });
    } catch {}
}

export const deleteUserFromTeamGroups = (pkuser: number, pkteamgroups: number, pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_DELETE",
        url: `/TeamGroups/v1/RemoveUserFromTeamGroups/${pkuser}/${pkteamgroups}`,
        dispatch,
        auth: true,
        baseurl: false,
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            dispatch(getTeamGroups(pkcustomer))
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("User succesfully removed from the group") }));
        });
    } catch {}
}

export const deleteRoomFromTeamGroups = (pkroom: number, pkteamgroups: number, pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_DELETE",
        url: `/TeamGroups/v1/RemoveRoomFromTeamGroups/${pkroom}/${pkteamgroups}`,
        dispatch,
        auth: true,
        baseurl: false,
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            dispatch(getTeamGroups(pkcustomer))
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room succesfully removed from the group") }));
        });
    } catch {}
}

export const deleteTeamGroups = (pkteamgroups: number, pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_DELETE",
        url: `/TeamGroups/v1/DeleteTeamGroup/${pkteamgroups}`,
        dispatch,
        auth: true,
        baseurl: false,
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            dispatch(getTeamGroups(pkcustomer))
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("Group succesfully removed") }));
        });
    } catch {}
}

export const updateGroup = (group: TeamGroups, pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_PUT",
        url: `/TeamGroups/v1/UpdateTeamGroup`,
        dispatch,
        auth: true,
        baseurl: false,
        httpOptions: {
            data: group
        }
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            dispatch(getTeamGroups(pkcustomer))
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("Group succesfully updated") }));
        });
    } catch {}
}

export const addNewTeamGroups = (group: TeamGroups, pkcustomer: number) => async(dispatch) => {
    const httpHandler: HttpHandler = {
        method: "HTTP_POST",
        url: `/TeamGroups/v1/AddNewTeamGroup`,
        dispatch,
        auth: true,
        baseurl: false,
        httpOptions: {
            data: group
        }
    };
    try {
        interceptor<RequestResponse>(httpHandler).then((res) => {
            dispatch(getTeamGroups(pkcustomer))
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("Group succesfully added") }));
        });
    } catch {}
}