import * as microsoftTeams from "@microsoft/teams-js";
import { t } from "i18next";
import jwt_decode from "jwt-decode";
import environment from "../../../environment";
import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from "../../../models/requestResponse";
import { addAlertMessage } from "../../components/message-bar/store/alertMessage.store";
import { getHomePage } from "../felogic/felogic.action";
import { AppThunk } from "../store";
import { setLoginDate } from "../users/users.actions";
import { getDecodedTokenSuccess, getTokenSuccess, logout } from "./auth.store";

const debugTokenString = "xxx";
const debugTokenObj = {
  name: "Filiberto Nencioni",
  tid: "4be0b68c-fc38-467d-aab3-66165ffab8ad",
  oid: "fa5f325d-a5d7-4c9e-b5e8-2298c3bbd3e3",
  upn: "daniel.neagu@mminformatica.it"
}

export const login = () => async (dispatch) => {
  if(process.env.REACT_APP_ENVIRONMENT === undefined){
    //CASO IN CUI SIAMO NEL DEBUG
    dispatch(getTokenSuccess(debugTokenString))
    dispatch(getDecodedTokenSuccess(debugTokenObj))
    dispatch(getHomePage());
  }else{
    microsoftTeams.initialize();
    microsoftTeams.authentication.getAuthToken({
      successCallback: function(result) {
        sessionStorage.setItem(environment.COOKIE_TOKEN_KEY, result)
        dispatch(getTokenSuccess(result))
        dispatch(setLoginDate());
        dispatch(getDecodedTokenSuccess(jwt_decode(result)));
        dispatch(getHomePage());
      },
      failureCallback: function(error) {
        console.log(error)
      }
    });
  }
}

export const saveLogin = (login: string) => async (dispatch) => {
  const httpHandler : HttpHandler = {
    method: "HTTP_GET",
    url:`User/v1/Login`,
    dispatch,
    auth:true,
    baseurl:false
  }

  if(process.env.REACT_APP_ENVIRONMENT === undefined){
    dispatch(getTokenSuccess(debugTokenString))
    dispatch(getDecodedTokenSuccess(debugTokenObj))
    try {
      interceptor<RequestResponse>(httpHandler).then((res) =>{
        dispatch(getHomePage());
        if(login != "enteredTheApp"){
          dispatch(addAlertMessage({ type: "success", htmlMessage: t("Login successfully") }));
        }
      })
    }catch(err){}
  }else{
    microsoftTeams.initialize();
    microsoftTeams.authentication.getAuthToken({
      successCallback: function(result) {
        sessionStorage.setItem(environment.COOKIE_TOKEN_KEY, result)
        dispatch(getTokenSuccess(result))
        dispatch(setLoginDate());
        dispatch(getDecodedTokenSuccess(jwt_decode(result)));
        try {
          interceptor<RequestResponse>(httpHandler).then((res) =>{
            dispatch(getHomePage());
            dispatch(addAlertMessage({ type: "success", htmlMessage: t("Login successfully") }));
          })
        }catch(err){}
      },
      failureCallback: function(error) {
        console.log(error)
      }
    });
  }
}

export const saveLogout=():AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_GET",
    url:`User/v1/Logout`,
    dispatch,
    auth:true,
    baseurl:false
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(logout());
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Logout successfully") }));
      dispatch(getHomePage());
    })
  }catch(err){}
}
