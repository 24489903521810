import { Button, Drawer, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { addAlertMessage } from "../../../core/components/message-bar/store/alertMessage.store";
import { addNewSite, editSiteName } from "../../../core/store/room/administration-room/administration-room.actions";
import { RootState } from "../../../core/store/store";
import { Site } from '../../../models/site';
import style from "../rooms-management.module.scss";
interface SideMenuProps{
    type: "Rename" | "New";
    pksite?: number;
    open: boolean;
    onClose: ()=> void;
    initValue?: string;
}

export const SideMenu: FC<SideMenuProps> = (props) =>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom);
    const [name, setName] = useState<string>("");
    const [isMain, setIsMain] = useState<boolean>(true);
    const [parent, setParent] = useState<number | null>(null)

    useEffect(() => {
        if(props.initValue !== undefined)
            setName(props.initValue);
    },[props.initValue])

    useEffect(() => {
        if(isMain) setParent(null);
    }, [isMain])

    const save = () =>{
        if(parent === null && !isMain) {
            dispatch(addAlertMessage({ type: "warning", htmlMessage: t("Not main site without parent msg") }));
            return
        }

        if(props.type==="Rename"){
            dispatch(editSiteName(props.pksite, name));
        }else{ //NEW
            const siteToAdd: Pick<Site, 'sitename' | 'parent'> = {
                sitename: name,
                parent: parent ? parent : 0
            }
            dispatch(addNewSite(siteToAdd));
        }
        setName("");
        setParent(null);
        setIsMain(true);
        props.onClose();
    }

    return(
        <Drawer
            PaperProps={{sx:{width:"35%"}}}
            anchor="right"
            open={props.open}
            onClose={props.onClose}
        >
            <div className="container-fluid px-4 mt-4 h-100">
                <div className="d-flex flex-column h-100">
                    <div className="d-flex flex-column gap-4 mb-auto">
                        <h3 className="mb-0 primary">{props.type==="Rename"? t("Rename Site"): t("New Site")}</h3>
                        <TextField
                            label={t("Name")}
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                        />
                        {
                            props.type==="New" &&
                            <div>
                                <div className="row">
                                    <div className="col-12 d-flex">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={isMain}
                                                    onChange={() => setIsMain(!isMain)}
                                                />
                                            }
                                            label={t("Main site").toString()}
                                            labelPlacement="top"
                                            style={{"alignSelf": "start"}}
                                        />
                                        <p className="align-items-end d-flex m-0 mb-2">{isMain ? t("Yes") : t("No")}</p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FormControl sx={{width:"100%"}}>
                                            <InputLabel id="parentLabel">{t("Parent site")}</InputLabel>
                                            <Select
                                                labelId="parentLabel"
                                                label={t("Parent site")}
                                                value={parent}
                                                onChange={(e) => setParent(e.target.value as number)}
                                                disabled={isMain}
                                            >
                                                {adminSites.filter(as => as.parent === 0).map(as =>
                                                    <MenuItem key={as.pksites} value={as.pksites}>
                                                        {as.sitename}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="d-flex flex-row gap-3 mb-4 justify-content-end">
                        <Button
                            variant="contained"
                            onClick={props.onClose}
                            className={cn(style["button-secondary"], style["button-overlay"])}
                            color="secondary"
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={save}
                            className={cn(style["button-primary"], style["button-overlay"])}
                        >
                            {t("Save")}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}