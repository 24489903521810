import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabPanel } from "@mui/lab";
import { Button, IconButton, Tab, Tabs } from "@mui/material";
import cn from "classnames";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";
import { DeleteAllUserReservationInDate, DeleteUserReservationByShiftAndDate } from "../../core/store/room/user-room/user-room.actions";
import { RootState } from "../../core/store/store";
import { WhereaboutsUser } from "../../models/whereabouts";
import { momentToDateString } from "../../utils";
import style from "./reservations-management.module.scss";

interface ReservationsManagementTabProps{
    userReservation: WhereaboutsUser;
    days: string[]
    pkuser: number;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRoomImage: React.Dispatch<React.SetStateAction<string>>;
    mobile: boolean;
}

export const ReservationsManagementTab: FC<ReservationsManagementTabProps> = (props) =>{
    const [ thisPropsReservations, setThisPropsReservations] = useState(props.userReservation.reservations)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("0");
    const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom);
    const { user } = useSelector((state: RootState) => state.auth)


    useEffect(()=>{
        setThisPropsReservations(props.userReservation.reservations)
    },[dispatch,props])

    const handleDelete = (fkshifts,reservationDate,pkuser,_day) =>{
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Do you want to remove this reservation?")}</div>),
            dialogTitle : t("Reservation summary"),
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                setThisPropsReservations(thisPropsReservations.filter(r=> !(moment(r.reservationdate).format("DD-MM-YYYY") === moment(reservationDate).format("DD-MM-YYYY") && r.fkshifts === fkshifts)));
                dispatch(DeleteUserReservationByShiftAndDate(fkshifts, moment(reservationDate), pkuser));
            }
        }
        dispatch(getDialogMessage(message))
    }

    const handleDeleteAllForDay = (_day,pkshifts) =>{
        let reservationDate = moment(_day, "DD-MM-YYYY")
        let message : DialogMessageType = {
            dialogMsg : (<div>{t("Do you want to remove this reservations?")}</div>),
            dialogTitle : t("Reservation summary"),
            dialogAcceptButton: t("Delete"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {
                setThisPropsReservations(thisPropsReservations.filter(r=>moment(r.reservationdate).format("DD-MM-YYYY") !== _day));
                dispatch(DeleteAllUserReservationInDate(reservationDate,pkshifts));
            }
        }
        dispatch(getDialogMessage(message))
    }

    const handleClickImage = (pksites: number, pkrooms: number) =>{
        const roomImage = adminSites.find(a=> a.pksites === pksites).rooms.find(room=> room.pkrooms === pkrooms).roomattachment;
        props.setRoomImage((roomImage)? roomImage: "");
        props.setIsModalOpen(true);
    }

    return(
        <>
        <TabContext value={value}>
            <Tabs
                value={value}
                onChange={(e, newValue)=>setValue(newValue)}
                variant="scrollable"
                orientation={(props.mobile)?"horizontal": "vertical"}
                sx={(props.mobile)?{}:{ borderRight: 1, borderColor: 'divider' }}
            >
                {
                    props.days.map((_day, index)=>{
                        const day = moment(_day, "DD-MM-YYYY")
                        return(
                            <Tab id={"Day"+_day} label={day.format("DD/MM")} value={index.toString()}/>
                        )
                    })
                }
            </Tabs>
            {
                props.days.map((_day, index)=>{
                    const day = moment(_day, "DD-MM-YYYY");
                    const dayRes = thisPropsReservations.filter(r=>moment(r.reservationdate).format("DD-MM-YYYY") === _day);

                    return (
                        <TabPanel value={index.toString()} className={(props.mobile)?"px-2": "w-100 pt-0"} style={!props.mobile? {"overflowY": "auto", "overflowX": "hidden"} : {}}>
                            <div className="col-12 w-100">
                                <h5 className={cn(style["title"], "mb-3")}>
                                    {momentToDateString(day).join(" ")}
                                    {
                                        dayRes && dayRes.length > 0 && user.functionalities.findIndex(f => f.nome === "Delete_Reservations_For_Other_Users") >= 0 ? <Button variant="text" className="text-danger" onClick={() => handleDeleteAllForDay(_day, props.userReservation.user.pkusers)}>{t("Delete") +" "+t("All")}</Button> : <></>
                                    }
                                </h5>
                                <div className="row">
                                    {(dayRes && dayRes.length > 0)?
                                    <>
                                        {dayRes.map((r, index, tot) =>
                                        <><div className="row">
                                            <div className={"d-flex justify-content-center "+((props.mobile)?"col-2 ":"col-1")}>
                                                <IconButton
                                                    className={cn(style["tab-btn"])}
                                                    onClick={()=> handleClickImage(r.fksites, r.pkrooms) }
                                                >
                                                    <FontAwesomeIcon icon={faCamera} />
                                                </IconButton>
                                            </div>
                                            <div className={"d-flex flex-column justify-content-center "+((props.mobile)?"col-7":"col-10")}>
                                                <p className={cn(style["room-name"])}>{r.roomname}</p>
                                                <p className={cn(style["shift-name"])}>{r.shiftname}</p>
                                            </div>
                                            <div className={"d-flex justify-content-center "+((props.mobile)?"col-3":"col-1")}>
                                                {
                                                    (user.functionalities.findIndex(f => f.nome === "Delete_Reservations_For_Other_Users") >= 0 ? <Button variant="text" className="text-danger" onClick={() => handleDelete(r.fkshifts, r.reservationdate, props.userReservation.user.pkusers,_day)}>{t("Delete")} </Button>: <></>)
                                                }

                                            </div>
                                            {
                                                (tot[index+1])?
                                                    <div className={cn(style["divider"],"mt-1 mb-1")}></div>
                                                : ""
                                            }
                                        </div></>
                                        )}
                                    </>: <h6 className={cn(style["info-text"])}>{t("No reservations")}</h6>
                                    }

                                </div>
                            </div>
                        </TabPanel>
                    )
                })
            }
        </TabContext>

        </>
    );
}