import { useEffect, useState } from "react";
import cn from "classnames";
import css from "./popupDesktop.module.scss";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setPopupState } from "../../store/popup/popup.store";
import { t } from "i18next";
import moment from "moment";

const PopupDesktop: React.FC = () => {
    //Utils
    const dispatch = useDispatch()

    //Selectors
    const { isOpen, variant, userGuide, changelog } = useSelector((state: RootState) => state.popup)
    const { userGuideFile } = useSelector((state: RootState) => state.guide)

    //UseState
    const [open, setOpen] = useState(isOpen)

    //UseEffects
    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    //Functions
    const handleClose = () => {
        setOpen(false)
        dispatch(setPopupState(false))
    }

    return (
        // changelog popup
        (open && variant === "changelog") ?
        <div className={cn(css["popup"])}>
            <div className={cn(css["container"])}>
                <div className={cn(css["fixedHeader"], "row", "col-auto")}>
                    <div className="col-11">
                        <h4 className={cn(css["textPrimary"], "m-0")}>
                            {changelog.title}
                        </h4>
                        <p>{moment(changelog.datetodisplay).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className={cn(css["closeDiv"], "col-1 mt-1")}>
                        <IconButton onClick={handleClose} className="p-0">
                            <FontAwesomeIcon
                                icon={faClose}
                                className={cn(css["btnClose"])}
                            />
                        </IconButton>
                    </div>
                </div>
                <div className={cn(css["container2"])}>
                    {changelog.changelogattachment && changelog.contenttype !== "mp4" &&
                        <div className="row d-flex justify-content-center mt-2">
                            <img src={"data:image/" + changelog.contenttype + ";base64," + changelog.changelogattachment} className={cn(css["imgClass"])} alt={t("Image not available")}></img>
                        </div>
                    }
                    {changelog.changelogattachment && changelog.contenttype === "mp4" && 
                        <div className="row d-flex justify-content-center mt-2">
                            <video autoPlay controls src={"data:video/" + changelog.contenttype + ";base64," + changelog.changelogattachment} className={cn(css["imgClass"])}></video>
                        </div>
                    }
                    <div className="row mt-3">
                        <p>{changelog.description}</p>
                    </div>
                    {changelog.sections && changelog.sections.length > 0 &&
                        changelog.sections.map(s => (
                            <div className="row mt-2 d-flex align-items-center">
                                <div className={cn(css["fitContent"], "col-auto")}>
                                    <h6 className={cn(css["textPrimary"], "my-auto")}>{s.sectiontitle}</h6>
                                </div>
                                <div className="col">
                                    <hr className={css["hr"]}/>
                                </div>
                                <p>{s.sectiontext}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div> 
        : 
        // userguide popup
        (open && variant === "userGuide") && userGuide ? 
        <div className={cn(css["popup"], "d-flex align-items-center")}>
            <div className={cn(css["container"])} style={{"height": "fit-content", "top": "auto"}}>
                <div className="row">
                    <div className="col-11">
                        <h5 className={cn(css["textPrimary"])}>{userGuide.name}</h5>
                    </div>
                    <div className={cn(css["closeDiv"], "col-1")}>
                        <IconButton onClick={handleClose} className="p-0">
                            <FontAwesomeIcon
                                icon={faClose}
                                className={cn(css["btnClose"])}
                            />
                        </IconButton>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col d-flex justify-content-center">
                        {userGuide.filetype !== "mp4" &&
                            <img src={"data:image/" + userGuide.filetype + ";base64," + userGuideFile} className={cn(css["imgClass"])} alt={t("Image not available")}></img>
                        }
                        {userGuide.filetype === "mp4" && 
                            <video autoPlay controls src={"data:video/" + userGuide.filetype + ";base64," + userGuideFile} className={cn(css["imgClass"])}></video>
                        }
                    </div>
                </div>
                <div className={cn(css["userguide-description"],"row mt-3")}>
                    <div className="col-12">
                        <p className={cn(css["wordBreak"],"mb-0")}>{userGuide.description}</p>
                    </div>
                </div>
            </div>
        </div> 
        : 
        // popup chiuso o inesistente
        <></>
    )
}

export default PopupDesktop