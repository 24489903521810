import { IconName, IconPrefix, faChairOffice, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, IconButton, List, ListItem, ListItemText } from "@mui/material";
import cn from "classnames";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { getSeatsDialog } from "../../../core/store/dialogs/dialogs.store";
import { GetReservationInfoByPk, GetReservationsByDate, getRoomseatsForRoom } from "../../../core/store/room/user-room/user-room.actions";
import { getReservationInfoSuccess } from "../../../core/store/room/user-room/user-room.store";
import { RootState } from "../../../core/store/store";
import { deleteUserReservation } from "../../../core/store/users/users.actions";
import { MyReservation } from "../../../models/reservation";
import { momentToDateString } from "../../../utils";
import style from '../nextReservations/nextReservations.module.scss';

interface iconType{
  room: string,
  prefix: IconPrefix,
  name:IconName
}

const NextReservationMobile: React.FC = () => {
    const userReservation = useSelector((state: RootState) => state.users.myReservations);
    const { userSites, reservationInfo } = useSelector((state: RootState) => state.room.userRoom);
    const { user } = useSelector((state: RootState) => state.auth);
    const [reservationsList, setReservationsList] = useState<{[key: string]: MyReservation[]}>({})
    const [icons, setIcons] = useState<iconType[]>([]);
    const dispatch = useDispatch();

    useEffect(()=>{
      const iconsTmp = [];
      userSites.forEach(s => {
        s.rooms.forEach(r => {
          if(r.iconname){
            iconsTmp.push({room: r.roomname, prefix: r.iconname.split(" ")[0] as IconPrefix, name: r.iconname.split(" ")[1] as IconName})
          }
        })
      })
      setIcons(iconsTmp);
    },[userSites])

    useEffect(() => {
      const res = userReservation
        .reduce((reservations, res) => {
          const dateKey = moment(res.reservationdate).format("YYYY-MM-DD")
          if (!reservations[dateKey]) {
            reservations[dateKey] = [];
          }
          reservations[dateKey].push(res);
          return reservations;
        }, {});
      setReservationsList(res);
    }, [userReservation]);

    const deleteReservationClick = (reservation: MyReservation) => {
      let message : DialogMessageType = {
        dialogMsg : (<div>{moment(reservation.reservationdate).format("DD/MM/YYYY")}<br/> {reservation.roomname} - {reservation.shiftname}</div>),
        dialogTitle : t("Do you want to cancel this reservation?"),
        dialogAcceptButton: t("Delete"),
        dialogDismissButton: t("Cancel"),
        isOpen : true,
        onDismiss: () => {},
          onAccept: () => {
            dispatch(deleteUserReservation([reservation.pkreservations]))

        }
      }
      dispatch(getDialogMessage(message))
    }

    const changeSeatClick = (reservation: MyReservation) =>{
      //recupero le informazioni aggiuntive della prenotazioni (come stanza, postazione e turno)
      dispatch(GetReservationInfoByPk(reservation.pkreservations));
    }

    useEffect(()=>{
      if(reservationInfo){
        const date = userReservation.find(r=> r.pkreservations === reservationInfo.pkreservation).reservationdate;
        dispatch(GetReservationsByDate(reservationInfo.room.pkrooms, moment(date)));
        dispatch(getRoomseatsForRoom(reservationInfo.room.pkrooms));

        dispatch(getSeatsDialog({
          isOpen: true,
          shift: reservationInfo.shift,
          room: reservationInfo.room,
          selectedDate: moment(date),
          startDate: moment(),
          variant: "next-reservations"
        }))

        dispatch(getReservationInfoSuccess(null));
      }
    }, [reservationInfo])


    return (
    <>
    {Object.keys(reservationsList)
                  .sort()
                  .slice(0, 3)
                  .map((date) => (
                    <div key={date} className="container-fluid ps-1 pe-1">
                      <div className="row">
                        <h5 className="primary pt-1 pb-2">
                          <span>{momentToDateString(moment(date)).join(" ")}</span>
                        </h5>
                      </div>
                      <div className="row me-2">
                        <div className="col">
                          <List className="pt-0">
                          {
                            reservationsList[date].map((r) => (
                              <div>

                              <ListItem
                                className="ps-0 pb-0 pt-0"
                                key={r.pkreservations}>
                                <ListItemText>
                                {icons.find(i=> i.room === r.roomname) !== undefined && <FontAwesomeIcon className="me-1 ms-1" icon={[icons.find(i=> i.room === r.roomname).prefix, icons.find(i=> i.room === r.roomname).name]} size="sm" />}
                                <strong className="ps-1">{r.roomname}</strong>{`  -  ${t("From")} ${r.starttime} ${t("To")} ${r.endtime}`} <br/>

                                <IconButton className="ps-1 pt-3" onClick={() => deleteReservationClick(r)} style={{visibility: user.functionalities.findIndex(f => f.nome === "Delete_reservation") === -1 ? "hidden" : "visible"}}>
                                  <FontAwesomeIcon className="primary me-2" icon={faTimes} size="sm" /><h6 className="primary mb-0"> {t("Delete")}</h6>
                                </IconButton>

                                <IconButton
                                  className="ps-1 pt-3"
                                  onClick={() => changeSeatClick(r)}
                                  style={{visibility: (user.functionalities.findIndex(f => f.nome === "Reserve_Room_Seat") >= 0 && user.ispremium && !r.isshared ) ? "visible" : "hidden"}}
                                >
                                  <FontAwesomeIcon className="primary me-2" icon={faChairOffice} size="sm" /><h6 className="primary mb-0"> {t("Change seat")}</h6>
                                </IconButton>

                                </ListItemText>
                              </ListItem>
                              <Divider className="mb-3"/>
                              </div>
                            ))
                          }
                          </List>

                        </div>

                      </div>
                    </div>
                  ))
                }
            <div className="ms-1 mb-1 mt-2">
              <Link to="/reservations" className={cn(style["link-text"])}>
                {Object.keys(reservationsList).length >= 3 && (Object.keys(reservationsList).length - 3) > 0 ? `${t("See other")} ${Object.keys(reservationsList).length - 3} ${t("Reservations").toLocaleLowerCase()}` : ""}
              </Link>
            </div>
    </>
    )
}
export default NextReservationMobile