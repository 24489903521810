import cn from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch } from "react-redux";
import svgImage from "../../../asset/img/user-not-active.svg";
import { getDefaultCustomerLanguage } from "../../../core/store/users/users.actions";
import css from "./AlertUserDoNotExists.module.scss";

const AlertUserDoNotExists: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getDefaultCustomerLanguage());
  },[dispatch])

  return (
    <div className={cn(css["container"],'container-fluid d-grid justify-content-center')}>
      <div className="row">
        <div className='col text-center'>
          <img
            className={cn(css["icon-image"])}
            src={svgImage}
            alt={t("Man placing a red circled X on a screen")}
          />
        </div>  
      </div>  
      <div className='row mt-4'>
        <div className="col-12">
          <h2 className={cn(css["title"], "text-center")}>{t("User do not exists")}</h2>  
        </div>
        <div className="col-12">
          <p className='text-center'>{t("User do not exists subtitle")}</p>  
        </div>
      </div>
    </div> 
  );
}

export default AlertUserDoNotExists
