import { interceptor } from "../../../interceptors/.interceptor"
import { HttpHandler } from "../../../models/http-interceptor"
import { RequestResponse } from "../../../models/requestResponse"
import { AppThunk } from "../store"
import { getHomePageSuccess } from "./felogic.store"

export const getHomePage=():AppThunk =>(dispatch)=>{
  const httpHandler : HttpHandler = {
    method: "HTTP_GET",
    url:`FELogics/v1/gethomepage/`,
    dispatch,
    auth:true,
    baseurl:false,
  }
  try
  {
    interceptor<RequestResponse>(httpHandler).then((res) =>{
      dispatch(getHomePageSuccess(res.msg));
    })
  }catch(err){}
}