import { t } from "i18next";
import moment, { Moment } from "moment";
import Swal from "sweetalert2";
import { interceptor } from "../../../../interceptors/.interceptor";
import { HttpHandler } from "../../../../models/http-interceptor";
import { MultipleReservation } from "../../../../models/multipleReservation";
import { RequestResponse } from "../../../../models/requestResponse";
import { RoomSeat } from "../../../../models/room";
import { Shift } from "../../../../models/shift";
import { Site } from "../../../../models/site";
import { SeatsDialogVariant } from "../../../components/SeatsDialog/SeatsDialog";
import { addAlertMessage } from "../../../components/message-bar/store/alertMessage.store";
import { getMeSuccess } from "../../auth/auth.store";
import { AppThunk } from "../../store";
import { getUserReservations, getUserReservationsByDate } from "../../users/users.actions";
import { setUsersReservationsAlreadyReserved } from "../../users/users.store";
import { getAllRoomsAndShiftsSuccess, getAvailableRoomSeatsSuccess, getAvailableRoomsAndShiftsByDateSuccess, getCheckRoomIntersectingTimeSlots, getImageHasLoadedSuccess, getNotAvailableSeatsNumberSuccess, getReservationInfoSuccess, getReservationsByDate, getRoomMapSuccess, getRoomReservations, getRoomUserSelectedSuccess, getRoomseatsForRoomSuccess, getShiftsAvailableFromDateToDateSuccess, getUserRoomsImageSuccess, getUserSitesChangedSuccess, getUserSitesSuccess } from "./user-room.store";

const reservation_resource_url = "Reservation";

export const getMe = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Me/v1/GetMe`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.outcome === "Success"){
        dispatch(getMeSuccess(res.user));
      }
    });
  } catch {}
};

export const deleteUserReservation = (pkreservation: number[]): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Reservation/v1/deleteReservation`,
    dispatch,
    httpOptions: {
      data: pkreservation,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      Swal.fire({
        title: t("Booking canceled"),
        icon: "success",
        iconColor: "#0C5468",
        reverseButtons: true,
      });
    });
  } catch {}
}

//only for mobile version
export const deleteUserReservation2 = (pkreservation: number[], pkroom: number, date: Moment): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Reservation/v1/deleteReservation`,
    dispatch,
    httpOptions: {
      data: pkreservation,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      Swal.fire({
        title: t("Booking canceled"),
        icon: "success",
        iconColor: "#0C5468",
        reverseButtons: true,
      });
      dispatch(GetReservationsByDate(pkroom, date))
      dispatch(getUserReservationsByDate(date.format("YYYY-MM-DD")))
    });
  } catch {}
}

export const changeRoomSeat = (
  variant: SeatsDialogVariant,
  pkreservation: number,
  shift: Shift,
  date: Moment,
  pkroom: number,
  roomSeat: RoomSeat,
  startDate: Moment
): AppThunk => async (dispatch) => {
    //Elimino la vecchia prenotazione
    const httpHandler: HttpHandler = {
      method: "HTTP_DELETE",
      url: `/Reservation/v1/deleteReservation`,
      dispatch,
      httpOptions: {
        data: [pkreservation],
      },
      auth: true,
      baseurl: false,
    };
    try {

      interceptor<RequestResponse>(httpHandler).then((res) => {
          //Procedo con la nuova prenotazione
          const httpHandler2: HttpHandler = {
            method: "HTTP_POST",
            url: `/${reservation_resource_url}/v1/addReservation/${shift.pkshifts}/${date.format("yyyy-MM-DD")}/${roomSeat.pkroomseats}`,
            dispatch,
            auth: true,
            baseurl: false,
          };
          try{
            interceptor<RequestResponse>(httpHandler2).then((res2) => {
              //Calcolo il codice per il referesh delle prenotazioni
              let code = 0;
              switch (variant) {
                case "desktop":
                  code = 1
                  break;
                case "mobile":
                  code = 0;
                  break;
                case "next-reservations":
                  code = 2;
                  break;
              }
              if (res2.outcome === "Success") {
                Swal.fire({
                  title: t("Booking reserved"),
                  html: `
                    <div class="text-center">
                      <p class="mb-3">${t("Reservation details")}:</p>
                      <div class="mb-3">
                        <p class="mb-1"><b>${t("Date")}:</b> ${date.format("DD/MM/YYYY")} </p>
                        <p class="mb-1"><b>${t("Shift")}:</b> ${shift.shiftname}</p>
                        <p class="mb-0"><b>${t("Seat")}:</b> ${roomSeat.seatname} </p>
                      </div>
                    </div>
                  `,
                  icon: "success",
                  iconColor: "#0C5468",
                  reverseButtons: true,
                });

                refreshReservation(dispatch, code, pkroom, date, startDate, shift.pkshifts);

              } else if(res2.outcome === "Warning"){
                Swal.fire({
                  title: res2.msg !== "" ? t(res2.msg) : t("Error add reservation"),
                  icon: "warning",
                  iconColor: "#0C5468",
                  reverseButtons: true,
                });
                refreshReservation(dispatch, code, pkroom, date, startDate, shift.pkshifts);
              }
            });
          }catch{}
      });
    } catch {}


}

export const getAvailableRoomsAndShiftsByDate = (date: Moment): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getroomsandshiftsavailablebyday/${date.format("YYYY-MM-DD")}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAvailableRoomsAndShiftsByDateSuccess(res.rooms));
    });
  } catch (err) {}
};

export const getShiftsAvailableFromDateToDate = (fromDate: Moment,toDate: Moment,pkshifts: string,pkroom: number,pkseat: number,daysSelected: string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getShiftsAvailableFromDateToDate/${fromDate.format("YYYY-MM-DD")}/${toDate.format("YYYY-MM-DD")}/${pkshifts}/${pkroom}/${pkseat}/${daysSelected}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getShiftsAvailableFromDateToDateSuccess(res.multipleReservations));
    });
  } catch (err) {}
};

export const getAllRoomsAndShifts = (): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getallroomsandshiftsforuser/`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAllRoomsAndShiftsSuccess(res.rooms));
    });
  } catch (err) {}
};

export const addDayReservation = (shifts: number[],date: Moment, pkroom: number, code: number, startDate?: Moment): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/${reservation_resource_url}/v1/addDayReservation/${date.format("YYYY-MM-DD")}`,
    httpOptions:{
      data: shifts
    },
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success" ) {
        Swal.fire({
          title: t("Booking reserved"),
          icon: "success",
          iconColor: "#0C5468",
          reverseButtons: true,
        });

        refreshReservation(dispatch, code, pkroom, date, startDate, 0);

      }else if(res.outcome === "WarningConfirm"){
        //Casistica in cui ci sono alcuni turni prenotati nella stessa fascia oraria di un turno già prenotato
        var ListOfAvaible = "";
        {res.shifts.map(shift => {
          if(ListOfAvaible != ""){
            ListOfAvaible += " / " + shift.starttime + "-" + shift.endtime;
          }else{
            ListOfAvaible =  shift.starttime + "-" + shift.endtime;
          }
        })}
        Swal.fire({
          title: t(res.msg) + ListOfAvaible,
          icon: "error",
          iconColor: "#0C5468",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: t("Confirm"),
          cancelButtonText: t("Cancel")
        }).then(r=> {
          if(r.isConfirmed){
            const availableShifts = shifts.filter(pkShift=> res.shifts.findIndex(shift=>shift.pkshifts ===pkShift) >= 0); //Prende le pk degli shift che possono essere inseriti
            dispatch(addDayReservation(availableShifts, date, pkroom, code, startDate));//Richiamo se stessa per inserire gli shift che so già per certo che possono essere inseriti
          }
        })
      }else{
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("Error add reservation"),
          icon: "error",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
      }
    });
  } catch (err) {}
};


export const AddUserReservation = (ShiftId: number, date: Moment, pkroom: number, code: number, pkseat?: number, startDate?: Moment): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/${reservation_resource_url}/v1/addReservation/${ShiftId}/${date.format("yyyy-MM-DD")}/${(pkseat)?pkseat:0}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success") {
        Swal.fire({
          title: t("Booking reserved"),
          icon: "success",
          iconColor: "#0C5468",
          reverseButtons: true,
        });

        refreshReservation(dispatch, code, pkroom, date, startDate, ShiftId);

      } else if(res.outcome === "Warning"){
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("Error add reservation"),
          icon: "warning",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
        refreshReservation(dispatch, code, pkroom, date, startDate, ShiftId);
      }
    });
  } catch (err) {}
};

export const AddMultipleUserReservation = (reservationsToReserve: MultipleReservation[],pkroomseat :number,room:string,shift:string,reservationFromDate: Moment,reservationToDate: Moment,daysSelected: string[]): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/${reservation_resource_url}/v1/addMultipleReservation/${pkroomseat}`,
    httpOptions:{
      data: reservationsToReserve
    },dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success") {
        let days = [];
        daysSelected.forEach((d) => {
          days.push(t(d))
        })
        Swal.fire({
          title: t("Bookings reserved"),
          html: `

                  <div>${t("DetailsReservation")}</div>
                  <div style="margin-top:5px;"><strong style="color:black">${t("From day")}: </strong> ${reservationFromDate.format("DD/MM/yyyy")}</div>
                  <div style="margin-top:5px;"><strong style="color:black">${t("To day")}: </strong>${reservationToDate.format("DD/MM/yyyy")}</div>
                  ${daysSelected.length != 7 ? '<div style="margin-top:5px;"><strong style="color:black">'+t("Days")+': </strong>'+days.join(',') : ''}
                  <div style="margin-top:5px;"><strong style="color:black">${t("Room")}: </strong>${room}</div>
                  <div style="margin-top:5px;"><strong style="color:black">${t("Shift")}: </strong>${shift}</div>
                `,
          icon: "success",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
        dispatch(getUserReservations());

      } else if(res.outcome === "Warning"){
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("Error add reservation"),
          icon: "warning",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
        dispatch(getUserReservations());
      }
    });
  } catch (err) {}
};

export const DeleteUserReservationByShiftAndDate = (pkshift: number, date: Moment, pkuser: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Reservation/v1/deleteUserReservationByShiftAndDate/${pkshift}/${date.format("yyyy-MM-DD")}/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: false
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success") {
        Swal.fire({
          title: t("Reservation canceled"),
          icon: "success",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
      } else{
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("ErrorDeleteReservation"),
          icon: "warning",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
      }
    });
  } catch (err) {}
};

export const DeleteAllUserReservationInDate = (date: Moment, pkuser: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Reservation/v1/deleteAllUserReservationInDate/${date.format("yyyy-MM-DD")}/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: false
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success") {
        Swal.fire({
          title: t("Reservations canceled"),
          icon: "success",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
      } else{
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("ErrorDeleteReservation"),
          icon: "warning",
          iconColor: "#0C5468",
          reverseButtons: true,
        });
      }
    });
  } catch (err) {}
};

export const AddUserReservationWithoutSeat = (shiftId: number, date: Moment, pkroom: number, startDate: Moment, variant:"desktop"|"mobile", callback: (date:Moment, shift:Shift, roomSeat:RoomSeat)=>void) => async (dispatch) =>{
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/${reservation_resource_url}/v1/addReservationForFirstAvailableSeat/${shiftId}/${date.format("yyyy-MM-DD")}`,
    dispatch,
    auth: true,
    baseurl: false,
  };

  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome === "Success") {
        callback(date, res.shifts[0], res.roomSeats[0]);
        refreshReservation(dispatch, (variant ==="desktop")?1:0, pkroom, date, startDate, shiftId);

      } else if(res.outcome === "Warning"){
        Swal.fire({
          title: res.msg !== "" ? t(res.msg) : t("Error add reservation"),
          icon: "warning",
          iconColor: "#0C5468",
          reverseButtons: true,
        });

        refreshReservation(dispatch, (variant ==="desktop")?1:0, pkroom, date, startDate, shiftId);

      }
    });
  } catch (err) {}
}

const refreshReservation = (dispatch, code: number, pkroom: number, date: Moment, startDate: Moment, ShiftId: number ) => {
  switch(code) {
    case 0: //roommodalmobile single reservation
      dispatch(GetReservationsByDate(pkroom, date));
      dispatch(getUserReservationsByDate(date.format("YYYY-MM-DD")))
      break;
    case 1: //reserve-seat single reservation
      const starttime = moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const endtime = moment(startDate, "DD/MM/YYYY").add(6, "d").format("YYYY-MM-DD");

      dispatch(GetReservationByWeek(pkroom, starttime, endtime));
      break;
    case 2: //quick-reservation stepper e fastreservation
      dispatch(getUserReservations())
      break;
    case 3: //Whereabout
      dispatch(setUsersReservationsAlreadyReserved({pkusers: pkroom, pkshift: ShiftId, date: date}));
      //pkrooms = pkusers
      break;

    default:
      break;
  }
}


export const GetReservationByWeek = (pkrooms: number, starttime:string, endtime:string): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/${reservation_resource_url}/v1/GetReservationsByWeek/${pkrooms}/${starttime}/${endtime}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getRoomReservations(res.shifts))
    });
  } catch (err) {}
};

export const GetReservationsByDate = (roomId: number, date: Moment) : AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/${reservation_resource_url}/v1/getreservationsbyday/${roomId}/${date.format("YYYY-MM-DD")}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getReservationsByDate(res.shifts))
    });
  } catch (err) {}
}

export const GetUserSites = () : AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getSitesRoomsShifts`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome==="Success") {
        dispatch(getUserSitesSuccess(res.sites));
        dispatch(getImageHasLoadedSuccess(false));
        //dispatch(GetUserRoomsImage());
        dispatch(getUserSitesChangedSuccess(false));
      }
    });
  } catch (err) {}
}

export const getUserRoomsSelectedUser = (pkusers: number) : AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/getuserrooms/${pkusers}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if (res.outcome==="Success") {
        dispatch(getRoomUserSelectedSuccess(res.rooms));
      }
    });
  } catch (err) {}
}

export const GetUserSitesChanged = (oldSites: Site[]) : AppThunk => async (dispatch) => {
  let oldSitesMap = oldSites.map( s => {
    let _rooms = s.rooms.map(r=>{
      return {
        pkrooms: r.pkrooms,
        dou: r.dou,
        shifts: r.shifts.map(sh => {
          return {
            pkshifts: sh.pkshifts,
            dou: sh.dou
          }
        })
      }
    });
    return{
      pksites: s.pksites, dou: s.dou, rooms: _rooms
    }
  });
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/FELogics/v1/SitesRoomsShiftsChanged`,
    dispatch,
    httpOptions: {
      data: oldSitesMap,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserSitesChangedSuccess(res.boolean));
    });
  } catch (err) {}
}

export const GetUserRoomsImage = () : AppThunk => (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/FELogics/v1/getUserRoomsImage`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getUserRoomsImageSuccess(res.sites));
      dispatch(getImageHasLoadedSuccess(true));
      dispatch(getUserSitesChangedSuccess(false));
    });
  } catch (err) {}
}

export const getRoomMapByPkRoom = (pkroom: number, pksite:number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Attachment/v1/getattachment/${pkroom}/roomsmaps`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: false
  }
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getRoomMapSuccess({pkroom, pksite, roommap: res.msg}));
    });
  } catch {}
}

export const RemoveRoomFromFavorites = (pkuser:number, pkroom: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/Room/v1/removeroomfromfavorites/${pkuser}/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(GetUserSites());
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room removed from favorites")}))
    });
  } catch (err) {}
}

export const AddRoomToFavorites = (pkuser:number, pkroom: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/Room/v1/addroomtofavorites/${pkuser}/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(GetUserSites());
      dispatch(addAlertMessage({ type: "success", htmlMessage: t("Room added to favorites")}))
    });
  } catch (err) {}
}

export const getAvailableRoomSeats = (shifts: string, pkroom: number, date: Moment) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/RoomSeat/v1/getavailableroomseats/${shifts}/${pkroom}/${date.format("YYYY-MM-DD")}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAvailableRoomSeatsSuccess(res.roomSeats));
    });
  } catch (err) {}
}

export const getRoomSeats = (pkroom: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/RoomSeat/v1/getroomseats/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getAvailableRoomSeatsSuccess(res.roomSeats));
    });
  } catch (err) {}
}

export const getRoomseatsForRoom = (pkroom: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/RoomSeat/v1/getroomseatsfromroompk/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getRoomseatsForRoomSuccess(res.roomSeats));
    });
  } catch (err) {}
}

export const GetNotAvailableSeatsNumber = (pkroom: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/RoomSeat/v1/getunavailableroomseatsfromroompk/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: true
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getNotAvailableSeatsNumberSuccess(res.num));
    });
  } catch (err) {}
}

export const getInfoCheckRoomIntersectingTimeSlot = (pkroom : number)  => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Room/v1/checkroomintersectingtimeslots/${pkroom}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: false
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCheckRoomIntersectingTimeSlots(res.boolean));
    });
  } catch (err) {}
}

export const GetReservationInfoByPk = (pkreservation: number) => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `/Reservation/v1/getReservationInfoByPk/${pkreservation}`,
    dispatch,
    auth: true,
    baseurl: false,
    ignoreCounter: false
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getReservationInfoSuccess({
        pkreservation,
        room: res.room,
        shift: res.shifts[0],
        roomseat: res.roomSeats[0]
      }));
    });
  } catch (err) {}
}