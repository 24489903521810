import { IconName, IconPrefix } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabPanel } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonBase, Card, Grid, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../core/components/dialog/store/dialogMessage.store";
import { getTeamGroups } from "../../core/store/room/administration-group/administration-group.actions";
import { GetAdminRoomsImage, deleteRoom, deleteSite, getAdminSites, getAdminSitesChanged, getRoomForUpdate, getRoomForUpdateWithoutAttachment, removeAttachmentRoomForUpdate } from "../../core/store/room/administration-room/administration-room.actions";
import { RootState } from "../../core/store/store";
import { updateUIState } from "../../core/store/ui/ui.store";
import { getTenantUsers } from "../../core/store/users/users.actions";
import { Room } from "../../models/room";
import { Site } from "../../models/site";
import { SideMenu } from "./components/SideMenu";
import RoomModal from "./modal/RoomModal";
import style from './rooms-management.module.scss';

interface siteTab {
    site: number;
    tab: string;
}

export const RoomsManagement: FC = () =>{
    //Initialization
    const { t } = useTranslation();
    const { adminSites, roomForUpdate, adminSitesChanged, imageHasLoaded } = useSelector((state: RootState) => state.room.administrationRoom);
    const dispatch = useDispatch();
    const [selectedSite, setSelectedSite] = useState<Site> (adminSites[0]);
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const [roomAction, setRoomAction] = useState<"New"|"Duplicate"|"Update">("New");
    const [requestShowModal, setRequestShowModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [drawerState, setDrawerState] = useState({new: false, rename: false});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [dialogOpen, setDialogOpen] = useState<boolean>(true);
    // const [tab, setTab] = useState<string>("0");
    const [tabs, setTabs] = useState<siteTab[]>([]);
    const { user } = useSelector((state: RootState) => state.auth);

    //Functions
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, room: Room) => {
        setSelectedRoom(room);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSelectedRoom(null);
        setAnchorEl(null);
    };

    const onDelete = () =>{
        setDialogOpen(true)
        let dialog : DialogMessageType = {
          dialogTitle : t("Delete room"),
          dialogMsg : <p className="mb-0" style={{fontWeight:"600"}}>{t("Delete Room Confirm") +" "+selectedRoom.roomname + "?"}</p>,
          isOpen : dialogOpen,
          onDismiss: () => {setDialogOpen(false)},
          onAccept: () => {
            dispatch(deleteRoom(selectedRoom.pkrooms));
            setDialogOpen(false);
          },
          dialogAcceptButton: t("Yes delete"),
          dialogDismissButton: t("No cancel")
        }
        dispatch(getDialogMessage(dialog));

    }

    const onEdit = () =>{
        dispatch(getRoomForUpdate(selectedRoom.pkrooms));
        setRoomAction("Update");
        setRequestShowModal(true);
    }

    const onDuplicate = () =>{
        dispatch(getRoomForUpdateWithoutAttachment(selectedRoom.pkrooms));
        setRoomAction("Duplicate");
        setRequestShowModal(true);
    }

    const renameGroup = (pksite: number) =>{
        setSelectedSite(adminSites.find((s)=>s.pksites===pksite));
        setDrawerState({...drawerState, rename:true});
    }

    const deleteGroup = (pksite: number) =>{
        setDialogOpen(true)
        let dialog : DialogMessageType = {
          dialogTitle : t("Delete Site"),
          dialogMsg : t("Delete site confirm"),
          isOpen : dialogOpen,
          onDismiss: () => {setDialogOpen(false)},
          onAccept: () => {
            dispatch(deleteSite(pksite));
            setDialogOpen(false);
          },
          dialogAcceptButton: t("Yes delete"),
          dialogDismissButton: t("No close")
        }
        dispatch(getDialogMessage(dialog));
    }

    const newSite = () => {
        setDrawerState({...drawerState, new:true});
    }

    const newRoom = () => {
        setRoomAction("New");
        dispatch(removeAttachmentRoomForUpdate());
        dispatch(updateUIState({type: "SET_RUN", run: false}))
        setShowModal(true);
    }

    const deleteLevel = (pksite: number) => {
        setDialogOpen(true)
        let dialog : DialogMessageType = {
          dialogTitle : t("Delete Site"),
          dialogMsg : t("Delete site confirm"),
          isOpen : dialogOpen,
          onDismiss: () => {setDialogOpen(false)},
          onAccept: () => {
            dispatch(deleteSite(pksite));
            setDialogOpen(false);
          },
          dialogAcceptButton: t("Yes delete"),
          dialogDismissButton: t("No close")
        }
        dispatch(getDialogMessage(dialog));
    }

    const handleTabChange = (newTab: string, pksites: number) => {
        let actualTabs = tabs.slice()
        actualTabs.find(at => at.site === pksites).tab = newTab
        setTabs(actualTabs)
    }

    useEffect(() => {
        (adminSites.length === 0)?dispatch(getAdminSites()): dispatch(getAdminSitesChanged(adminSites));
        dispatch(getTeamGroups(user.fkcustomers));
        dispatch(getTenantUsers());
    }, [dispatch]);

    useEffect(()=>{
        if(adminSites.length > 0 && !imageHasLoaded){
            dispatch(GetAdminRoomsImage());
        }
        if(adminSitesChanged){
          dispatch(getAdminSites());
        }
    },[adminSitesChanged])

    useEffect(()=>{
        dispatch(getTeamGroups(user.fkcustomers));
        if(adminSites.length > 0) {
            let siteTabs: siteTab[] = []
            adminSites.forEach(as => siteTabs.push({site: as.pksites, tab: "0"}))
            setTabs(siteTabs)
        }
    },[adminSites, user.fkcustomers])

    useEffect(()=>{
        if(roomForUpdate && roomForUpdate.room && requestShowModal){
            setShowModal(true);
            setRequestShowModal(false);
        }
    },[roomForUpdate, requestShowModal])

    function checkSiteHasRooms(site: Site):boolean{
        let found = false;
        if(!site)
            return false;

        //Controllo rooms del site padre
        if(site.rooms && site.rooms.length > 0)
            found = true;

        //controllo rooms site figli
        const childSites = adminSites.filter(s=>s.parent === site.pksites);

        childSites.forEach(s=>{
            if(s.rooms && s.rooms.length > 0){
                found = true;
            }
        });

        //SONO STATI EFFETTUATI TUTTI I CONTROLLI E NON ESISTO ROOMS
        return found;
    }

    return(
        <Grid container className="container-fluid p-4">

            {/* Buttons NewGroup/NewRoom */}
            <Grid item xs={12} className="mb-2">
                <Grid container spacing={2} >
                    {/* Button NewSite */}
                    {user.functionalities.findIndex(f => f.nome === "Add_new_site") >= 0 &&
                        <Grid item >
                            <Button
                                variant="contained"
                                onClick={newSite}
                                className={cn(style["button-primary"])}
                                data-joyride="btn-create-site"
                            >
                                {t("New Site")}
                            </Button>
                        </Grid>
                    }
                    {/* Button NewRoom */}
                    {user.functionalities.findIndex(f => f.nome === "Add_new_room") >= 0 &&
                        <Grid item >
                            <Button
                                variant="contained"
                                onClick={newRoom}
                                className={cn(style["button-primary"])}
                                data-joyride="btn-create-room"
                            >
                                {t("New Room")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>

            {/* All accordions of Sites with room cards as details */}
            <Grid container >
                {/* Accordion */}
                {adminSites && adminSites.length > 0 ? adminSites.filter(as => as.parent === 0).map((site)=>(

                    <Accordion className="mt-3" sx={{width: "100%"}} elevation={1}>
                        {/* Accordion Summary */}
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className={cn(style["accordion-title"],"d-flex flex-row w-100 gap-2")}>
                                <h5 className="me-auto mb-0">{site.sitename}</h5>
                                {user.functionalities.findIndex(f => f.nome === "Update_site_name") >= 0 &&
                                    <p className="mb-0" onClick={(e)=>{e.stopPropagation(); renameGroup(site.pksites)}}> {t("Rename")} </p>
                                }
                                {user.functionalities.findIndex(f => f.nome === "Update_site_name") >= 0 && user.functionalities.findIndex(f => f.nome === "Delete_site") >= 0 &&
                                    <p className="mb-0" style={{color:"gray"}}> | </p>
                                }
                                {user.functionalities.findIndex(f => f.nome === "Delete_site") >= 0 &&
                                    <p className="mb-0 me-2" onClick={(e)=>{e.stopPropagation(); deleteGroup(site.pksites)}}> {t("Delete")} </p>
                                }
                            </div>
                        </AccordionSummary>

                        {/* Rooms cards: Accordion details */}
                        <AccordionDetails>
                            <Grid container spacing={4} sx={{padding:"2% 2%"}} >
                                {tabs && tabs.length > 0 &&
                                    <TabContext
                                    value={tabs.find(t => t.site === site.pksites)?.tab}>
                                            <div className="row w-100">
                                                <Tabs
                                                    className="ms-2"
                                                    value={tabs.find(t => t.site === site.pksites)?.tab}
                                                    onChange={(e, v) => handleTabChange(v, site.pksites)}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    <Tab key={0} value={"0"} label={t("All")} />
                                                    {adminSites && adminSites.filter(as => as.parent === site.pksites).map((s) =>
                                                        <Tab key={s.pksites} value={s.pksites.toString()} label={s.sitename} />
                                                    )}
                                                </Tabs>
                                            </div>
                                            <div className="w-100">
                                                {/* TAB TUTTE DI UN SITE (CONTIENE I SUOI SITE + QUELLI DEI FIGLI) */}
                                                <TabPanel className="ps-2 pt-2 pb-0" key={0} value={"0"}>
                                                    <div className="row">
                                                        {
                                                            site.rooms && [site].concat(adminSites.filter(s => s.parent === site.pksites)).filter(s => s.rooms != null && s.rooms.length > 0).map(s=> s.rooms.map((r) => (
                                                                <Grid item xs={12} sm={6} md={2} lg={2} className={cn("d-flex align-items-stretch pb-2", style["room-grid-item"])}>
                                                                    <Card
                                                                        key={r.pkrooms}
                                                                        className={cn("w-100 pb-0 pt-0", style["roomCard"], "bgc-primary", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                                                        sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                                                    >
                                                                        <ButtonBase className="h-100 w-100" onClick={(e)=>{handleClick(e, r)}}>
                                                                            <div className="row w-100 h-100 mt-2 me-0 ms-0 align-items-end">
                                                                                <div className={cn("col d-flex ps-2 pe-2 pb-2 pt-1", style["bg-room"])}>

                                                                                    {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                                                                    <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                                                                    </button>}
                                                                                    <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p>

                                                                                </div>
                                                                            </div>
                                                                        </ButtonBase>
                                                                    </Card>
                                                                </Grid>
                                                            )))
                                                        }
                                                    </div>
                                                </TabPanel>

                                                {/* TAB SITES FIGLI */}
                                                {adminSites && adminSites.filter(as => as.parent === site.pksites).map((s) => (
                                                    <TabPanel className="ps-2 pt-2 pb-0" key={s.pksites} value={s.pksites.toString()}>
                                                        <div className="row ps-2 pt-3">
                                                            <div className="col">
                                                            {
                                                                user.functionalities.findIndex(f => f.nome === "Update_site_name") >= 0 &&
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => deleteLevel(s.pksites)}
                                                                >
                                                                    {t("Delete level")}
                                                                </Button>
                                                            }
                                                            {
                                                                user.functionalities.findIndex(f => f.nome === "Delete_site") >= 0 &&
                                                                <Button
                                                                    className={user.functionalities.findIndex(f => f.nome === "Update_site_name") >= 0 ? "ms-2" : ""}
                                                                    variant="contained"
                                                                    onClick={(e)=>{e.stopPropagation(); renameGroup(s.pksites)}}
                                                                >
                                                                    {t("Rename level")}
                                                                </Button>
                                                            }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            {
                                                                s.rooms && s.rooms.map((r) => (
                                                                <Grid item xs={12} sm={6} md={2} lg={2} className={cn("d-flex align-items-stretch pb-2", style["room-grid-item"])}>
                                                                    <Card
                                                                        key={r.pkrooms}
                                                                        className={cn("w-100 pb-0 pt-0", style["roomCard"], "bgc-primary", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                                                        sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                                                    >
                                                                        <ButtonBase className="h-100 w-100" onClick={(e)=>{handleClick(e, r)}}>
                                                                            <div className="row w-100 h-100 mt-2 me-0 ms-0 align-items-end">
                                                                                <div className={cn("col d-flex ps-2 pe-2 pb-2 pt-1", style["bg-room"])}>

                                                                                    {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                                                                    <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                                                                    </button>}
                                                                                    <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p>
                                                                                </div>
                                                                            </div>
                                                                        </ButtonBase>
                                                                    </Card>
                                                                </Grid>
                                                                ))
                                                            }
                                                        </div>

                                                    </TabPanel>
                                                ))}
                                            </div>
                                    </TabContext>
                                }

                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {user.functionalities.findIndex(f => f.nome === "Modify_room") >= 0 &&
                                        <MenuItem onClick={()=>{
                                            handleClose();
                                            onEdit();
                                        }}> {t("Edit Room")} </MenuItem>
                                    }
                                    {user.functionalities.findIndex(f => f.nome === "Duplicate_room") >= 0 &&
                                        <MenuItem onClick={()=>{
                                            handleClose();
                                            onDuplicate();
                                        }}> {t("Duplicate Room")} </MenuItem>
                                    }
                                    {user.functionalities.findIndex(f => f.nome === "Delete_room") >= 0 &&
                                        <MenuItem onClick={()=>{
                                            handleClose();
                                            onDelete();
                                        }}> {t("Delete room")} </MenuItem>
                                    }
                                </Menu>

                                {selectedSite && <SideMenu
                                        onClose={()=>setDrawerState({...drawerState, rename:false})}
                                        type="Rename"
                                        open={drawerState.rename}
                                        pksite={(selectedSite.pksites === undefined)? 0 : selectedSite.pksites}
                                        initValue={(selectedSite.sitename === undefined)? "": selectedSite.sitename}
                                    />
                                }
                            </Grid>
                            {checkSiteHasRooms(site) === false?
                                <p className="text-muted">
                                    {t("No room")}
                                    <a className={cn(style["action-text"])} onClick={newRoom}>{t("No room action")}</a>
                                </p>
                            : null}
                        </AccordionDetails>
                    </Accordion>

                )) :
                    <p className="text-muted">
                        {t("No group")}
                        <a className={cn(style["action-text"])} onClick={newSite}>{t("No group action")}</a>
                    </p>
                }
            </Grid>
            <SideMenu
                onClose={()=>setDrawerState({...drawerState, new:false})}
                type="New"
                open={drawerState.new}
            />
            {showModal && <RoomModal type={roomAction} showModal={showModal} onCloseModal={()=>setShowModal(false)} />}
        </Grid>
    )
}

