import React from "react";
import {Autocomplete, AutocompleteRenderOptionState, FilterOptionsState, AutocompleteRenderGetTagProps, TextField} from "@mui/material";
import { Controller } from "react-hook-form";
import { HookFormProps } from "./HookFormProps";
import { IconOption } from "../../../pages/rooms-management/modal/components/RoomDataForm";

interface ControlledAutocompleteProps{
    options: any[],
    getOptionLabel?: (option: any)=> string,
    groupBy?: (option:any) => string,
    renderOptionLabel?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState)=> React.ReactNode,
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode,
    filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[],
    multiple?: boolean,
    renderTags?: (value: any[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode,
    value?: any[],
    onChange?: (value: any[]) => void
    label: string,
    placeholder?: string,
    size?: "small" | "medium",
    setDefaultValue?: React.Dispatch<React.SetStateAction<IconOption>>;
    disableCloseOnSelect?: boolean;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
    disabled?: boolean,
    fullwidth?: boolean,
    limitTags?: number
}

export const ControlledAutocomplete: React.FC<HookFormProps & ControlledAutocompleteProps> = (props)=>{    
    return( 
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules} 
        defaultValue={props.defaultValue}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => (
            <Autocomplete
                isOptionEqualToValue={props.isOptionEqualToValue}
                size={props.size}
                disablePortal
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                groupBy= {props.groupBy}
                renderOption={props.renderOption}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        error={error&& true} 
                        helperText={error && error.message}
                        label={props.label} 
                        placeholder={props.placeholder}
                    />)
                } 
                filterOptions={props.filterOptions}
                multiple={props.multiple}
                renderTags={props.renderTags}
                value={props.value}
                onChange={(e,data, reason) => {
                    if(props.onChange === undefined){
                        onChange(data);
                    }else{
                        onChange(data);
                        props.onChange(data);
                    }

                    if(props.setDefaultValue){
                        props.setDefaultValue(data as IconOption)
                    }
                }}
                defaultValue={props.defaultValue}
                disableCloseOnSelect={props.disableCloseOnSelect}
                disabled={props.disabled}
                fullWidth={props.fullwidth}
                limitTags={props.limitTags}
            />
        )}
        />
    )
}
  