import { Button } from "@mui/material";
import cn from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../asset/img/logo.svg";
import { ControlledTextField } from "../../../core/components/mui-input/ControlledTextField";
import { addNewCustomerAndUser } from "../../../core/store/customer/customer.action";
import { RootState } from "../../../core/store/store";
import css from "./AlertNewCustomer.module.scss";

interface NewCustomerFields{
    usernameinput: string;
    companynameinput: string;
}

const AlertNewCustomer: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { decodedToken } = useSelector((state: RootState) => state.auth);


    useEffect(()=>{
        if(decodedToken.name !== undefined && decodedToken.name !== ""){
            setValue("usernameinput", decodedToken.name);
        }
    },[decodedToken])

    const { control, handleSubmit, setValue,  }
    = useForm<NewCustomerFields>({
        mode: "all",
        reValidateMode: "onSubmit",
    })

    const handleSignIn = handleSubmit(data => {
        if(decodedToken.tid && decodedToken.oid && decodedToken.upn){
            dispatch(addNewCustomerAndUser(decodedToken.tid, data.companynameinput, decodedToken.oid, data.usernameinput,decodedToken.upn));
        }
    });

    return(
        <div className='container-fluid d-grid justify-content-center mt-4'>
            {/* Image */}
            <div className="row">
                <div className='col text-center'>
                    <img
                    className={cn(css["icon-image"])}
                    src={logo}
                    alt="JustSeat Logo"
                    />
                </div>
            </div>

            {/* TEXT */}
            <div className='row mt-5'>
                <h2 className={cn(css["title"],"text-center primary mb-4")}>{t("Welcome to justseat")}</h2>
                <h4 className="text-center primary mb-4">{t("First access")}</h4>
                <h5 className="text-center secondary mb-4">{t("New customer warning")}</h5>
            </div>

            {/* FORM */}
            <div className="row justify-content-center">
                <div className="col-6 mt-4">
                    <ControlledTextField
                        name="usernameinput"
                        className="mb-3"
                        sx={{width:"100%"}}
                        label={t("Full name")}
                        control={(control as any)}
                        required
                        rules={{required: {value: true, message: t("Field Required")},maxLength:150}}
                        InputLabelProps={{ shrink: true }}
                    />
                    <ControlledTextField
                        name="companynameinput"
                        className="mb-3"
                        sx={{width:"100%"}}
                        label={t("Company name")}
                        control={(control as any)}
                        required
                        rules={{required: {value: true, message: t("Field Required")},maxLength:100}}
                    />
                    <div className="d-flex flex-row justify-content-center">
                        <Button
                            variant="outlined"
                            className={cn(css["sign-in-btn"])}
                            onClick={handleSignIn}
                        >
                            {t("Register")}
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AlertNewCustomer
