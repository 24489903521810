import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DecodedToken } from "../../../models/token";
import { User } from "../../../models/user";

interface Auth {
  user: User;
  token: string;
  decodedToken: DecodedToken;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    token: "",
    decodedToken: {}
  } as Auth,
  reducers: {
    getTokenSuccess: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    getMeSuccess: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    getCustomerLogoSuccess: (state, action: PayloadAction<string>) =>{
      state.user.customerlogo = action.payload;
    },
    logout: (state) => {
      state.user = {} as User;
      state.decodedToken = {} as DecodedToken;
      state.token = "";
    },
    getDecodedTokenSuccess: (state, action: PayloadAction<DecodedToken>) => {
      state.decodedToken = action.payload
    },
  },
});

export const {
  getTokenSuccess,
  getMeSuccess,
  getCustomerLogoSuccess,
  logout,
  getDecodedTokenSuccess
} = authSlice.actions;
