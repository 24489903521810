import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import moment from "moment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserReservation, getUserReservations, getUserReservationsByDate } from "../../../core/store/users/users.actions";
import { MyReservation } from "../../../models/reservation";
import { Box, Button, TextField } from "@mui/material";
import style from "./user-reservation-mobile.module.scss";
import cn from "classnames";
import BoxCard from "../../../core/components/box-card/BoxCard";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { RootState } from "../../../core/store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { momentToDateString } from "../../../utils";

const UserReservationMobile: React.FC = () => {
  const [reservation, setReservation] = useState<{ [key: string]: MyReservation[]; }>({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Moment | null>(null)
  const {myReservations: reservations} = useSelector((state:RootState)=>state.users);

      useEffect(() => {
        const res = reservations
          .reduce((reservations, res) => {
            const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
             if (!reservations[dateKey]) {
                  reservations[dateKey] = [];
                }
                reservations[dateKey].push(res);
                return reservations;
          }, {});
        setReservation(res);
      }, [reservations]);

      useEffect(() => {
        if(filter){
          dispatch(getUserReservationsByDate(filter.format("YYYY-MM-DD")))
        }
        else {
          dispatch(getUserReservations())
        }
        filterReservation(filter)
      }, [filter])
    
      function deleteReservation(reservation: MyReservation) {
        let message : DialogMessageType = {
          dialogMsg : (<div>{moment(reservation.reservationdate).format("DD/MM/YYYY")}<br/>{reservation.roomname} - {reservation.shiftname}</div>),
          dialogTitle : t("Do you want to cancel this reservation?"),
          dialogAcceptButton: t("Delete"),
          dialogDismissButton: t("Cancel"),
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {
            const pks: number[] = [reservation.pkreservations] 
            dispatch(deleteUserReservation(pks)) 
          }
        }
        dispatch(getDialogMessage(message))
      }
        
      function deleteAllReservation(reservations: MyReservation[]) {

        let message : DialogMessageType = {
          dialogMsg : (<div><p>{t("Do you really want to delete all your reservations")} {t("on date")} {moment(reservations[0].reservationdate).format("dddd DD MMMM")}?</p></div>),
          dialogTitle : t("Delete reservations"),
          dialogAcceptButton: t("Delete"),
          dialogDismissButton: t("Cancel"),
          isOpen : true,
          onDismiss: () => {},
          onAccept: () => {
            const pks: number[] = []
            reservations.forEach((res) => {
              pks.push(res.pkreservations)
            })
            dispatch(deleteUserReservation(pks))
          }
        }
        dispatch(getDialogMessage(message))
      }
    
      function filterReservation(date: Moment) {
        if(date) {
          const res = reservations
            .filter((res) => moment(res.reservationdate).isSame(moment(date).format("YYYY-MM-DD")))
            .reduce((reservations, res) => {
              const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
              if (!reservations[dateKey]) {
                    reservations[dateKey] = []
                  }
                  reservations[dateKey].push(res);
                  return reservations
            }, {})
          setReservation(res)
        }
        else {
          const res = reservations
          .reduce((reservations, res) => {
            const dateKey = moment(res.reservationdate).format("YYYY-MM-DD");
             if (!reservations[dateKey]) {
                  reservations[dateKey] = [];
                }
                reservations[dateKey].push(res);
                return reservations;
          }, {});
          setReservation(res);
        }
      }
    
      const handleChange = (newDate: Moment) => {
        setFilter(newDate)
      };

      const clearFilter = () => {
        setFilter(null);
      }
    
    return (
        <>
            <div className="row mt-3">
              <div className={cn("col-12 ps-3 pb-3", style["date-filter"])}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker 
                    label={t("Search in date")}
                    value={filter} 
                    renderInput={(params) => <TextField {...params} />}
                    onChange={handleChange}
                    className="w-100"
                  />      
                </LocalizationProvider>
              </div>
              <div className="col-xs-12">
                <Button 
                  variant="contained" 
                  onClick={clearFilter}
                  hidden={!filter}
                  className="mt-1 align-bottom">
                  {t("See next reservations")}
                </Button>
              </div>
            </div>
            {reservations && reservations.length > 0 ?
              Object.keys(reservation)
              .sort()
              .map((date) => (
              <div key={date} className="container-fluid ps-0 mt-3 mb-4">
                <div className="row ps-1">
                  <div className="col d-flex flex-column">
                      <div>
                          <h4 className={style["section-title"]}>
                              {<span className={style["day-text"]}>{momentToDateString(moment(date)).splice(0,2).join(" ") + " "}</span>}
                              <span> {momentToDateString(moment(date)).splice(2).join(" ")} </span>
                          </h4>
                      </div>

                      <div>
                          <h6 className={cn(style["text-aligned-middle"], style["link-text"])} onClick={() => deleteAllReservation(reservation[date])}>
                          {t("Cancel All Reservation").toLowerCase()}
                          </h6>
                      </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <Box className="d-flex flex-column">
                    {reservation[date].map((r) => (
                      (!filter || filter.isSameOrAfter(moment(),"date")) ?
                        <BoxCard
                          fullWidth={true}
                          includeAction={true}
                          key={r.pkreservations}
                          title={r.roomname}
                          subtitle2={t("Site") + " " + r.sitename}
                          footer={
                              <h5 className="card-subtitle1">
                              {r.shiftname}
                              <br />
                              {`${r.starttime} - ${r.endtime}`}
                              </h5>
                          }
                          mobile={true}
                          action={<FontAwesomeIcon className="white mt-2 pe-2" icon={faTimes} size="lg" onClick={() => deleteReservation(r)}/>}
                          />
                          :
                          <BoxCard
                          fullWidth={true}
                          includeAction={false}
                          key={r.pkreservations}
                          title={r.roomname}
                          subtitle2={t("Site") + " " + r.sitename}
                          footer={
                              <h5 className="card-subtitle1">
                              {r.shiftname}
                              <br />
                              {`${r.starttime} - ${r.endtime}`}
                              </h5>
                          }
                        />
                      ))
                    }
                  </Box>
                </div>
              </div>))
              : 
              <div className="container-fluid">
                <h4>{t("No reservations found")}</h4>
              </div>
            }
        </>
    )

}

export default UserReservationMobile;