import { Autocomplete, Button, Drawer, FormControl, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from 'classnames';
import moment, { Moment } from "moment";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import { AddNewLicense, UpdateLicense, getAllCustomersPkAndName, getAlllicenses, getCountLicenses } from '../../../core/store/superadmin/superadmin.actions';
import { License } from "../../../models/license";
import style from "./LicenseForm.module.scss";


export type LicenseFormProps = {
  selectedLicense: License | null
  type: "Edit" |"Add"
  open: boolean
  handleClose: () => void
  onlyActive: boolean
  nameFilter: string
};

const LicenseForm: React.FC<LicenseFormProps> = ({selectedLicense, type, open, handleClose,onlyActive,nameFilter}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [NameClient, setNameClient] = useState("")
  const [NumUtenze, setNumUtenze] = useState<number>(0)
  const [DataInizio, setDataInizio] = useState<Moment | string | null>(null);
  const [DataInizioEdit, setDataInizioEdit] = useState<Moment | string | null>(null);
  const [DataScadenza, setDataScadenza] = useState<Moment | string | null>(null);
  const [Note,setNote] =useState("")
  const { allCustomersPkAndName } = useSelector((state: RootState) => state.superadmin)

  useEffect(() => {
    dispatch(getAllCustomersPkAndName())
  }, [])

  const handleNameClientChange = (val: string) => {
    setNameClient(val)
  };

  const handleNumUtenzeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumUtenze(event.target.valueAsNumber)
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value)
  };

  const handleSave = () => {
    if(type === "Edit") {
      let tmp: License
      const castedStartDate = (DataInizio as Moment);
      const castedEndDate = (DataScadenza as Moment);
      tmp = {
        pklicenses:selectedLicense.pklicenses,
        fkcustomers: selectedLicense.fkcustomers,
        numusers: NumUtenze,
        startdate: moment(castedStartDate).format("YYYY-MM-DDTHH:mm:ss"),
        enddate: moment(castedEndDate).format("YYYY-MM-DDTHH:mm:ss") ,
        note:Note,
        isdeleted: selectedLicense.isdeleted,
        doi: selectedLicense.doi,
        dou:moment(Date.now())
      }
      let message : DialogMessageType = {
        dialogMsg :(<div className="msg">{t("You are trying to edit the license for")+" "+ (allCustomersPkAndName.length > 0) ? allCustomersPkAndName.find(c => c.pkcustomers == selectedLicense.fkcustomers).customername : ""} </div>),
        dialogTitle : t("Attention"),
        dialogAcceptButton: t("Yes"),
        dialogDismissButton: t("Cancel"),
        isOpen : true,
        onDismiss: () => {},
        onAccept: () => {
          dispatch(UpdateLicense(onlyActive,tmp,nameFilter));
          closeForm();
        }
      }
      dispatch(getDialogMessage(message));
    }

    if(type === "Add") {
      let tmp:License
      tmp = {
        pklicenses:0,
        fkcustomers: 0,
        numusers: NumUtenze,
        startdate: moment(DataInizio).format("YYYY-MM-DDTHH:mm:ss"),
        enddate: moment(DataScadenza).format("YYYY-MM-DDTHH:mm:ss"),
        note:Note,
        isdeleted:false,
        doi: moment(Date.now()),
        dou:moment(Date.now()),

      }
      let message : DialogMessageType = {
        dialogMsg :(<div>{t("Do you want to create this new License")}</div>),
        dialogTitle : t("New License insertion"),
        dialogAcceptButton: t("Yes create"),
        dialogDismissButton: t("No cancel"),
        isOpen : true,
        onDismiss: () => {},
        onAccept: () => {

          dispatch(AddNewLicense(tmp,NameClient,onlyActive,nameFilter));
          closeForm();
        }
      }
      dispatch(getDialogMessage(message));
    }
    dispatch(getAlllicenses(onlyActive,1,nameFilter))
    dispatch(getCountLicenses(onlyActive,nameFilter));
  }

  const cancelClicked = () => {
    let message : DialogMessageType = {
      dialogMsg :(<div>{t("Close From Alert")}</div>),
      dialogTitle : t("Attention"),
      dialogAcceptButton: t("Yes close"),
      dialogDismissButton: t("No go back"),
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {
        closeForm();
      }
    }
    dispatch(getDialogMessage(message));
  }

  const closeForm = () => {
    setNameClient("")
    setNumUtenze(0)
    handleClose()
  }

  useEffect(() => {
    if(selectedLicense !== null) {
      setNameClient((allCustomersPkAndName.length > 0) ? allCustomersPkAndName.find(c => c.pkcustomers == selectedLicense.fkcustomers).customername : "")
      setNumUtenze(selectedLicense.numusers)
      setDataInizio((selectedLicense.startdate) )
      setDataInizioEdit((selectedLicense.startdate) )
      setDataScadenza(selectedLicense.enddate)
      setNote(selectedLicense.note)
    } else if (selectedLicense === null) {
        setNameClient("")
        setNumUtenze(null)
        setDataInizio(null)
        setDataScadenza(null)
        setDataInizioEdit((null) )
        setNote("")
    }
  }, [selectedLicense])

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={type === "Add" ? cancelClicked : closeForm}
        className={style["MuiPaper-root"]}
      >
        <div className="container-fluid ps-4">
          <div className="row mt-4 mb-4">
            <div className="col">
              {type === "Edit" &&
                <h3 className="primary">{t("Edit License")}</h3>
              }
              {type === "Add" &&
                <h3 className="primary">{t("New License")}</h3>
              }

            </div>
          </div>
          <div className="row mb-4 w-100">
            <div className="col">
              {(type === "Add" || type === "Edit") &&
                <Autocomplete
                  value={NameClient}
                  onChange={(e: any, val: string) => handleNameClientChange(val)}
                  size="small"
                  renderInput={(params) => <TextField {...params} label="Cliente" />}
                  options={allCustomersPkAndName ? allCustomersPkAndName.map(c => c.customername) : []}
                  fullWidth
                />
              }
            </div>
          </div>
          {(type === "Add" || type === "Edit") &&
            <div className="row w-100">
              <div className="col-12 mb-4">
                <TextField
                  value={NumUtenze}
                  onChange={handleNumUtenzeChange}
                  size="small"
                  label={t("Number of Users")}
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              <div className="col-6 mb-3 d-flex">
              <FormControl sx={{width:"100%"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label={t("From day")+"*"}
                                value={DataInizio}
                                onChange={(date)=> setDataInizio(date)}
                                inputFormat="DD/MM/yyyy"
                                minDate={moment(DataInizioEdit) < moment(Date.now()) ? null : moment(Date.now())}
                                disabled={moment(DataInizioEdit) != null && moment(DataInizioEdit) < moment(Date.now())}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                </div>
                <div className="col-6 mb-3 d-flex">
              <FormControl sx={{width:"100%"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label={t("Expiration Date")+"*"}
                                value={DataScadenza}
                                onChange={(date)=> setDataScadenza(date)}
                                inputFormat="DD/MM/yyyy"
                                minDate={DataInizio}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>

                </div>
                <div className="col-12 mb-4">
                <TextField
                  value={Note}
                  onChange={handleNoteChange}
                  label="Note"
                  maxRows={3}
                  fullWidth
                />
              </div>

              </div>

          }
          <div className={cn("row", style["buttons"])}>
            <div className="col">
              <Button
                variant="contained"
                onClick={type === "Add" ? cancelClicked : closeForm}
                color="secondary"
                className={style["secondaryBtn"]}>
                {t("Cancel")}
              </Button>
              <Button
                color={"primary"}
                variant="contained"
                onClick={handleSave}
                className="ms-2">
                {type === "Edit" && t("Save")}
                {type === "Add" && t("Insert")}

              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default LicenseForm;