import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Switch, TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from 'classnames';
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createCustomerSettings, getCustomerSettings, updateActiveCustomerSettings, updateLimitDaysCustomerSettings, updateLimitIdentifyCustomerSettings } from "../../core/store/customer/settings-customer/settings-customer.action";
import { createCustomerSettingsProfiles, deleteCustomerSettingsProfiles, getCustomerSettingsProfiles, updateLimitDaysCustomerForProfileSettings, updateLimitIdentifyCustomerSettingsProfiles } from "../../core/store/customer/settings-profiles-customer/settings-profiles-customer.action";
import { RootState } from "../../core/store/store";
import { getUsrGroups } from '../../core/store/users/users.actions';
import { UsrGroup } from "../../models/user";
import style from "./reservations-management-limits.module.scss";

export const ReservationsManagementLimits: FC = () =>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { usrgroups } = useSelector((state: RootState) => state.users);
    const { user } = useSelector((state: RootState) => state.auth)
    const [ limitReservationSelected, setLimitReservationSelected ] = useState<number>()
    const [ limitReservationForProfileSelected, setLimitReservationForProfileSelected ] = useState<number>()
    const [ limitReservationSettedSelected, setLimitReservationSettedSelected ] = useState<number>()
    const [isActive, setIsActive] = useState<boolean>()
    const { customerSettings } = useSelector((state:RootState)=> state.customerSettings);
    const { customerSettingsProfiles } = useSelector((state:RootState)=> state.customerSettingsProfiles);
    const [ menuSetted ] = useState<string[]>([t("Set limit"),t("No limits")])
    const [ menuOption ] = useState<string[]>([t("Next week"),t("Next month"),t("Set custom limit")])
    const [ menuSettedIsOpen, setMenuSettedIsOpen] = useState<boolean>(false);
    const [ menuForProfileIsOpen, setMenuForProfileIsOpen] = useState<boolean>(false);
    const [ menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const [ hiddenLimitDaysForProfile, setHiddenLimitDaysForProfile] = useState<boolean>(true);
    const [ selectHidden, setSelectHidden] = useState<boolean>(true);
    const [ usrGroupsReoder, setusrGroupsReorder] = useState<UsrGroup[]>(null);
    const [ pkUsrGroupsChecked, setPkUsrGroupsChecked ] = useState<number[]>([]);
    const [ selectedDays, setSelectedDays] = useState<number>(0);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 450,
            minWidth: 450,
          },
        },
    };
    const MenuSettedProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
            minWidth: 250,
          },
        },
    };

    useEffect(() => {
        dispatch(getUsrGroups())
        dispatch(getCustomerSettings(user.fkcustomers));
        dispatch(getCustomerSettingsProfiles(user.fkcustomers));
        setPkUsrGroupsChecked([]);
        setLimitReservationSettedSelected(0);
        setLimitReservationForProfileSelected(0);
      }, [dispatch])

      useEffect(() => {
        let usrreord = structuredClone(usrgroups);
        setusrGroupsReorder(usrreord.sort((a, b) => a.nome > b.nome ? 1 : -1));
      }, [usrgroups])


      useEffect(()=>{
          dispatch(getCustomerSettings(user.fkcustomers));
          dispatch(getCustomerSettingsProfiles(user.fkcustomers));
      },[user])

    const handleLimitDaysReservationChanged = event => {
        dispatch(updateLimitDaysCustomerSettings(customerSettings.pkcustomerssettings,event.target.value))
    }
    const daysChosen = event => {
        setSelectedDays(event.target.value);
    }
    const handleLimitDaysReservationForProfileChanged = () => {
        let customerSettingProfilesToUpdate = [];
        let customerSettingProfilesToInsert = [];
        pkUsrGroupsChecked.map((pkusrgrpck) =>
        customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck) != undefined ?
            customerSettingProfilesToUpdate.push(customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck).pkcustomerssettingsprofiles) : customerSettingProfilesToInsert.push(pkusrgrpck)
        )
        if(customerSettingProfilesToInsert.length > 0){
            dispatch(createCustomerSettingsProfiles(user.fkcustomers,customerSettingProfilesToInsert,3,selectedDays))
        }
        if(customerSettingProfilesToUpdate.length > 0){
            dispatch(updateLimitDaysCustomerForProfileSettings(user.fkcustomers,customerSettingProfilesToUpdate,selectedDays))
        }
        setSelectHidden(true)
        setHiddenLimitDaysForProfile(true);
        setPkUsrGroupsChecked([]);
        setLimitReservationSettedSelected(0);
        setLimitReservationForProfileSelected(0);
    }

    const handleOpenMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    const handleOpenSettedMenu = () => {
        if(pkUsrGroupsChecked.length != 0){
            setMenuSettedIsOpen(!menuSettedIsOpen);
        }
    };

    const handleOpenMenuForProfile = () => {
        if(pkUsrGroupsChecked.length != 0){
            setMenuForProfileIsOpen(!menuForProfileIsOpen);
        }
    };

    useEffect(()=>{
        if(customerSettings != null){
            setLimitReservationSelected(customerSettings.limitidentify);
            setIsActive(customerSettings.limitisactive)
        }else{
            setLimitReservationSelected(0);
            setIsActive(false)
        }
    },[customerSettings])

    const handleChangeSelect = event => {
        setMenuIsOpen(false);
        setLimitReservationSelected(event.target.value);
        dispatch(updateLimitIdentifyCustomerSettings(customerSettings.pkcustomerssettings,event.target.value))
    };

    const handleChangeSettedSelect = event => {
        setMenuSettedIsOpen(false);
        setLimitReservationSettedSelected(event.target.value);
        if(event.target.value == 2){
            setSelectHidden(true)
            let pkCustomerSettingProfilesToDelete = [];
            if(customerSettingsProfiles != null){
                pkUsrGroupsChecked.map((pkusrgrpck) =>
                    customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck) != undefined ?
                    pkCustomerSettingProfilesToDelete.push(customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck).pkcustomerssettingsprofiles) : ""
                )
            }
            if(pkCustomerSettingProfilesToDelete.length > 0){
                dispatch(deleteCustomerSettingsProfiles(pkCustomerSettingProfilesToDelete,user.fkcustomers));
            }
            setSelectHidden(true)
            setHiddenLimitDaysForProfile(true);
            setPkUsrGroupsChecked([]);
            setLimitReservationSettedSelected(0);
            setLimitReservationForProfileSelected(0);
        }else{
            setSelectHidden(false)
        }
    };

    const handleChangeForProfileSelect = event => {
        setMenuForProfileIsOpen(false);
        setLimitReservationForProfileSelected(event.target.value);

        if(event.target.value == 3){
            setHiddenLimitDaysForProfile(false);
        }else{
            let customerSettingProfilesToUpdate = [];
            let customerSettingProfilesToInsert = [];
            if(customerSettingsProfiles == null){
                customerSettingProfilesToInsert = pkUsrGroupsChecked;
            }else{
                pkUsrGroupsChecked.map((pkusrgrpck) =>
                    customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck) == undefined ?
                        customerSettingProfilesToInsert.push(pkusrgrpck) : customerSettingProfilesToUpdate.push(customerSettingsProfiles.find((c) => c.fkusrgroups == pkusrgrpck).pkcustomerssettingsprofiles)
                )
            }// se è null non esiste ancora nessun setting profilo, quindi sicuramente sono tutti da inserire, altrimenti controllo se ci sta o no
            if(customerSettingProfilesToInsert.length > 0){
                dispatch(createCustomerSettingsProfiles(user.fkcustomers,customerSettingProfilesToInsert,event.target.value,1))
            }
            if(customerSettingProfilesToUpdate.length > 0){
                dispatch(updateLimitIdentifyCustomerSettingsProfiles(user.fkcustomers,customerSettingProfilesToUpdate,event.target.value))
            }
                setSelectHidden(true)
                setHiddenLimitDaysForProfile(true);
                setPkUsrGroupsChecked([]);
                setLimitReservationSettedSelected(0);
                setLimitReservationForProfileSelected(0);
        }
    };

    function ActivateLimit(isActive: boolean) {
        if(limitReservationSelected == 0){
            setLimitReservationSelected(1);
            dispatch(createCustomerSettings(user.fkcustomers,isActive));
        }else{
            dispatch(updateActiveCustomerSettings(customerSettings.pkcustomerssettings,isActive));
            setIsActive(isActive)
        }
    }

    function handleChangeCheckedUsrGroups(pkuser:number){
        let pkusrgrops = pkUsrGroupsChecked;
        if(pkusrgrops.findIndex(pk => pk === pkuser) >= 0){
            pkusrgrops = pkusrgrops.filter((pk) => pk !== pkuser)
        }else{
            pkusrgrops.push(pkuser)
            pkusrgrops = pkusrgrops.filter((pk) => pk !== null)
        }
        setPkUsrGroupsChecked(pkusrgrops);
    }

    const textForm = <div>
                            {limitReservationSelected === 3 ?
                                <div>
                                    <TextField
                                        name="limitdays"
                                        defaultValue={customerSettings  ? customerSettings.limitdays : 1}
                                        label={t("LimitDays")}
                                        onBlur={(e) => {
                                            handleLimitDaysReservationChanged(e)
                                        }}
                                        size="small"
                                        fullWidth
                                        type="tel"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        className="mt-4"
                                    />
                                </div> :
                                <div></div> }
                                <div className="ms-1">
                                    <small>
                                    {limitReservationSelected == 1 ? t("BookableWeek") :
                                     limitReservationSelected == 2 ? t("BookableMonth") :
                                                                     t("BookableDays").replace("X",""+ (customerSettings ? customerSettings.limitdays : 1))
                                    }
                                    {isActive ? "": "("+t("NotActive").toUpperCase()+")"}
                                    </small>
                                </div>
                     </div>;

    const neverActivateForm = <div>{t("No limits set")}</div>;

    const activeForm = <>
                            <div>
                                <FormControl className='w-100'>
                                    <Select
                                    value={limitReservationSelected ?? null}
                                    renderValue={() => menuOption[limitReservationSelected-1]}
                                    MenuProps={MenuProps}
                                    className={cn(style["sizeinput"])}
                                    data-joyride="user-profile"
                                    onClick={handleOpenMenu}
                                    open={menuIsOpen}
                                    >
                                    {
                                        <div>
                                            {menuOption.map((option,index) => (
                                                <MenuItem onClick={handleChangeSelect} value={index+1}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    }
                                    </Select>
                                    {textForm}
                                </FormControl>
                            </div>
                        </>;

    const LimitedReservation =  <>
                                    <Card sx={{ height: '31.8vh'}} className="mt-2 ms-1 me-2">
                                        <CardContent>
                                            <div className="d-flex">
                                                <h5 className={cn(style["title"],"mt-1")}>{t("ReservationLimit")}</h5>
                                                <FormControlLabel control={
                                                    <Switch
                                                        checked={isActive}
                                                        onChange={() => ActivateLimit(!isActive) }
                                                    />
                                                }
                                                    label={ (isActive ? t("Active").toString() : t("Deactivate").toString()) }
                                                    labelPlacement="start"
                                                    className="ms-auto me-1"
                                                />
                                            </div>
                                            { !isActive && limitReservationSelected === 0 ?
                                                neverActivateForm
                                                :
                                                activeForm
                                            }
                                        </CardContent>
                                    </Card>
                                </>;

function SwitchLimitIdentify(limitidentify,limitdays) {
    switch(limitidentify) {
        case 1:
         return t("Next week");
        case 2:
         return t("Next month");
        case 3:
         return limitdays > 1 ? limitdays + " " + t("Days") : limitdays + " " + t("Day");
        default:
         return  t("Not set");
    }
}

const LimitedReservationForUser =  <>
                                <Card sx={{ height: '84.5vh',overflowY: "scroll"}} className="mt-2 ms-1 me-2">
                                    <CardContent>
                                        <div className={cn("position-relative")}>
                                            <h5 className={cn(style["title"],"mt-1")}>{t("Reservations limits user")}</h5>
                                            {usrGroupsReoder != null && usrGroupsReoder.length > 0 && usrGroupsReoder.map((ug,index) => (
                                                <div className={cn(style["borderforprofiles"])}>
                                                    <FormGroup  className={cn("d-inline")}>
                                                        <FormControlLabel
                                                        value={ug.pkusrgroups}
                                                        control={
                                                                <Checkbox value={ug.pkusrgroups} onChange={(e) => handleChangeCheckedUsrGroups(Number(e.target.value))} checked={pkUsrGroupsChecked.findIndex(ugc => ugc === ug.pkusrgroups) >= 0} />
                                                            }
                                                            label={<><b>{ug.nome}</b></>}
                                                            className={cn(style["paddingforprofiles"])}
                                                        />
                                                        <p className={cn(style["setted"])}>{customerSettingsProfiles != null && customerSettingsProfiles.find((c) => c.fkusrgroups == ug.pkusrgroups) != undefined
                                                            ? SwitchLimitIdentify(customerSettingsProfiles.find((c) => c.fkusrgroups == ug.pkusrgroups).limitidentify,customerSettingsProfiles.find((c) => c.fkusrgroups == ug.pkusrgroups).limitdays)
                                                            : t("Not set")}</p>
                                                    </FormGroup>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <Select
                                                value={limitReservationSettedSelected ?? null}
                                                renderValue={() => menuSetted[limitReservationSettedSelected-1]}
                                                MenuProps={MenuSettedProps}
                                                className={cn(style["sizeinputSetted"])}
                                                onClick={handleOpenSettedMenu}
                                                disabled={pkUsrGroupsChecked.length == 0}
                                                open={menuSettedIsOpen}
                                                >
                                                {
                                                    <div>
                                                        {menuSetted.map((option,index) => (
                                                            <MenuItem onClick={handleChangeSettedSelect} value={index+1}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </div>
                                                }
                                            </Select>
                                            <Select
                                                value={limitReservationForProfileSelected ?? null}
                                                renderValue={() => menuOption[limitReservationForProfileSelected-1]}
                                                MenuProps={MenuProps}
                                                className={cn(style["sizeinputSetted"])}
                                                onClick={handleOpenMenuForProfile}
                                                hidden={selectHidden}
                                                open={menuForProfileIsOpen}
                                                >
                                                {
                                                    <div>
                                                        {menuOption.map((option,index) => (
                                                            <MenuItem onClick={handleChangeForProfileSelect} value={index+1}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </div>
                                                }
                                            </Select>
                                            <div className={cn(style["divinputDays"])}>
                                                <TextField
                                                    name="limitdaysforprofile"
                                                    defaultValue={1}
                                                    label={t("LimitDays")}
                                                    onBlur={(e) => {
                                                        daysChosen(e)
                                                    }}
                                                    size="small"
                                                    type="tel"
                                                    hidden={hiddenLimitDaysForProfile}
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    className={cn(style["sizeinputDays"])}
                                                />
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleLimitDaysReservationForProfileChanged()}
                                                    hidden={hiddenLimitDaysForProfile}
                                                    className={cn(style["button-primary"], style["button-overlay"],"ms-2")}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </>;

    return(
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {
                <div className="row mt-2">
                    <div style={{ paddingLeft: 30}} className="col-8" >
                        {LimitedReservationForUser}
                    </div>
                    <div className={cn(style["limitedReservationCard"],"col-4")}>
                        {(user.functionalities.findIndex(f => f.nome === "Set_Reservation_Limits") >= 0 ? LimitedReservation : <></>)}
                    </div>
                </div>
            }
       </LocalizationProvider>
    );
}