import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DialogMessageType } from '../dialog-message';


interface DialogMessageSliceType {
    dialogMessage: DialogMessageType
}

export const dialogMessageSlice = createSlice({
    name: "dialog-message",
    initialState: {
        dialogMessage: {
            isOpen: false,
            dialogMsg: null,
            dialogTitle: null,
            dialogDate:"",
            dialogSubmsg: null,
            dialogAcceptButton: "",
            dialogDismissButton: "",
        }
    } as DialogMessageSliceType,
    reducers: {
        getDialogMessage: (state, action: PayloadAction<DialogMessageType>) => {
            state.dialogMessage = action.payload
        },
        closeDialogMessage: (state) => {
            state.dialogMessage = dialogMessageSlice.getInitialState().dialogMessage
        }
    }
})

export const {
    getDialogMessage,
    closeDialogMessage
} = dialogMessageSlice.actions