import style from './exploreRooms.module.scss'
import cn from "classnames"
import { useEffect, useState } from 'react'
import { Tabs, Tab, InputAdornment, Card, CardContent, TextField, Tooltip, styled, TooltipProps, tooltipClasses, Button} from "@mui/material"
import {TabPanel} from "@mui/lab"
import TabContext from '@mui/lab/TabContext'
import { useTranslation } from "react-i18next/"
import FormControl from "@mui/material/FormControl"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from '../../../core/store/store'
import SearchIcon from '@mui/icons-material/Search'
import {Link} from "react-router-dom";
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRoomSuccess, setSelectedTab } from '../../../core/store/room/user-room/user-room.store'
import { faStar as starEmpty} from '@fortawesome/pro-regular-svg-icons'
import { faStar as starFull} from '@fortawesome/pro-solid-svg-icons'
import { AddRoomToFavorites, RemoveRoomFromFavorites } from '../../../core/store/room/user-room/user-room.actions';
import { Room } from '../../../models/room'

const ExploreRooms: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { userSites, selectedTab } = useSelector((state: RootState) => state.room.userRoom)
  const meUser = useSelector((state: RootState) => state.auth.user)  
  const [tab, setTab] = useState<string>("0")
  const [filter, setFilter] = useState<string>("");
  const [allRooms, setAllRooms] = useState<Room[]>([]);
   
  const handleTabChange = (event, value) => {
    setFilter("")
    setTab(value)
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  function removeRoomFromFavorites(pkroom: number) {
    dispatch(RemoveRoomFromFavorites(meUser.pkusers, pkroom))
  }

  function addRoomToFavorites(pkroom: number) {
    dispatch(AddRoomToFavorites(meUser.pkusers, pkroom))
  }

  function roomCompare (a: Room, b: Room) {
    if(a.isfavorite && !b.isfavorite) return -1;
    if(a.isfavorite && b.isfavorite) return 0;
    return 1;
  }

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        backgroundColor: 'transparent',
        padding: '0'
      },
  });

  useEffect(() => {
    setAllRooms([])
    let rooms = []
    userSites.forEach(us => rooms.push(...us.rooms))
    setAllRooms(rooms)
  }, [userSites])

  return (
    <Card className="ms-1 me-2">
      <CardContent>
        <div className="row">
        <div className="col-12">
          <div className="row ms-2">
            <div className="col">
              <h5 className="primary">{t("Explore")}</h5>
            </div>
          </div>
          <TabContext value={tab}>
            <div>
              <div>
                <Tabs 
                  className="ms-2"
                  value={tab} 
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  >
                    <Tab key={0} value={"0"} label={t("All sites")}></Tab>
                    {userSites && userSites.filter(us => us.parent === 0).map((s) => (
                      <Tab key={s.pksites} value={s.pksites.toString()} label={s.sitename}></Tab>
                    ))}
                </Tabs>
              </div>
                <div className="row mt-3 ms-2">
                  <div className="col ps-0">
                    <div className="row">
                      <div className="col">
                        <TextField
                          value={filter}
                          onChange={handleFilterChange}
                          label={t("Search room")}
                          hidden={tab !== "0"}
                          InputProps={{
                            endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
                          }}
                          size="small"
                        />   
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col-12">
                        <FormControl className="w-100">
                          <TabPanel key={0} value={"0"} className="ps-0 pb-2 pe-0">
                            <div className="row">
                              {
                                allRooms.length > 0 && allRooms.filter((r) => r.roomname && r.roomname.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => roomCompare(a, b)).map((r) => (
                                  <div className="col-xl-4 col-lg-4 col-md-4 pe-0 d-flex align-items-stretch">
                                    <CustomWidthTooltip 
                                      disableInteractive
                                      placement='left'
                                      enterDelay={1000}
                                      title={(r.roomattachment && r.roomattachment.length > 0)?
                                      (<>
                                        {
                                          r.roomdescription && r.roomdescription.length > 0 ?
                                          <div className={cn('d-flex flex-column', style["bordi-tondi"])} style={{ boxShadow: "0px 3px 4px #00000042" }}>
                                            <div className={cn(style["rounded-img"])} style={{ width: "400px", height: "300px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')" }}></div>
                                            <div className={cn(style["rounded-div"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "white", maxWidth: "400px" }}>
                                              <p className={cn(style["rounded-p"], "ms-2 mb-2 mt-2 me-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                            </div>
                                          </div>
                                          : 
                                          <div style={{width: "400px", height: "300px", borderRadius: "8px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')"}}></div>
                                        }
                                      </>) : 
                                      (r.roomdescription && r.roomdescription.length > 0 ? (
                                        <>
                                          <div className={cn(style["bordi-tondi"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "#FFFFFF", maxWidth: "400px", boxShadow: "0px 3px 4px #00000042" }}>
                                            <p className={cn(style["bordi-tondi"], "ps-2 pt-2 pe-2 pb-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                          </div>
                                        </>
                                      ) : "")}
                                    >
                                      <Card 
                                        key={r.pkrooms} 
                                        className={cn("w-100", style["roomCard"], "bgc-primary", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                        sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                        onClick={()=>{dispatch(setSelectedTab(tab)); dispatch(getRoomSuccess(r))}}
                                        >
                                        <Button 
                                          className={cn(style["fav-btn"], "pb-2")} 
                                          style={{color: r.isfavorite ? "yellow" : "white"}}
                                          disableRipple 
                                          disableFocusRipple
                                          onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                            <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="1x" className={style["star-icon"]} />
                                        </Button>
                                        <Link 
                                          to={{
                                            pathname: "/reserve-seat",
                                          }}                                      
                                        style={{  textDecoration: "none", color:"white", position:"relative"}}
                                        className="h-100"
                                        >
                                        <div className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                          <div className={cn("col d-flex ps-2 pe-2 pb-2 pt-1", style["bg-room"])}>
                                            {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                              <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                            </button>}
                                            <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                          </div>
                                        </div>
                                        </Link>
                                      </Card>
                                    </CustomWidthTooltip>
                                  </div>
                                ))
                              }
                            </div>
                          </TabPanel>
                          {userSites && userSites.filter(us => us.parent === 0).map((s) => ((s.rooms && s.rooms.length > 0) || userSites.findIndex(us => us.parent === s.pksites && us.rooms && us.rooms.length > 0) >= 0) ? (
                            <TabPanel key={s.pksites} value={s.pksites.toString()} className="ps-0 pt-1 pb-2 pe-0">
                              <div className="row">
                                {
                                  s.rooms.slice().sort((a, b) => roomCompare(a, b)).map((r) => (
                                    <div className="col-xl-4 col-lg-4 col-md-4 pe-0 d-flex align-items-stretch">
                                      <CustomWidthTooltip 
                                        disableInteractive
                                        placement='left'
                                        enterDelay={1000}
                                        title={(r.roomattachment && r.roomattachment.length > 0)?
                                        (<>
                                          {
                                            r.roomdescription && r.roomdescription.length > 0 ?
                                            <div className={cn('d-flex flex-column', style["bordi-tondi"])} style={{ boxShadow: "0px 3px 4px #00000042" }}>
                                              <div className={cn(style["rounded-img"])} style={{ width: "400px", height: "300px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')" }}></div>
                                              <div className={cn(style["rounded-div"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "white", maxWidth: "400px" }}>
                                                <p className={cn(style["rounded-p"], "ms-2 mb-2 mt-2 me-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                              </div>
                                            </div>
                                            : 
                                            <div style={{width: "400px", height: "300px", borderRadius: "8px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')"}}></div>
                                          }
                                        </>) : 
                                        (r.roomdescription && r.roomdescription.length > 0 ? (
                                          <>
                                            <div className={cn(style["bordi-tondi"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "#FFFFFF", maxWidth: "400px", boxShadow: "0px 3px 4px #00000042" }}>
                                              <p className={cn(style["bordi-tondi"], "ps-2 pt-2 pe-2 pb-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                            </div>
                                          </>
                                        ) : "")}
                                      >
                                      <Card 
                                        key={r.pkrooms} 
                                        className={cn("w-100", style["roomCard"], "bgc-primary", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                        sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                        onClick={()=>{dispatch(setSelectedTab(tab)); dispatch(getRoomSuccess(r))}}
                                      >
                                        <Button 
                                          className={cn(style["fav-btn"], "pb-2")} 
                                          style={{color: r.isfavorite ? "yellow" : "white"}}
                                          disableRipple 
                                          disableFocusRipple
                                          onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                            <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="1x" className={style["star-icon"]} />
                                        </Button>
                                        <Link to={{
                                          pathname: "/reserve-seat",
                                        }}
                                        style={{  textDecoration: "none", color:"white", position:"relative"}}
                                        className="h-100"
                                        >                                                                               
                                        <div className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                          <div className={cn("col d-flex ps-2 pe-2 pb-2 pt-1", style["bg-room"])}>

                                            {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                              <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                            </button>}
                                            <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                          </div>
                                         </div>
                                        </Link>
                                      </Card>
                                      </CustomWidthTooltip>
                                    </div>
                                  ))
                                }
                              </div>
                              {
                                userSites.filter(us => us.parent === s.pksites && us.rooms && us.rooms.length > 0).map(s => (
                                  <div>
                                    <h5 className="primary">{s.sitename}</h5>
                                    <hr className={style["divider"]} />
                                    {
                                      s.rooms.map(r => 
                                      <div className="col-xl-4 col-lg-4 col-md-4 pe-0 d-flex align-items-stretch">
                                        <CustomWidthTooltip 
                                          disableInteractive
                                          placement='left'
                                          enterDelay={1000}
                                          title={(r.roomattachment && r.roomattachment.length > 0)?
                                          (<>
                                            {
                                              r.roomdescription && r.roomdescription.length > 0 ?
                                              <div className={cn('d-flex flex-column', style["bordi-tondi"])} style={{ boxShadow: "0px 3px 4px #00000042" }}>
                                                <div className={cn(style["rounded-img"])} style={{ width: "400px", height: "300px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')" }}></div>
                                                <div className={cn(style["rounded-div"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "white", maxWidth: "400px" }}>
                                                  <p className={cn(style["rounded-p"], "ms-2 mb-2 mt-2 me-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                                </div>
                                              </div>
                                              : 
                                              <div style={{width: "400px", height: "300px", borderRadius: "8px", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundImage: "url('data:image/jpeg;base64,"+r.roomattachment+"')"}}></div>
                                            }
                                          </>) : 
                                          (r.roomdescription && r.roomdescription.length > 0 ? (
                                            <>
                                              <div className={cn(style["bordi-tondi"])} style={{ backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", backgroundColor: "#FFFFFF", maxWidth: "400px", boxShadow: "0px 3px 4px #00000042" }}>
                                                <p className={cn(style["bordi-tondi"], "ps-2 pt-2 pe-2 pb-2")} style={{ color: "#0A0A0A", fontSize: "15px", wordBreak: "break-word" }}>{r.roomdescription}</p>
                                              </div>
                                            </>
                                          ) : "")}
                                        >
                                        <Card 
                                          key={r.pkrooms} 
                                          className={cn("w-100", style["roomCard"], "bgc-primary", ((r.fkattachments && r.fkattachments !== 0) && (!r.roomattachment || r.roomattachment === ""))?style["shimmer"]:"", "position-relative")}
                                          sx={(r.roomattachment !== undefined || r.roomattachment !== "")?{backgroundImage:"url('data:image/jpeg;base64,"+r.roomattachment+"')"}:{}}
                                          onClick={()=>{dispatch(setSelectedTab(tab)); dispatch(getRoomSuccess(r))}}
                                        >
                                          <Button 
                                          className={cn(style["fav-btn"], "pb-2")} 
                                          style={{color: r.isfavorite ? "yellow" : "white"}}
                                          disableRipple 
                                          disableFocusRipple
                                          onClick={() => r.isfavorite ? removeRoomFromFavorites(r.pkrooms) : addRoomToFavorites(r.pkrooms)}>
                                            <FontAwesomeIcon icon={r.isfavorite ? starFull : starEmpty} size="1x" className={style["star-icon"]} />
                                          </Button>
                                          <Link to={{
                                          pathname: "/reserve-seat",
                                          }}
                                          style={{  textDecoration: "none", color:"white", position:"relative"}}
                                          className="h-100"
                                          >
                                            <div className={cn("row w-100 mt-2 me-0 ms-0 align-items-end", style["h-70"])}>
                                            <div className={cn("col d-flex ps-2 pe-2 pb-2 pt-1", style["bg-room"])}>

                                              {r.iconname && <button className={cn(style["font-button-no-action"], "me-2")} >
                                                <FontAwesomeIcon icon={[r.iconname.split(" ")[0] as IconPrefix, r.iconname.split(" ")[1] as IconName]} />
                                              </button>}
                                              <p className={r.iconname ? "mb-0" : "mb-1 ms-2"}>{r.roomname}</p> 

                                            </div>
                                            </div>
                                          </Link>
                                        </Card>
                                        </CustomWidthTooltip>
                                      </div>)
                                    }
                                </div>))
                              }
                            </TabPanel>
                          ): null)}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </TabContext>
        </div>
      </div>
      </CardContent>
    </Card>
  );
}

export default ExploreRooms