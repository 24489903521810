import { interceptor } from "../../../../interceptors/.interceptor";
import { HttpHandler } from "../../../../models/http-interceptor";
import { RequestResponse } from '../../../../models/requestResponse';
import { AppThunk } from "../../store";
import { getCustomerSettingsProfilesSuccess } from "./settings-profiles-customer.store";


export const getCustomerSettingsProfiles = (fkcustomers:number): AppThunk => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/CustomerSettingsProfiles/v1/getcustomersettingsprofiles/${fkcustomers}`,
      dispatch,
      auth: true,
      baseurl: false,
    };
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getCustomerSettingsProfilesSuccess(res.customerSettingsProfiles))
      });
    } catch {}
}

export const createCustomerSettingsProfiles = (fkcustomers:number,pkUsrGroupsChecked:number[],limitidentify: number,limitdays: number): AppThunk => (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettingsProfiles/v1/createcustomersettingsprofiles/${fkcustomers}/${limitidentify}/${limitdays}`,
    dispatch,
    httpOptions: {
      data: pkUsrGroupsChecked,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      dispatch(getCustomerSettingsProfiles(fkcustomers));
    });
  } catch {}
}

export const deleteCustomerSettingsProfiles = (pkCustomerSettingProfilesToDelete:number[],fkcustomers): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_DELETE",
    url: `/CustomerSettingsProfiles/v1/deletecustomersettingsprofiles`,
    dispatch,
    httpOptions: {
      data: pkCustomerSettingProfilesToDelete,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getCustomerSettingsProfiles(fkcustomers));
    });
  } catch {}
}

export const updateLimitIdentifyCustomerSettingsProfiles = (fkcustomers,pkcustomersettingsprofiles:number[],limitidentify:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettingsProfiles/v1/updatelimitidentifycustomersettingsprofiles/${limitidentify}`,
    dispatch,
    httpOptions: {
      data: pkcustomersettingsprofiles,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getCustomerSettingsProfiles(fkcustomers));
    });
  } catch {}
}

export const updateLimitDaysCustomerForProfileSettings = (fkcustomers:number,pkcustomersettings:number[],limitdays:number): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_POST",
    url: `/CustomerSettingsProfiles/v1/updatelimitdayscustomerforprofilesettings/${limitdays}`,
    dispatch,
    httpOptions: {
      data: pkcustomersettings,
    },
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(getCustomerSettingsProfiles(fkcustomers));
    });
  } catch {}
}