import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Room, RoomForUpdate, RoomSite } from "../../../../models/room";
import { Site } from '../../../../models/site';

interface AdministrationRoomStore {
  selectedRoom: Room | undefined;
  roomForUpdate: RoomForUpdate;
  sitesRooms: Room[];
  adminSites: Site[];
  oldPkAttachment: number;
  CustomerAllRooms:RoomSite[];
  adminSitesChanged: boolean;
  imageHasLoaded: boolean;
}

export const administrationRoomSlice = createSlice({
  name: "room/administration",
  initialState: {
    selectedRoom: undefined,
    roomForUpdate: {},
    sitesRooms: [],
    oldPkAttachment: 0,
    adminSites: [],
    CustomerAllRooms:[],
    adminSitesChanged: false,
    imageHasLoaded: false
  } as AdministrationRoomStore,
  reducers: {
    editSiteNameSuccess: (state, action: PayloadAction<Site>) => {
      const index = state.adminSites.findIndex((g) => g.pksites === action.payload.pksites);
      action.payload.rooms = state.adminSites[index].rooms;
      state.adminSites[index] = action.payload;
    },
    insertSiteSuccess: (state, action: PayloadAction<Site>) => {
      state.adminSites.push(action.payload);
    },
    deleteSiteSuccess: (state, action: PayloadAction<number>) => {
      state.adminSites = state.adminSites.filter(s=> s.pksites !== action.payload);
    },
    deleteRoomSuccess: (state, action: PayloadAction<number>) => {
      let tmpAdminSites = state.adminSites;
      tmpAdminSites.forEach(as=> {
        as.rooms = as.rooms.filter(ar=> ar.pkrooms !== action.payload)
      });

      state.adminSites = tmpAdminSites;
    },
    insertRoomSuccess: (state, action: PayloadAction<Room>) => {
      const index = state.adminSites.findIndex(s=>s.pksites === action.payload.fksites);
      if(state.adminSites[index].rooms === null || state.adminSites[index].rooms === undefined){
        state.adminSites[index].rooms = [action.payload];
      }else{
        state.adminSites[index].rooms.push(action.payload);
      }
    },
    updateRoomSuccess: (state, action: PayloadAction<Room>) => {
      const siteIndex = state.adminSites.findIndex(s=>s.pksites === action.payload.fksites);
      const roomIndex = state.adminSites[siteIndex].rooms.findIndex(r=> r.pkrooms === action.payload.pkrooms);

      // console.log({siteIndex, roomIndex});

      //CASO SITE CAMBIATO
      if(roomIndex === -1){
        //RIMUOVO LA STANZA DA QUALSIASI SITE
        state.adminSites.forEach(s=>{
          s.rooms = s.rooms.filter(r=> r.pkrooms !== action.payload.pkrooms);
        });

        //LA AGGIUNGO AL SITE CORRETTO
        const newSite = state.adminSites.findIndex(s=>s.pksites === action.payload.fksites);
        state.adminSites[newSite].rooms.push(action.payload);
      }else{
        state.adminSites[siteIndex].rooms[roomIndex] = action.payload;
      }
    },
    getRoomForUpdateSuccess: (state, action: PayloadAction<RoomForUpdate>) => {
      state.roomForUpdate = action.payload;
    },
    resetRoomForUpdateSuccess: (state) => {
      state.roomForUpdate = undefined;
    },
    getSelectedRoomSuccess: (state, action: PayloadAction<Room | undefined>) => {
      state.selectedRoom = action.payload;
    },
    removeAttachmentSuccess: (state) => {
      let oldRoomForUpdate = state.roomForUpdate;
      if(oldRoomForUpdate.room?.fkattachments !== 0){
        state.oldPkAttachment = oldRoomForUpdate.room.fkattachments;
      }
      oldRoomForUpdate.room.fkattachments = 0;
      oldRoomForUpdate.room.roomattachment = undefined;

      state.roomForUpdate = oldRoomForUpdate;
    },
    getAdminSitesSuccess: (state, action: PayloadAction<Site[]>) => {
      state.adminSites = action.payload;
    },
    getCustomerAllRoomsSuccess: (state, action: PayloadAction<RoomSite[]>) => {
      state.CustomerAllRooms = action.payload;
    },
    setOldPkAttachmentSuccess: (state, action: PayloadAction<number>) =>{
      state.oldPkAttachment = action.payload;
    },
    resetOldPkAttachmentSuccess: (state) => {
      state.oldPkAttachment = 0;
    },
    getAdminSitesChangedSuccess: (state, action: PayloadAction<boolean>) => {
      state.adminSitesChanged = action.payload;
    },
    getAdminRoomsImageSuccess: (state, action: PayloadAction<Site[]>) => {
      let newSites = state.adminSites;
      action.payload.forEach(s=>{
        s.rooms.forEach(r=>{
          newSites.find(ns=> ns.pksites === s.pksites).rooms.forEach((nr) => {
            if(nr.pkrooms === r.pkrooms) {
              nr.roomattachment = r.roomattachment
              nr.mapattachment = r.mapattachment
            }
          })
        });
      });

      state.adminSites = newSites;
    },
    getImageHasLoadedSuccess: (state, action: PayloadAction<boolean>) => {
      state.imageHasLoaded = action.payload;
    },
  },
});

export const {
  editSiteNameSuccess,
  insertSiteSuccess,
  insertRoomSuccess,
  updateRoomSuccess,
  getRoomForUpdateSuccess,
  resetRoomForUpdateSuccess,
  removeAttachmentSuccess,
  getAdminSitesSuccess,
  resetOldPkAttachmentSuccess,
  setOldPkAttachmentSuccess,
  getCustomerAllRoomsSuccess,
  deleteSiteSuccess,
  deleteRoomSuccess,
  getAdminSitesChangedSuccess,
  getAdminRoomsImageSuccess,
  getImageHasLoadedSuccess
} = administrationRoomSlice.actions;
