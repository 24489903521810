import cn from "classnames";
import { useTranslation } from "react-i18next/";
import svgImage from "../../../asset/img/user-not-active.svg";
import css from "./AlertLicensesRatio.module.scss";


const AlertLicensesRatio: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='container-fluid d-grid justify-content-center mt-4'>
      <div className="row">
        <div className='col text-center'>
        <img
          className={cn(css["icon-image"])}
          src={svgImage}
          alt={t("Man placing a red circled X on a screen")}
        /></div>  
      </div>  
      <div className='row mt-4'>
        <div className="col-12">
        <h2 className='text-center primary'>{t("Warning")}</h2>
        </div>
        <div className="col-12">
        <h2 className="text-center">{t("More user than licenses")}</h2>  
        </div>
        <div className="col-12">
        <p className='text-center'>{t("User not active subtitle")}</p>  
        </div>
        
      </div>
    </div>);
}

export default AlertLicensesRatio;
