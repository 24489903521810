import  React from  "react";
import { Redirect, Route } from "react-router-dom";

export type GuardRouteType = {
    userCan: boolean
    userLogged: boolean
    path: string,
    exact: boolean
    component?: React.FC
}

const GuardRoute: React.FC<GuardRouteType> = ({...prop}) => {
    
    return (
        <>
        {
            prop.userLogged && prop.userCan? 
            (<Route  {...prop} >{prop.children}</Route>) : 
             (<Redirect  to="/home"  />)
        }
        </>
    )
}

export default  GuardRoute
