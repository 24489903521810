import { toggleToolbarMenu } from "./store/toolbar.store";
import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import css from "./toolbar.module.scss";
import { useTranslation } from "react-i18next/";
import { Dialog, IconButton, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TransitionProps } from "@mui/material/transitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faHome, faUserCheck, faCog, faLocationDot, faRightFromBracket } from "@fortawesome/pro-solid-svg-icons"
import { saveLogout } from "../../store/auth/auth.actions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ToolbarMobile: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCollapsed } = useSelector((state: RootState) => state.toolbar);
  const { user } = useSelector((state: RootState) => state.auth)

  const handleClose = () => {
    dispatch(toggleToolbarMenu(!isCollapsed));
  };

  const handleLogout = () =>{
    dispatch(saveLogout());
    handleClose();
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={cn("container", css["menu-mobile"])}>
          {/* MENU HEADER */}
          <div className="row mt-4 ps-3">
            <div className="col-6 d-flex justify-content-start align-items-center">
              <h3 className="ms-1">{t("Menu")}</h3>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <IconButton className="me-1 pe-3"  onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} size="lg"  />
              </IconButton>
            </div>
          </div>


          {/* MENU OPTIONS HERE */}
          <div className="mt-5 ps-3">

            {/* HOME */}
            <div className="row align-items-center mb-3">
              <div className="col-3 ps-0">
                <IconButton onClick={handleClose} className="w-100">
                  <Link to="/home">
                    <FontAwesomeIcon icon={faHome} size="lg" />
                  </Link>
                </IconButton>
              </div>
              <div className="col-9 ps-0">
                <Link to="/home" >
                  <h5 className={cn(css["menuLinkMobile"], "mb-0")} onClick={handleClose}>{t("Home")}</h5>
                </Link>
              </div>
            </div>


            {/* MY RESERVATIONS */}
            {user.functionalities.findIndex(f => f.nome === "Book_shift_in_room") >= 0 &&
              <div className="row align-items-center mb-3">
                <div className="col-3 ps-0">
                  <IconButton onClick={handleClose} className="w-100">
                    <Link to="/reservations" >
                      <FontAwesomeIcon icon={faUserCheck} size="lg"  />
                    </Link>
                  </IconButton>
                </div>
                <div className="col-9 ps-0">
                  <Link to="/reservations">
                    <h5 className={cn(css["menuLinkMobile"], "mb-0")} onClick={handleClose}>{t("My reservations")}</h5>
                  </Link>
                </div>
              </div>
            }

            {/* WHEREABOUT */}
            {user.functionalities.findIndex(f => f.nome === "Whereabouts") >= 0 &&
            <div className="row align-items-center mb-3">
              <div className="col-3 ps-0">
                <IconButton onClick={handleClose} className="w-100">
                  <Link to="/whereabouts">
                    <FontAwesomeIcon icon={faLocationDot} size="lg"  />
                  </Link>
                </IconButton>
              </div>
              <div className="col-9 ps-0">
                <Link to="/whereabouts">
                  <h5 className={cn(css["menuLinkMobile"], "mb-0")} onClick={handleClose}>{t("Whereabouts")}</h5>
                </Link>
              </div>
            </div>}

            {user.functionalities.findIndex(f => f.nome === "View_settings") >= 0 &&
              <div className="row align-items-center mb-3">
                <div className="col-3 ps-0">
                  <IconButton onClick={handleClose} className="w-100">
                    <Link to="/settings">
                      <FontAwesomeIcon icon={faCog} size="lg"/>
                    </Link>
                  </IconButton>
                </div>
                <div className="col-9 ps-0">
                  <Link to="/settings">
                    <h5 className={cn(css["menuLinkMobile"], "mb-0")} onClick={handleClose}>{t("Settings")}</h5>
                  </Link>
                </div>
              </div>
            }
            {user &&
              <div className="row align-items-center mb-3">
                <div className="col-3 ps-0">
                  <IconButton onClick={handleLogout} className="w-100">
                    <FontAwesomeIcon icon={faRightFromBracket} size="lg"/>
                  </IconButton>
                </div>
                <div className="col-9 ps-0">
                  <h5 className={cn(css["menuLinkMobile"], "mb-0")} onClick={handleLogout}>{t("Logout")}</h5>
                </div>
              </div>
            }
          </div>
        </div> 
      </Dialog>
    </div>
  );
};

export default ToolbarMobile;


