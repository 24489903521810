import { interceptor } from "../../../interceptors/.interceptor";
import { AllegatoForFE } from "../../../models/allegatoforfe";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from "../../../models/requestResponse";
import { startupContent } from "../../../models/startupContent";
import { addStartupImage, addStartupImageBulk } from "./startup.store";

export const getAttachmentBase64 = (pkattachment: number) => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/Attachment/v1/getattachment/${pkattachment}/attachments`,
      dispatch,
      auth: true,
      baseurl: false
    }
    try {
      interceptor<RequestResponse>(httpHandler).then((res) => {
        dispatch(addStartupImage({
          base64: res.msg,
          type: res.contenttype
        }));
      });
    } catch {}
  }

  export const getAttachmentForTutorial = () => async (dispatch) => {
    const httpHandler: HttpHandler = {
      method: "HTTP_GET",
      url: `/Attachment/v1/getstartuptutorialattachments`,
      dispatch,
      auth: true,
      baseurl: false
    }
    try {
      interceptor<AllegatoForFE[]>(httpHandler).then((res) => {
        if(res && res.length) {
          let convertResponse: startupContent[] = res.map(r => {
            return {
              base64: r.img,
              type: r.type
            }
          })
          dispatch(addStartupImageBulk(convertResponse));
        }
      });
    } catch {}
  }
