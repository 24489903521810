import { Button, Checkbox, Drawer, ListItemText, MenuItem } from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { ControlledAutocomplete, ControlledTextField } from '../../../core/components/mui-input/MuiInput';
import { addNewTeamGroups, updateGroup } from '../../../core/store/room/administration-group/administration-group.actions';
import { RootState } from '../../../core/store/store';
import { updateUIState } from '../../../core/store/ui/ui.store';
import { Room } from '../../../models/room';
import { TeamGroups } from '../../../models/teamgroups';
import { User } from '../../../models/user';
import style from "./groupform.module.scss";

export type GroupFormProps = {
  selectedGroup: TeamGroups | null
  type: "Edit" | "New"
  open: boolean
  handleClose: () => void
};

interface GroupFormVal {
  teamname: string,
  users: UsersGroup[],
  rooms: SiteRooms[]
}

interface SiteRooms {
  sitename: string,
  pksite: number,
  room: Room,
  type: ("Rooms" | "SELECT-ALL-ROOMS")
}

export interface UsersGroup{
  user: User,
  type: ("Users" | "SELECT-ALL-USERS"),
}

const GroupForm: React.FC<GroupFormProps> = ({selectedGroup, type, open, handleClose}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [ initRooms, setInitRooms] = useState<SiteRooms[]>([]);
  const [ rooms, setRooms] = useState<SiteRooms[]>([]);
  const [ UsersGroup, setUsersGroup] = useState<UsersGroup[]>([]);
  const [ valueUsersGroup, setvalueUsersGroup] = useState<UsersGroup[]>([]);
  const [ valueRooms, setValueRooms] = useState<SiteRooms[]>([]);
  const { groupsList } = useSelector((state: RootState) => state.groups)
  const { tenantUsers } = useSelector((state: RootState) => state.users)
  const { adminSites } = useSelector((state: RootState) => state.room.administrationRoom)
  const { user } = useSelector((state: RootState) => state.auth)
  const { control, handleSubmit, reset }
  = useForm<GroupFormVal>({
      mode: "all",
      reValidateMode: "onSubmit",
  });
  const SELECTALLUSERS: UsersGroup = {user: {pkusers: 0,fkcustomers: 0,fklanguages: 0,useridentify: "",username: "Select all users",email: "",manager: "",emailmanager: "",group: "",firstlogindate: moment(),lastlogindate: moment(),isactive: false,isadmin: false,isdemouser: false,isdeleted: false,doi: moment(),dou: moment(),userrooms: [],functionalities: [],showstartuppopup: false}, type: "SELECT-ALL-USERS"};
  const SELECTALLROOMS: SiteRooms = {sitename:"Select all rooms",pksite: 0, room: {pkrooms: 0, fksites: 0, fkattachments: 0, roomname:"Select all rooms",roomdescription:"",roomseats:0,roomavailable:0,isdeleted:false,doi:moment(),dou:moment(),shifts:[],iconname:"",roomattachment:"",isfavorite:false,mapname:""}, type: "SELECT-ALL-ROOMS"};

    useEffect(()=>{
    const tmpRooms:SiteRooms[] = [];
    tmpRooms.push(SELECTALLROOMS);
    adminSites.forEach(s=>{
      s.rooms.forEach(r=>{
        tmpRooms.push({sitename: s.sitename, pksite: s.pksites, room: r,type: "Rooms"});
      })
    });
    setRooms(tmpRooms);
  },[dispatch, adminSites])

  const onSave = handleSubmit(data => {
    const tmpUser:User[] = [];

    if(data.users.findIndex(u => u.type === "SELECT-ALL-USERS") >= 0){
      tmpUser.push(...UsersGroup.map(u => u.user));
    }else{
      data.users.forEach(u=>{
        tmpUser.push(u.user);
      });
    }
    if(data.rooms.findIndex(u => u.type === "SELECT-ALL-ROOMS") >= 0){
      data.rooms = [...rooms];
    }

    if(type === "Edit") {
      let groupForUpdate: TeamGroups = {
        pkteamgroups: selectedGroup.pkteamgroups,
        fkcustomer: selectedGroup.fkcustomer,
        teamname: data.teamname,
        users: tmpUser.filter(u => u.pkusers !== 0) as User[],
        rooms: data.rooms.filter(r => r.pksite !== 0).map(r=>(r.room)) as Room[],
        doi: selectedGroup.doi,
        dou: moment()
      }
      dispatch(updateGroup(groupForUpdate, user.fkcustomers))
    } else {
      let newTeamGroup: TeamGroups = {
        pkteamgroups: 0,
        fkcustomer: user.fkcustomers,
        teamname: data.teamname,
        users: tmpUser.filter(u => u.pkusers !== 0) as User[],
        rooms: data.rooms.filter(r => r.pksite !== 0).map(r=>(r.room)) as Room[],
        doi: moment(),
        dou: moment()
      }
      dispatch(addNewTeamGroups(newTeamGroup, user.fkcustomers))
    }
    dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 21 }))
    closeForm()

  });

  const cancelClicked = () => {
    let message : DialogMessageType = {
      dialogMsg :(<div>{t("Close From Alert")}</div>),
      dialogTitle : t("Attention"),
      dialogAcceptButton: t("Yes close"),
      dialogDismissButton: t("No go back"),
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {
        closeForm();
        dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 17 }))
      }
    }
    dispatch(getDialogMessage(message));
  }

  const closeForm = () => {
    reset({ teamname: "" });
    setInitRooms([]);
    handleClose()
  }

  useEffect(() => {

    const tmpUsersGroup: UsersGroup[] = [];

    tmpUsersGroup.push(SELECTALLUSERS);

    tenantUsers.forEach(u=>{
      tmpUsersGroup.push({user: u, type: "Users"});
    });

    if(selectedGroup) {
      //CASO EDIT

      //SETTO ROOMS
      const tmpInitRooms: SiteRooms[] = selectedGroup.rooms.map(r=> { return {
        sitename: adminSites.find(s=>s.pksites === r.fksites).sitename,
        pksite: r.fksites,
        room:r,
        type:"Rooms"
      }});
      setValueRooms(tmpInitRooms);

      //SETTO USERS
      const tmpInitUsers: UsersGroup[] = selectedGroup.users.map(u=>{ return {
       user: u,
       type: "Users"
      }})
      setvalueUsersGroup(tmpInitUsers)

      //Imposto nome team
      reset({ teamname: selectedGroup.teamname, users: tmpInitUsers, rooms: tmpInitRooms});
    } else {
      //CASO FORM NUOVO GROUP
      reset({ teamname: "" });
      setInitRooms([]);
      setValueRooms([]);
      setvalueUsersGroup([]);
    }
    setUsersGroup(tmpUsersGroup);
  }, [selectedGroup, groupsList, tenantUsers, adminSites])

  const handleChange = (newValue: SiteRooms[]) =>{
    const oldIsSelectAllRooms:boolean = (valueRooms.findIndex(v=>v.type==="SELECT-ALL-ROOMS") === -1)? false: true;
    const newIsSelectAllRooms: boolean = (newValue.findIndex(v=>v.type==="SELECT-ALL-ROOMS") === -1)? false: true;
    if(!oldIsSelectAllRooms && newIsSelectAllRooms){ //Selezioni select-all-rooms
      let tampleArray : SiteRooms[] = [];
      adminSites.forEach(s=>{
        tampleArray.push(...newValue.filter(v=>v.type!=="Rooms" && v.type!=="SELECT-ALL-ROOMS").concat( s.rooms.map(r=>({sitename: s.sitename, pksite: s.pksites, room: r, type: "Rooms"}))));
      })
      tampleArray.push(SELECTALLROOMS);
      setValueRooms(tampleArray);
    }
    else if(oldIsSelectAllRooms && !newIsSelectAllRooms){ //Deselezioni select-all-rooms
      setValueRooms(newValue.filter(v=>v.type!=="Rooms"));
    }/*
    else if(!newIsSelectAllRooms) //Check select-all-users when i select all of them
    {
      setValueRooms(newValue.concat([SELECTALLROOMS]));
    }*/
    else if(newIsSelectAllRooms) //DeCheck select-all-users when all the users are not selected
    {
      setValueRooms(newValue.filter(v=>v.type !== "SELECT-ALL-ROOMS"));
    }
    else
    {
      setValueRooms(newValue);
    }

  }

  const handleChangeUsers = (newValue: UsersGroup[]) =>{

    const oldIsSelectAllRooms:boolean = (valueUsersGroup.findIndex(v=>v.type==="SELECT-ALL-USERS") === -1)? false: true;
    const newIsSelectAllRooms: boolean = (newValue.findIndex(v=>v.type==="SELECT-ALL-USERS") === -1)? false: true;
    if(!oldIsSelectAllRooms && newIsSelectAllRooms){ //Selezioni select-all-rooms
      setvalueUsersGroup(newValue.filter(v=>v.type!=="Users").concat(tenantUsers.map(u=>({user: u, type: "Users"}))));
    }
    else if(oldIsSelectAllRooms && !newIsSelectAllRooms){ //Deselezioni select-all-rooms
      setvalueUsersGroup(newValue.filter(v=>v.type!=="Users"));
    }/*
    else if(!newIsSelectAllRooms) //Check select-all-users when i select all of them
    {
      setvalueUsersGroup(newValue.concat([SELECTALLUSERS]));
    }*/
    else if(newIsSelectAllRooms) //DeCheck select-all-users when all the users are not selected
    {
      setvalueUsersGroup(newValue.filter(v=>v.type !== "SELECT-ALL-USERS"));
    }
    else
    {
      setvalueUsersGroup(newValue);
    }
  }
  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={type === "New" ? cancelClicked : closeForm}
      >
        <div className="container-fluid ps-4">
          <div className="row mt-4 mb-4">
            <div className="col">
              {type === "Edit" &&
                <h3 className="primary">{t("Edit group")}</h3>
              }
              {type === "New" &&
                <h3 className="primary">{t("New group")}</h3>
              }
            </div>
          </div>
          <div className="row mb-4 w-100">
            <div className="col" data-joyride="group-name">
              <ControlledTextField
                control={control}
                name="teamname"
                rules={{required: {value: true, message: t("Field Required")}}}
                size="small"
                label={t("Group Name")}
                fullWidth
              />
            </div>
          </div>
          <div className="row w-100">
            <div className="col-12 mb-4 d-flex" data-joyride="group-users">
                <ControlledAutocomplete
                  name="users"
                  label={t("Group Users")}
                  multiple
                  fullwidth
                  control={control}
                  options={UsersGroup}
                  getOptionLabel={(option)=>option.user.username}
                  renderOption={(e,option, {selected})=>(
                    <MenuItem {...e} key={option.type+option.user.pkusers} value={option.user.username} >
                      <Checkbox checked={selected} />
                      <ListItemText primary={(option.type === "SELECT-ALL-USERS")?t(option.user.username):option.user.username} />
                    </MenuItem>
                  )}
                  disabled={user.functionalities.findIndex(f => f.nome === "Add_user_to_group") === -1}
                  disableCloseOnSelect
                  limitTags={1}
                  rules={{required: {value: true, message: t("Field Required")}}}
                  onChange={(newValue) => handleChangeUsers(newValue as UsersGroup[])}
                  value={valueUsersGroup}
                  isOptionEqualToValue={(o,v)=> o.type+o.user.pkusers === v.type+v.user.pkusers}
                />
            </div>
          </div>
          <div className="row w-100">
            <div className="col-12 mb-4 d-flex" data-joyride="group-rooms">
                <ControlledAutocomplete
                  fullwidth
                  multiple
                  name="rooms"
                  label={t("Group Rooms")}
                  control={control}
                  options={rooms}
                  getOptionLabel={(option)=>option.room.roomname}
                  groupBy={(option)=> adminSites.find(as => as.sitename === option.sitename) ? adminSites.find(as => as.sitename === option.sitename).parent === 0 ? option.sitename : adminSites.find(as => as.pksites === adminSites.find(as2 => as2.sitename === option.sitename).parent).sitename + " - " + option.sitename : option.sitename}
                  disabled={user.functionalities.findIndex(f => f.nome === "Add_room_to_group") === -1}
                  renderOption={(e,option, {selected})=>(
                    <MenuItem key={option.room.pkrooms} value={option.room.roomname} {...e}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.room.roomname} />
                    </MenuItem>
                  )}
                  disableCloseOnSelect
                  limitTags={1}
                  onChange={(newValue) => handleChange(newValue as SiteRooms[])}
                  value={valueRooms}
                  defaultValue={initRooms}
                  isOptionEqualToValue={(o,v)=> o.room.pkrooms === v.room.pkrooms}

                />
            </div>
          </div>
          <div className={cn("row", style["buttons"])}>
            <div className="col">
              <Button
                variant="contained"
                onClick={closeForm}
                color="secondary"
                className={style["secondaryBtn"]}>
                {t("Cancel")}
              </Button>
              <Button
                color={"primary"}
                variant="contained"
                onClick={onSave}
                className="ms-2">
                {type === "Edit" && t("Save")}
                {type === "New" && t("Insert")}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default GroupForm;