import { faBan, faCrown, faMagnifyingGlass, faUserCheck, faUserXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControlLabel, IconButton, InputAdornment, Pagination, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { RootState } from "../../../core/store/store";
import { SAActivateUser, SADeactivateUser, getAllUsersXSA, getCountUsersXSA } from '../../../core/store/superadmin/superadmin.actions';
import { getAllUsersXClientsSuccess } from '../../../core/store/superadmin/superadmin.store';
import { SA_User } from "../../../models/sa_users";
import UserForm from "../../users-management/user-form/UserForm";
import style from './SAUsersManagement.module.scss';
import { SAUsersManagementModal } from './SAUsersManagementModal';

export const SAUsersManagement: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [nameFilter, setNameFilter] = useState<string>("")
  const [customerFilter, setCustomerFilter] = useState<string>("")
  const [onlyActive, setOnlyActive] = useState<boolean>(false)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const { user } = useSelector((state: RootState) => state.auth)
  const { allCustomersUsers,numberCustomersUsersPages } = useSelector((state: RootState) => state.superadmin)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<SA_User|undefined>(undefined);
  const { counter } = useSelector((state: RootState) => state.loader)
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllUsersXSA(onlyActive,currentPage,nameFilter,customerFilter))
    dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
  }, [dispatch])

  useEffect(() => {
    return () => { //component unmount
      dispatch(getAllUsersXClientsSuccess([]))
    }
  }, [])

  const handleDisable = (u: SA_User) => {
    let dialog: DialogMessageType = {
      dialogTitle: "Disattivazione utente",
      dialogMsg: <p>Vuoi disattivare l'utente {u.username}?</p>,
      dialogAcceptButton: "DISATTIVA",
      dialogDismissButton: "ANNULLA",
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(SADeactivateUser(u.email))
        setCurrentPage(1);
        dispatch(getAllUsersXSA(onlyActive,1,nameFilter,customerFilter));
        dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
      }
    }
    dispatch(getDialogMessage(dialog))
  }

  const handleDelete = (u: SA_User) => {
    setSelectedUser(u);
    setIsModalOpen(true);
  }

  const handleReactivate = (u: SA_User) => {
    let dialog: DialogMessageType = {
      dialogTitle: "Attivazione utente",
      dialogMsg: <p>Vuoi attivare l'utente {u.username}?</p>,
      dialogAcceptButton: "ATTIVA",
      dialogDismissButton: "ANNULLA",
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(SAActivateUser(u.email))
        setCurrentPage(1);
        dispatch(getAllUsersXSA(onlyActive,1,nameFilter,customerFilter));
        dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
      }
    }
    dispatch(getDialogMessage(dialog))
  }

  const closeForm = () => {
    setIsFormOpen(false)
  }

  useEffect(() => {
    setCurrentPage(1);
    dispatch(getAllUsersXSA(onlyActive,1,nameFilter,customerFilter));
    dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
  }, [onlyActive])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    dispatch(getAllUsersXSA(onlyActive,value,nameFilter,customerFilter))
    dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
  };

  const handleSearch = () =>{
    setCurrentPage(1);
    dispatch(getAllUsersXSA(onlyActive,1,nameFilter,customerFilter))
    dispatch(getCountUsersXSA(onlyActive,nameFilter,customerFilter));
  };

  return(
  <>
    <div className="container-fluid mt-4 ps-4 pe-4 mb-3">
      <div className="row">
        <div className="col-md-3 pe-0">
          <TextField
            id="search-customer-input"
            className="w-100"
            value={customerFilter}
            onChange={(e) => setCustomerFilter(e.target.value)}
            label={"Cerca cliente"}
            InputProps={{
              endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
            }}
            size="small"
          />
        </div>
        <div className="col-md-3 pe-0">
          <TextField
            id="search-user-input"
            className="w-100"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            label={t("Search User")}
            InputProps={{
              endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
            }}
            size="small"
          />
        </div>
        <div className="col">
          <IconButton onClick={handleSearch} className={cn(style["search-btn-desktop"])}>
              <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
          </IconButton>
          {user.functionalities.findIndex(f => f.nome === "SA_Add_User") >= 0 &&
            <Button
            className="bgc-primary"
            variant="contained"
            onClick={() => setIsFormOpen(true)}>
            {t("Insert")}
            </Button>
          }
        </div>
        <div className="col-3 d-flex justify-content-end">
          <FormControlLabel control={
            <Switch
              checked={onlyActive}
              onChange={() => setOnlyActive(!onlyActive)}
            />
          }
          label={"Solo utenti attivi"}/>
        </div>
      </div>
      <div  className={cn(style["divPageMargin"])}>
          <Pagination  className={cn(style["paginationCenter"])} count={numberCustomersUsersPages} page={currentPage} defaultPage={1} showFirstButton showLastButton onChange={handleChange} />
      </div>
      <div className="row mt-4 pb-4">
        <div className="col">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>{t("User")}</b></TableCell>
                  <TableCell align="left"><b>Email</b></TableCell>
                  <TableCell align="left"><b>{t("Active")}</b></TableCell>
                  <TableCell align="left"><b>Azienda</b></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allCustomersUsers.length > 0 && counter === 0 && allCustomersUsers.map((u) => (
                  <TableRow
                    className="table-hover-user"
                    key={u.pkusers}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {u.username}
                      {u.functionalities?.findIndex(f => f.nome === "Admin") >= 0 ? <FontAwesomeIcon icon={faCrown} size="1x" className="primary ms-2" /> : ''}
                    </TableCell>
                    <TableCell align="left">{u.email}</TableCell>
                    <TableCell align="left">{u.isactive ? t("Yes") : t("No")}</TableCell>
                    <TableCell align="left">{u.customername}</TableCell>
                    <TableCell align="right">
                      {u.isactive === false && user.functionalities.findIndex(f => f.nome === "SA_Add_User") >= 0 &&
                        <>
                        <Tooltip disableFocusListener disableTouchListener id="reactivate-user-tooltip" title="attiva">
                          <IconButton style={{padding: 0}} onClick={() => handleReactivate(u)}>
                            <FontAwesomeIcon icon={faUserCheck} size="1x" className="primary m-0 p-0 pointer" style={{fontSize: "medium"}} />
                          </IconButton>
                        </Tooltip>
                        </>
                      }
                      {u.isactive && user.functionalities.findIndex(f => f.nome === "SA_Delete_User") >= 0 &&
                        <>
                          <Tooltip disableFocusListener disableTouchListener id="disable-user-tooltip" title="disattiva">
                            <IconButton style={{padding: 0}} onClick={() => handleDisable(u)}>
                              <FontAwesomeIcon icon={faBan} size="1x" className="primary m-0 p-0 pointer me-2" style={{fontSize: "medium"}} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip disableFocusListener disableTouchListener id="delete-user-tooltip" title="elimina">
                            <IconButton style={{padding: 0}} onClick={() => handleDelete(u)}>
                              <FontAwesomeIcon icon={faUserXmark} size="1x" className="primary m-0 p-0 pointer ms-2" style={{fontSize: "medium"}} />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div  className={cn(style["divPageMargin"])}>
          <Pagination  className={cn(style["paginationCenter"])} count={numberCustomersUsersPages} page={currentPage} defaultPage={1} showFirstButton showLastButton onChange={handleChange} />
      </div>
      <UserForm
        type={"SuperAdmin"}
        selectedUser={null}
        open={isFormOpen}
        handleClose={() => closeForm()}
      />
      <SAUsersManagementModal
        open={isModalOpen}
        onClose={()=>{setIsModalOpen(false)}}
        user={selectedUser}
      />

    </div>
  </>

)}