import {
  Modal,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import style from "./SACustomersManagement.module.scss";
import { useTranslation } from "react-i18next/";
import { TabContext, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import {
  deleteCustomer,
  getAllUsersXClient,
  getClientExcel,
} from "../../../core/store/superadmin/superadmin.actions";
import { Customer } from "../../../models/customer";
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { GetCustomersAllRooms } from "../../../core/store/room/administration-room/administration-room.actions";

interface SACustomerManagementModalProps {
  open: boolean;
  onClose: () => void;
  Customer: Customer | undefined;
}

export const SACustomerManagementModal: React.FC<SACustomerManagementModalProps> = ({ open, onClose, Customer }) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState<string>("0");
  const dispatch = useDispatch();
  const { CustomerAllRooms } = useSelector((state: RootState) => state.room.administrationRoom);
  const { allCustomersUsers } = useSelector((state: RootState) => state.superadmin);

  useEffect(() => {
    if (Customer) {
      dispatch(getAllUsersXClient(Customer.tenantid, 1, 0));
      dispatch(GetCustomersAllRooms(Customer.pkcustomers));
    }
  }, [Customer]);

  const handleClose = () => {
    let message: DialogMessageType = {
      dialogMsg: (
        <div className="msg">
          {t("Si sta cercando di cancellare il customer ") +
            Customer.customername}
        </div>
      ),
      dialogTitle: t("Attention"),
      dialogAcceptButton: t("Yes"),
      dialogDismissButton: t("Cancel"),
      isOpen: true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(deleteCustomer(Customer.pkcustomers));
        onClose();
      },
    };
    dispatch(getAllUsersXClient(Customer.tenantid, 1, 1));
    dispatch(getDialogMessage(message));
  };

  return (
    <>
      {Customer && (
        <Modal open={open} onClose={onClose}>
          <div className={cn(style["modal-dialog"])}>
            <div className="col h-100 d-flex flex-column">
              {/* Title */}
              <div className="row">
                <p className={cn(style["modal-title"])}>{t("Delete User")}</p>
              </div>
              {/* Content */}
              <div className={cn(style["modal-content"])}>
                <p>
                  {t("Delete User paragraph 1")} <b>{Customer.customername}</b>{" "}
                  {t("Delete User paragraph 2")} <b>{""}</b>.
                </p>
                <p>{t("Delete User paragraph 3")}</p>

                {/* TABS */}
                <TabContext value={index}>
                  <Tabs
                    indicatorColor="secondary"
                    value={index}
                    onChange={(e, newValue) => setIndex(newValue)}
                    variant="fullWidth"
                    centered
                  >
                    <Tab
                      value="0"
                      label={t("Associated Users")}
                      style={
                        index === "0"
                          ? { width: "50%", color: "black" }
                          : { width: "50%", color: "gray" }
                      }
                    />
                    <Tab
                      value="1"
                      label={t("Associated rooms")}
                      style={
                        index === "1"
                          ? { width: "50%", color: "black" }
                          : { width: "50%", color: "gray" }
                      }
                    />
                  </Tabs>

                  <div className={cn(style["tab-container"], "row")}>
                    <TabPanel value="0" className={cn(style["tab"])}>
                      <>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <b>{t("Nome e Cognome ")}</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Email</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allCustomersUsers.length > 0 &&
                                allCustomersUsers
                                  .filter(
                                    (u) =>
                                      u.customername === Customer.customername
                                  )
                                  .map((cu) => {
                                    return (
                                      <TableRow
                                        className="table-hover-user"
                                        key={cu.pkusers}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {cu.username}
                                        </TableCell>
                                        <TableCell align="left">
                                          {cu.email}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    </TabPanel>

                    <TabPanel value="1" className={cn(style["tab"])}>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                <b>{t("Room")}</b>
                              </TableCell>
                              <TableCell align="left">
                                <b>{t("Site")}</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {CustomerAllRooms &&
                              CustomerAllRooms.map((r, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      {r.roomName}
                                    </TableCell>
                                    <TableCell align="left">
                                      {r.siteName}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </div>
                </TabContext>
                {/* Buttons */}
                <div className="d-flex flex-row justify-content-between mt-2">
                  <div className="d-flex flex-row gap-3">
                    <Button
                      style={{ borderColor: "#0000004D", color: "black" }}
                      variant="outlined"
                      onClick={() => onClose()}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button variant="contained" onClick={handleClose}>
                      {t("Delete")}
                    </Button>
                  </div>
                  <div className="d-flex flex-row justify-content-between mt-2">
                    <div className="group">
                      <Button
                        style={{ borderColor: "#0000004D", color: "black" }}
                        variant="outlined"
                        onClick={() =>
                          dispatch(getClientExcel(Customer.tenantid))
                        }
                      >
                        {t("Download Client details")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
