import { interceptor } from "../../../interceptors/.interceptor";
import { HttpHandler } from "../../../models/http-interceptor";
import { RequestResponse } from '../../../models/requestResponse';
import { AppThunk } from "../store";
import { setPopupChangelog, setPopupState, setPopupVariant } from "./popup.store";

export const getChangelog = (pkuser): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `Changelog/v1/GetChangelogForUserFromPk/${pkuser}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.changelog !== null) {
        dispatch(setPopupChangelog(res.changelog))
        dispatch(setPopupVariant("changelog"))
        dispatch(setPopupState(true))
      }
    });
  } catch (err) {}
};

export const getChangelogFromPk = (pkchangelog): AppThunk => async (dispatch) => {
  const httpHandler: HttpHandler = {
    method: "HTTP_GET",
    url: `Changelog/v1/GetChangelogFromPk/${pkchangelog}`,
    dispatch,
    auth: true,
    baseurl: false,
  };
  try {
    interceptor<RequestResponse>(httpHandler).then((res) => {
      if(res.changelog !== null) {
        dispatch(setPopupChangelog(res.changelog))
        dispatch(setPopupVariant("changelog"))
        dispatch(setPopupState(true))
      }
    });
  } catch (err) {}
}