import { utils, write } from "xlsx/xlsx";
import FileSaver from "file-saver";
import { ReportRow } from "../../models/report";
import moment from "moment";
import { Site } from "../../models/site";
import { t } from "i18next";

export function XlsxReport(reports:ReportRow[], adminSites: Site[]) {
    const fileName= "JustSeat_Prenotazioni_"+moment().format("yyyyMMDD_hhss");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    
    const reportData=[];
    reports.forEach((elem)=>{        
        const reportRow={
            sitename: adminSites.filter(s => {
                return s.rooms.findIndex(r => r.roomname === elem.roomname) >= 0;
            })[0].sitename,
            roomname: elem.roomname,
            shiftname: elem.shiftname,
            reservationdate: moment(elem.reservationdate).format("DD/MM/yyyy"),
            starttime: elem.starttime,
            endtime: elem.endtime,
            postiprenotati: elem.postiprenotati,
            utenti: elem.utenti
        }
        reportData.push(reportRow);
    })
    const wscols = [
        { wch: Math.max(...reportData.map(report => report.sitename.length)) +1 },
        { wch: Math.max(...reportData.map(report => report.roomname.length)) +1 },
        { wch: Math.max(...reportData.map(report => report.shiftname.length)) +1 },
        { wch: Math.max(...reportData.map(report => report.reservationdate.length)) +1},
        { wch: Math.max(...reportData.map(report => report.starttime.length)) +1},
        { wch: Math.max(...reportData.map(report => report.endtime.length)) +1},
        { wch: Math.max(...reportData.map(report => report.postiprenotati.toString().length)) +3 },
        { wch: Math.max(...reportData.map(report => report.utenti.length)) + 1 }
    ];

    const Heading = [
        {
            sitename: t("Site"),
            roomname: t("Room"),
            shiftname: t("Shift"),
            reservationdate: t("Date"),
            starttime: t("From Time"),
            endtime: t("To Time"),
            postiprenotati: t("Seats"),
            utenti: t("Users")
        }
    ];

    const ws = utils.json_to_sheet(Heading, {
        header: ["sitename","roomname","shiftname", "reservationdate", "starttime", "endtime", "postiprenotati", "utenti"],
        skipHeader: true,
        //origin: 0 //ok
      });
      ws["!cols"] = wscols;
      utils.sheet_add_json(ws, reportData, {
        header: ["sitename","roomname","shiftname", "reservationdate", "starttime", "endtime", "postiprenotati", "utenti"],
        skipHeader: true,
        origin: -1 //ok
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
}