import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UIStore {
  page: string,
  stepIndex: number,
  run: boolean
}

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    page: 'homepage',
    stepIndex: 0,
    run: true
  } as UIStore,
  reducers: {
    updateUIState: (state, action: PayloadAction<any>) => {
        switch(action.payload.type) {
            case 'SET_PAGE':
                return { ...state, page: action.payload };
            case 'SET_STEP_INDEX':
                return { ...state, stepIndex: action.payload.stepIndex };
            case 'SET_RUN':
              let newState = { ...state, run: action.payload.run };
              if(action.payload.stepIndex){
                newState.stepIndex = action.payload.stepIndex;
              }
                return newState;
            default:
                return state;
        }
    }
  },
});

export const { 
  updateUIState
} = uiSlice.actions;
