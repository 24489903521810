
import axios, { AxiosError, AxiosResponse } from "axios";
import environment from "../environment";
import { HttpHandler } from "../models/http-interceptor";



/**
 * 
 * @param dipsatch 
 * @param httpOptions 
 * @param auth inserisce nell'header della chiamata l'authorization
 */ 
export const http = async <T>(handler: HttpHandler): Promise<T> => {
    
    let {baseurl, httpOptions, method, url } = handler

    axios.defaults.baseURL = baseurl? environment.BASE_URL : environment.API_URL
    switch(method){
        case "HTTP_GET":
            return axios.get(url, httpOptions).then((result: AxiosResponse<T>) => {
                return result.data
            }).catch((error: AxiosError) => {
                throw handleError(error)
            })
        case "HTTP_POST":
            return axios.post(url, httpOptions?.data, httpOptions).then((result: AxiosResponse<T>) => {
                return result.data
            }).catch(error => {
                throw handleError(error)
            })
        case "HTTP_DELETE":
            return axios.delete(url, httpOptions).then((result: AxiosResponse<T>) => {
                return result.data
            }).catch(error => {
                throw handleError(error)
            })
        case "HTTP_PATCH":
            return axios.patch(url, httpOptions?.data, httpOptions).then((result: AxiosResponse<T>) => {
                return result.data
            }).catch(error => {
                throw handleError(error)
            })
        case "HTTP_PUT":
            return axios.put(url, httpOptions?.data, httpOptions).then((result: AxiosResponse<T>) => {
                return result.data
            }).catch(error => {
                throw handleError(error)
            })
    }
}

const handleError = (error: AxiosError): AxiosResponse<any> | undefined => {
    if(error.message === "Network Error"){
        error.response = {...(error.response as AxiosResponse), status: 504 }
    }
    return error.response
}
