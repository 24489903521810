import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { Moment } from "moment";
import { Language } from "../../../models/language";
import { MyReservation } from '../../../models/reservation';
import { User, UsersCompered, UsersUsrGroups, UsrGroup } from '../../../models/user';
import { WhereaboutsUser } from "../../../models/whereabouts";

interface UsersStore {
  tenantUsers: User[];
  language: Language | undefined;
  myReservations: MyReservation[];
  languages: Language[];
  usersLicense?: number;
  usersFromAzureAD: UsersCompered;
  lastSyncDate: Moment;
  usersReservations: WhereaboutsUser[];
  usrgroups: UsrGroup[];
  usersusrgroups: UsersUsrGroups;
}

export const usersSlice = createSlice({
  name: "user",
  initialState: {
    tenantUsers: [],
    language: undefined,
    myReservations: [],
    languages: [],
    usersFromAzureAD: undefined,
    lastSyncDate: undefined,
    usersReservations: [],
    usrgroups: [],
    usersusrgroups: undefined
  } as UsersStore,
  reducers: {
    getTenantUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.tenantUsers = action.payload;
    },
    getUserLanguageSuccess: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    getUserReservationsSuccess: (state, action: PayloadAction<MyReservation[]>) => {
      state.myReservations = action.payload;
    },
    addUserReservationsByDateSuccess: (state, action: PayloadAction<MyReservation[]>) => {
      let tmpMyReservations = state.myReservations.filter(r=> r.reservationdate !== action.payload[0].reservationdate);
      tmpMyReservations = tmpMyReservations.concat(action.payload);
      state.myReservations = tmpMyReservations;
    },
    getLanguagesSuccess: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    },
    getClientLicenseSuccess: (state, action: PayloadAction<number>) => {
      state.usersLicense = action.payload;
    },
    getUsersFromAzureADSuccess: (state, action: PayloadAction<UsersCompered>) => {
      state.usersFromAzureAD = action.payload;
    },
    updateUsersToRemove: (state, action: PayloadAction<User[]>) => {
      state.usersFromAzureAD.userstoremove = action.payload;
      state.usersFromAzureAD.userstoremovelength = action.payload.length;
    },
    getLastSyncDateSuccess: (state, action: PayloadAction<Moment>) => {
      state.lastSyncDate = action.payload;
    },
    getUsersReservationsSuccess: (state, action: PayloadAction<WhereaboutsUser[]>) => {
      state.usersReservations = action.payload;
    },
    setUsersReservationsAlreadyReserved: (state, action: PayloadAction<{pkusers: number, pkshift: number, date: Moment}>) => {
      const userIndex = state.usersReservations.findIndex(ur=> ur.user.pkusers === action.payload.pkusers);
      const reservationIndex = state.usersReservations[userIndex].reservations.findIndex(r=> r.fkshifts === action.payload.pkshift && moment(r.reservationdate).format("YYYY-MM-DD") === moment(action.payload.date).format("YYYY-MM-DD"));

      state.usersReservations[userIndex].reservations[reservationIndex].alreadybooked = true;
    },
    getUsrGroupsSuccess: (state, action: PayloadAction<UsrGroup[]>) => {
      state.usrgroups = action.payload;
    },
    getUsersUsrGroupsSuccess: (state, action: PayloadAction<UsersUsrGroups>) => {
      state.usersusrgroups = action.payload;
    }
  },
});

export const {
  getTenantUsersSuccess,
  getUserLanguageSuccess,
  getUserReservationsSuccess,
  addUserReservationsByDateSuccess,
  getLanguagesSuccess,
  getClientLicenseSuccess,
  getUsersFromAzureADSuccess,
  getLastSyncDateSuccess,
  updateUsersToRemove,
  getUsersReservationsSuccess,
  setUsersReservationsAlreadyReserved,
  getUsrGroupsSuccess,
  getUsersUsrGroupsSuccess
} = usersSlice.actions;
