import { useEffect, useState } from "react";
import cn from "classnames";
import css from "./startupGuide.module.scss";
import { IconButton, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { t } from "i18next";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import { hideStartupPopup } from "../../store/users/users.actions";
import { getAttachmentForTutorial } from "../../store/startup/startup.actions";

const StartupGuide: React.FC = () => {
    const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#0C546870',
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: '#0C5468',
        },
    }));
    
    //Static variables
    const texts = [t("Startup step 1"),t("Startup step 2"),t("Startup step 3"),t("Startup step 4"),t("Startup step 5")]

    //Utils
    const dispatch = useDispatch()

    //Selectors
    const { content } = useSelector((state: RootState) => state.startup)

    //UseState
    const [index, setIndex] = useState(0)
    const [confirmed, setConfirm] = useState(false)

    //UseEffects
    useEffect(() => {
        if(content && content.length === 0) {
            dispatch(getAttachmentForTutorial())
        }
    }, [])

    //Functions
    const handleChangeIndex = (change) => {
        if(change < 0) {
            if(index > 0) {
                setIndex(index-1)
            }
        }
        else if(index < (texts.length-1)) {
            setIndex(index+1)
        }
    }

    const handleConfirmClick = () => {
        setConfirm(!confirmed)
    }

    const handleStartBtnClick = () => {
        if(confirmed) {
            dispatch(hideStartupPopup())
        }
    }

    return (
        <div className={cn(css["container"], "d-flex align-items-center justify-content-center")}>
            <div className="row mt-4">
                <div className="col">
                    <h2 className={css["textPrimary"]}>{t("Startup permission title")}</h2>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col d-flex justify-content-center">
                    <h5 className={css["subtitle"]}>{t("Startup permission text")}</h5>
                </div>
            </div>
            <div className={cn(css["shadow"], "row")}>
                <div className="col">
                    <div className={cn("row")}>
                        <div className={cn(css["bgWhite"], "col d-flex justify-content-center align-items-center")}>
                            <p className={cn("m-0 pt-2 pb-2", css["tutorial-text"])}>{t(texts[index])}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={cn("col d-flex justify-content-center px-0", css["media-container"])}>
                            {!content || !content.length || !content[index] &&
                                <div className={cn(css["loading-placeholder"])}></div>
                            }
                            {content && content.length > 0 && content[index] && content[index].type !== "mp4" &&
                                <>
                                    <input type="checkbox" id="zoomCheck" className={css["zoom-check"]}></input>
                                    <label htmlFor="zoomCheck">
                                        <img src={"data:image/" + content[index].type + ";base64," + content[index].base64} className={cn(css["imgClass"])} alt={t("Image not available")}></img>
                                    </label>
                                </>
                            }
                            {content && content.length > 0 && content[index] && content[index].type === "mp4" &&
                                <video autoPlay controls src={"data:video/" + content[index].type + ";base64," + content[index].base64} className={cn(css["imgClass"])} />
                            }
                        </div>
                    </div>
                    <div className={cn(css["bgWhite"], "row p-2")}>
                        <div className={cn(css["cursorPointer"], "col-3 d-flex align-items-center justify-content-start")} onClick={() => handleChangeIndex(-1)}>
                            <IconButton>    
                                <FontAwesomeIcon
                                    className={css["textPrimary"]}
                                    icon={faChevronLeft}/>
                            </IconButton>
                            <p className={cn(css["textPrimary"], "m-0")}>{t("Back").toUpperCase()}</p>
                        </div>
                        <div className={cn("col-6 m-auto p-0")}>
                            <StyledLinearProgress variant="determinate" value={((index+1)/texts.length)*100} />
                        </div>
                        <div className={cn(css["cursorPointer"], "col-3 d-flex align-items-center justify-content-end")} onClick={() => handleChangeIndex(1)}>
                            <p className={cn(css["textPrimary"], "m-0")}>{t("Forward").toUpperCase()}</p>
                            <IconButton>
                                <FontAwesomeIcon
                                    className={css["textPrimary"]}
                                    icon={faChevronRight}/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col d-flex">
                    <Checkbox defaultChecked={false} onClick={handleConfirmClick} className={cn(css["textPrimary"], "p-0 me-2")} />
                    <p className="m-0">{t("Confirm startup steps")}</p>
                </div>
            </div>
            <div className="row mt-3 mb-4">
                <div className="col">
                    <button className={cn(css["startBtn"], confirmed ? css["btnActive"] : "")} disabled={!confirmed} onClick={handleStartBtnClick}>{t("Start").toUpperCase()}</button>
                </div>
            </div>
        </div>
    )
}

export default StartupGuide