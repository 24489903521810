import { useTranslation } from 'react-i18next/'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import css from './tutorials.module.scss'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { Card, CardContent, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { RootState } from '../../core/store/store'
import { setPopupState, setPopupUserGuide, setPopupVariant } from '../../core/store/popup/popup.store'
import { getGuideFile } from '../../core/store/tutorials/guide.actions'

const Tutorials: React.FC = () => {
  //Utils
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //Selectors
  const { guidesList, guidesCategories } = useSelector((state: RootState) => state.guide)
  
  //Usestates
  const [filter, setFilter] = useState<string>("")
  const [categoryFilter, setCategoryFilter] = useState<number>(0)

  //Functions
  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value)
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const openUserGuide = (index) => {
    dispatch(getGuideFile(guidesList[index].filename))
    dispatch(setPopupVariant("userGuide"))
    dispatch(setPopupUserGuide(guidesList[index]))
    dispatch(setPopupState(true))
  }

  return <>
    <div className="container-fluid ps-4">
      <div className="row ms-2 mt-4 mb-3">
        <div className="col">
          <h4 className={cn(css["primary"],"mb-0")}>{t("Tutorials")}</h4>
        </div>
      </div>
      <div className="row ms-2 pb-4">
        <div className='col-auto d-flex pe-0'>
          {/* pe-none = pointer-events: none --> impedisce che al click dell'icona o dello span il bottone non riceva il click */}
          <button className={categoryFilter == 0 ? cn(css["btnGuide"], css["btnActive"]) : cn(css["btnGuide"])} value={0} onClick={(e) => handleCategoryChange(e)}>
            <FontAwesomeIcon className='pe-none' icon={["far" as IconPrefix, "fa-book" as IconName]} /> 
            &nbsp; &nbsp;
            <span className={cn(css["guide-name"], "pe-none")}>{t("All tutorials")}</span>
          </button>
        </div>
        {
          guidesCategories.map((obj, i) => (
            <div className='col-auto d-flex pe-0' key={"guideBtnDiv" + i}>
              <button className={(i+1) == categoryFilter ? cn(css["btnGuide"], css["btnActive"]) : cn(css["btnGuide"])} value={(i+1)} onClick={(e) => handleCategoryChange(e)} key={"btnGuide" + (i+1)}>
                <FontAwesomeIcon className='pe-none' icon={[obj.iconname.split(" ")[0] as IconPrefix, obj.iconname.split(" ")[1] as IconName]} key={"icon" + (i+1)} />
                &nbsp; &nbsp;
                <span className={cn(css["guide-name"], 'pe-none')}>{t(obj.name)}</span>
              </button>
            </div>
          ))
        }
      </div>
      <div className="row ms-2 me-4 pb-2">
        <div className="col">
          <Card>
            <CardContent>
              <div className="row mb-2">
                <div className="col-12">
                  <TextField
                    value={filter}
                    onChange={handleFilterChange}
                    label={t("Search")}
                    InputProps={{
                      endAdornment:<InputAdornment position="end"><SearchIcon fontSize={"small"}/></InputAdornment>
                    }}
                    size="small"
                  />   
                </div>
              </div>
              {
                guidesList.filter(t => t.name.toLowerCase().includes(filter.toLowerCase()) && (categoryFilter == 0 ? 1 : t.fkguidescategories == categoryFilter)).map((obj, i) => (
                  <>
                    <div className={cn(css["guide-row"], "row d-flex align-items-center py-3")} key={"guideRow" + i} onClick={() => openUserGuide(i)}>
                      <div className="col-10">
                        <p className='m-0'>{obj.name}</p>
                      </div>
                      <div className={cn(css["groupLabel"], "ms-5 col-1 d-flex justify-content-center")}>
                        <p className={cn("m-0")}>{t(guidesCategories.find(gc => gc.pkguidescategories == obj.fkguidescategories)?.name)}</p>
                      </div>
                    </div>
                    <hr className="my-0"/>
                  </>
                ))
              }
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  </>
};

export default Tutorials;
