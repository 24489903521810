import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "./HookFormProps";
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker'
import { TextField } from "@mui/material";

export const ControlledMobileDatePicker: FC<HookFormProps & MobileDatePickerProps<any, any>> = (props)=>{
    return(
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules} 
        defaultValue={props.defaultValue || ""}
        render={({
            field: { onChange, onBlur, name: fieldName, value },
            fieldState: { error }
        }) => (
            <MobileDatePicker
                {...props}
                renderInput={(oprops)=>
                    <TextField {...oprops} 
                        error={error&& true}
                        helperText={error && error.message}
                        onBlur={onBlur}
                        name={fieldName}
                    />
                }
                onChange={(e)=>{
                    onChange(e);
                    if(props.onChange){
                        props.onChange(e);
                    }
                }}
                value={value}
            />
        )}
        />
    )
}