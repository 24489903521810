import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Button, Checkbox, Drawer, FormControl, FormControlLabel, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessageType } from "../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../core/components/dialog/store/dialogMessage.store";
import { addAlertMessage } from "../../../core/components/message-bar/store/alertMessage.store";
import { getHomePage } from '../../../core/store/felogic/felogic.action';
import { getTeamGroups } from '../../../core/store/room/administration-group/administration-group.actions';
import { GetCustomersAllRooms } from "../../../core/store/room/administration-room/administration-room.actions";
import { getUserRoomsSelectedUser } from "../../../core/store/room/user-room/user-room.actions";
import { RootState } from '../../../core/store/store';
import { addUserXClient, getAllCustomersPkAndName } from '../../../core/store/superadmin/superadmin.actions';
import { updateUIState } from '../../../core/store/ui/ui.store';
import { addUser, addUsers, editUser, getUsersUsrGroupsAdmin, getUsrGroups } from '../../../core/store/users/users.actions';
import { RoomSite } from "../../../models/room";
import { SA_User } from '../../../models/sa_users';
import { User, UserForUpdate } from '../../../models/user';
import style from "./UserForm.module.scss";

export type UserFormProps = {
  selectedUser: User | null
  type: "Edit" | "Single" | "Massive" | "SuperAdmin"
  open: boolean
  handleClose: () => void
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserForm: React.FC<UserFormProps> = ({selectedUser, type, open, handleClose}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fileName, setFileName] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [isActive, setIsActive] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [usersToAdd, setUsersToAdd] = useState(null)
  const [usrGroups, setUsrGroups] = useState<number>()
  const { tenantUsers, usrgroups: usrgroupslist, usersusrgroups } = useSelector((state: RootState) => state.users);
  const meUser = useSelector((state: RootState) => state.auth.user)
  const { allCustomersPkAndName } = useSelector((state: RootState) => state.superadmin)
  const { teamGroups } = useSelector((state: RootState) => state.groups)
  const { roomUserSelected } = useSelector((state:RootState) => state.room.userRoom)
  const [groupsNames, setGroupsNames] = useState<string[]>([])
  const [roomsNames, setRoomsNames] = useState<string[]>([])
  const { CustomerAllRooms } = useSelector((state: RootState) => state.room.administrationRoom);
  const { user } = useSelector((state: RootState) => state.auth)
  const [ customerRoomsExludedGroups, setCustomerRoomsExludedGroups] = useState<RoomSite[]>([])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  };

  const handleSave = () => {
    let userForUpdate: UserForUpdate
    if(type === "Edit") {
      let tmp: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "fklanguages" | "fkcustomers" | "userrooms">;
      tmp = {
        pkusers: selectedUser.pkusers,
        username: name,
        email: email,
        isactive: isActive,
        isadmin: isAdmin,
        fklanguages: selectedUser.fklanguages,
        fkcustomers:selectedUser.fkcustomers,
        userrooms: []
      }

      let teams = []
      groupsNames.forEach(gn => teams.push(teamGroups.find(tg => tg.teamname === gn).pkteamgroups))

      let rooms = []
      roomsNames.forEach(rn => rooms.push(customerRoomsExludedGroups.find(cr => cr.roomName === rn).pkrooms))

      userForUpdate = {
        user: tmp,
        teamspks: teams,
        roomspks: rooms,
        usrgroups: usrGroups
      }

      dispatch(editUser(userForUpdate, meUser));
      closeForm();
      dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 16 }))
    }

    if(type === "Single") {
      let tmp: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "fklanguages" | "userrooms">
      tmp = {
        pkusers: 0,
        username: name,
        email: email,
        isactive: isActive,
        isadmin: isAdmin,
        fklanguages: 1,
        userrooms: []
      }
      let message : DialogMessageType = {
        dialogMsg :(<div>{t("Do you want to create this new user")}?</div>),
        dialogTitle : t("New user insertion"),
        dialogAcceptButton: t("Yes create"),
        dialogDismissButton: t("No cancel"),
        isOpen : true,
        onDismiss: () => {},
        onAccept: () => {
          dispatch(addUser(tmp));
          dispatch(updateUIState({type: "SET_RUN", run: false, stepIndex: 11}))
          closeForm();
        }
      }
      dispatch(getDialogMessage(message));
    }

    if(type === "Massive") {
      if(usersToAdd != null && usersToAdd.length > 0) {
        dispatch(addUsers(usersToAdd));
        dispatch(updateUIState({type: "SET_RUN", run: false, stepIndex: 11}))
        closeForm();
      }
    }

    if(type === "SuperAdmin") {
      let tmp: Omit<SA_User, "pkusers">
      tmp = {
        customername: customerName,
        email: email,
        isactive: isActive,
        isadmin: isAdmin,
        username: name,
      }

      const pkcustomer = allCustomersPkAndName.find(c => c.customername === customerName).pkcustomers;

      let message : DialogMessageType = {
        dialogMsg :(<div>{t("Do you want to create this new user")}?</div>),
        dialogTitle : t("New user insertion"),
        dialogAcceptButton: t("Yes create"),
        dialogDismissButton: t("No cancel"),
        isOpen : true,
        onDismiss: () => {},
        onAccept: () => {
          dispatch(addUserXClient(tmp, pkcustomer));
          closeForm();
        }
      }
      dispatch(getDialogMessage(message));
    }

    setTimeout(() => { dispatch(getHomePage()); }, 1000);
  }

  const handleGroupsNamesChange = (event: SelectChangeEvent<typeof groupsNames>) => {

    const {
      target: { value },
    } = event;
    const listOfGroupNames = typeof value === 'string' ? value.split(',') : value;
    setGroupsNames( listOfGroupNames );

    let teamGroupsFiltered = []
    listOfGroupNames.map(lgn => {
      teamGroupsFiltered.push(teamGroups.find(t => t.teamname == lgn))
    })
    let notInTeamGroup = CustomerAllRooms;
    teamGroupsFiltered.map((t) => {
      t.rooms.map((r) => {
        notInTeamGroup = notInTeamGroup.filter(n => n.roomName != r.roomname)
      })
    })
    setCustomerRoomsExludedGroups(notInTeamGroup);

  };

  const handleRoomsNamesChange = (roomPicked: string) => {
    const roomSelected = roomsNames;
    if(roomSelected.findIndex(r => r == roomPicked) >= 0){
      setRoomsNames(roomSelected.filter(o => o != roomPicked));
    }else{
      roomSelected.push(roomPicked)
      setRoomsNames(roomSelected.filter(o => o != ""));
    }
  };

  const handleRoomsNamesSelectAll = () => {
    if(customerRoomsExludedGroups.length == roomsNames.length){
      setRoomsNames([])
    }else{
      const selectAll = [];
      customerRoomsExludedGroups.map(c =>{
        selectAll.push(c.roomName)
      })
      setRoomsNames(selectAll);
    }
  }

  useEffect(() => {
    dispatch(getTeamGroups(meUser.fkcustomers))
  }, [dispatch, meUser.fkcustomers])

  useEffect(() => {
    let names = []
    let notInTeamGroup = CustomerAllRooms;

    if(teamGroups.length > 0){
      const teamGroupsFiltered =  teamGroups.filter(tg => tg.users.findIndex(u => u.pkusers === selectedUser?.pkusers) > -1);

      teamGroupsFiltered.map((t) => {
        t.rooms.map((r) => {
          notInTeamGroup = notInTeamGroup.filter(n => n.roomName != r.roomname)
        })
      })

      teamGroupsFiltered.forEach(tg => names.push(tg.teamname))
    }

    setCustomerRoomsExludedGroups(notInTeamGroup);
    setGroupsNames(names)
  }, [teamGroups, selectedUser,CustomerAllRooms])


  useEffect(() => {
    let names = []
    let namesFiltered = []

      if(roomsNames.length > 0){
        roomsNames.forEach(rn => names.push(rn))
      }


    if(customerRoomsExludedGroups.length > 0){
      customerRoomsExludedGroups.map((c) => {
        if(names.findIndex(n => n == c.roomName) > -1){
          namesFiltered.push(names.find(n => n == c.roomName))
        }
      })
    }else{
      namesFiltered = names;
    }

    setRoomsNames(namesFiltered)
  }, [customerRoomsExludedGroups])

  useEffect(() => {
    let names = []
    let namesFiltered = []

      if(roomUserSelected.length > 0){
        roomUserSelected.forEach(ru => names.push(ru.roomname))
      }


    if(customerRoomsExludedGroups.length > 0){
      customerRoomsExludedGroups.map((c) => {
        if(names.findIndex(n => n == c.roomName) > -1){
          namesFiltered.push(names.find(n => n == c.roomName))
        }
      })
    }else{
      namesFiltered = names;
    }

    setRoomsNames(namesFiltered)
  }, [roomUserSelected,selectedUser])

  useEffect(()=>{
    dispatch(getHomePage());
  },[tenantUsers, dispatch]);

  const handleFileChange = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf(".") + 1) === "xlsx") {
        setFileName(fileName)
        renderFile(fileObj)
      } else {
        dispatch(addAlertMessage({ type: "error", htmlMessage: t("File format not correct").toString()}))
      }
    }
  };

  function renderFile(fileObj: any): any {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err)
      } else {
        let users: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "isdeleted" | "fklanguages">[] = []
        resp.rows.forEach((item) => {
          if (item[0] !== "User" && item[0] !== null && item[0] !== undefined && item[1] !== null && item[1] !== undefined ) {
            let user: Pick<User, "pkusers" | "username" | "email" | "isactive" | "isadmin" | "isdeleted" | "fklanguages"> = {
              pkusers: 0,
              username: item[0],
              email: item[1],
              isactive: item[2] === undefined ? false : (item[2].toLowerCase() === "yes" || item[2].toLowerCase() === "si" || item[2].toLowerCase() === "sì" || item[2].toLowerCase() === "oui" || item[2].toLowerCase() === "sí" || item[2] === "1") ? true : false,
              isadmin:  item[3] === undefined ? false : (item[3].toLowerCase() === "yes" || item[3].toLowerCase() === "si" || item[3].toLowerCase() === "sì" || item[3].toLowerCase() === "oui" || item[3].toLowerCase() === "sí" || item[3] === "1") ? true : false,
              isdeleted: false,
              fklanguages: 2
            }
            //Per evitare che vada in errore con .toLowerCase() se non è stato inserito nulla lo confronto con vuoto invece di item[4]
            switch((item[4] === undefined ? "" : item[4]).toLowerCase()){
              case "it":
                user.fklanguages = 1;
               break;
              case "es":
                user.fklanguages = 3;
              break;
              case "fr":
                user.fklanguages = 4;
              break;
              default://Nel caso non ne venga scelta nessuna lingua di default metto Inglese
                user.fklanguages = 2;
              break;
            }
            users.push(user)
          }
        })
        setUsersToAdd(users)
      }
    });
  }

  const cancelClicked = () => {
    let message : DialogMessageType = {
      dialogMsg :(<div>{t("Close From Alert")}</div>),
      dialogTitle : t("Attention"),
      dialogAcceptButton: t("Yes close"),
      dialogDismissButton: t("No go back"),
      isOpen : true,
      onDismiss: () => {},
      onAccept: () => {
        dispatch(updateUIState({type: "SET_RUN", run: false, stepIndex: 10}))
        closeForm();
      }
    }
    dispatch(getDialogMessage(message));
  }

  const cancelClickedEdit = () => {
    dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex: 13 }));
    closeForm();
  }

  const closeForm = () => {
    setName("")
    setEmail("")
    setIsActive(true)
    setIsAdmin(false)
    setFileName("")
    handleClose()
  }
  useEffect(() => {
    dispatch(getAllCustomersPkAndName())
  },[])

  useEffect(() => {
    dispatch(getUsrGroups())    
    if(selectedUser != null){      
      dispatch(getUsersUsrGroupsAdmin(selectedUser.useridentify))
      dispatch(GetCustomersAllRooms(user.fkcustomers))
      dispatch(getUserRoomsSelectedUser(selectedUser.pkusers))
    }
  }, [dispatch, selectedUser])

  useEffect(() => {
    if(selectedUser !== null) {
      setName(selectedUser.username)
      setEmail(selectedUser.email)
      setIsActive(selectedUser.isactive)
      if(selectedUser.functionalities?.findIndex(f=>f.nome==="Admin") === -1){
        setIsAdmin(false);
      }else{
        setIsAdmin(true);
      }

    } else if (selectedUser === null) {
      setName("")
      setEmail("")
      setIsActive(true)
      setIsAdmin(false)
    }
  }, [selectedUser])

  useEffect(() => {
    if(usrgroupslist && selectedUser && usersusrgroups) {      
        setUsrGroups(usersusrgroups.fkusrgroups)
    }
  }, [selectedUser, usrgroupslist, usersusrgroups])

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        onClose={type === "Single" ? cancelClicked : closeForm}
        className={style["MuiPaper-root"]}
      >
        <div className="container-fluid ps-4">
          <div className="row mt-4 mb-4">
            <div className="col">
              {type === "Edit" &&
                <h3 className="primary">{t("Edit user")}</h3>
              }
              {(type === "Single" || type === "SuperAdmin") &&
                <h3 className="primary">{t("New user")}</h3>
              }
              {type === "Massive" &&
                <h3 className="primary">{t("Users import")}</h3>
              }
            </div>
          </div>
          {(type === "SuperAdmin") &&
            <div className="row mb-4 w-100">
              <div className="col">
                <Autocomplete
                  value={customerName}
                  onChange={(e: any, val: string) => setCustomerName(val)}
                  size="small"
                  renderInput={(params) => <TextField {...params} label="Cliente" />}
                  options={allCustomersPkAndName.map(r => r.customername)}
                  fullWidth
                />
              </div>
            </div>
          }
          <div className="row mb-4 w-100">
            <div className="col">
              {type === "Massive" &&
                <a className={cn(style["greenText"], "m-0 p-0")} href={require("../../../Templates/Utenti.xlsx")} download>
                  <FontAwesomeIcon icon={faFileExcel} size="2x" className="me-2 ms-1"/>
                  {t("Download template")}
                </a>
              }

              {(type === "Single" || type === "Edit" || type === "SuperAdmin") &&
                <TextField
                  value={name}
                  onChange={handleNameChange}
                  size="small"
                  label={t("Full name")}
                  fullWidth
                />
              }
            </div>
          </div>
          {(type === "Single" || type === "Edit" || type === "SuperAdmin") &&
            <div className="row w-100">
              <div className="col-12 mb-4">
                <TextField
                  value={email}
                  onChange={handleEmailChange}
                  size="small"
                  label="Email"
                  fullWidth
                  disabled={type === "Edit"}
                />
              </div>
              <div className="col-12 mb-3 d-flex">
                <FormControlLabel control={
                  <Switch
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                  />
                }
                label={t("Active").toString()}
                labelPlacement="top"/>
                <p className="align-items-end d-flex mb-2 ms-1">{isActive ? t("Yes") : t("No")}</p>
              </div>
              {type === "Edit" &&
                <div className="col-12 mb-4 d-flex">
                  {usrGroups != undefined ? 
                  <FormControl className='w-100'>
                    <InputLabel id="profile-select-label">{t("Profile")}</InputLabel>
                    <Select
                      labelId="profile-select-label"
                      value={usrGroups}

                      input={<OutlinedInput label={t("Profile")} />}
                      MenuProps={MenuProps}
                      disabled={meUser.functionalities.findIndex(f => f.nome === "Add_user_to_group") === -1}
                      data-joyride="user-profile"
                    >
                      {
                        (user.ispremium ? usrgroupslist : usrgroupslist.filter((u) => u.pkusrgroups != 16 && u.pkusrgroups != 17 && u.pkusrgroups != 18 && u.pkusrgroups != 20 && u.pkusrgroups != 21)).map((ugl) => (
                          <MenuItem onClick={() => setUsrGroups(ugl.pkusrgroups)} key={ugl.pkusrgroups} value={ugl.pkusrgroups}>
                            {ugl.nome}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  : <> </> }
                </div>
              }
              {type === "Single" &&
                <div className="col-12 mb-4 d-flex">
                  <FormControlLabel control={
                    <Switch
                      checked={isAdmin}
                      onChange={() => setIsAdmin(!isAdmin)}
                    />
                  }
                  label={t("Administrator").toString()}
                  labelPlacement="top"/>
                  <p className="align-items-end d-flex mb-2 ms-1">{isAdmin ? t("Yes") : t("No")}</p>
                </div>
              }
              {type === "Edit" &&
                <div className="col-12 mb-4 d-flex">
                  <FormControl className="w-100">
                    <InputLabel id="group-users-select-label">{t("User Groups")}</InputLabel>
                    <Select
                      labelId="group-users-select-label"
                      multiple
                      value={groupsNames}
                      onChange={handleGroupsNamesChange}
                      input={<OutlinedInput label={t("User Groups")} />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      disabled={meUser.functionalities.findIndex(f => f.nome === "Add_user_to_group") === -1}
                      data-joyride="user-group"
                    >
                      {teamGroups && teamGroups.map((tg) => (
                        <MenuItem key={tg.pkteamgroups} value={tg.teamname}>
                          <Checkbox checked={groupsNames.indexOf(tg.teamname) > -1} />
                          <ListItemText primary={tg.teamname} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              }
              {type === "Edit" &&
                <div className="col-12 mb-4 d-flex">
                  <FormControl className="w-100">
                    <InputLabel id="associated-rooms-select-label">{t("Associated rooms")}</InputLabel>
                    <Select
                      labelId="associated-rooms-select-label"
                      multiple
                      value={roomsNames}
                      input={<OutlinedInput label={t("Associated rooms")} />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      disabled={meUser.functionalities.findIndex(f => f.nome === "Add_room_to_user") === -1}
                      data-joyride="associated-rooms"
                    >
                      <MenuItem  onClick={() => handleRoomsNamesSelectAll()}><Checkbox checked={customerRoomsExludedGroups.length == roomsNames.length} /><ListItemText primary={t("Select All")} /></MenuItem>
                      {customerRoomsExludedGroups && customerRoomsExludedGroups.map((cr,i,arr) => (
                        <div>
                            {(i == 0 || arr[i-1].siteName != cr.siteName ) ? <ListSubheader className={cn(style["site-label"])} value={cr.siteName.toString()}>{cr.siteName}</ListSubheader> : <></>}
                            <MenuItem onClick={() => handleRoomsNamesChange(cr.roomName)} key={cr.pkrooms} value={cr.roomName}>
                              <Checkbox checked={roomsNames.indexOf(cr.roomName) > -1} />
                              <ListItemText primary={cr.roomName} />
                            </MenuItem>
                        </div>
                      ))}
                    </Select>
                  </FormControl>

                </div>
              }
            </div>
          }
          {type === "Massive" &&
            <div className="row w-100">
              <div className="row ms-1">
                <div className="col p-0">
                  <p className="p-0 m-0">{t("Pick excel file")}</p>
                </div>
              </div>
              <div className="row ms-1 w-100">
                <div className="col-7 p-0 m-0">
                  <label className={cn(style["fakeInput"])}>
                    {fileName}
                  </label>
                </div>
                <div className="col-5 d-flex p-0 m-0">
                  <label
                    htmlFor="fileInput"
                    className={cn(style["customFileUpload"], "bgc-primary")}>
                    {t("Choose file")}
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    className={cn("bgc-primary", style["fileInput"])}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          }
          <div className={cn("row", style["buttons"])}>
            <div className="col">
              <Button
                variant="contained"
                onClick={type === "Single" ? cancelClicked : cancelClickedEdit}
                color="secondary"
                className={style["secondaryBtn"]}>
                {t("Cancel")}
              </Button>
              <Button
                color={"primary"}
                variant="contained"
                onClick={handleSave}
                className="ms-2">
                {type === "Edit" && t("Save")}
                {(type === "Single" || type === "SuperAdmin") && t("Insert")}
                {type === "Massive" && t("Import")}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default UserForm;