import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import "./App.scss";
import { submenu } from "./api/const/submenu";
import DialogMessage, { DialogMessageType } from "./core/components/dialog/dialog-message";
import { getDialogMessage } from "./core/components/dialog/store/dialogMessage.store";
import Loader from "./core/components/loader/Loader";
import AlertMessage from "./core/components/message-bar/AlertMessage";
import PopupDesktop from "./core/components/popup/PopupDesktop";
import RoomseatsMapDialog from "./core/components/roomseats-map/RoomseatsMapDialog";
import StartupGuide from "./core/components/startup-guide/StartupGuide";
import Toolbar from "./core/components/toolbar/Toolbar";
import { ToolbarSubmenu, setToolbarSubmenu, toggleRenderToolbar } from './core/components/toolbar/store/toolbar.store';
import { login, saveLogin } from "./core/store/auth/auth.actions";
import { getCurrentLicense } from "./core/store/customer/customer.action";
import { getMe } from './core/store/room/user-room/user-room.actions';
import { setShowPopupGuide } from "./core/store/startup/startup.store";
import { RootState } from "./core/store/store";
import { getUserReservations, getUserSettings, hideAppTour } from './core/store/users/users.actions';
import GuardRoute from "./guard/GuardRoute";
import MsTeamsRoute from "./guard/MsTeamsRoute";
import useMsTeams from "./hooks/useMsTeams";
import i18n from "./i18n/i18n";
import { CustomizeApp } from "./pages/customize-app/CustomizeApp";
import { GroupsManagement } from "./pages/groups-management/GroupsManagement";
import AlertLicenseExpired from "./pages/home/AlertLicenseExpired/AlertLicenseExpired";
import AlertLicensesRatio from "./pages/home/AlertLicensesRatio/AlertLicensesRatio";
import AlertLicenseExpiredRenewal from "./pages/home/AlertLiceseExpiredRenewal/AlertLicenseExpiredRenewal";
import AlertNewCustomer from "./pages/home/AlertNewCustomer/AlertNewCustomer";
import AlertUserDoNotExists from "./pages/home/AlertUserDoNotExists/AlertUserDoNotExists";
import AlertUserLoggedOut from "./pages/home/AlertUserLoggedOut/AlertUserLoggedOut";
import AlertUserNotActive from "./pages/home/AlertUserNotActive/AlertUserNotActive";
import Home from "./pages/home/Home";
import { Report } from "./pages/report/Report";
import ReserveSeat from "./pages/reserve-seat/reserve-seat";
import { RoomsManagement } from "./pages/rooms-management/RoomsManagement";
import Settings from "./pages/settings/Settings";
import { SACustomersManagement } from './pages/superadmin/customers-management/SACustomersManagement';
import { SALicensesManagement } from './pages/superadmin/licenses-management/SALicensesManagement';
import { SAUsersManagement } from './pages/superadmin/users-management/SAUsersManagement';
import Tutorials from "./pages/tutorials/Tutorials";
import UserReservation from "./pages/user-reservation/UserReservation";
import UsersManagement from "./pages/users-management/UsersManagement";
import { Whereabouts } from "./pages/whereabouts/Whereabouts";
import { abortController } from "./utils";

import { doNothing } from "@mui/x-date-pickers/internals/utils/utils";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import SeatsDialog from "./core/components/SeatsDialog/SeatsDialog";
import { updateUIState } from "./core/store/ui/ui.store";
import AlertPendingCustomer from "./pages/home/AlertPendingCustomer/AlertPendingCustomer";
import { ReservationsManagementLimits } from "./pages/reservations-management-limits/reservations-management-limits";
import { ReservationsManagement } from "./pages/reservations-management/reservations-management";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { renderToolbar } = useSelector((state: RootState) => state.toolbar)
  const { isMsTeams } = useMsTeams();
  const { language } = useSelector((state: RootState) => state.users)
  const { token, user } = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation();
  const {homePage} = useSelector((state:RootState)=>state.felogic);
  const { page, stepIndex, run } = useSelector((state: RootState) => state.ui);

  let steps = [];
  if(user.showapptour){
    steps = [
      {
        title: t("Tooltip title 1"),
        target: '[data-joyride=admin]',
        content: t("Tooltip content 1")
      },
      {
        title: t("Tooltip title 2"),
        target: 'button[data-joyride=btn-create-site]',
        content: t("Tooltip content 2"),
        placement: 'bottom' as const,
      },
      {
        title: t("Tooltip title 3"),
        target: '[data-joyride=btn-create-room]',
        content: t("Tooltip content 3"),
        placement: 'bottom' as const,
      },
      {
        content: t("Tooltip content 4"),
        target: '[data-joyride=belonging-site]',
        disableOverlay: true,
        placement: 'right' as const,
        styles: {
          options: {
            zIndex: 10000,
          }
        }
      },
      {
        content: t("Tooltip content 5"),
        target: '[data-joyride=room-icon]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 6"),
        target: '[data-joyride=room-image]',
        disableOverlay: true,
        placement: 'right' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 7"),
        target: '[data-joyride=room-name]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 8"),
        target: '[data-joyride=room-seats]',
        disableOverlay: true,
        placement: 'right' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: <div><span>{t("Tooltip content 9")}</span></div>,
        target: '[data-joyride=room-description]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 10"),
        target: '[data-joyride="manage"]',
        disableOverlay: true,
        placement: 'left' as const
      },
      {
        content: t("Tooltip content 11"),
        target: '[data-joyride="btn-add-user"]',
        disableOverlay: true,
        placement: 'right' as const
      },
      {
        content: t("Tooltip content 12"),
        target: '[data-joyride="sync-ad"]',
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 13"),
        target: '[data-joyride="user-active"]',
        disableOverlay: true,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 14"),
        target: '[data-joyride="user-edit"]',
        styles: {
          buttonNext: {
            display: 'none'
          },
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 15"),
        target: '[data-joyride="user-profile"]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 16"),
        target: '[data-joyride="user-group"]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 17"),
        target: '[data-joyride="manage"]',
        disableOverlay: true,
        placement: 'left' as const
      },
      {
        content: t("Tooltip content 18"),
        target: '[data-joyride="add-group"]',
        styles: {
          buttonNext: {
            display: 'none'
          }
        }
      },
      {
        content: t("Tooltip content 19"),
        target: '[data-joyride="group-name"]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 20"),
        target: '[data-joyride="group-users"]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: t("Tooltip content 21"),
        target: '[data-joyride="group-rooms"]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: <div><span>{t("Tooltip content 22")}</span></div>,
        target: '[data-joyride=user-description]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: <div><span>{t("Tooltip content 23")}</span></div>,
        target: '[data-joyride=shift-description]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
      {
        content: <div><span>{t("Tooltip content 24")}</span></div>,
        target: '[data-joyride=seat-description]',
        disableOverlay: true,
        placement: 'left' as const,
        styles: {
          options: {
            zIndex: 10000
          }
        }
      },
    ]
  }

  const setPage = (page: string) => {
    dispatch(updateUIState({ type: "SET_PAGE", page }));
  };

  const setStepIndex = (stepIndex: number) => {
    dispatch(updateUIState({ type: "SET_STEP_INDEX", stepIndex }));
  };

  const setRun = (run: boolean, stepIndex?: number) => {
    if(stepIndex != null){
      dispatch(updateUIState({ type: "SET_RUN", run, stepIndex }))
    } else {
      dispatch(updateUIState({ type: "SET_RUN", run }))
    }
  }

  const handleJoyrideCallback = (data?: CallBackProps) => {
      const { action, index, type, status } = data;
      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
        setRun(false, 0);
        // Salvo su db che l'utente non vuole vedere il tour al prossimo avvio
        dispatch(hideAppTour());
      }
      else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
        let newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        if(newIndex == 7 && user.ispremium){
          newIndex = newIndex + (action === ACTIONS.PREV ? -1 : 1);
        }
        if(index==0 && action === ACTIONS.NEXT){
          setRun(false, 1);
          history.push("/administration-rooms");
          setTimeout(() => {
            setRun(true);
          }, 1000);
        } else if(index == 1 && action === ACTIONS.PREV){
          setRun(false, 0);
          history.push("/")
          setTimeout(() => {
            setRun(true, 0)
          }, 5000);
        }
        else if((index == 2 || index == 8 || index == 10 || index == 15) && action === ACTIONS.NEXT){
          //metto in pausa fino a quando l'utente apre la modale (index 2 e 10)
          //aspetto che l'utente salvi la stanza prima di procedere con il tutorial di abilitazione utenti (index 8)
          setRun(false);
        }else if(index == 22 || index == 23 || index == 24){
          doNothing()
        }
        else {
          setStepIndex(newIndex);
        }
      }
      //console.info(type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type, data);
  };

  const joyrideStyles = {
    options: {
      primaryColor: '#0C5468',
      textColor: '#0C5468',
      beaconSize: 25
    },
    tooltip: {
      padding: 0,
      borderRadius: 8
    },
    tooltipContainer: {
      padding: 15,
    },
    tooltipTitle: {
      fontSize: 20,
      color: "#0C5468",
      marginTop: 5
    },
    tooltipContent: {
      padding: '15px 10px 5px 10px',
      color: '#0A0A0A'
    },
    tooltipFooter: {
      marginTop: 0,
      padding: 8,
      backgroundColor: "#0C54681A"
    }
  }

  useEffect(() => {
  }, [page, stepIndex, run])


  history.listen(()=>{
    abortController.abortPendingRequest();
  });

  useEffect(() => {
    if (isMsTeams && user) {
      i18n.changeLanguage(language ? language.langcode : "en");
      moment.locale(language ? language.langcode : "en");
    }
    // // Redirect from localhost to Teams WebApp
    // if (!isMsTeams){
    //   if(isOpen) window.open("https://teams.microsoft.com/", "_self").focus()
    //   setIsOpen(true)
    // };

  }, [user, isMsTeams, language, dispatch]);

  useEffect(() => {
    if (isMsTeams) {
      dispatch(toggleRenderToolbar(isMsTeams));
    }
  }, [dispatch, isMsTeams]);

  useEffect(() => {
    if(user && user.functionalities && user.functionalities.length > 0) {
      let elements: ToolbarSubmenu[] = []
      elements.push(...submenu.filter(x => x.title === "Home"));

      if (user.functionalities.findIndex(f => f.nome === "Book_shift_in_room") >= 0) {
        elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "My reservations"));
      }
      if (user.functionalities.findIndex(f => f.nome === "Whereabouts") >= 0) {
        elements.push(...submenu.filter(x => x.title === "Whereabouts"));
      }

      if (user.functionalities.findIndex(f => f.nome === "Admin") >= 0)
      {
        elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Admin"));

        if (user.functionalities.findIndex(f => f.nome === "Manage_Admin") >= 0){
          if(user.ispremium){
            let manage = structuredClone(submenu.find((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "ManagePremium"));
            if(manage){
              manage.dropDownMenu.menuItems = manage.dropDownMenu.menuItems.filter((m) =>
                user.functionalities.findIndex(f => f.nome === m.functionalities) >= 0
              );
              elements.push(manage);
            }
          }else{
            let manage = structuredClone(submenu.find((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Manage"));
            if(manage){
              manage.dropDownMenu.menuItems = manage.dropDownMenu.menuItems.filter((m) =>
                user.functionalities.findIndex(f => f.nome === m.functionalities) >= 0
              );
              elements.push(manage);
            }
          }
        }
        if (user.functionalities.findIndex(f => f.nome === "View_Report") >= 0)
          elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Report"));

        if (user.functionalities.findIndex(f => f.nome === "SuperAdmin") >= 0)
          elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Superadmin"));

        if (user.functionalities.findIndex(f => f.nome === "SA_User_Management") >= 0)
          elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Gestione Utenti"));

        if (user.functionalities.findIndex(f => f.nome === "SA_Customer_Management") >= 0)
          elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Gestione Clienti"));

        if (user.functionalities.findIndex(f => f.nome === "SA_License_Management") >= 0)
          elements.push(...submenu.filter((x) => x.visibleOnlyInPath.indexOf(location.pathname) >= 0 && x.title === "Gestione Licenze"));
      }
      dispatch(setToolbarSubmenu(elements))
    }
  }, [location.pathname, user, dispatch]);

  useEffect(() => {
    dispatch(saveLogin("enteredTheApp"))
    dispatch(login())
  }, [dispatch])

  useEffect(() => {

    if(
      token !== "" &&
      token !== null &&
      homePage !== "07" &&
      homePage !== "09" &&
      homePage !== "00" &&
      homePage !== ""
    ) {
      dispatch(getMe())
      dispatch(getUserSettings())
      dispatch(getUserReservations())
      dispatch(getCurrentLicense())
    }
  }, [token, dispatch, homePage])


  useEffect(()=>{
    if(homePage === "01" && user.functionalities?.findIndex(f => f.nome === "Admin") >= 0){
      let message : DialogMessageType = {
        dialogMsg :(<div>{t("More user than licenses")}</div>),
        dialogTitle : t("Attention"),
        dialogAcceptButton: t("Ok"),
        isOpen : true,
        onDismiss: () => {},
        onAccept: () => {}
      }
      dispatch(getDialogMessage(message));
    }
  },[homePage, user, t, dispatch]);

  useEffect(() => {
    if(user.showstartuppopup) {
      dispatch(setShowPopupGuide(true))
    }
  }, [user])

  return (
    <MsTeamsRoute isMsTeams={isMsTeams}>
      <>
        <Loader />
        {user.showapptour && <Joyride
          run={run}
          steps={steps}
          continuous
          stepIndex={stepIndex}
          callback={handleJoyrideCallback}
          disableOverlayClose
          disableCloseOnEsc
          spotlightClicks
          styles={joyrideStyles}
        />}

        {/* ToolBar */}
        {(() => {
          switch(homePage) {
            case "02":{
              if(renderToolbar && location.pathname !== "/reserve-seat" && user.isactive && !user.showstartuppopup){
                return(
                  <Toolbar/>
                )
              }
              break;
            }
          }
        })()}

        {/* Content */}
        {(() => {

          switch(homePage){
            case ("00"):{ // NEW CUSTOMER
              return(
                <AlertNewCustomer/>
              );
            }

            case("07"):{ //Alert user do not exists
              return(
                <AlertUserDoNotExists />
              );
            }

            case("09"):{ //Alert user logged out
              return(
                <AlertUserLoggedOut />
              );
            }

            case("10"):{
              return <AlertPendingCustomer />
            }
          }

          if(user && user.functionalities){
            switch(homePage){
              case("01"):{// LICENSE RATIO
                if(user.functionalities.findIndex(f => f.nome === "Admin") >= 0){
                  return(
                    <UsersManagement />
                  );
                }else{
                  return(
                    <AlertLicensesRatio/>
                  );
                }
              }

              case("02"):{ // HOME
                return(user.showstartuppopup ?
                  <StartupGuide />
                  :
                  <>
                    <Switch>
                      <GuardRoute userLogged={true} path={"/home"} userCan={true} exact>
                        <Home/>
                      </GuardRoute>
                      <GuardRoute userLogged={!!user} path={"/reservations"} userCan={true} exact>
                        <UserReservation/>
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/whereabouts"} userCan={user.functionalities.findIndex(f => f.nome === "Whereabouts") >= 0} exact>
                        <Whereabouts/>
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/reserve-seat"} userCan={true} exact>
                        <ReserveSeat/>
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/administration-rooms"} userCan={true} exact>
                        <RoomsManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/administration-groups"} userCan={true} exact>
                        <GroupsManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/reservations-management"} userCan={user.ispremium} exact>
                        <ReservationsManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/reservations-management-limits"} userCan={user.ispremium} exact>
                        <ReservationsManagementLimits />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/administration-report"} userCan={true} exact>
                        <Report />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/administration-customize"} userCan={true} exact>
                        <CustomizeApp />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/administration-users"} userCan={user.functionalities.findIndex(f => f.nome === "Admin") >= 0} exact>
                        <UsersManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/settings"} userCan={user.isactive} exact>
                        <Settings />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/tutorials"} userCan={user.isactive} exact>
                        <Tutorials />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/superadmin-users"} userCan={user.isactive} exact>
                        <SAUsersManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/superadmin-customers"} userCan={user.isactive} exact>
                        <SACustomersManagement />
                      </GuardRoute>

                      <GuardRoute userLogged={!!user} path={"/superadmin-licenses"} userCan={user.isactive} exact>
                        <SALicensesManagement />
                      </GuardRoute>

                      <Redirect to={"/home"} />
                    </Switch>
                  </>
                );
              }

              case("03"):{ // Demo Expired
                return(
                  <AlertLicenseExpired/>
                );
              }

              case("04"):{ // Demo Expired Remewal
                return(
                  <AlertLicenseExpiredRenewal/>
                );
              }

              case("05"):{ // License Expired
                return(
                  <AlertLicenseExpired/>
                );
              }

              case("06"):{ //License Expired Renewal
                return(
                  <AlertLicenseExpiredRenewal/>
                );
              }

              case("08"):{ //User not active
                return(
                  <AlertUserNotActive/>
                );
              }
            }
          }
        }
      )()}
        <AlertMessage />
        <DialogMessage />
        <PopupDesktop />
        {
          token !== "" && token !== null && <>
            <RoomseatsMapDialog />
            <SeatsDialog/>
          </>
        }
      </>
    </MsTeamsRoute>
  );
};

export default App;
