import { faUser, faUserFriends } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Chip, Grid, ListItemText, MenuItem, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { ControlledAutocomplete } from "../../../../core/components/mui-input/ControlledAutocomplete";
import { RootState } from "../../../../core/store/store";
import { TeamGroups } from "../../../../models/teamgroups";
import { User } from "../../../../models/user";

interface RoomUserFormProps{
  control: any;
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  users: User[];
  groups: TeamGroups[];
  setGroups: React.Dispatch<React.SetStateAction<TeamGroups[]>>;
  typeRoom: ("New" | "Duplicate" | "Update");
}
export interface UsersAndGroups{
  pk: number,
  displayName: string,
  type: ("Groups" | "Users" | "SELECT-ALL-USERS" | "SELECT-ALL-GROUPS"),
}
export interface RoomFormUserFields{
  users: User[];
}

export const RoomUserForm: React.FC<RoomUserFormProps> = ({control, setUsers, users, groups, setGroups,typeRoom}) => {
  const SELECTALLUSERS: UsersAndGroups = {pk:0,displayName: "Select all users", type: "SELECT-ALL-USERS"};
  const SELECTALLGROUPS: UsersAndGroups = {pk:0,displayName: "Select all groups", type: "SELECT-ALL-GROUPS"};

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        backgroundColor: 'transparent',
        padding: '0'
    },
  });

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { tenantUsers } = useSelector((state: RootState) => state.users);
  const { user } = useSelector((state: RootState) => state.auth);
  const { teamGroups } = useSelector((state: RootState) => state.groups);
  const [ usersAndGroups, setUsersAndGroups] = useState<UsersAndGroups[]>([]); //Containes the groups and users of the tenant (all of them)
  const [ valueUsersAndGroups, setValueUsersAndGroups] = useState<UsersAndGroups[]>([]); //Contain the groups and users associated to the room

  const onDeleteUser = (pkuser: number) => () => {
    setUsers((oldUsers) => oldUsers.filter((u) => u.pkusers !== pkuser));
    setValueUsersAndGroups((oldUAG)=> oldUAG.filter(uag=> uag.pk !== pkuser && uag.type !== "SELECT-ALL-USERS"));
  };

  const onDeleteGroup = (pkteamsgroup: number) => () => {
    setGroups((g) => g.filter((group) => group.pkteamgroups !== pkteamsgroup));
    setValueUsersAndGroups((oldUAG)=> oldUAG.filter(uag=> uag.pk !== pkteamsgroup && uag.type !== "SELECT-ALL-GROUPS"));
  };


  //populate usersAndGroups (used for render the list of option in autocomplete in RoomUserForm.tsx)
  useEffect(()=>{
    const tmpUsersAndGroups: UsersAndGroups[] = [];

    tmpUsersAndGroups.push(SELECTALLGROUPS, SELECTALLUSERS);

    tenantUsers.forEach(u=>{
      tmpUsersAndGroups.push({pk: u.pkusers, displayName: u.username, type: "Users"});
    });

    teamGroups.forEach(t=>{
      tmpUsersAndGroups.push({pk: t.pkteamgroups, displayName: t.teamname, type: "Groups"});
    });

    setUsersAndGroups(tmpUsersAndGroups);
  },[tenantUsers, teamGroups]);


  // Populate valueUsersAndGroups with users and groups already associated to the room (case change tab or just entered in the modal)
  useEffect(()=>{
    const tmpValueUsersAndGroups: UsersAndGroups[] = valueUsersAndGroups;

    if(users.length === tenantUsers.length){
      tmpValueUsersAndGroups.push(SELECTALLUSERS);
    }

    if(groups.length === teamGroups.length){
      tmpValueUsersAndGroups.push(SELECTALLGROUPS);
    }

    if(typeRoom==="New" && users.length===0){
      tmpValueUsersAndGroups.push({pk: user.pkusers, displayName: user.username, type: "Users"});
    }

    users.forEach(u=>{
      tmpValueUsersAndGroups.push({pk: u.pkusers, displayName: u.username, type: "Users"});
    });
    groups.forEach(t=>{
      tmpValueUsersAndGroups.push({pk: t.pkteamgroups, displayName: t.teamname, type: "Groups"});
    });

    setValueUsersAndGroups(tmpValueUsersAndGroups);
  }, [dispatch])



  //Populate users and groups as I select something from the autocomeplete (Except for removing a user or a group from the chip component)
  useEffect(()=>{
    const newUsers: User[] = [];
    const newGroups: TeamGroups[] = [];

    valueUsersAndGroups.forEach(v=>{
      if(v.type=== "Users"){
        newUsers.push(tenantUsers.find(u=>u.pkusers === v.pk));
      }else if(v.type==="Groups"){
        newGroups.push(teamGroups.find(g=>g.pkteamgroups === v.pk));
      }
    });

    setUsers(newUsers);
    setGroups(newGroups);
  },[valueUsersAndGroups]);


  const handleChange = (newValue: UsersAndGroups[]) =>{
    const oldIsSelectAllUsers:boolean = (valueUsersAndGroups.findIndex(v=>v.type==="SELECT-ALL-USERS") === -1)? false: true;
    const newIsSelectAllUsers: boolean = (newValue.findIndex(v=>v.type==="SELECT-ALL-USERS") === -1)? false: true;
    const oldIsSelectAllGroups:boolean = (valueUsersAndGroups.findIndex(v=>v.type==="SELECT-ALL-GROUPS") === -1)? false: true;
    const newIsSelectAllGroups: boolean = (newValue.findIndex(v=>v.type==="SELECT-ALL-GROUPS") === -1)? false: true;

    if(!oldIsSelectAllUsers && newIsSelectAllUsers) //Selezioni select-all-users
      setValueUsersAndGroups(newValue.filter(v=>v.type!=="Users").concat(tenantUsers.map(u=>({pk: u.pkusers, displayName: u.username, type: "Users"}))));

    else if(oldIsSelectAllUsers && !newIsSelectAllUsers) //Deselezioni select-all-users
      setValueUsersAndGroups(newValue.filter(v=>v.type!=="Users"));

    else if(!oldIsSelectAllGroups && newIsSelectAllGroups) //Selezioni select-all-groups
      setValueUsersAndGroups(newValue.filter(v=>v.type!=="Groups").concat(teamGroups.map(t=>({pk: t.pkteamgroups, displayName: t.teamname, type: "Groups"}))));

    else if(oldIsSelectAllGroups && !newIsSelectAllGroups) //Deselezioni select-all-groups
      setValueUsersAndGroups(newValue.filter(v=>v.type!=="Groups"));

    else if(tenantUsers.length === newValue.filter(v=>v.type==="Users").length && !newIsSelectAllUsers) //Check select-all-users when i select all of them
      setValueUsersAndGroups(newValue.concat([SELECTALLUSERS]));

    else if(tenantUsers.length !== newValue.filter(v=>v.type==="Users").length && newIsSelectAllUsers) //DeCheck select-all-users when all the users are not selected
      setValueUsersAndGroups(newValue.filter(v=>v.type !== "SELECT-ALL-USERS"));

    else if(teamGroups.length === newValue.filter(v=>v.type==="Groups").length && !newIsSelectAllGroups)
      setValueUsersAndGroups(newValue.concat([SELECTALLGROUPS]));

    else if(teamGroups.length !== newValue.filter(v=>v.type==="Groups").length && newIsSelectAllGroups)
      setValueUsersAndGroups(newValue.filter(v=>v.type !== "SELECT-ALL-GROUPS"));

    else
     setValueUsersAndGroups(newValue);

  }

  return (
    //Autocomplete Search Users
    <Grid container spacing={1} sx={{padding:"1.5% 1.5%"}} >
        <Grid item lg={12} md={12} xs={12} sm={12} style={{display: user.functionalities.findIndex(f => f.nome === "Add_User_To_Room") === -1 ? "none" : "block"}}>
            <div data-joyride="user-description">
              <ControlledAutocomplete
                name="users"
                size="small"
                control={control}
                multiple
                options={usersAndGroups}
                getOptionLabel={(option) => option.displayName}
                isOptionEqualToValue={(o,v)=> o.pk+o.type === v.pk+v.type}
                groupBy={(option)=> (option.type==="SELECT-ALL-GROUPS" || option.type === "SELECT-ALL-USERS")?"":t(option.type)}
                value={valueUsersAndGroups}
                onChange={(newValue) => handleChange(newValue as UsersAndGroups[])}
                renderTags={() => null}
                label={t("Users or group search")}
                renderOption={(e,option, {selected})=>(
                  <MenuItem key={option.type+option.pk} value={option.displayName} {...e}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={(option.type==="SELECT-ALL-GROUPS" || option.type === "SELECT-ALL-USERS")?t(option.displayName):option.displayName} />
                  </MenuItem>
                )}
                disableCloseOnSelect
              />
            </div>
        </Grid>
        {valueUsersAndGroups.length === 0 &&
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '27vh' }}
          >
              <Grid item>
                  <span className="fw-bold">{t("No Association")}</span>
              </Grid>
              <Grid item>
                  <span>{t("Search for a user or a group")}</span>
              </Grid>
          </Grid>
        }
        {valueUsersAndGroups.length > 0  &&
          <Grid container spacing={1} className="mt-2 user-grid" sx={{padding:"1.5% 1.5%", height:"30vh", overflow:"auto"}} >
              {valueUsersAndGroups.map((u) => (u.type==="SELECT-ALL-GROUPS" || u.type === "SELECT-ALL-USERS")?(""):(
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <CustomWidthTooltip
                    disableInteractive
                    placement='top'
                    enterDelay={1000}
                    title={u.displayName.length > 20 ?
                      <div
                        className="ps-1 pe-1 d-flex"
                        style={{width: "auto", height: "25px", color: "black", backgroundSize: "100% 100%", borderRadius: "5px", backgroundColor: "darkgray", fontSize: "15px", alignItems: "center"}}>{u.displayName}
                      </div> : ""}
                    >
                    <div className="col">
                      <Chip
                        key={u.type+u.pk}
                        label={u.displayName.length > 20 ? u.displayName.substring(0,20) + "..." : u.displayName}
                        onDelete={user.functionalities.findIndex(f => f.nome === "Remove_User_From_Room") === -1 ? null : (u.type === "Users")? onDeleteUser(u.pk): onDeleteGroup(u.pk)}
                        icon={<FontAwesomeIcon icon={u.type === "Groups" ? faUserFriends : faUser}></FontAwesomeIcon>}
                      />
                    </div>
                  </CustomWidthTooltip>
                </Grid>
              ))}
          </Grid>
        }
    </Grid>
  );
};
